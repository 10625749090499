<!--
 * @Description: 会员管理
-->
<template>
  <div>
    <div class="TableTop">
      <!-- 搜索 -->
      <div class="TableSearch">
        <el-form :model="query" @submit.native.prevent>
          <el-form-item label="时间" class="form-item-b0">
            <div class="block">
              <el-date-picker
                v-model="query.date"
                align="right"
                type="date"
                placeholder="选择日期"
                format="yyyy 年 MM 月 dd 日"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </div>
          </el-form-item>

          <el-form-item label="类名" class="form-item-b0">
            <el-input v-model="query.category2" placeholder="请输入类名" size="small" clearable></el-input>
          </el-form-item>

          <el-form-item label="名称" class="form-item-b0">
            <el-input v-model="query.title" placeholder="请输入名称" size="small" clearable></el-input>
          </el-form-item>

          <el-form-item label="切换" class="form-item-b0">
            <el-select v-model="query.category1" placeholder="请选择" @change="getList('place')">
              <el-option label="烟" value="全部"></el-option>
              <el-option label="国酒" value="国酒"></el-option>
              <el-option label="洋酒" value="洋酒"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="TableSearch_btn">
          <el-button type="primary" size="small" @click="getList('place')">筛选</el-button>
          <el-button size="small" @click="init()">重置</el-button>
        </div>
      </div>
    </div>
    <!-- 表格 -->
    <el-table ref="TableListRef" class="TableList" border :data="list" v-loading="loading">
      <el-table-column prop="Id" label="Id" align="center"></el-table-column>

      <el-table-column prop="Title" label="名称" align="center"></el-table-column>

      <el-table-column prop="OfferValue" label="报价" align="center" v-if="query.category1 !== '全部'">
        <template slot-scope="scope" v-if="query.category1 !== '全部'">
          <div
            :style="{
              color: Math.abs(scope.row.OfferValue - 0 - (scope.row.RetrieveValue - 0)) > 50 ? 'red' : '#606266'
            }"
          >
            今日:{{ scope.row.OfferValue || 0 }}
          </div>
          <div
            :style="{
              color: Math.abs(scope.row.OfferValue - 0 - (scope.row.RetrieveValue - 0)) > 50 ? 'red' : '#606266'
            }"
          >
            昨日:{{ scope.row.RetrieveValue || 0 }}
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="OfferValue" label="流沙价" align="center" v-if="query.category1 === '全部'">
        <template slot-scope="scope" v-if="query.category1 === '全部'">
          <div
            :style="{
              color: Math.abs(scope.row.OfferValue - 0 - (scope.row.RetrieveValue - 0)) > 50 ? 'red' : '#606266'
            }"
          >
            今日:{{ scope.row.OfferValue || 0 }}
          </div>
          <div
            :style="{
              color: Math.abs(scope.row.OfferValue - 0 - (scope.row.RetrieveValue - 0)) > 50 ? 'red' : '#606266'
            }"
          >
            昨日:{{ scope.row.RetrieveValue || 0 }}
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="ZSJValue" label="珠三角" align="center" v-if="query.category1 === '全部'">
        <template slot-scope="scope" v-if="query.category1 === '全部'">
          <div
            :style="{
              color: Math.abs(scope.row.ZSJValue - 0 - (scope.row.ZSJRetrieveValue - 0)) > 50 ? 'red' : '#606266'
            }"
          >
            今日:{{ scope.row.ZSJValue || 0 }}
          </div>
          <div
            :style="{
              color: Math.abs(scope.row.ZSJValue - 0 - (scope.row.ZSJRetrieveValue - 0)) > 50 ? 'red' : '#606266'
            }"
          >
            昨日:{{ scope.row.ZSJRetrieveValue || 0 }}
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="SZValue" label="深圳" align="center" v-if="query.category1 === '全部'">
        <template slot-scope="scope" v-if="query.category1 === '全部'">
          <div
            :style="{
              color: Math.abs(scope.row.SZValue - 0 - (scope.row.SZRetrieveValue - 0)) > 50 ? 'red' : '#606266'
            }"
          >
            今日:{{ scope.row.SZValue || 0 }}
          </div>
          <div
            :style="{
              color: Math.abs(scope.row.SZValue - 0 - (scope.row.SZRetrieveValue - 0)) > 50 ? 'red' : '#606266'
            }"
          >
            昨日:{{ scope.row.SZRetrieveValue || 0 }}
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="SZValue2" label="深圳B" align="center" v-if="query.category1 === '全部'">
        <template slot-scope="scope" v-if="query.category1 === '全部'">
          <div
            :style="{
              color: Math.abs(scope.row.SZValue2 - 0 - (scope.row.SZRetrieveValue2 - 0)) > 50 ? 'red' : '#606266'
            }"
          >
            今日:{{ scope.row.SZValue2 || 0 }}
          </div>
          <div
            :style="{
              color: Math.abs(scope.row.SZValue2 - 0 - (scope.row.SZRetrieveValue2 - 0)) > 50 ? 'red' : '#606266'
            }"
          >
            昨日:{{ scope.row.SZRetrieveValue2 || 0 }}
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="Date" label="日期" align="center">
        <template slot-scope="scope">
          {{ new Date(scope.row.Date) | parseTime('{y}-{m}-{d}') }}
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" width="140" fixed="right">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onEditInfo(scope.row)"> 修改价格 </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="fenye">
      <el-pagination
        class="TablePagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageindex"
        :page-sizes="[20, 40, 60, 100]"
        :page-size="query.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <!-- 编辑价格 -->
    <el-dialog
      :visible.sync="editPrice"
      class="FormDialogBox"
      width="600px"
      title="编辑价格"
      :close-on-click-modal="false"
      @close="editPriceCancel()"
    >
      <el-form label-width="auto" :model="editForm" :rules="rules" ref="ruleForm">
        <div style="display: flex">
          <el-form-item label="Id" prop="Id">
            <el-input v-model="editForm.Id" disabled></el-input>
          </el-form-item>

          <el-form-item label="名称" prop="Title" style="margin-left: 10px">
            <el-input v-model="editForm.Title" disabled></el-input>
          </el-form-item>
        </div>

        <div style="display: flex">
          <el-form-item label="分类" prop="Title">
            <el-input v-model="editForm.Category2"></el-input>
          </el-form-item>

          <el-form-item label="厂名" prop="Factory" style="margin-left: 10px">
            <el-input v-model="editForm.Factory"></el-input>
          </el-form-item>
        </div>

        <div style="display: flex">
          <el-form-item label="规格" prop="Spec">
            <el-input v-model="editForm.Spec"></el-input>
          </el-form-item>

          <el-form-item label="爆珠" prop="Title" style="margin-left: 10px">
            <el-input v-model="editForm.Bead"></el-input>
          </el-form-item>
        </div>

        <div style="display: flex">
          <el-form-item label="代码" prop="FactoryCode">
            <el-input v-model="editForm.FactoryCode"></el-input>
          </el-form-item>

          <el-form-item label="待定" prop="BoxCode" style="margin-left: 10px">
            <el-input v-model="editForm.BoxCode" disabled></el-input>
          </el-form-item>
        </div>

        <template v-if="query.category1 !== '全部'">
          <div style="display: flex">
            <el-form-item label="今日" prop="OfferValue">
              <el-input v-model="editForm.OfferValue" placeholder=""></el-input>
            </el-form-item>

            <el-form-item label="昨日" prop="OfferValue" style="margin-left: 10px">
              <el-input v-model="editForm.RetrieveValue" placeholder=""></el-input>
            </el-form-item>
          </div>
        </template>

        <template v-if="query.category1 === '全部'">
          <div style="display: flex">
            <el-form-item label="流沙" prop="OfferValue">
              <el-input v-model="editForm.OfferValue" placeholder="流沙价格"></el-input>
            </el-form-item>
            <el-form-item label="流沙(昨日)" prop="RetrieveValue" style="margin-left: 10px">
              <el-input v-model="editForm.RetrieveValue" placeholder="流沙昨日价格"></el-input>
            </el-form-item>
          </div>

          <div style="display: flex">
            <el-form-item label="珠三角" prop="ZSJValue">
              <el-input v-model="editForm.ZSJValue" placeholder="珠三角价格"></el-input>
            </el-form-item>
            <el-form-item label="珠三角(昨日)" prop="ZSJRetrieveValue" style="margin-left: 10px">
              <el-input v-model="editForm.ZSJRetrieveValue" placeholder="珠三角昨日价格"></el-input>
            </el-form-item>
          </div>

          <div style="display: flex">
            <el-form-item label="深圳" prop="SZValue">
              <el-input v-model="editForm.SZValue" placeholder="深圳价格"></el-input>
            </el-form-item>
            <el-form-item label="深圳(昨日)" prop="SZRetrieveValue" style="margin-left: 10px">
              <el-input v-model="editForm.SZRetrieveValue" placeholder="深圳昨日价格"></el-input>
            </el-form-item>
          </div>

          <div style="display: flex">
            <el-form-item label="深圳B" prop="SZValue2">
              <el-input v-model="editForm.SZValue2" placeholder="深圳B价格"></el-input>
            </el-form-item>
            <el-form-item label="深圳B(昨日)" prop="SZRetrieveValue2" style="margin-left: 10px">
              <el-input v-model="editForm.SZRetrieveValue2" placeholder="深圳B昨日价格"></el-input>
            </el-form-item>
          </div>

          <div style="display: flex">
            <el-form-item label="湖南" prop="HNValue">
              <el-input v-model="editForm.HNValue" placeholder="湖南价格"></el-input>
            </el-form-item>
            <el-form-item label="湖南(昨日)" prop="HNRetrieveValue" style="margin-left: 10px">
              <el-input v-model="editForm.HNRetrieveValue" placeholder="湖南昨日价格"></el-input>
            </el-form-item>
          </div>
        </template>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editPrice = false">取 消</el-button>
        <el-button type="primary" @click="editPriceDetermine()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'offerViewIndex',
  data() {
    return {
      // 日期选择
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      list: [],
      loading: true,
      query: {
        pageindex: 1,
        pagesize: 20,
        category1: '全部',
        date: this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}')
      },
      total: 0, // 总条数
      // 修改手机号
      editPrice: false,
      editForm: {
        Phone: ''
      },
      rules: {
        Phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3|5|7|8|9]\d{9}$/, message: '请输入正确的号码格式', trigger: 'change' }
        ]
      }
    }
  },
  created() {
    this.getList('place')
  },
  methods: {
    // 初始化
    init() {
      this.query = {
        pageindex: 1,
        pagesize: 20,
        category1: '全部',
        date: this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}')
      }

      this.getList('place')
    },

    // 获取列表
    async getList(place) {
      this.query.date = this.$globalFun.parseTime(new Date(this.query.date), '{y}-{m}-{d}')
      // console.log('111111111', this.query.date)

      if (this.query.date === '1970-01-01' || this.query.date === 'NaN-NaN-NaN') {
        delete this.query.date
        this.query.pagesize = 100
      }

      if (!this.query.title) delete this.query.title
      if (!this.query.category2) delete this.query.category2

      // 重置页数
      if (place) {
        this.query.pageindex = 1
        // 滚动条回到顶部
        this.$nextTick(() => {
          this.$refs.TableListRef.bodyWrapper.scrollTop = 0
        })
      }

      console.log(this.query)

      // const { data } = await this.$api.offer.listServer({
      //   pageindex: 1,
      //   pagesize: 1000,
      //   category1: this.query.category1
      // })
      // console.log('获取列表', data)

      const dataList = await this.$api.offer.listServer(this.query)
      console.log('获取列表', dataList)

      const res = dataList.data

      this.loading = false
      this.list = res
      this.total = dataList.count
    },
    // 每页显示条数和当前页码
    handleSizeChange(newSize) {
      this.query.pagesize = newSize
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    },
    handleCurrentChange(newPage) {
      this.query.pageindex = newPage
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    },

    // 编辑价格
    onEditInfo(item) {
      const form = this.$globalFun.deepClone(item)
      form.Phone = form.LoginName
      this.editForm = form
      console.log('1111', this.editForm)
      this.editPrice = true
    },
    editPriceCancel() {
      // console.log('对话框关闭')
      this.editForm = {}
    },
    async editPriceDetermine() {
      let obj = {}
      if (this.query.category1 === '全部') {
        obj = {
          Id: this.editForm.Id,
          Title: this.editForm.Title,
          Category1: this.editForm.Category1,
          Category2: this.editForm.Category2,
          Factory: this.editForm.Factory,
          Spec: this.editForm.Spec,
          Bead: this.editForm.Bead,
          FactoryCode: this.editForm.FactoryCode,
          OfferValue: this.editForm.OfferValue,
          RetrieveValue: this.editForm.RetrieveValue,
          ZSJValue: this.editForm.ZSJValue,
          ZSJRetrieveValue: this.editForm.ZSJRetrieveValue,
          SZValue: this.editForm.SZValue,
          SZRetrieveValue: this.editForm.SZRetrieveValue,
          SZValue2: this.editForm.SZValue2,
          SZRetrieveValue2: this.editForm.SZRetrieveValue2,
          HNValue: this.editForm.HNValue,
          HNRetrieveValue: this.editForm.HNRetrieveValue
        }
      } else {
        obj = {
          Id: this.editForm.Id,
          Title: this.editForm.Title,
          Category1: this.query.category1,
          OfferValue: this.editForm.OfferValue,
          RetrieveValue: this.editForm.RetrieveValue
        }
      }
      console.log(obj)
      const { data } = await this.$api.offer.editData(obj)
      console.log(data)
      this.$message.success('修改成功')
      this.getList()
      this.editPrice = false
    }
  }
}
</script>

<style lang="scss" scoped></style>
