<!--
 * @Description: 会员管理
-->
<template>
  <div>
    <div class="TableTop">
      <!-- 搜索 -->
      <div class="TableSearch">
        <el-form :model="query" @submit.native.prevent>
          <el-form-item label="手机号码：" class="form-item-b0">
            <el-input v-model="query.loginName" placeholder="请输入手机号码" size="small" clearable></el-input>
          </el-form-item>

          <el-form-item label="分类：" class="form-item-b0">
            <!-- <el-input v-model="query.category1" placeholder="请输入分类" size="small" clearable></el-input> -->
            <el-select v-model="query.category1" placeholder="请选择分类">
              <el-option label="全部" :value="null"></el-option>
              <el-option label="香烟" value="香烟"></el-option>
              <el-option label="酒类" value="酒类"></el-option>
              <el-option label="茶叶" value="茶叶"></el-option>
              <el-option label="其他" value="其他"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="商品名称：" class="form-item-b0">
            <el-input v-model="query.name" placeholder="请输入商品名称" size="small" clearable></el-input>
          </el-form-item>

          <el-form-item label="注册时间：" class="form-item-b0">
            <el-date-picker
              v-model="beginDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>

        <div class="TableSearch_btn">
          <el-button type="primary" size="small" @click="screening()">筛选</el-button>
          <el-button size="small" @click="init()">重置</el-button>
        </div>
      </div>
    </div>

    <!-- 表格 -->
    <el-table ref="TableListRef" class="TableList" border :data="list" v-loading="loading">
      <el-table-column prop="LoginName" label="手机号码" align="center">
        <template slot-scope="scope">
          <template>
            <div>{{ scope.row.LoginName }}</div>
          </template>
          <template>
            <span v-if="ifOverdue(scope.row.VipTime)" style="color: #f00">(VIP已过期)</span>
            <span v-else>(VIP {{ scope.row.VipTime | remainingDays }}天)</span>
          </template>
        </template>
      </el-table-column>

      <el-table-column prop="Category1" label="分类" align="center"></el-table-column>

      <el-table-column prop="count" label="添加数量" align="center"></el-table-column>

      <!-- <el-table-column prop="Price" label="零售价" align="center"></el-table-column>

      <el-table-column prop="OutPrice" label="大客" align="center"></el-table-column>

      <el-table-column prop="OutPrice2" label="中客" align="center"></el-table-column>

      <el-table-column prop="OutPrice3" label="小客" align="center"></el-table-column> -->

      <el-table-column prop="Date" label="添加时间" align="center">
        <template slot-scope="scope">
          {{ new Date(scope.row.Date) | parseTime('{y}-{m}-{d}') }}
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" width="140" fixed="right">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onInfo(scope.row)"> 操作 </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- <el-table ref="TableListRef" class="TableList" border :data="list" v-loading="loading">
      <el-table-column prop="LoginName" label="手机号码" align="center">
        <template slot-scope="scope">
          <template>
            <div>{{ scope.row.LoginName }}</div>
          </template>
          <template>
            <span v-if="ifOverdue(scope.row.VipTime)" style="color: #f00;">VIP已过期</span>
            <span v-else>(VIP {{ scope.row.VipTime | remainingDays }}天)</span>
          </template>
        </template>
      </el-table-column>

      <el-table-column prop="Category1" label="分类" align="center"></el-table-column>

      <el-table-column prop="Name" label="名称" align="center"></el-table-column>

      <el-table-column prop="InPrice" label="进货价" align="center"></el-table-column>

      <el-table-column prop="Price" label="零售价" align="center"></el-table-column>

      <el-table-column prop="OutPrice" label="大客" align="center"></el-table-column>

      <el-table-column prop="OutPrice2" label="中客" align="center"></el-table-column>

      <el-table-column prop="OutPrice3" label="小客" align="center"></el-table-column>

      <el-table-column prop="Date" label="添加时间" align="center">
        <template slot-scope="scope">
          {{ new Date(scope.row.Date) | parseTime }}
        </template>
      </el-table-column>

      <el-table-column prop="Image" label="产品图片" align="center">
        <template slot-scope="scope">
          <el-image style="width: 100px; height: 100px" :src="scope.row.Image" fit="contain"></el-image>
        </template>
      </el-table-column>
    </el-table> -->

    <div class="fenye">
      <!-- 分页 -->
      <el-pagination
        class="TablePagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageindex"
        :page-sizes="[10, 20, 40, 60]"
        :page-size="pageSize1"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <!-- 编辑会员资料 -->
    <el-dialog
      :visible.sync="editDialog"
      class="FormDialogBox"
      width="80%"
      title=""
      top="4vh"
      :close-on-click-modal="false"
      @close="Cancel()"
      ref="eDialog"
    >
      <div class="tabs">
        <el-tabs v-model="activeName" stretch @tab-click="handleClick">
          <el-tab-pane label="商品库列表" name="商品库列表"></el-tab-pane>
          <el-tab-pane label="商户资料" name="商户资料"></el-tab-pane>
        </el-tabs>
      </div>

      <template v-if="activeName === '商品库列表'">
        <el-table ref="TableListRef1" class="TableList TableList1" border :data="blist" v-loading="loading">
          <el-table-column prop="LoginName" label="手机号码" align="center">
            <template slot-scope="scope">
              <template>
                <div>{{ scope.row.LoginName }}</div>
              </template>
              <template>
                <span v-if="ifOverdue(scope.row.VipTime)" style="color: #f00">VIP已过期</span>
                <span v-else>(VIP {{ scope.row.VipTime | remainingDays }}天)</span>
              </template>
            </template>
          </el-table-column>

          <el-table-column prop="Category1" label="分类" align="center"></el-table-column>

          <el-table-column prop="Name" label="名称" align="center"></el-table-column>

          <el-table-column prop="InPrice" label="进货价" align="center"></el-table-column>

          <el-table-column prop="Price" label="零售价" align="center"></el-table-column>

          <el-table-column prop="OutPrice" label="大客" align="center"></el-table-column>

          <el-table-column prop="OutPrice2" label="中客" align="center"></el-table-column>

          <el-table-column prop="OutPrice3" label="小客" align="center"></el-table-column>

          <el-table-column prop="Date" label="添加时间" align="center">
            <template slot-scope="scope">
              {{ new Date(scope.row.Date) | parseTime('{y}-{m}-{d}') }}
            </template>
          </el-table-column>

          <el-table-column prop="Image" label="产品图片" align="center">
            <template slot-scope="scope">
              <el-image
                style="width: 40px; height: 40px"
                :src="scope.row.Image"
                :preview-src-list="[scope.row.Image]"
                fit="contain"
              ></el-image>
            </template>
          </el-table-column>
        </el-table>

        <div class="fenye1">
          <!-- 分页 -->
          <el-pagination
            class="TablePagination"
            background
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
            :current-page="query.pageindex"
            :page-sizes="[10, 20]"
            :page-size="20"
            layout="total, sizes, prev, pager, next, jumper"
            :total="blistLength"
          ></el-pagination>
        </div>
      </template>

      <template v-else>
        <div class="merchants_info">
          <div class="merchants_info_item">
            <div style="width: 80px; margin-right: 20px; color: #909399">登录手机号</div>
            {{ merchantsInfo.LoginName }}(VIP{{ merchantsInfo.VipTime | remainingDays }}天)
          </div>

          <div class="merchants_info_item">
            <div style="width: 80px; margin-right: 20px; color: #909399">商家手机号</div>
            {{ merchantsInfo.StorePhone || '暂无认证' }}
          </div>

          <div class="merchants_info_item">
            <div style="width: 80px; margin-right: 20px; color: #909399">商铺名称</div>
            {{ merchantsInfo.StoreName || '暂无认证' }}
          </div>

          <div class="merchants_info_item">
            <div style="width: 80px; margin-right: 20px; color: #909399">认证名称</div>
            {{ merchantsInfo.Name || '暂无认证' }}
          </div>

          <div class="merchants_info_item">
            <div style="width: 80px; margin-right: 20px; color: #909399">商铺地区</div>
            {{ merchantsInfo.Province + merchantsInfo.City + merchantsInfo.Area || '暂无认证' }}
          </div>

          <div class="merchants_info_item">
            <div style="width: 80px; margin-right: 20px; color: #909399">商铺地址</div>
            {{ merchantsInfo.StoreAddress || '暂无认证' }}
          </div>
        </div>

        <div slot="footer" class="dialog-footer">
          <!-- <el-button @click="editDialog = false">取 消</el-button> -->
          <el-button type="primary" @click="editDialog = false">确 认</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'membersIndex',
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      // beginDate: [this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}'), this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}')],
      beginDate: [],
      loading: true,
      query: {
        pageindex: 1,
        type: 1,
        status: 1,
        pagesize: 99999999,
        category1: ''
      },
      total: 0, // 总条数
      editDialog: false,
      list: [],
      editForm: {},

      alist: [],
      pageSize1: 20,
      pageSize2: 20,
      blist: [],
      blistLength: 0,

      activeName: '商品库列表',
      merchantsInfo: {}
    }
  },
  created() {
    this.getList('place')
  },
  computed: {},
  methods: {
    // 初始化
    init() {
      this.beginDate = []
      this.query = {
        pageindex: 1,
        pagesize: 99999999,
        category1: ''
      }

      this.getList('place')
    },

    async screening() {
      console.log(this.query)

      this.getList('place')
    },

    async onInfo(item) {
      const form = this.$globalFun.deepClone(item)
      this.editForm = form
      // console.log('this.editForm', this.editForm)
      this.editDialog = true
      this.getListInfo('place')

      const { data: res } = await this.$api.members.getStoreList({
        search: this.editForm.LoginName
      })
      this.merchantsInfo = res.list[0]
      console.log('获取商户资料', this.merchantsInfo)
    },

    Cancel() {
      console.log('对话框关闭')
      this.editForm = {}
      this.query = {
        pageindex: 1,
        pagesize: 99999999,
        category1: this.query.category1
      }
      this.merchantsInfo = {}
      this.activeName = '商品库列表'
    },

    async handleClick(e) {},

    // 获取列表
    async getList(place) {
      // 重置页数
      if (place) {
        this.query.pageindex = 1
        // 滚动条回到顶部
        this.$nextTick(() => {
          this.$refs.TableListRef.bodyWrapper.scrollTop = 0
        })
      }

      if (!this.beginDate || !this.beginDate.length) {
        delete this.query.beginTime
        delete this.query.endTime
      } else {
        this.query.beginTime = this.beginDate[0]
        this.query.endTime = this.beginDate[1]
      }

      console.log('this.query', this.query)

      const { data: res } = await this.$api.release.goodsList(this.query)
      console.log('获取商圈列表', res)

      this.alist = res.list
      var obj = {}
      this.alist.forEach((item) => {
        const loginName = item.LoginName
        if (obj[loginName]) {
          if (item.Category1 && obj[loginName].Category1.indexOf(item.Category1) === -1) {
            obj[loginName].Category1 += '/' + item.Category1
          }
          obj[loginName].count += 1
          // obj[loginName].Category1 += ('/' + item.Category1)
        } else {
          item.count = 1
          obj[loginName] = item
        }
      })
      this.alist = []
      for (const k in obj) {
        this.alist.push(obj[k])
      }

      this.loading = false
      this.list = this.alist.slice(0, this.pageSize1)
      this.total = this.alist.length
    },

    // 详情
    async getListInfo(place) {
      // 重置页数
      if (place) {
        this.query.pageindex = 1
        // 滚动条回到顶部
        this.$nextTick(() => {
          this.$refs.TableListRef1.bodyWrapper.scrollTop = 0
        })
      }

      const form = this.query
      form.loginName = this.editForm.LoginName
      form.pagesize = this.pageSize2
      console.log('form', form)

      const { data: res } = await this.$api.release.goodsList(form)
      console.log('获取商圈列表', res)

      this.blist = res.list
      this.loading = false
      this.blistLength = res.count
    },

    // 每页显示条数和当前页码
    handleSizeChange(newSize) {
      this.pageSize1 = newSize
      this.list = []
      // this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
        this.list = this.alist.slice((this.query.pageindex - 1) * this.pageSize1, this.query.pageindex * this.pageSize1)
      })
    },
    handleCurrentChange(newPage) {
      this.query.pageindex = newPage
      this.list = []
      // this.getList()
      // console.log('TableListRef', this.$refs.TableListRef)
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
        this.list = this.alist.slice((newPage - 1) * this.pageSize1, newPage * this.pageSize1)
      })
    },

    // 每页显示条数和当前页码
    handleSizeChange1(newSize) {
      this.pageSize2 = newSize
      this.getListInfo()
      // this.query.pagesize = newSize
      // this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef1.bodyWrapper.scrollTop = 0
      })
    },
    handleCurrentChange1(newPage) {
      this.query.pageindex = newPage
      this.blist = []
      this.$nextTick(() => {
        this.$refs.TableListRef1.bodyWrapper.scrollTop = 0
      })

      this.getListInfo()
    },

    // 判断是否过期
    ifOverdue(time) {
      // console.log('time', time)
      const nowDate = new Date()
      const date = new Date(time).getTime() - nowDate.getTime()

      return date < 0
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .FormDialogBox .el-dialog__body {
  max-height: 82vh;
}

.TableList1 {
  margin-bottom: 60px;
}

.fenye1 {
  width: calc(100% - 30px);
  height: 50px;
  padding-top: 9px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  right: 30px;
  z-index: 9;
}

.tabs {
  background-color: #fff;
  position: sticky;
  top: -30px;
  z-index: 9;
}

.merchants_info {
  min-height: 600px;

  .merchants_info_item {
    padding: 10px;
    color: #606266;
    font-size: 14px;
    line-height: 40px;
    font-weight: bold;
    display: flex;
  }
}
</style>
