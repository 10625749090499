<!--
 * @Description: 会员管理
-->
<template>
  <div>
    <div class="TableTop">
      <!-- 搜索 -->
      <div class="TableSearch">
        <el-form :model="query" @submit.native.prevent>

          <!-- <el-form-item label="手机号码：" class="form-item-b0">
            <el-input v-model="query.status" placeholder="请输入手机号码" size="small" clearable></el-input>
          </el-form-item> -->

          <el-form-item label="" class="form-item-b0">
            <el-button :type="checkBtnCurrent == 1?'primary':''" size="small" @click="getList('place', 1)">提现中</el-button>
            <el-button :type="checkBtnCurrent == 2?'success':''" size="small" @click="getList('place', 2)">提现完成</el-button>
            <el-button :type="checkBtnCurrent == 0?'warning':''" size="small" @click="getList('place', 0)">驳回</el-button>
          </el-form-item>

        </el-form>

        <!-- <div class="TableSearch_btn">
          <el-button type="primary" size="small" @click="getList('place')">筛选</el-button>
          <el-button size="small" @click="init()">重置</el-button>
        </div> -->

      </div>
    </div>

    <!-- 表格 -->
    <el-table ref="TableListRef" class="TableList" border :data="list" v-loading="loading">

      <el-table-column prop="LoginName" label="手机号码" align="center"></el-table-column>

      <el-table-column prop="Name" label="商户名称" align="center"></el-table-column>

      <el-table-column prop="CreateTime" label="提现时间" align="center">
        <template slot-scope="scope">
          {{ new Date(scope.row.CreateTime) | parseTime }}
        </template>
      </el-table-column>

      <el-table-column prop="BankCard" label="银行卡号" align="center"></el-table-column>

      <el-table-column prop="Price" label="提现金额" align="center"></el-table-column>

      <el-table-column label="操作" align="center" width="140px" fixed="right">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onEditInfo(scope.row)">
            审核
          </el-button>
        </template>
      </el-table-column>

    </el-table>

    <!-- 分页 -->
    <div class="fenye">
      <el-pagination
        class="TablePagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageindex"
        :page-sizes="[20, 40, 60, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <!-- 编辑会员资料 -->
    <el-dialog
      :visible.sync="editPhoneDialog"
      class="FormDialogBox"
      width="600px"
      title="编辑会员资料"
      :close-on-click-modal="false"
      @close="editCancel()"
    >

      <div class="tabs">
        <el-tabs v-model="activeName" stretch @tab-click="handleClick">
          <el-tab-pane label="审核" name="审核"></el-tab-pane>
          <el-tab-pane label="佣金记录" name="佣金记录"></el-tab-pane>
        </el-tabs>
      </div>

      <template v-if="activeName === '审核'">
        <el-form label-width="auto" :model="editForm" :rules="rules" ref="ruleForm">

          <el-form-item label="手机号">
            <div>{{ editForm.LoginName }}</div>
            <!-- <el-input v-model="editForm.LoginName" placeholder="手机号" disabled></el-input> -->
          </el-form-item>

          <el-form-item label="姓名">
            <div>{{ info.CardName }}</div>
            <!-- <el-input v-model="editForm.BankName" placeholder="银行卡绑定姓名" disabled></el-input> -->
          </el-form-item>

          <el-form-item label="开户行">
            <div>{{ info.BankOfDeposit }}</div>
            <!-- <el-input v-model="editForm.BankCard" placeholder="银行卡" disabled></el-input> -->
          </el-form-item>

          <el-form-item label="银行卡">
            <div>{{ info.BankCard }}</div>
            <!-- <el-input v-model="editForm.BankCard" placeholder="银行卡" disabled></el-input> -->
          </el-form-item>

          <el-form-item label="提现金额">
            <div>{{ editForm.Price }}</div>
            <!-- <el-input v-model="editForm.Price" placeholder="提现金额" disabled></el-input> -->
          </el-form-item>

          <el-form-item label="银行卡图片" v-if="editForm.CardImage">
            <el-image style="width: 100px;" :src="editForm.CardImage" :preview-src-list="[editForm.CardImage]" alt=""></el-image>
          </el-form-item>

          <el-form-item label="审核">
            <el-input v-model="editForm.Status" placeholder="审核结果" disabled></el-input>
            <el-radio v-model="editForm.Status" :label="1">审核中</el-radio>
            <el-radio v-model="editForm.Status" :label="2">提现完成</el-radio>
            <el-radio v-model="editForm.Status" :label="0">驳回</el-radio>
          </el-form-item>

          <el-form-item label="审核内容">
            <el-input type="textarea" v-model="editForm.Content"></el-input>
          </el-form-item>

        </el-form>
      </template>

      <template v-else>
        <el-table ref="TableListRef" class="TableList" border :data="recordList" v-loading="loading">

          <el-table-column prop="RechargeLoginName" label="充值号码" align="center"></el-table-column>

          <el-table-column prop="CommonPrice" label="佣金" align="center"></el-table-column>

          <el-table-column prop="Time" label="时间" align="center">
            <template slot-scope="scope">
              {{ new Date(scope.row.Time) | parseTime }}
            </template>
          </el-table-column>

        </el-table>
      </template>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editPhoneDialog = false">取 消</el-button>
        <el-button type="primary" @click="editPhoneDetermine()">确 定</el-button>
      </div>

    </el-dialog>

  </div>
</template>

<script>

export default {
  name: 'commissionIndex',
  data () {
    return {
      query: {
        pageindex: 1,
        pageSize: 20,
        status: 1
      },
      checkBtnCurrent: 1,
      editForm: {},
      list: [],

      total: 0,

      rules: {},
      loading: true,
      editPhoneDialog: false,

      recordList: [],
      activeName: '审核',

      info: {},
      cardName: '',
      bankCard: ''
    }
  },
  created () {
    this.getList()
  },
  methods: {
    // 初始化
    init () {
      this.query.status = 1
    },

    // 获取列表
    async getList (place, status) {
      // 重置页数
      if (place) {
        this.query.pageindex = 1
        // 滚动条回到顶部
        this.$nextTick(() => {
          this.$refs.TableListRef.bodyWrapper.scrollTop = 0
        })
      }

      if (status) {
        this.query.status = status
      } else if (status === 0) {
        this.query.status = 0
      }

      this.checkBtnCurrent = this.query.status

      console.log(this.query)

      const { data: res } = await this.$api.commission.getCashList(this.query)
      console.log('获取列表', res)

      this.loading = false
      this.list = res
      this.total = res.length
    },

    handleClick (e) {},

    // 每页显示条数和当前页码
    handleSizeChange (newSize) {
      this.query.pagesize = newSize
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    },
    handleCurrentChange (newPage) {
      this.query.pageindex = newPage
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    },

    // 编辑会员信息
    async onEditInfo (item) {
      const form = this.$globalFun.deepClone(item)
      this.editForm = form
      this.editPhoneDialog = true

      const { data: res } = await this.$api.members.getMembersList({
        pageindex: 1,
        search: this.editForm.LoginName
      })
      this.info = res.list[0]
      console.log('商家信息', res)

      const { data } = await this.$api.commission.getCommonList({
        pageindex: 1,
        pageSize: 10,
        name: this.editForm.LoginName
      })
      console.log('获取列表', data)
      this.recordList = data
    },
    editCancel () {
      // console.log('对话框关闭')
      this.editForm = {}
      this.activeName = '审核'
      this.recordList = []
      this.info = {}
    },
    async editPhoneDetermine () {
      this.$refs.ruleForm.validate(async valid => {
        // eslint-disable-next-line no-useless-return
        if (!valid) return
      })

      const { data } = await this.$api.commission.getCashAudit({
        ids: this.editForm.Id,
        status: this.editForm.Status,
        content: this.editForm.Content
      })
      console.log('审核结果', data)

      this.editForm = {}
      this.getList()
      this.editPhoneDialog = false
    },

    // 判断是否过期
    ifOverdue (time) {
      // console.log('time', time)
      const nowDate = new Date()
      const date = new Date(time).getTime() - nowDate.getTime()

      return date < 0
    }
  }
}
</script>

<style lang="scss" scoped></style>
