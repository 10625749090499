<!--
 * @Description: 会员管理
-->
<template>
  <div>
    <div class="TableTop">
      <!-- 搜索 -->
      <div class="TableSearch">
        <el-form :model="query" @submit.native.prevent>
          <!-- 订单 -->
          <el-form-item label="订单：" class="form-item-b0">
            <el-input
              v-model="query.search"
              placeholder="请输入订单"
              size="small"
              clearable
              @keyup.native.enter="getList('place')"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="TableSearch_btn">
          <el-button type="primary" size="small" @click="getList('place')">筛选</el-button>
          <el-button size="small" @click="init()">重置</el-button>
          <el-button type="success" size="mini" @click="addDialog = true">添加产品</el-button>
        </div>
      </div>
    </div>
    <!-- 表格 -->
    <el-table ref="TableListRef" class="TableList" border :data="list" v-loading="loading">
      <el-table-column prop="Name" label="名称" align="center"></el-table-column>

      <el-table-column prop="Title" label="标题" align="center"></el-table-column>

      <el-table-column prop="Price" label="价格" align="center"></el-table-column>

      <el-table-column prop="Integral" label="积分" align="center"></el-table-column>

      <el-table-column prop="Content" label="详情" align="center"></el-table-column>

      <!-- <el-table-column prop="ImgUrl" label="图片" align="center">
        <template slot-scope="scope">
          <div class="auditImg" v-if="scope.row.ImgUrl">
            <div class="block" v-for="(item, index) in scope.row.ImgUrl.split(',')" :key="index">
              <img :src="item.Url" alt="" />
            </div>
          </div>
        </template>
      </el-table-column> -->

      <el-table-column label="操作" align="center" width="140" fixed="right">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onEditInfo(scope.row)"> 修改信息 </el-button>

          <el-button style="margin-top: 6px" type="danger" size="mini" @click="onDelete(scope.row.ProductId)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 商户管理 -->
    <el-dialog
      :visible.sync="addDialog"
      class="FormDialogBox"
      width="680px"
      title="添加产品"
      :close-on-click-modal="false"
      append-to-body
      @close="addDialog = false"
    >
      <el-form label-width="auto" :model="addForm" ref="ruleForm">
        <el-form-item label="产品名称">
          <el-input v-model="addForm.name" placeholder="产品名称"></el-input>
        </el-form-item>

        <el-form-item label="产品标题">
          <el-input v-model="addForm.title" placeholder="产品标题"></el-input>
        </el-form-item>

        <el-form-item label="价格">
          <el-input v-model="addForm.price" placeholder="价格"></el-input>
        </el-form-item>

        <el-form-item label="积分">
          <el-input v-model="addForm.integral" placeholder="积分"></el-input>
        </el-form-item>

        <el-form-item label="详情">
          <el-input v-model="addForm.content" placeholder="积分"></el-input>
        </el-form-item>

        <el-form-item label="状态">
          <el-switch
            v-model="addForm.status"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>

        <el-form-item label="图片">
          <el-upload
            ref="upload"
            action="https://api.chajiuqu.com/api/current/uploadimage"
            list-type="picture-card"
            :file-list="myImage"
            multiple
            accept="image"
            :limit="9"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialog = false">取 消</el-button>
        <el-button type="danger" @click="addDetermine()"> 确 定 </el-button>
      </div>
    </el-dialog>

    <div class="pre_img" v-if="dialogVisible">
      <i class="el-icon-circle-close icon" @click="imgClose"></i>
      <el-image class="img" style="width: 20%; display: block; margin: 0 auto" :src="dialogImageUrl"></el-image>
    </div>
  </div>
</template>

<script>
export default {
  name: 'membersIndex',
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,

      list: [],

      addDialog: false,
      addForm: {
        id: 0,
        name: '',
        title: '',
        price: '',
        integral: '',
        ImgUrl: '',
        content: '',
        status: 1
      },

      loading: true,
      total: 0,

      query: {
        pageindex: 1,
        pagesize: 20,
        search: ''
      }
    }
  },

  created() {
    this.getList('place')
  },

  computed: {
    myImage: function () {
      const arr = []
      if (this.addForm.ImgUrl) {
        const list = this.addForm.ImgUrl.split(',')
        list.forEach((item) => {
          if (item) {
            arr.push({
              url: item
            })
          }
        })
      }
      return arr
    }
  },

  methods: {
    // 获取列表
    async getList(place) {
      // 重置页数
      if (place) {
        this.query.pageindex = 1
        // 滚动条回到顶部
        await this.$nextTick()
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      }

      console.log(this.query)

      const { data: res } = await this.$api.integral.list(this.query)
      console.log('获取订单列表', res)

      this.list = res.list

      this.loading = false
      this.list = res.list
      this.total = res.count
    },

    onEditInfo(data) {
      this.addForm = {
        id: data.ProductId,
        name: data.Name,
        title: data.Title,
        price: data.Price,
        integral: data.Integral,
        ImgUrl: data.ImgUrl,
        content: data.Content,
        status: data.Status
      }
      this.addDialog = true
    },

    async onDelete(id) {
      const { data } = await this.$api.integral.delete({
        id
      })
      console.log('删除产品', data)

      this.$message.success('删除成功')
      this.getList()
    },

    async addDetermine() {
      console.log('addForm', this.addForm)
      console.log('imgUrls', this.$refs.upload.uploadFiles)
      const imgUrl = this.$refs.upload.uploadFiles
        .map((item) => {
          const url = item.response ? item.response.data : ''
          return url
        })
        .join(',')
      this.addForm.imgUrl = imgUrl

      const { data } = await this.$api.integral.add(this.addForm)
      console.log('添加产品', data)

      this.addDialog = false
      this.addForm = {
        id: 0,
        name: '',
        title: '',
        price: '',
        integral: '',
        ImgUrl: '',
        content: '',
        status: 1
      }
      this.getList()

      this.$message.success('添加成功')
    },

    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePictureCardPreview(file) {
      console.log(file.url)
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    imgClose() {
      this.dialogImageUrl = ''
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .data-list {
  width: 620px !important;

  ul.el-scrollbar__view {
    display: flex;
    flex-wrap: wrap;
  }
}

.auditImg {
  display: flex;
  .block {
    margin-right: 25px;
    span {
      display: block;
      width: 120px;
      text-align: center;
    }
    img {
      width: 120px;
    }
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.pre_img {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999988;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 60px;
    top: 60px;
    font-size: 40px;
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
  }

  .img {
    width: 20%;
    display: block;
  }
}
</style>
