<!--
 * @Description: 会员管理
-->
<template>
  <div>
    <div class="TableTop">
      <!-- 搜索 -->
      <div class="TableSearch">
        <el-form :model="query" @submit.native.prevent>

          <el-form-item label="手机号码：" class="form-item-b0">
            <el-input v-model="query.phone" placeholder="请输入手机号码" size="small" clearable></el-input>
          </el-form-item>

          <el-form-item label="用户Id" class="form-item-b0">
            <el-input v-model="query.id" placeholder="请输入用户Id" size="small" clearable></el-input>
          </el-form-item>

          <!-- <el-form-item label="排序" class="form-item-b0">
            <el-select v-model="query.sort" placeholder="请选择排序">
              <el-option label="下线人数" value="UserCount"></el-option>
              <el-option label="佣金" value="CommonPrice"></el-option>
              <el-option label="充值金额" value="Price"></el-option>
              <el-option label="空" value=""></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="正反序" class="form-item-b0">
            <el-select v-model="query.isDESC" placeholder="请选择排序">
              <el-option label="正序" :value="true"></el-option>
              <el-option label="反序" :value="false"></el-option>
            </el-select>
          </el-form-item> -->

          <!-- <el-form-item label="注册时间：" class="form-item-b0">
            <el-date-picker
              v-model="beginDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item> -->

        </el-form>

        <div class="TableSearch_btn">
          <el-button type="primary" size="small" @click="getList('place')">筛选</el-button>
          <el-button size="small" @click="init()">重置</el-button>
        </div>

      </div>
    </div>

    <!-- 表格 -->
    <el-table ref="TableListRef" class="TableList" border :data="list" v-loading="loading" @sort-change="paixu"
      :default-sort = "{prop: 'CurrPrice', prop: 'UserCount', prop: 'Price', order: 'CommonPrice'}">

      <el-table-column label="序" align="center" type="index" min-width="50"></el-table-column>

      <el-table-column prop="Id" label="Id" align="center"></el-table-column>

      <el-table-column prop="LoginName" label="手机号" align="center"></el-table-column>

      <el-table-column prop="CreateTime" label="注册时间" align="center" width="240">
        <template slot-scope="scope">
          {{ new Date(scope.row.CreateTime) | parseTime }}
        </template>
      </el-table-column>

      <el-table-column prop="CurrPrice" label="可提金额" sortable="custom" align="center"></el-table-column>

      <el-table-column prop="UserCount" label="下线人数" sortable="custom" align="center"></el-table-column>

      <el-table-column prop="Price" label="充值金额" sortable="custom" align="center"></el-table-column>

      <el-table-column prop="CommonPrice" label="总佣金" sortable="custom" align="center"></el-table-column>

      <el-table-column label="操作" align="center" width="140px" fixed="right">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onEditInfo(scope.row)">
            佣金记录
          </el-button>
        </template>
      </el-table-column>

    </el-table>

    <!-- 分页 -->
    <div class="fenye">
      <el-pagination
        class="TablePagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageindex"
        :page-sizes="[20, 40, 60, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <!-- 记录 -->
    <el-dialog
      :visible.sync="editPhoneDialog"
      class="FormDialogBox"
      width="800px"
      title="记录"
      :close-on-click-modal="false"
      @close="editCancel()"
    >

      <div class="tabs">
        <el-tabs v-model="activeName" stretch @tab-click="handleClick">
          <el-tab-pane label="佣金记录" name="佣金记录"></el-tab-pane>
          <el-tab-pane label="提现记录" name="提现记录"></el-tab-pane>
        </el-tabs>
      </div>

      <div style="height: 600px;" v-if="activeName === '佣金记录'">
        <el-table ref="TableListRef1" class="TableList" border :data="rList" v-loading="loading">

          <el-table-column prop="LoginName" label="手机号" align="center" v-if="activeName === '佣金记录'"></el-table-column>

          <el-table-column prop="Price" label="充值金额" align="center" v-if="activeName === '佣金记录'"></el-table-column>

          <el-table-column prop="CommonPrice" label="佣金" align="center" v-if="activeName === '佣金记录'"></el-table-column>

          <el-table-column prop="CreateTime" label="注册时间" align="center" width="200" v-if="activeName === '佣金记录'">
            <template slot-scope="scope" v-if="activeName === '佣金记录'">
              {{ new Date(scope.row.CreateTime) | parseTime }}
            </template>
          </el-table-column>

          <el-table-column label="操作" align="center" width="140px" fixed="right" v-if="activeName === '佣金记录'">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="record(scope.row.LoginName)">
                充值记录
              </el-button>
            </template>
          </el-table-column>

        </el-table>
      </div>

      <div style="height: 600px;" v-if="activeName === '提现记录'">
        <el-table ref="TableListRef1" class="TableList" border :data="dList" v-loading="loading">

          <el-table-column prop="LoginName" label="手机号码" align="center" v-if="activeName === '提现记录'"></el-table-column>

          <el-table-column prop="CreateTime" label="提现时间" align="center" v-if="activeName === '提现记录'">
            <template slot-scope="scope" v-if="activeName === '提现记录'">
              {{ new Date(scope.row.CreateTime) | parseTime }}
            </template>
          </el-table-column>

          <el-table-column prop="BankCard" label="银行卡号" align="center" v-if="activeName === '提现记录'"></el-table-column>

          <el-table-column prop="Price" label="提现金额" align="center" v-if="activeName === '提现记录'"></el-table-column>

        </el-table>
      </div>

      <div class="fenye1">
        <!-- 分页 -->
        <el-pagination
          class="TablePagination"
          background
          @size-change="handleSizeChange1"
          @current-change="handleCurrentChange1"
          :current-page="pageindex1"
          :page-sizes="[10, 20, 40, 60]"
          :page-size="20"
          layout="total, sizes, prev, pager, next, jumper"
          :total="blistLength"
        ></el-pagination>
      </div>

    </el-dialog>

    <!-- 编辑会员资料 -->
    <el-dialog
      :visible.sync="editPhoneDialog1"
      class="FormDialogBox"
      width="800px"
      title="编辑会员资料"
      :close-on-click-modal="false"
      @close="editCancel()"
    >

      <div style="height: 600px;">
        <el-table ref="TableListRef1" class="TableList" border :data="cList" v-loading="loading">

          <el-table-column prop="LoginName" label="手机号" align="center"></el-table-column>

          <el-table-column prop="Price" label="充值金额" align="center"></el-table-column>

          <el-table-column prop="CommonPrice" label="佣金" align="center"></el-table-column>

          <el-table-column prop="CreateDate" label="充值时间" align="center">
            <template slot-scope="scope">
              {{ new Date(scope.row.CreateDate) | parseTime }}
            </template>
          </el-table-column>

        </el-table>
      </div>

    </el-dialog>

  </div>
</template>

<script>

export default {
  name: 'commissionIndex',
  data () {
    return {
      query: {
        pageindex: 1,
        pageSize: 20,
        sort: 'CreateTime',
        phone: ''
      },

      sort: '',

      editForm: {},
      list: [],

      total: 0,

      rules: {},
      loading: true,
      editPhoneDialog: false,
      editPhoneDialog1: false,

      rList: [],
      dList: [],

      pageindex1: 1,
      blistLength: 0,

      cList: [],
      cLength: 0,

      activeName: '佣金记录',
      activeName2: '佣金记录'
    }
  },
  created () {
    this.getList()
  },
  methods: {
    // 初始化
    init () {
      // this.LoginDate = []
      // this.beginDate = []
      // this.query.name = ''
      this.query = {
        pageindex: 1,
        pageSize: 20,
        sort: 'CreateTime',
        phone: ''
      }

      this.getList('place')
    },

    async handleClick (tab) {
      if (this.activeName === this.activeName2) return
      this.activeName2 = this.activeName

      this.pageindex1 = 1
      this.rList = []
      this.dList = []
      this.blistLength = 0

      this.getList2(this.editForm)
    },

    // 获取列表
    async getList (place) {
      // 重置页数
      if (place) {
        this.query.pageindex = 1
        // 滚动条回到顶部
        this.$nextTick(() => {
          this.$refs.TableListRef.bodyWrapper.scrollTop = 0
        })
      }

      if (this.query.sort === '') {
        delete this.query.sort
        delete this.query.isDESC
      }

      if (!this.query.id) {
        delete this.query.id
      }

      console.log(this.query)

      const data = await this.$api.commission.userchild(this.query)
      console.log('获取列表', data)

      this.loading = false
      if (data.success) {
        this.total = data.count
        this.list = data.data
      }
    },

    // 获取列表
    async getList2 (form) {
      if (this.activeName === '佣金记录') {
        const data = await this.$api.commission.getUserChildList({
          pageindex: this.pageindex1,
          userId: this.editForm.Id
        })
        console.log('获取列表', data)

        if (data.success) {
          this.rList = data.data
          this.blistLength = data.count
        }
      } else {
        const data = await this.$api.commission.getCashList({
          pageindex: this.pageindex1,
          pageSize: 20,
          phone: form.LoginName
        })

        if (data.success) {
          this.dList = data.data
          this.blistLength = data.count
        }
        console.log('获取列表', data)
      }
    },

    paixu (column, prop, order) {
      if (this.sort === column.order && this.query.sort === column.prop) return

      if (column.prop === 'UserCount') {
        if (column.order === 'ascending') {
          this.query.isDESC = true
        } else if (column.order === 'descending') {
          this.query.isDESC = false
        } else {
          delete this.query.isDESC
        }
      } else if (column.prop === 'Price') {
        if (column.order === 'ascending') {
          this.query.isDESC = true
        } else if (column.order === 'descending') {
          this.query.isDESC = false
        } else {
          delete this.query.isDESC
        }
      } else if (column.prop === 'CommonPrice') {
        if (column.order === 'ascending') {
          this.query.isDESC = true
        } else if (column.order === 'descending') {
          this.query.isDESC = false
        } else {
          delete this.query.isDESC
        }
      } else if (column.prop === 'CurrPrice') {
        if (column.order === 'ascending') {
          this.query.isDESC = true
        } else if (column.order === 'descending') {
          this.query.isDESC = false
        } else {
          delete this.query.isDESC
        }
      }
      console.log(column.prop, column.order)
      if (column.order) {
        this.query.sort = column.prop
        this.sort = column.order
      } else {
        delete this.query.sort
      }

      this.getList()
    },

    // 每页显示条数和当前页码
    handleSizeChange (newSize) {
      this.query.pagesize = newSize
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    },
    handleCurrentChange (newPage) {
      this.query.pageindex = newPage
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    },

    // 每页显示条数和当前页码
    handleSizeChange1 (newSize) {
      this.query.pagesize = newSize
      this.getList2(this.editForm)
      this.$nextTick(() => {
        this.$refs.TableListRef1.bodyWrapper.scrollTop = 0
      })
    },
    handleCurrentChange1 (newPage) {
      this.rList = []
      this.pageindex1 = newPage
      this.getList2(this.editForm)
      this.$nextTick(() => {
        this.$refs.TableListRef1.bodyWrapper.scrollTop = 0
      })
    },

    // 充值记录
    async record (LoginName) {
      console.log(LoginName)
      this.editPhoneDialog1 = true

      const { data } = await this.$api.members.getTopUpWaterList({
        pageindex: 1,
        pagesize: 20,
        status: 1,
        search: LoginName
      })
      this.cList = data.list

      console.log('充值记录', data)
    },

    // 编辑会员信息
    onEditInfo (item) {
      const form = this.$globalFun.deepClone(item)
      this.editForm = form
      this.editPhoneDialog = true

      this.getList2(form)
    },
    editCancel () {
      // console.log('对话框关闭')
      this.editForm = {}
      this.activeName = '佣金记录'
      this.activeName2 = '佣金记录'
      this.recordList = []
      this.pageindex1 = 1
    },
    async editPhoneDetermine () {
      this.$refs.ruleForm.validate(async valid => {
        // eslint-disable-next-line no-useless-return
        if (!valid) return
      })

      const { data } = await this.$api.commission.getCashAudit({
        ids: this.editForm.Id,
        status: this.editForm.Status,
        content: this.editForm.Content
      })
      console.log('审核结果', data)

      this.editForm = {}
      this.getList()
      this.editPhoneDialog = false
    },

    // 判断是否过期
    ifOverdue (time) {
      // console.log('time', time)
      const nowDate = new Date()
      const date = new Date(time).getTime() - nowDate.getTime()

      return date < 0
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table td, .el-table th {
  padding: 5px 0;
}

.tabs {
  background-color: #fff;
  position: sticky;
  top: -30px;
  z-index: 9;
}

.fenye1 {
  width: calc(100% - 30px);
  height: 50px;
  padding-top: 9px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  right: 30px;
  z-index: 9;
}
</style>
