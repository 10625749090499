<!--
 * @Description: 会员资料
-->
<template>
  <div>
    <el-form label-width="auto" :model="info" :rules="rules" ref="ruleForm">
      <div style="display: flex;">
        <el-form-item label="手机号">
          <el-input v-model="info.LoginName" placeholder="手机号" disabled clearable maxlength="11"></el-input>
        </el-form-item>

        <el-form-item label="状态">
          <el-switch
            v-model="info.Status"
            :active-value="1"
            :inactive-value="0"
            @change="editStatus(info)"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>

        <el-form-item label="IP">
          <span>{{ info.LastLoginIp }}</span>
        </el-form-item>
      </div>

      <el-form-item label="密码" prop="Password">
        <el-input v-model="info.PassWord" placeholder="密码" clearable minlength="6" maxlength="12"></el-input>
      </el-form-item>

      <div style="display: flex;">
        <el-form-item label="会员" prop="VipTime">
          <el-date-picker
            v-model="info.VipTime"
            type="date"
            placeholder="会员日期"
            style="width: 100%;"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="商户" prop="VipTime" style="margin-left: 10px;">
          <el-date-picker
            v-model="info.StoreVipTime"
            type="date"
            placeholder="商户会员"
            style="width: 100%;"
          ></el-date-picker>
        </el-form-item>
      </div>

      <div style="display: flex;">
        <el-form-item label="姓名">
          <el-input v-model="info.CardName" placeholder="银行卡姓名" clearable></el-input>
        </el-form-item>

        <el-form-item label="开户行" style="margin-left: 10px;">
          <el-input v-model="info.BankOfDeposit" placeholder="开户行" clearable></el-input>
        </el-form-item>
      </div>

      <el-form-item label="银行卡">
        <el-input v-model="info.BankCard" placeholder="银行卡" clearable></el-input>
      </el-form-item>

      <div style="display: flex;">
        <el-form-item label="等级" prop="Level">
          <el-input v-model="info.Level" placeholder="会员等级" type="number"></el-input>
        </el-form-item>

        <el-form-item label="积分" prop="Integral" style="margin-left: 10px;">
          <el-input v-model="info.Integral" placeholder="积分" type="number"></el-input>
        </el-form-item>
      </div>

      <el-form-item label="省市区" prop="Province">
        <el-cascader
          v-model="region"
          :options="regionOptions"
          :props="{
            value: 'label',
            label: 'label',
            children: 'children'
          }"
          @change="handleChange"
          clearable
          style="width: 100%;"
        ></el-cascader>
      </el-form-item>

      <el-form-item label="头像">
        <el-upload
          ref="upload"
          action="https://api.chajiuqu.com/api/current/uploadimage"
          list-type="picture-card"
          :file-list="myImage"
          :limit="1"
          :on-success="handleAvatarSuccess"
          :on-remove="handleRemove"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import regionArr from '@/utils/citys.json'

export default {
  name: 'membersInfo',
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      rules: {
        Phone: [
          { required: true, message: ' ', trigger: 'blur' },
          { pattern: /^1[3|4|5|6|7|8|9]\d{9}$/, message: '请输入正确的号码格式', trigger: 'change' }
        ],
        // PassWord: [{ required: true, message: '密码长度6-12', trigger: 'change' }],
        VipTime: [{ required: true, message: ' ', trigger: 'change' }],
        Level: [{ required: true, message: ' ', trigger: 'blur' }],
        Integral: [{ required: true, message: ' ', trigger: 'blur' }]
        // Province: [{ required: true, message: '请选择省市区', trigger: 'change' }]
      },

      // 省市区
      region: null,
      regionOptions: regionArr,

      cardName: '',
      bankCard: '',
      bankOfDeposit: ''
    }
  },
  watch: {
    info (newVal, ordVal) {
      // console.log('111111', ordVal, newVal)
      this.region = [newVal.Province, newVal.City, newVal.Area]
      this.bankCard = newVal.BankCard
      this.cardName = newVal.CardName
      this.bankOfDeposit = newVal.BankOfDeposit
    }
  },
  computed: {
    myImage: function () {
      const arr = []
      if (this.info.StoreHeadImage) {
        arr.push({
          url: this.info.StoreHeadImage
        })
      }
      return arr
    }
  },
  methods: {
    // 状态改变
    async editStatus (item) {
      console.log('状态改变', item)

      try {
        const form = {
          listid: [item.Id],
          status: item.Status
        }
        await this.$api.members.editMembersStatus(form)
        this.$message.success('状态改变成功')
        this.getList()
      } catch (e) {
        this.getList()
      }
    },

    async editDetermine () {
      this.$refs.ruleForm.validate(async valid => {
        if (!valid) return

        console.log('region', this.region)

        if (this.region) {
          this.info.Province = this.region[0]
          this.info.City = this.region[1]
          this.info.Area = this.region[2]
        } else {
          this.info.Province = ''
          this.info.City = ''
          this.info.Area = ''
        }

        const form = {
          Id: this.info.Id,
          StoreheadImage: (this.info.StoreHeadImage && this.info.StoreHeadImage.length > 0) ? this.info.StoreHeadImage : 'https://api.chajiuqu.com/uploadImage/img/defaultPict.png',
          PassWord: this.info.PassWord
        }
        if (this.info.PassWord.length > 10) delete form.PassWord
        await this.$api.members.edit(form)

        if (this.bankCard !== this.info.BankCard || this.cardName !== this.info.CardName || this.bankOfDeposit !== this.info.BankOfDeposit) {
          const { data: res } = await this.$api.commission.editBank({
            Id: this.info.Id,
            BankCard: this.info.BankCard,
            CardName: this.info.CardName,
            BankOfDeposit: this.info.BankOfDeposit,
            CardImage: this.info.CardImage
          })
          console.log('银行卡', res)
        }

        console.log('info', this.info)
        await this.$api.members.editInfo(this.info)
        this.$message.success('修改成功')
        this.$emit('editOk')
      })
    },

    // 地区选择
    handleChange (e) {
      console.log('e', e)
      this.info.Province = e[0]
      this.info.City = e[1]
      this.info.Area = e[2]
    },

    handleAvatarSuccess (res, file, filelist) {
      console.log(res, file, filelist)
      this.info.StoreHeadImage = res.data
    },
    handleRemove (file, filelist) {
      console.log(file, filelist)
      this.info.StoreHeadImage = ''
    }
  }
}
</script>

<style lang="scss" scoped></style>
