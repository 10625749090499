<template>
  <div class="showLogo">
    <!-- 顶部图标 -->
    <logo :collapse="isCollapse" />
    <!-- 侧边栏 -->
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical"
      >
        <template v-for="route in routes">
          <sidebar-item v-if="ifAdmin(route)" :key="route.path" :item="route" :base-path="route.path" />
        </template>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'

export default {
  components: { SidebarItem, Logo },
  data() {
    return {
      // routes: this.$router.options.routes,
      authority: [],

      timer: null
    }
  },
  computed: {
    ...mapGetters(['sidebar']),
    routes() {
      // console.log('this.$router.options.routes', this.$router.options.routes)
      return this.$router.options.routes
    },
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    variables() {
      return variables
    },
    // 判断侧边栏收缩
    isCollapse() {
      return !this.sidebar.opened
    }
  },
  async created() {
    if (this.$store.state.user.token) {
      // const info = this.$store.state.user.info
      // await this.judgeAuthority(info.LoginName)
      this.$store
        .dispatch('user/getInfo')
        .then((res) => {
          if (!res.Categorys) {
            this.authority = []
            return
          }
          console.log('信息', res)
          this.authority = res.Categorys.split('，')
        })
        .catch((err) => {
          console.log('出错了请新登录', err)
          // this.$router.push('/login')
        })
    }

    this.getNum()
    clearInterval(this.timer)
    this.timer = setInterval(() => {
      this.getNum()
    }, 1000 * 60)
  },
  methods: {
    // 判断权限
    ifAdmin(route) {
      if (route.permissions) {
        return this.authority.indexOf(route.meta.title) > -1
      } else {
        return true
      }
      // if (
      //   route.permissions &&
      //   (route.permissions[0] === 'admin' || route.permissions[1] === 'bbb' || route.permissions[2] === 'ccc')
      // ) {
      //   console.log('2222222', this.$store.state.user.info)
      //   return (
      //     this.$store.state.user.info.Name === 'admin' ||
      //     this.$store.state.user.info.Name === 'bbb' ||
      //     this.$store.state.user.info.Name === 'ccc'
      //   )
      // } else {
      //   return true
      // }
    },

    async judgeAuthority(search) {
      const { data } = await this.$api.user.adminlist({
        pageindex: 1,
        pagesize: 10,
        search,
        status: 1
      })
      if (data.list.length < 1) return
      if (!data.list[0].Categorys) {
        this.authority = []
        return
      }
      console.log('信息', data.list[0])
      this.authority = data.list[0].Categorys.split('，')
    },

    async getNum() {
      const { data } = await this.$api.members.auditcount()
      // console.log('审核数量', data)

      // if (
      //   data.storeCount > 0 ||
      //   data.articleCount > 0 ||
      //   data.imageCount > 0 ||
      //   data.provinceCount > 0 ||
      //   data.goodsCount > 0
      // ) {
      //   const mp3 = new Audio('https://api.chajiuqu.com/uploadImage/img/prompt.mp3')
      //   mp3.play()
      //   setTimeout(() => {
      //     mp3.pause()
      //   }, 2000)
      // }

      this.routes.forEach((element) => {
        if (element.path === '/businessManagement') {
          element.children.forEach((item, index) => {
            let title = item.meta.title
            if (title.indexOf('商户管理') > -1) {
              if (data.storeCount > 0) {
                title = '商户管理' + '(' + data.storeCount + ')'
              } else {
                title = '商户管理'
              }
              element.children[index].meta.title = title
              element.children[index].meta.num = data.storeCount
            } else if (title.indexOf('商品发布') > -1) {
              if (data.goodsCount > 0) {
                title = '商品发布' + '(' + data.goodsCount + ')'
              } else {
                title = '商品发布'
              }
              element.children[index].meta.title = title
              element.children[index].meta.num = data.goodsCount
            } else if (title.indexOf('文章发布') > -1) {
              if (data.articleCount > 0) {
                title = '文章发布' + '(' + data.articleCount + ')'
              } else {
                title = '文章发布'
              }
              element.children[index].meta.title = title
              element.children[index].meta.num = data.articleCount
            } else if (title.indexOf('相册管理') > -1) {
              if (data.imageCount > 0) {
                title = '相册管理' + '(' + data.imageCount + ')'
              } else {
                title = '相册管理'
              }
              element.children[index].meta.title = title
              element.children[index].meta.num = data.imageCount
            }
          })
        }
      })
    }
  }
}
</script>
