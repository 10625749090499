<!--
 * @Description: 商户资料
-->
<template>
  <div>
    <el-form label-width="auto" :model="info" :rules="rules" ref="ruleForm">
      <div style="display: flex;">
        <el-form-item label="认证套餐">
          <el-radio v-model="info.CertificationType" label="个人">680</el-radio>
          <el-radio v-model="info.CertificationType" label="商户">1680</el-radio>
        </el-form-item>

        <el-form-item label=" ">
          <span style="margin-left: 10px;">认证时间：</span>
          <span>{{ new Date(info.StoreSubmitTime) | parseTime }}</span>
        </el-form-item>
      </div>

      <div style="display: flex;">
        <el-form-item label="手机号" prop="Phone">
          <el-input v-model="info.Phone" placeholder="手机号" disabled></el-input>
        </el-form-item>
        <el-form-item label="">
          <span v-if="ifOverdue(info.VipTime)" style="color: #f00;">(VIP已过期)</span>
          <span v-else>(VIP {{ info.VipTime | remainingDays }}天)</span>

          <span v-if="!info.StoreVipTime" style="color: #f00; margin-left: 20px;">(商户审核中)</span>
          <span v-else-if="ifOverdue(info.StoreVipTime)" style="color: #f00; margin-left: 20px;">(商户VIP已过期)</span>
          <span v-else style="margin-left: 20px;">(商户 {{ info.StoreVipTime | remainingDays }}天)</span>

          <span style="margin-left: 10px;">注册时间：</span>
          <span>{{ new Date(info.CreateTime) | parseTime }}</span>
        </el-form-item>
      </div>

      <div style="display: flex;">
        <el-form-item label="商家电话">
          <el-input v-model="info.StorePhone" placeholder="商家电话"></el-input>
        </el-form-item>
        <el-form-item label="商铺名称" style="margin-left: 20px;">
          <el-input v-model="info.StoreName" placeholder="商铺名称"></el-input>
        </el-form-item>
      </div>

      <el-form-item label="认证名称">
        <el-input v-model="info.Name" placeholder="认证名称"></el-input>
      </el-form-item>

      <div style="display: flex;">
        <el-form-item label="省份">
          <el-input v-model="info.Province" placeholder="省份"></el-input>
        </el-form-item>
        <el-form-item label="城市">
          <el-input v-model="info.City" placeholder="城市"></el-input>
        </el-form-item>
        <el-form-item label="区域">
          <el-input v-model="info.Area" placeholder="区域"></el-input>
        </el-form-item>
      </div>

      <el-form-item label="具体地址">
        <el-input v-model="info.StoreAddress" placeholder="具体地址"></el-input>
      </el-form-item>

      <el-form-item label="商铺类型">
        <el-radio v-model="info.StoreType" label="行情商">行情商</el-radio>
        <el-radio v-model="info.StoreType" label="酒商">酒商</el-radio>
        <el-radio v-model="info.StoreType" label="酒厂">酒厂</el-radio>
        <el-radio v-model="info.StoreType" label="茶商">茶商</el-radio>
        <el-radio v-model="info.StoreType" label="茶厂">茶厂</el-radio>
        <el-radio v-model="info.StoreType" label="其他">其他</el-radio>
      </el-form-item>

      <el-form-item label="商户描述">
        <el-input v-model="info.StoreBriefIntroduction" placeholder="商户描述"></el-input>
      </el-form-item>

      <div style="display: flex;">
        <el-form-item label="经度">
          <el-input v-model="info.Longitude" placeholder="经度"></el-input>
        </el-form-item>
        <el-form-item label="纬度">
          <el-input v-model="info.Latitude" placeholder="纬度"></el-input>
        </el-form-item>
      </div>

      <!-- <el-form-item label="头像">
        <el-upload
          ref="upload"
          action="https://api.chajiuqu.com/api/current/uploadimage"
          list-type="picture-card"
          :file-list="StoreHeadImage"
          :limit="1"
          :on-success="handleAvatarSuccess"
          :on-remove="handleRemove"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item> -->

      <el-form-item label="图片审核">
        <div class="auditImg">
          <div class="block">
            <span>身份证正面</span>
            <div v-if="!info.StoreLicenseImage" style="text-align: center;">无上传</div>
            <el-image v-else :src="info.StoreIDImage" :preview-src-list="srcList" alt=""></el-image>
          </div>
          <div class="block">
            <span>身份证反面</span>
            <div v-if="!info.StoreLicenseImage" style="text-align: center;">无上传</div>
            <el-image v-else :src="info.StoreIDImage2" :preview-src-list="srcList" alt=""></el-image>
          </div>
          <div class="block">
            <span>营业执照</span>
            <div v-if="!info.StoreLicenseImage" style="text-align: center;">无上传</div>
            <el-image v-else :src="info.StoreLicenseImage" :preview-src-list="srcList" alt=""></el-image>
          </div>
          <div class="block">
            <span>其他资质图片</span>
            <div v-if="!info.OtherDocuments" style="text-align: center;">无上传</div>
            <el-image v-else :src="info.OtherDocuments" :preview-src-list="srcList" alt=""></el-image>
          </div>
        </div>
      </el-form-item>

      <el-form-item label="审核状态">
        <el-radio v-model="info.StoreAuditStatus" :label="1">正在审核</el-radio>
        <el-radio v-model="info.StoreAuditStatus" :label="2">审核通过</el-radio>
        <el-radio v-model="info.StoreAuditStatus" :label="3">审核退回</el-radio>
      </el-form-item>
      <el-form-item label="审核内容">
        <el-input type="textarea" v-model="info.StoreAuditContent"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'MerchantsInfo',
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      rules: {
        Phone: [
          { required: true, message: ' ', trigger: 'blur' },
          { pattern: /^1[3|4|5|6|7|8|9]\d{9}$/, message: '请输入正确的号码格式', trigger: 'change' }
        ],
        // PassWord: [{ required: true, message: '密码长度6-12', trigger: 'change' }],
        VipTime: [{ required: true, message: ' ', trigger: 'change' }],
        Level: [{ required: true, message: ' ', trigger: 'blur' }],
        Integral: [{ required: true, message: ' ', trigger: 'blur' }]
        // Province: [{ required: true, message: '请选择省市区', trigger: 'change' }]
      }
    }
  },
  watch: {},
  computed: {
    Address () {
      return this.info.Province + this.info.City + this.info.Area
    },
    srcList () {
      return [
        this.info.StoreLicenseImage,
        this.info.StoreIDImage,
        this.info.StoreIDImage2,
        this.info.StoreBannerImage,
        this.info.OtherDocuments
      ]
    }
  },
  methods: {
    async editDetermine () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.3)'
      })

      const form = {}
      form.Id = this.info.Id
      form.StoreName = this.info.StoreName
      form.Name = this.info.Name
      form.StorePhone = this.info.StorePhone
      form.StoreHeadImage = this.info.StoreHeadImage
      form.Province = this.info.Province
      form.City = this.info.City
      form.Area = this.info.Area
      form.StoreAddress = this.info.StoreAddress
      form.StoreWx = this.info.StoreWx
      form.Longitude = this.info.Longitude - 0
      form.Latitude = this.info.Latitude - 0
      form.StoreType = this.info.StoreType
      form.CertificationType = this.info.CertificationType
      form.StoreBriefIntroduction = this.info.StoreBriefIntroduction
      form.IsServer = true

      console.log('form', form)

      const { data: res } = await this.$api.members.editStore(form)
      console.log('提交结果', res)

      const { data: res2 } = await this.$api.members.auditStore({
        Id: this.info.Id,
        StoreAuditStatus: this.info.StoreAuditStatus,
        StoreAuditContent: this.info.StoreAuditContent
      })
      console.log('审核结果', res2)

      if (
        !this.info.IsStore &&
        this.info.StoreAuditStatus === 2 &&
        this.info.CertificationType === '商户'
      ) {
        const { data: res1 } = await this.$api.release.articleAdd({
          userId: this.info.Id,
          title: '只要圈子足够大 一切皆有可能！我已完成认证，敬请多多留言交流哦！',
          imgurls:
            'https://cjq-1259532874.cosgz.myqcloud.com/ON96NKES0PQL.jpg,https://cjq-1259532874.cosgz.myqcloud.com/AIQD5RP5L9P3.jpg,https://cjq-1259532874.cosgz.myqcloud.com/HD0P3B2HX3YD.jpg'
        })
        const { data: res2 } = await this.$api.release.articleAudit({
          id: res1.Id,
          status: 2
        })
        console.log('发布结果', res2)
        window.location.hash = '#/businessManagement/release'
      } else if (!this.info.IsStore && this.info.StoreAuditStatus === 2) {
        const { data: res3 } = await this.$api.release.goodsSave({
          UserId: this.info.Id,
          Id: 0,
          Status: 1,
          Type: 2,
          Name: '只要圈子足够大 一切皆有可能！我已完成认证，敬请多多留言交流哦！',
          DetailImage:
            'https://cjq-1259532874.cosgz.myqcloud.com/ON96NKES0PQL.jpg,https://cjq-1259532874.cosgz.myqcloud.com/AIQD5RP5L9P3.jpg,https://cjq-1259532874.cosgz.myqcloud.com/HD0P3B2HX3YD.jpg',
          Category1: '首发'
        })
        console.log('发布结果', res3)
        window.location.hash = '#/businessManagement/releaseGoods'
      }

      this.$message.success('修改成功')
      loading.close()
      this.$emit('editOk')
    },

    // 判断是否过期
    ifOverdue (time) {
      // console.log('time', time)
      const nowDate = new Date()
      const date = new Date(time).getTime() - nowDate.getTime()

      return date < 0
    }
  }
}
</script>

<style lang="scss" scoped>
.auditImg {
  display: flex;
  .block {
    margin-right: 25px;
    span {
      display: block;
      width: 120px;
      text-align: center;
    }
    img {
      width: 120px;
    }
  }
}
</style>
