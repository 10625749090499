<!--
 * @Description: 关注/粉丝
-->
<template>
  <div>
    <el-table ref="TableListRef" class="TableList" border :data="List" v-loading="loading">
      <el-table-column prop="LoginName" label="手机号码" align="center">
        <template slot-scope="scope">
          {{ scope.row.LoginName }}
        </template>
      </el-table-column>

      <el-table-column prop="StoreName" label="商户名称" align="center">
        <template slot-scope="scope">
          {{ scope.row.StoreName || '--' }}
        </template>
      </el-table-column>

      <el-table-column label="省市区" align="center">
        <template slot-scope="scope"> {{ scope.row.Province }}-{{ scope.row.City }} </template>
      </el-table-column>

      <el-table-column prop="VipTime" label="会员剩余时间" align="center">
        <template slot-scope="scope">
          <template>
            <span v-if="ifOverdue(scope.row.VipTime)" style="color: #f00;">已过期</span>
            <span v-else>{{ scope.row.VipTime | remainingDays }}天</span>
          </template>
          <!-- ({{ new Date(scope.row.VipTime) | parseTime('{y}/{m}/{d} {h}:{i}:{s}') }}) -->
        </template>
      </el-table-column>

      <el-table-column prop="CreateTime" label="创建时间" align="center">
        <template slot-scope="scope">
          {{ new Date(scope.row.CreateTime) | parseTime }}
        </template>
      </el-table-column>
    </el-table>

    <div class="fenye1">
      <!-- 分页 -->
      <el-pagination
        class="TablePagination"
        background
        @current-change="handleCurrentChange"
        :current-page="pageIndex"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FocusAndFans',
  props: {
    type: {
      type: String,
      default: '0'
    },
    loading: {
      type: Boolean,
      default: false
    },
    UserId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      pageIndex: 1,

      total: 0,

      List: []
    }
  },
  created () {
    this.getList()
  },
  watch: {
    type (newVal, oldVal) {
      this.getList()
    }
  },
  methods: {
    async getList (pageindex = 1) {
      let data = null
      if (this.type === '0') {
        // 关注
        data = await this.$api.release.getStoreSupportList({
          pageindex,
          userId: this.UserId
        })
      } else if (this.type === '1') {
        // 粉丝
        data = await this.$api.release.getStoreFansList({
          pageindex,
          userId: this.UserId
        })
      }
      this.List = data.data.list
      this.total = data.data.count
      console.log('列表', this.List)
    },

    // 判断是否过期
    ifOverdue (time) {
      // console.log('time', time)
      const nowDate = new Date()
      const date = new Date(time).getTime() - nowDate.getTime()
      return date < 0
    },

    async handleCurrentChange (newPage) {
      this.pageIndex = newPage
      this.List = []
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })

      this.getList(newPage)
    }
  }
}
</script>

<style lang="scss" scoped></style>
