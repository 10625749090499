<!--
 * @Description: 轮播图管理
-->
<template>
  <div>
    <div class="TableTop">
      <!-- 搜索 -->
      <div class="TableSearch">
        <el-form :model="query" @submit.native.prevent>
          <el-form-item label="显示位置：" class="form-item-b0" clearable>
            <el-select v-model="query.category" placeholder="请选择显示位置">
              <el-option label="首页" :value="-1"></el-option>
              <el-option label="报价列表" :value="-2"></el-option>
              <el-option v-for="item in columnList" :key="item.Id" :label="item.Title" :value="item.Id"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="页数：" class="form-item-b0" clearable>
            <el-input v-model="query.pageindex" placeholder="请输入页数" size="small" clearable @keyup.native.enter="getList('place')"></el-input>
          </el-form-item>
        </el-form>
        <div class="TableSearch_btn">
          <el-button type="primary" size="small" @click="getList('place')">筛选</el-button>
          <el-button size="small" @click="init()">重置</el-button>
        </div>
      </div>
      <!-- 按钮 -->
      <div class="TableTopBtn">
        <el-button type="primary" size="small" icon="el-icon-plus" @click="onAddOrEdit()">
          新增
        </el-button>
      </div>
    </div>
    <!-- 表格 -->
    <el-table ref="TableListRef" class="TableList" border :data="list" v-loading="loading">
      <el-table-column label="图片" align="center" width="120">
        <template slot-scope="scope">
          <ImgBox :src="scope.row.Image" :width="80" :height="80"></ImgBox>
        </template>
      </el-table-column>
      <el-table-column prop="CategoryTitle" label="显示位置" align="center">
        <template slot-scope="scope">{{
          scope.row.CategoryTitle === '热点行情' ? '首页' : scope.row.CategoryTitle
        }}</template>
      </el-table-column>
      <el-table-column prop="Title" label="轮播名称" align="center"></el-table-column>
      <el-table-column prop="Link" label="跳转地址" align="center">
        <template slot-scope="scope">{{ scope.row.Link || '-' }}</template>
      </el-table-column>
      <el-table-column prop="Sort" label="排序" align="center"> </el-table-column>
      <el-table-column prop="Date" label="更新时间" align="center">
        <template slot-scope="scope">
          {{ new Date(scope.row.Date) | parseTime }}
        </template>
      </el-table-column>
      <el-table-column prop="Status" label="状态" align="center">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.Status" :active-value="1" :inactive-value="0" @change="editStatus(scope.row)" active-color="#13ce66" inactive-color="#ff4949">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="100" fixed="right">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onAddOrEdit(scope.row.Id)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增、编辑对话框 -->
    <AddOrEditDialog ref="addOrEditDialogRef" @fetch-data="getList('place')" />
  </div>
</template>

<script>
import AddOrEditDialog from './addOrEditDialog'

export default {
  name: 'advertisingIndex',
  components: {
    AddOrEditDialog
  },
  data () {
    return {
      list: [],
      loading: true,
      query: {
        pageindex: 1,
        category: null
      },
      // 栏目列表
      columnList: []
    }
  },
  created () {
    this.getColumnList()
    this.init()
  },
  methods: {
    // 初始化
    init () {
      this.query.category = null
      this.getList('place')
    },

    // 获取栏目列表
    async getColumnList () {
      const { data: res } = await this.$api.column.getList({})
      // console.log('columnList', res)
      this.columnList = res
    },

    // 获取列表
    async getList (place) {
      this.query.pageindex = this.query.pageindex - 0

      const { data: res } = await this.$api.ad.getList(this.query)
      console.log('获取列表', res)
      this.list = res
      this.loading = false
    },

    // 新增、编辑
    onAddOrEdit (id) {
      this.$refs.addOrEditDialogRef.showAddOrEdit(id)
    },

    // 状态改变
    async editStatus (item) {
      console.log('状态改变', item)

      const form = {
        listid: [item.Id],
        status: item.Status
      }
      await this.$api.ad.edit_status(form)
      this.$message.success('状态改变成功')
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped></style>
