<!--
 * @Description: 报价公告
-->
<template>
  <div>
    <!-- 按钮 -->
    <!-- <div class="TableTopBtn">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="onAddOrEdit()">
        新增
      </el-button>
    </div> -->
    <!-- 表格 -->
    <el-table ref="TableListRef" class="TableList" border :data="list" v-loading="loading">
      <el-table-column prop="Content" label="内容" align="center"></el-table-column>
      <el-table-column label="类型" align="center" width="120">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.Type === 1">酒</el-tag>
          <el-tag v-else type="warning">烟</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="CreateTime" label="更新时间" align="center">
        <template slot-scope="scope">
          {{ new Date(scope.row.Date) | parseTime }}
        </template>
      </el-table-column>
      <el-table-column prop="Status" label="状态" align="center" width="120">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.Status"
            :active-value="1"
            :inactive-value="0"
            @change="editStatus(scope.row)"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="100" fixed="right">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onAddOrEdit(scope.row.Id)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增、编辑 -->
    <AddOrEditDialog ref="addOrEditDialogRef" @fetch-data="getList()" />
  </div>
</template>

<script>
import AddOrEditDialog from './announcementDialog'

export default {
  name: 'offerAnnouncement',
  components: {
    AddOrEditDialog
  },
  data () {
    return {
      loading: true,
      query: {
        type: 0
      },
      list: []
    }
  },
  created () {
    this.getList()
  },
  methods: {
    // 获取列表
    async getList () {
      const { data: res } = await this.$api.offer.getList(this.query)
      console.log('获取列表', res)
      this.loading = false
      this.list = res
    },

    // 新增、编辑
    onAddOrEdit (id) {
      this.$refs.addOrEditDialogRef.showAddOrEdit(id)
    },

    // 状态改变
    async editStatus (item) {
      console.log('状态改变', item)

      try {
        const form = {
          Id: item.Id,
          Status: item.Status
        }
        await this.$api.offer.edit_status(form)
        this.$message.success('状态改变成功')
        this.getList()
      } catch (e) {
        this.getList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.TableTopBtn {
  margin-bottom: 20px;
}
</style>
