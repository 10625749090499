<!--
 * @Description: 条码管理
-->
<template>
  <div>
    <div class="TableTop">
      <!-- 搜索 -->
      <div class="TableSearch">
        <el-form :model="query" @submit.native.prevent>
          <el-form-item label="条码：" class="form-item-b0">
            <el-input v-model="query.code" placeholder="请输入条码" size="small" clearable></el-input>
          </el-form-item>

          <el-form-item label="状态：" class="form-item-b0">
            <!-- <el-select v-model="query.status" placeholder="请选择状态">
              <el-option label="全部" :value="null"></el-option>
              <el-option label="已审核" :value="1"></el-option>
              <el-option label="未审核" :value="2"></el-option>
              <el-option label="已删除" :value="0"></el-option>
            </el-select>  -->
            <el-radio v-model="query.status" :label="2">未审核</el-radio>
            <el-radio v-model="query.status" :label="0">删除</el-radio>
            <el-radio v-model="query.status" :label="1">已审核</el-radio>
          </el-form-item>
        </el-form>

        <div class="TableSearch_btn">
          <el-button type="primary" size="small" @click="screening()">筛选</el-button>
          <el-button size="small" @click="init()">重置</el-button>

          <el-button type="primary" size="small" @click="multipleAudit(1)">批量审核</el-button>
          <el-button type="danger" size="small" @click="multipleAudit(0)">批量删除</el-button>
        </div>
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      ref="TableListRef"
      class="TableList"
      border
      :data="list"
      @selection-change="handleSelectionChange"
      v-loading="loading"
    >
      <el-table-column type="selection" align="center"> </el-table-column>

      <el-table-column prop="Id" label="Id" align="center"></el-table-column>

      <el-table-column prop="LoginName" label="手机号" align="center"></el-table-column>

      <el-table-column prop="Name" label="名称" align="center"></el-table-column>

      <el-table-column prop="BarCode" label="条码" align="center">
        <template slot-scope="scope">
          <span>
            {{ codeShow(scope.row.BarCode) }}
          </span>
        </template>
      </el-table-column>

      <el-table-column prop="Status" label="状态" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.Status == 1">已审核</span>
          <span v-else-if="scope.row.Status == 2" style="color: #f00">未审核</span>
          <span v-else style="color: #f00">已删除</span>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" width="300" fixed="right">
        <template slot-scope="scope">
          <!-- <el-button type="primary" size="mini" @click="operation(scope.row)"> 操作 </el-button> -->
          <div style="min-height: 30px; display: flex; justify-content: center; align-items: center">
            <el-radio-group v-model="scope.row.Status" @change="changeStatus(scope.row)">
              <el-radio v-if="scope.row.Status == 1" :label="2">未审核</el-radio>
              <el-radio :label="0">删除</el-radio>
              <el-radio :label="1">已审核</el-radio>
            </el-radio-group>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="fenye">
      <el-pagination
        class="TablePagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageindex"
        :page-sizes="[20, 40, 60, 80, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <!-- 编辑条码资料 -->
    <el-dialog
      :visible.sync="editDialog"
      class="FormDialogBox"
      width="400px"
      title=""
      :close-on-click-modal="false"
      @close="Cancel()"
      ref="eDialog"
    >
      <el-form label-width="auto" :model="editForm" :rules="rules" ref="ruleForm">
        <el-form-item label="Id" prop="Id">
          <el-input v-model="editForm.Id" placeholder="Id" clearable disabled></el-input>
        </el-form-item>

        <el-form-item label="名称" prop="Name">
          <el-input v-model="editForm.Name" placeholder="名称" clearable></el-input>
        </el-form-item>

        <el-form-item label="条码" prop="BarCode">
          <el-input v-model="editForm.BarCode" placeholder="条码" clearable></el-input>
        </el-form-item>

        <el-form-item label="状态" prop="Status">
          <el-radio v-model="editForm.Status" :label="2">未审核</el-radio>
          <el-radio v-model="editForm.Status" :label="0">删除</el-radio>
          <el-radio v-model="editForm.Status" :label="1">已审核</el-radio>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false">取 消</el-button>
        <el-button type="primary" @click="editDetermine()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'codeIndex',

  data() {
    return {
      loading: true,
      query: {
        code: '',
        status: 2,
        pageindex: 1,
        pageSize: 20
      },

      rules: [],

      total: 0, // 总条数
      editDialog: false,
      list: [],
      editForm: {},

      activeName: '商品库列表',
      merchantsInfo: {},

      selectList: []
    }
  },

  created() {
    this.getList('place')
  },

  computed: {},

  methods: {
    // 初始化
    init() {
      this.query = {
        code: '',
        status: 2
      }

      this.getList('place')
    },

    async screening() {
      console.log(this.query)

      this.getList('place')
    },

    Cancel() {
      console.log('对话框关闭')
      this.editForm = {}

      this.query = {
        code: '',
        status: 2
      }
    },

    operation(data) {
      console.log(data)
      this.editForm = {
        ...data
      }

      this.editDialog = true
    },

    async editDetermine() {
      try {
        await this.$api.offer.saveBarCode({
          code: this.editForm.BarCode,
          status: this.editForm.Status,
          name: this.editForm.Name
        })

        this.$message.success('修改成功')
      } catch (error) {
        this.$message.error('修改失败')
      }

      this.getList()
      this.editForm = {}
      this.editDialog = false
    },

    async changeStatus(e) {
      console.log(e)
      this.$confirm('确认修改？')
        .then(async (_) => {
          try {
            await this.$api.offer.saveBarCode({
              code: e.BarCode,
              status: e.Status,
              name: e.Name
            })

            this.$message.success('修改成功')

            this.getList()
          } catch (error) {
            this.$message.error('修改失败')
          }
        })
        .catch((_) => {
          const index = this.list.findIndex((item) => item.Id === e.Id)
          this.list[index].Status = 2
        })
    },

    handleSelectionChange(e) {
      this.selectList = e.map((item) => item.Id)
      console.log(this.selectList)
    },

    async multipleAudit(status) {
      console.log(this.selectList, status)

      if (this.selectList.length === 0) {
        return this.$message.warning('请选择条码')
      }
      this.$confirm('确认修改？')
        .then(async (_) => {
          try {
            await this.$api.offer.saveBarCodeStatus({
              id: this.selectList.join(','),
              status
            })

            this.$refs.TableListRef.clearSelection()
            this.$message.success('操作成功')

            this.selectList = []
            this.getList()
          } catch (error) {
            this.$message.error('操作失败')
          }
        })
        .catch((_) => {})
    },

    // 获取列表
    async getList(place) {
      // 重置页数
      if (place) {
        // 滚动条回到顶部
        this.$nextTick(() => {
          this.$refs.TableListRef.bodyWrapper.scrollTop = 0
        })
      }
      console.log('this.query', this.query)

      const res = await this.$api.offer.barCodeList(this.query)
      console.log('获取条码列表', res)

      this.loading = false
      this.list = res.data
      this.total = res.count
    },

    // 条码显示
    codeShow(code) {
      const len = code.length
      let str = ''

      for (let i = 0; i < len; i++) {
        if (i === 1 || (i - 1) % 6 === 0) {
          str += ' '
        }

        str += code[i]
      }

      return str
    },

    // 每页显示条数和当前页码
    handleSizeChange(newSize) {
      this.query.pageSize = newSize
      this.list = []

      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
        this.getList()
      })
    },
    handleCurrentChange(newPage) {
      this.query.pageindex = newPage
      this.list = []

      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
        this.getList()
      })
    },

    // 判断是否过期
    ifOverdue(time) {
      // console.log('time', time)
      const nowDate = new Date()
      const date = new Date(time).getTime() - nowDate.getTime()

      return date < 0
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .FormDialogBox .el-dialog__body {
  max-height: 82vh;
}

.TableList1 {
  margin-bottom: 60px;
}

.fenye1 {
  width: calc(100% - 30px);
  height: 50px;
  padding-top: 9px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  right: 30px;
  z-index: 9;
}

.tabs {
  background-color: #fff;
  position: sticky;
  top: -30px;
  z-index: 9;
}

.merchants_info {
  min-height: 600px;

  .merchants_info_item {
    padding: 10px;
    color: #606266;
    font-size: 14px;
    line-height: 40px;
    font-weight: bold;
    display: flex;
  }
}
</style>
