<!--
 * @Description: 地址选择器
-->
<template>
  <div>
    <div class="address_picker">
      <el-select
        v-model="provinceCheck"
        ref="select1"
        placeholder="请选择省"
        clearable
        @change="handleChange(1, $event)"
        filterable
        style="width: 100%;"
      >
        <el-option v-for="item in provinceSelect" :key="item.value" :label="item.label" :value="item"> </el-option>
      </el-select>

      <el-select
        v-model="cityCheck"
        ref="select2"
        placeholder="请选择市"
        clearable
        @change="handleChange(2, $event)"
        filterable
        style="width: 100%; margin-left: 4px;"
      >
        <el-option v-for="item in citySelect" :key="item.value" :label="item.label" :value="item"> </el-option>
      </el-select>

      <el-select
        v-model="areaCheck"
        ref="select3"
        placeholder="请选择区"
        clearable
        @change="handleChange(3, $event)"
        filterable
        style="width: 100%; margin-left: 4px;"
      >
        <el-option v-for="item in areaSelect" :key="item.value" :label="item.label" :value="item"> </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import province from '@/utils/provinceObj.json'
import city from '@/utils/cityObj.json'
import area from '@/utils/areaObj.json'

export default {
  name: 'AddressPicker',
  data () {
    return {
      province,
      city,
      area,

      provinceCheck: {
        value: '',
        label: ''
      },

      cityCheck: {
        value: '',
        label: ''
      },

      areaCheck: {
        value: '',
        label: ''
      }
    }
  },

  created () {},

  computed: {
    provinceSelect () {
      return Object.values(this.province['0'])
    },

    citySelect () {
      if (this.provinceCheck.value) {
        return Object.values(this.city['0,' + this.provinceCheck.value])
      } else {
        const arr = []
        for (const key in this.city) {
          // console.log(this.city[key])
          arr.push(...Object.values(this.city[key]))
        }
        return arr
      }
    },

    areaSelect () {
      if (this.provinceCheck.value && this.cityCheck.value) {
        return Object.values(this.area['0,' + this.provinceCheck.value + ',' + this.cityCheck.value])
      } else if (this.cityCheck.value) {
        const arr = []
        for (const key in this.area) {
          if (key.indexOf(this.cityCheck.value) > -1) {
            console.log(key, this.cityCheck.value, this.area[key])
            arr.push(...Object.values(this.area[key]))
          }
        }
        return arr
      } else {
        return []
      }
    }
  },

  methods: {
    init () {
      this.provinceCheck = {
        value: '',
        label: ''
      }
      this.cityCheck = {
        value: '',
        label: ''
      }
      this.areaCheck = {
        value: '',
        label: ''
      }
    },

    // 地区选择
    handleChange (type, e) {
      switch (type) {
        case 1:
          this.cityCheck = {
            value: '',
            label: ''
          }
          this.areaCheck = {
            value: '',
            label: ''
          }
          if (this.$refs.select1) {
            this.$refs.select1.blur()
          }
          break
        case 2:
          this.areaCheck = {
            value: '',
            label: ''
          }
          if (this.$refs.select2) {
            this.$refs.select2.blur()
          }
          break
        case 3:
          if (this.$refs.select3) {
            this.$refs.select3.blur()
          }
          break
      }

      this.$emit('handleChange', {
        province: this.provinceCheck.label,
        city: this.cityCheck.label,
        area: this.areaCheck.label
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.address_picker {
  display: flex;
}
</style>
