export const dataList = [
  {
    productName: '细支好猫长乐',
    isCheck: 1,
    Category: '全部',
    Category2: '陕西',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/21bf4e90d6864657a517eaa464047dff好猫（细支长乐）.png',
    productMoney: '153.0',
    oldName: '好猫（细支长乐）',
    guidePrice: 139.92
  },
  {
    productName: '好猫长乐',
    isCheck: 1,
    Category: '全部',
    Category2: '陕西',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/107f416ecab5486e8c00ecf738506a2e好猫（长乐）.png',
    productMoney: '130.0',
    oldName: '好猫（长乐）',
    guidePrice: 121.9
  },
  {
    productName: '黑招财猫',
    isCheck: 1,
    Category: '全部',
    Category2: '陕西',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/5816459eea1c4fda8bd6438a66756169好猫（招财猫1600）.png',
    productMoney: '150.0',
    oldName: '好猫（招财猫1600）',
    guidePrice: 158
  },
  {
    productName: '金丝猴',
    isCheck: 1,
    Category: '全部',
    Category2: '陕西',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/72ab9402cbbf4906a12afc458b568822好猫（金丝猴）.png',
    productMoney: '138.0',
    oldName: '好猫（金丝猴）',
    guidePrice: 129.3
  },
  {
    productName: '好猫长乐九美',
    isCheck: 1,
    Category: '全部',
    Category2: '陕西',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/d330a07cbd494605b37c9e93bbbe5dce.png',
    productMoney: '162.0',
    oldName: '好猫长乐九美',
    guidePrice: 175
  },
  {
    productName: '金猴王',
    isCheck: 1,
    Category: '全部',
    Category2: '陕西',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/681f8974ee3d49df8a93cbe7249d05ae好猫（金猴王）.png',
    productMoney: '77.0',
    oldName: '好猫（金猴王）',
    guidePrice: 66.8
  },
  {
    productName: '磨砂金猴王',
    isCheck: 1,
    Category: '全部',
    Category2: '陕西',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/908abc18d05d46918f9a40777d2e1977好猫（猴王磨砂）.png',
    productMoney: '122.0',
    oldName: '好猫（猴王磨砂）',
    guidePrice: 97
  },
  {
    productName: '红韵延安',
    isCheck: 1,
    Category: '全部',
    Category2: '陕西',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/bc77e62de698400da24647476450adac延安（红韵）.png',
    productMoney: '189.0',
    oldName: '延安（红韵）',
    guidePrice: 225
  },
  {
    productName: '好猫招财进宝',
    isCheck: 1,
    Category: '全部',
    Category2: '陕西',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/bf23e659a1534d6bbed0e1d7fa9469d4好猫（招财进宝）.png',
    productMoney: '127.0',
    oldName: '好猫（招财进宝）',
    guidePrice: null
  },
  {
    productName: '软延安',
    isCheck: 1,
    Category: '全部',
    Category2: '陕西',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/83c7563d898e4935abd30f5eb2545fdf延安（软）.png',
    productMoney: '71.0',
    oldName: '延安（软）',
    guidePrice: null
  },
  {
    productName: '七匹狼（银细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/874377e2a10e4132970b9b64a029bfd6.png',
    productMoney: '232.0',
    oldName: '七匹狼（银细支）',
    guidePrice: 225
  },
  {
    productName: '中支金丝猴',
    isCheck: 1,
    Category: '全部',
    Category2: '陕西',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/b783addcada64017bd2208373b29edb9好猫（中支金丝猴）.png',
    productMoney: '193.0',
    oldName: '好猫（中支金丝猴）',
    guidePrice: 201.4
  },
  {
    productName: '好猫长安印象',
    isCheck: 1,
    Category: '全部',
    Category2: '陕西',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/6f99f5731e6a45d4b3d6acca6692af28.png',
    productMoney: '205.0',
    oldName: '好猫（长安印象）',
    guidePrice: null
  },
  {
    productName: '好猫（细支招财猫）',
    isCheck: 2,
    Category: '全部',
    Category2: '好猫',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/86c33d0fe2eb4c269d248dd23a502386好猫（细支招财猫）.png',
    productMoney: '215.0',
    oldName: '好猫（细支招财猫）',
    guidePrice: 225
  },
  {
    productName: '好猫（细支天赋）',
    isCheck: 2,
    Category: '全部',
    Category2: '好猫',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/1ae3d4c7d135452896bea602739ea05c好猫（细支天赋）.png',
    productMoney: '300.0',
    oldName: '好猫（细支天赋）',
    guidePrice: 339.2
  },
  {
    productName: '延安（1935）',
    isCheck: 2,
    Category: '全部',
    Category2: '延安',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/3b6ef4213d7240cd97126ea9380d3900延安（1935）.png',
    productMoney: '415.0',
    oldName: '延安（1935）',
    guidePrice: 424
  },
  {
    productName: '延安（圣地河谷）',
    isCheck: 2,
    Category: '全部',
    Category2: '延安',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/434db035db0e4f4884718109b84e18ec延安（圣地河谷）.png',
    productMoney: '225.0',
    oldName: '延安（圣地河谷）',
    guidePrice: 263
  },
  {
    productName: '延安（公主）',
    isCheck: 2,
    Category: '全部',
    Category2: '延安',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/71537c3f63c74eb78f3cebddf6c7be00延安（公主）.png',
    productMoney: '117.0',
    oldName: '延安（公主）',
    guidePrice: 114.5
  },
  {
    productName: '延安（硬青春岁月）',
    isCheck: 2,
    Category: '全部',
    Category2: '延安',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/006dc95ff6a14875940300ea570d6296.png',
    productMoney: '145.0',
    oldName: '延安（硬青春岁月）',
    guidePrice: 175
  },
  {
    productName: '延安（细支1935）',
    isCheck: 2,
    Category: '全部',
    Category2: '延安',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/6a284622b6594016b759b28405085dd0延安（细支1935）.png',
    productMoney: '680.0',
    oldName: '延安（细支1935）',
    guidePrice: 510
  },
  {
    productName: '猴王（磨砂）',
    isCheck: 2,
    Category: '全部',
    Category2: '好猫',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/31d1fcac35d349fe8520b565bf67295b猴王（磨砂）.png',
    productMoney: '105.0',
    oldName: '猴王（磨砂）',
    guidePrice: null
  },
  {
    productName: '中支千年帝都',
    isCheck: 1,
    Category: '全部',
    Category2: '陕西',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/98d686c297fe488a989644eb7ec93d62延安（千年帝都中支）.png',
    productMoney: '720.0',
    oldName: '延安（千年帝都中支）',
    guidePrice: 678.4
  },
  {
    productName: '中支延安',
    isCheck: 0,
    Category: '全部',
    Category2: '中支延安',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/575fe11d7f044a9e97492b9a6cd23c9f延安（中支1935）.png',
    productMoney: '410.0',
    oldName: '延安（中支1935）',
    guidePrice: null
  },
  {
    productName: '中支红韵延安',
    isCheck: 1,
    Category: '全部',
    Category2: '陕西',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/43c0ebadb8074586a5d752d98967dd33.png',
    productMoney: '180.0',
    oldName: '延安（中支红韵）',
    guidePrice: 201.4
  },
  {
    productName: '细支千年帝都',
    isCheck: 1,
    Category: '全部',
    Category2: '陕西',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/df1a7e1976474f409e822b5d863b46ec延安（千年帝都细支）.png',
    productMoney: '330.0',
    oldName: '延安（千年帝都细支）',
    guidePrice: 339.2
  },
  {
    productName: '好猫（吉祥）',
    isCheck: 2,
    Category: '全部',
    Category2: '好猫',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/479bc8ec0d7e4d239d2e435199dc833e好猫（吉祥）.png',
    productMoney: '210.0',
    oldName: '好猫（吉祥）',
    guidePrice: 218.36
  },
  {
    productName: '好猫（金延安）',
    isCheck: 2,
    Category: '全部',
    Category2: '好猫',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/94ac92f6248842de94a853f6987661a7好猫（金延安）.png',
    productMoney: '130.0',
    oldName: '好猫（金延安）',
    guidePrice: null
  },
  {
    productName: '好猫（炫蓝）',
    isCheck: 2,
    Category: '全部',
    Category2: '好猫',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/845d7835f51047fb8e78dea0509ed16b好猫（炫蓝）.png',
    productMoney: '170.0',
    oldName: '好猫（炫蓝）',
    guidePrice: 169.6
  },
  {
    productName: '好猫（千年帝都）',
    isCheck: 2,
    Category: '全部',
    Category2: '好猫',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/4376442829734a4db10038a4561fae5d好猫（千年帝都）.png',
    productMoney: '190.0',
    oldName: '好猫（千年帝都）',
    guidePrice: null
  },
  {
    productName: '合延安',
    isCheck: 1,
    Category: '全部',
    Category2: '陕西',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/d89d19098b1741f383fd119476b41dd1延安（硬）.png',
    productMoney: '98.0',
    oldName: '延安（硬）',
    guidePrice: 88
  },
  {
    productName: '猴王（神采）',
    isCheck: 2,
    Category: '全部',
    Category2: '好猫',
    Factory: '陕西',
    productImg: 'https://yanjiuchacha.com/api/57ac79ec50ec4eda8db5d8195064c4c3猴王（神采）.png',
    productMoney: '108.0',
    oldName: '猴王（神采）',
    guidePrice: null
  },
  {
    productName: '细泰山心悦',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/4b3ca7a5c6d84f96bd99e9db5c593a29泰山（心悦）.png',
    productMoney: '151.0',
    oldName: '泰山（心悦）',
    guidePrice: 139.92
  },
  {
    productName: '细泰山颜悦',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/7440e132041348ffbf042486ef8aff46.png',
    productMoney: '180.0',
    oldName: '泰山（颜悦）',
    guidePrice: 167.48
  },
  {
    productName: '望岳泰山',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/eedf290f88b94f5eb195f79d64540990泰山（望岳）.png',
    productMoney: '162.0',
    oldName: '泰山（望岳）',
    guidePrice: 167.48
  },
  {
    productName: '细儒风泰山',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/de515533e9e44faea1e9e3aa8b9f01e1泰山（儒风细支）.png',
    productMoney: '250.0',
    oldName: '泰山（儒风细支）',
    guidePrice: 263
  },
  {
    productName: '泰山红将军',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/ac0f3049bdc1482facde2324f0451f6b泰山（红将军）.png',
    productMoney: '92.0',
    oldName: '泰山（红将军）',
    guidePrice: 88
  },
  {
    productName: '醇香哈德门',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/3eb2bb56fd694b469554039d800ce2ee哈德门（纯香）.png',
    productMoney: '73.0',
    oldName: '哈德门（纯香）',
    guidePrice: 47.7
  },
  {
    productName: '青秀泰山',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/6b4ce25d57de469e9bf759d0e8985a61泰山（青秀）.png',
    productMoney: '127.0',
    oldName: '泰山（青秀）',
    guidePrice: 121.9
  },
  {
    productName: '宏图泰山',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/410f899b30bc42849d813458c9defa8c泰山（宏图）.png',
    productMoney: '112.0',
    oldName: '泰山（宏图）',
    guidePrice: 93.28
  },
  {
    productName: '泰山白将军',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/5db079a5d0484efabb1c7da0603226a1泰山（白将军）.png',
    productMoney: '122.0',
    oldName: '泰山（白将军）',
    guidePrice: 114.5
  },
  {
    productName: '泰山（金将中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '泰山',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/cbcd056484334cff819dac49dd436a33泰山（金将中支）.png',
    productMoney: '192.0',
    oldName: '泰山（金将中支）',
    guidePrice: 201.4
  },
  {
    productName: '泰山香韵',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/694e01f822bb427e9c72b6269eedca23泰山（茉莉香韵）.png',
    productMoney: '420.0',
    oldName: '泰山茶香（茉莉香韵）',
    guidePrice: 424
  },
  {
    productName: '细泰山合悦',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/98d69ee54623479e9a47ad21e8aa484a泰山（合悦）.png',
    productMoney: '205.0',
    oldName: '泰山（合悦）',
    guidePrice: 225
  },
  {
    productName: '泰山（硬红八喜）',
    isCheck: 2,
    Category: '全部',
    Category2: '泰山',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/ae3b088cf87948fb84afa80b9f6ae165泰山（硬红八喜）.png',
    productMoney: '68.0',
    oldName: '泰山（硬红八喜）',
    guidePrice: 57.24
  },
  {
    productName: '泰山（儒风）',
    isCheck: 2,
    Category: '全部',
    Category2: '泰山',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/058d49f09b014efd95f94594a3e51e0c泰山（儒风）.png',
    productMoney: '330.0',
    oldName: '泰山（儒风）',
    guidePrice: 455.8
  },
  {
    productName: '泰山（将军）',
    isCheck: 2,
    Category: '全部',
    Category2: '泰山',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/df5299a04a944de29751e2109cea9277泰山（将军）.png',
    productMoney: '72.5',
    oldName: '泰山（将军）',
    guidePrice: null
  },
  {
    productName: '细支白将军',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/66eaa35511594e1396e7c8e3c1410b86泰山（白将军细支）.png',
    productMoney: '158.0',
    oldName: '泰山（白将军细支）',
    guidePrice: 155
  },
  {
    productName: '中支泰山将军',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/7624616f3a5248a5b8ca70a1df03a25c泰山（中支将军）.png',
    productMoney: '193.0',
    oldName: '泰山（中支将军）',
    guidePrice: 201.4
  },
  {
    productName: '金典哈德门',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/1c328124e62546ddbc7cce3ce5cd77d5哈德门（金典）.png',
    productMoney: '79.0',
    oldName: '哈德门（金典）',
    guidePrice: null
  },
  {
    productName: '泰山（儒风双中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '泰山',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/3a46a3529db3498c924de60d7259b601.png',
    productMoney: '260.0',
    oldName: '泰山（儒风双中支）',
    guidePrice: 339.2
  },
  {
    productName: '软哈德门',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/3e55817817b64be9a9cc5b727a555b4e哈德门（软）.png',
    productMoney: '55.0',
    oldName: '哈德门（软）',
    guidePrice: null
  },
  {
    productName: '泰山（拂光细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '泰山',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/41748fa62d9840fa886dedd4bcc317bb泰山（佛光细支）.png',
    productMoney: '330.0',
    oldName: '泰山（拂光细支）',
    guidePrice: 360.4
  },
  {
    productName: '华贵泰山',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/98eca42c52cd4726bbb63d6abebc92e3泰山（华贵）.png',
    productMoney: '105.0',
    oldName: '泰山（华贵）',
    guidePrice: 87.98
  },
  {
    productName: '精品哈德门',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/df35b14b0ac345ec9417dbb09b3a26ed哈德门（精品）.png',
    productMoney: '78.0',
    oldName: '哈德门（精品）',
    guidePrice: null
  },
  {
    productName: '泰山中海御叶',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/f1e007b699664f4f959c5bf0d08081f1泰山（中海御叶）.png',
    productMoney: '210.0',
    oldName: '泰山（中海御叶）',
    guidePrice: 310
  },
  {
    productName: '泰山（拂光）',
    isCheck: 2,
    Category: '全部',
    Category2: '泰山',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/427251060cbf4b4e8573d6f92a4ace14.jpg',
    productMoney: '580.0',
    oldName: '泰山（拂光）',
    guidePrice: 720.8
  },
  {
    productName: '泰山（新品）',
    isCheck: 2,
    Category: '全部',
    Category2: '泰山',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/939500c87eef4ad5915f5f16824530ac泰山（新品）.png',
    productMoney: '187.0',
    oldName: '泰山（新品）',
    guidePrice: 190.8
  },
  {
    productName: '平安泰山',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/ecc92ea238ad47839023ffa254562582泰山（平安）.png',
    productMoney: '120.0',
    oldName: '泰山（平安）',
    guidePrice: 93.28
  },
  {
    productName: '皇家礼炮',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/da7647fc13a04d549ae76e72447e4a68泰山（皇家礼炮21响）.png',
    productMoney: '295.0',
    oldName: '泰山（皇家礼炮21响）',
    guidePrice: 263
  },
  {
    productName: '泰山（巅峰2号）',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/e1cf9f4ce2a04eb5b2ae4d3448155e2b泰山（巅峰2号）.png',
    productMoney: '1650.0',
    oldName: '泰山（巅峰2号）',
    guidePrice: null
  },
  {
    productName: '泰山（好客细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '泰山',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/9ecf8cd8dfe64f05893a805b6445415f泰山（好客细支）.png',
    productMoney: '180.0',
    oldName: '泰山（好客细支）',
    guidePrice: 185.5
  },
  {
    productName: '泰山（巅峰5号）',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/9ce4bb2aea1c40d88cf609cc00732d75泰山（巅峰5号）.png',
    productMoney: '860.0',
    oldName: '泰山（巅峰5号）',
    guidePrice: null
  },
  {
    productName: '泰山（琥珀）',
    isCheck: 2,
    Category: '全部',
    Category2: '泰山',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/6afe302965824338bc87e06129ac2388泰山（琥珀）.png',
    productMoney: '135.0',
    oldName: '泰山（琥珀）',
    guidePrice: 129.3
  },
  {
    productName: '八喜泰山',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/e6e7a6181abc45e5860c5ec5a3bd3e71泰山（八喜）.png',
    productMoney: '190.0',
    oldName: '泰山（八喜）',
    guidePrice: 188.68
  },
  {
    productName: '将军战神6H',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/7ea7a0ad961d45ca99ba4868a56bd2be.png',
    productMoney: '65.0',
    oldName: '将军战神6H',
    guidePrice: null
  },
  {
    productName: '哈德门1号',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/0979bfb27c574cbab2fdc6c5efd26459哈德门（壹号）.png',
    productMoney: '190.0',
    oldName: '哈德门（壹号）',
    guidePrice: 201.4
  },
  {
    productName: '常胜将军',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/a3232974e4a044f4aee68fe71a8aca32泰山（常胜将军）.png',
    productMoney: '125.0',
    oldName: '泰山（常胜将军）',
    guidePrice: 129.3
  },
  {
    productName: '泰山（中海御叶中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '泰山',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/85fa3f33f6484870af397b1b9548b070.png',
    productMoney: '270.0',
    oldName: '泰山（中海御叶中支）',
    guidePrice: 470
  },
  {
    productName: '泰山黑豹',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/0c00ebd4f15543b08f1debf74f0a309f泰山（黑豹）.png',
    productMoney: '125.0',
    oldName: '泰山（黑豹）',
    guidePrice: 112
  },
  {
    productName: '泰山战神4',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/40f5bd0ea4274b3b893b34bfd34f7eb1将军（战神4号）.png',
    productMoney: '95.0',
    oldName: '将军（战神4号）',
    guidePrice: null
  },
  {
    productName: '泰山静五',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/bd04dd5e204e4ca58bb2a3382febfbb9泰山（瀞五）.png',
    productMoney: '260.0',
    oldName: '泰山（瀞五）',
    guidePrice: 424
  },
  {
    productName: '泰山（合悦红）',
    isCheck: 2,
    Category: '全部',
    Category2: '泰山',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/d13de1ab9c904e15bea31cc2e7439622.jpg',
    productMoney: '205.0',
    oldName: '泰山（合悦红）',
    guidePrice: 225
  },
  {
    productName: '泰山（巅峰6号）',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/5ca958fe9fca40d59ac49300dd4691b2泰山（巅峰6号）.png',
    productMoney: '315.0',
    oldName: '泰山（巅峰6号）',
    guidePrice: 540
  },
  {
    productName: '将军（大力神）',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/0c2e5916e91a4af698a4f8ae7949da6c将军（大力神）.png',
    productMoney: '115.0',
    oldName: '将军（大力神）',
    guidePrice: null
  },
  {
    productName: '泰山（好好学习）',
    isCheck: 2,
    Category: '全部',
    Category2: '泰山',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/e09afd59b37a4ffd9643cd5aefadeb4e泰山（好好学习）.png',
    productMoney: '178.0',
    oldName: '泰山（好好学习）',
    guidePrice: 175
  },
  {
    productName: '泰山（雪豹细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '泰山',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/61ebef84469345efb853fa52000c7147泰山（雪豹细支）.png',
    productMoney: '105.0',
    oldName: '泰山（雪豹细支）',
    guidePrice: 124
  },
  {
    productName: '巴哈马甜',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/a8c8be82399c41c7a1dd6ce4d697d1ae泰山（巴哈马甜味）.png',
    productMoney: '65.0',
    oldName: '泰山（巴哈马甜味）',
    guidePrice: null
  },
  {
    productName: '战神1',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/b9b9888d4a4b4534bbde998a8190296b将军（战神1号）.png',
    productMoney: '380.0',
    oldName: '将军（战神1号）',
    guidePrice: null
  },
  {
    productName: '将军3G',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/7ecc43f929bd450c99fb672fdd67e274将军（3G）.png',
    productMoney: '65.0',
    oldName: '将军（3G）',
    guidePrice: null
  },
  {
    productName: '战神荣耀',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/bcc8493d828c4a9a9ec8e767c78d3b8a将军（战神荣耀）.png',
    productMoney: '430.0',
    oldName: '将军（战神荣耀）',
    guidePrice: null
  },
  {
    productName: '泰山（沂蒙）',
    isCheck: 2,
    Category: '全部',
    Category2: '泰山',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/2ed12d6f9c164edb8fde6c9362a6abe3泰山（沂蒙）.png',
    productMoney: '127.0',
    oldName: '泰山（沂蒙）',
    guidePrice: 129.3
  },
  {
    productName: '3G沉香',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/6c0d3a028dbd454ea6fa09e08d279150泰山（3G沉香）.png',
    productMoney: '50.0',
    oldName: '泰山（3G沉香）',
    guidePrice: null
  },
  {
    productName: '泰山战神3',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/79502b35a9bc4c8bab8cf848e0b0678f将军（战神3号）.png',
    productMoney: '245.0',
    oldName: '将军（战神3号）',
    guidePrice: 169
  },
  {
    productName: '泰山（大鸡）',
    isCheck: 2,
    Category: '全部',
    Category2: '泰山',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/1008be2442b54bd0b442777c856f013c.png',
    productMoney: '130.0',
    oldName: '泰山（大鸡）',
    guidePrice: 139.92
  },
  {
    productName: '泰山（3G咖啡）',
    isCheck: 2,
    Category: '全部',
    Category2: '泰山',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/da98dd3f2896411785832ac65503bbb2泰山（3G咖啡）.png',
    productMoney: '50.0',
    oldName: '泰山（3G咖啡）',
    guidePrice: null
  },
  {
    productName: '泰山（新东方）',
    isCheck: 2,
    Category: '全部',
    Category2: '泰山',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/7fe2f32df1544b9ab40affe5d6da6ae9泰山（新东方）.png',
    productMoney: '125.0',
    oldName: '泰山（新东方）',
    guidePrice: null
  },
  {
    productName: '将军（战神07号）',
    isCheck: 2,
    Category: '全部',
    Category2: '泰山',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/d2253ef7937446288b11e3fbd9e3d832将军（战神07号）.png',
    productMoney: '295.0',
    oldName: '将军（战神07号）',
    guidePrice: null
  },
  {
    productName: '泰山（黑豹细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '泰山',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/165511cb3f0441e9b7907b1bdb74f732泰山（黑豹细支）.png',
    productMoney: '135.0',
    oldName: '泰山（黑豹细支）',
    guidePrice: 154
  },
  {
    productName: '泰山（新沂蒙山）',
    isCheck: 2,
    Category: '全部',
    Category2: '泰山',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/fc0fb35295584a0c9212aa01a2ecd852泰山（新沂蒙山）.png',
    productMoney: '123.0',
    oldName: '泰山（新沂蒙山）',
    guidePrice: null
  },
  {
    productName: '泰山（锦秀）',
    isCheck: 2,
    Category: '全部',
    Category2: '泰山',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/1baaac27b13f401ea127aabefcb9e1af泰山（锦秀）.png',
    productMoney: '102.0',
    oldName: '泰山（锦秀）',
    guidePrice: null
  },
  {
    productName: '泰山战神5',
    isCheck: 1,
    Category: '全部',
    Category2: '山东',
    Factory: '山东',
    productImg: 'https://yanjiuchacha.com/api/fcd51eba704148fb8feab5cb9dab51e2.jpg',
    productMoney: '120.0',
    oldName: '泰山(战神5号)',
    guidePrice: 260
  },
  {
    productName: '天叶',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/ee186ba300114d5aaa403ca16429b123黄金叶（天叶） (2).png',
    productMoney: '733.0',
    oldName: '黄金叶（天叶）',
    guidePrice: 678.4
  },
  {
    productName: '乐途黄金叶',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/c2194db085bd4f9a91465f8dfebeddd9黄金叶（乐途） (2).png',
    productMoney: '144.0',
    oldName: '黄金叶（乐途）',
    guidePrice: 121.9
  },
  {
    productName: '小天香',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/5ce8a9ec9d104b98b628f490bfd4df04黄金叶（天香细支） (2).png',
    productMoney: '443.0',
    oldName: '黄金叶（天香细支）',
    guidePrice: 455.8
  },
  {
    productName: '小天叶',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/b58c153d389d4406bcd1cdd5bf551f8b黄金叶（天叶细支） (2).png',
    productMoney: '853.0',
    oldName: '黄金叶（天叶细支）',
    guidePrice: 848
  },
  {
    productName: '商鼎叶',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/067727dc99854862bdc6b97777d9bae6黄金叶（黄金中支） (2).png',
    productMoney: '177.0',
    oldName: '黄金叶（商鼎）',
    guidePrice: 175
  },
  {
    productName: '爱尚黄金叶',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/c6f97664a5d44a20b12fbce14e04ec6c黄金叶（爱尚） (2).png',
    productMoney: '157.0',
    oldName: '黄金叶（爱尚）',
    guidePrice: 139.92
  },
  {
    productName: '喜满堂',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/ba777b2783e94308bc9256f94d5c4e73黄金叶（喜满堂） (2).png',
    productMoney: '112.0',
    oldName: '黄金叶（喜满堂）',
    guidePrice: 88
  },
  {
    productName: '金满堂',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/264c393f19d3471abe80ad3191f94dba黄金叶（金满堂） (2).png',
    productMoney: '112.0',
    oldName: '黄金叶（金满堂）',
    guidePrice: 93.28
  },
  {
    productName: '小目标',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/b341c19e24ed454cbb731d6805b26d31黄金叶（小目标） (2).png',
    productMoney: '145.0',
    oldName: '黄金叶（小目标）',
    guidePrice: 121.9
  },
  {
    productName: '中支天叶',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/f248f5d6922f40f3b6eef7d52f8461e5黄金叶（天叶中支） (2).png',
    productMoney: '1000.0',
    oldName: '黄金叶（天叶中支）',
    guidePrice: 1000
  },
  {
    productName: '中支天香',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/36fffef7299949deb1fe7b34b8b95805黄金叶（天香中支） (2).png',
    productMoney: '402.0',
    oldName: '黄金叶（天香中支）',
    guidePrice: 424
  },
  {
    productName: '软天叶',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/a73059f63ace41daa10ae7f0ad8ff3bb.jpg',
    productMoney: '928.0',
    oldName: '黄金叶（软天叶）',
    guidePrice: 763.2
  },
  {
    productName: '中支乐途',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/0a8241bdd5004ad7907037917fc85ac9黄金叶（乐途中支） (2).png',
    productMoney: '203.0',
    oldName: '黄金叶（乐途中支）',
    guidePrice: 201.4
  },
  {
    productName: '粗天香',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/9d35985996044f16bc654389a67b10fa.jpg',
    productMoney: '328.0',
    oldName: '黄金叶（天香）',
    guidePrice: 339.2
  },
  {
    productName: '红旗渠雪茄',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/11bcbaf023b046639e38b81b15e560fa红旗渠（雪茄型） (2).png',
    productMoney: '70.0',
    oldName: '红旗渠（雪茄型）',
    guidePrice: 44.5
  },
  {
    productName: '红旗渠芒果',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/a5c10d3c6f8f4b23adbe4957986ca4fa红旗渠（芒果） (2).png',
    productMoney: '78.0',
    oldName: '红旗渠（芒果）',
    guidePrice: null
  },
  {
    productName: '红旗渠（新版银河）',
    isCheck: 2,
    Category: '全部',
    Category2: '红旗渠',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/e39f88863691433188fc9fc16ba9aa7a红旗渠（新版银河） (2).png',
    productMoney: '62.0',
    oldName: '红旗渠（新版银河）',
    guidePrice: 44.5
  },
  {
    productName: '金帝豪',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/bf4ea6e308fe4aeea4ff5077bbc2234a黄金叶（硬帝豪） (2).png',
    productMoney: '117.0',
    oldName: '黄金叶（硬帝豪）',
    guidePrice: null
  },
  {
    productName: '大成黄金叶',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/d0f2dd33983849a4aee301d75011e47b.jpg',
    productMoney: '280.0',
    oldName: '黄金叶（大成细支）',
    guidePrice: 263
  },
  {
    productName: '黄金眼',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/36747a5521164135b75f39c9e30e3a2c黄金叶（黄金眼） (2).png',
    productMoney: '147.0',
    oldName: '黄金叶（黄金眼）',
    guidePrice: 121.9
  },
  {
    productName: '中支国色',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/83cd7630e1844348a6d4c3bed6c6dce3.png',
    productMoney: '265.0',
    oldName: '黄金叶国色双中支',
    guidePrice: 339.2
  },
  {
    productName: '银旗渠',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/62821d79c44b4657bfa9758eacf599c8红旗渠（银河之光） (2).png',
    productMoney: '74.0',
    oldName: '红旗渠（银河之光）',
    guidePrice: null
  },
  {
    productName: '合乐途黄金叶',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/acda138211094458966507e4689e508e.png',
    productMoney: '158.0',
    oldName: '黄金叶（乐途硬）',
    guidePrice: 175
  },
  {
    productName: '黄金叶（金丝路）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄金叶',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/546b0a24ec8c4b008553669681b9cb65黄金叶（金丝路） (2).png',
    productMoney: '156.0',
    oldName: '黄金叶（金丝路）',
    guidePrice: 158
  },
  {
    productName: '黄金叶（天香）新',
    isCheck: 2,
    Category: '全部',
    Category2: '黄金叶',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/34d5d23b32a247e68c14b40a12bfd233黄金叶（天香）新 (2).png',
    productMoney: '340.0',
    oldName: '黄金叶（天香）新',
    guidePrice: null
  },
  {
    productName: '大M黄金叶',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/2b6b6fd2dd5c48d39d5434084aa27464黄金叶（大M） (2).png',
    productMoney: '213.0',
    oldName: '黄金叶（大M）',
    guidePrice: 201.4
  },
  {
    productName: '小黄金黄金叶',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/c176c948365547a5932f0249a65e9687黄金叶（小黄金） (2).png',
    productMoney: '189.0',
    oldName: '黄金叶（小黄金）',
    guidePrice: 201.4
  },
  {
    productName: '黄金叶（小天叶）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄金叶',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/cf58087c74fa46c080984543f43d35a5黄金叶（小天叶） (2).png',
    productMoney: '605.0',
    oldName: '黄金叶（小天叶）',
    guidePrice: 720.8
  },
  {
    productName: '黄金叶（红十渠）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄金叶',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/207a7fd7b536498d9b7b5c90e50e7bab黄金叶（红十渠） (2).png',
    productMoney: '118.0',
    oldName: '黄金叶（红十渠）',
    guidePrice: null
  },
  {
    productName: '天尊黄金叶',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/dfa72fde37c2443782ae794b67a2e078黄金叶（天尊） (2).png',
    productMoney: '490.0',
    oldName: '黄金叶（天尊）',
    guidePrice: 510
  },
  {
    productName: '炫尚黄金叶',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/670d2cf6daa8494082ecedcfc6b4a71f.jpg',
    productMoney: '150.0',
    oldName: '黄金叶（炫尚）',
    guidePrice: 175
  },
  {
    productName: '黄金叶（硬红旗渠）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄金叶',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/950d5d308b9b47c086bbc17e21559bd3黄金叶（硬红旗渠） (2).png',
    productMoney: '118.0',
    oldName: '黄金叶（硬红旗渠）',
    guidePrice: null
  },
  {
    productName: '黄金叶（浓香细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄金叶',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/eec50c3151e04b64a1ce00c8f5c19887黄金叶（浓香细支） (2).png',
    productMoney: '258.0',
    oldName: '黄金叶（浓香细支）',
    guidePrice: null
  },
  {
    productName: '黄金叶（黄金细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄金叶',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/6de2f3de08e843daa42c2fe9013aad1f黄金叶（小黄金细支） (2).png',
    productMoney: '215.0',
    oldName: '黄金叶（黄金细支）',
    guidePrice: 225
  },
  {
    productName: '大金圆',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/2db7d73b1930428b875ac455bb0abe31黄金叶（大金圆） (2).png',
    productMoney: '180.0',
    oldName: '黄金叶（大金圆）',
    guidePrice: null
  },
  {
    productName: '黄金叶（百年浓香）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄金叶',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/b9b82cc2aaac46188a2a3badc991d3db黄金叶（百年浓香） (2).png',
    productMoney: '285.0',
    oldName: '黄金叶（百年浓香）',
    guidePrice: null
  },
  {
    productName: '中支浓香黄金叶',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/555009d3e5fa43339673ec01261dcb6b黄金叶（浓香中支） (2).png',
    productMoney: '257.0',
    oldName: '黄金叶浓香中支/上六片',
    guidePrice: 263
  },
  {
    productName: '黄金叶（洛阳牡丹国色细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄金叶',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/6f5aed408ed548e0aefbd030d674629a黄金叶（洛阳牡丹国色细支） (2).png',
    productMoney: '340.0',
    oldName: '黄金叶（洛阳牡丹国色细支）',
    guidePrice: null
  },
  {
    productName: '软红旗渠',
    isCheck: 1,
    Category: '全部',
    Category2: '河南',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/19287eac036949659c4c08ee0dc56d14红旗渠（软红） (2).png',
    productMoney: '57.0',
    oldName: '红旗渠（软红）',
    guidePrice: null
  },
  {
    productName: '红旗渠（硬银）',
    isCheck: 2,
    Category: '全部',
    Category2: '红旗渠',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/81970654441544938d899ee625e028ac红旗渠（硬银） (2).png',
    productMoney: '62.0',
    oldName: '红旗渠（硬银）',
    guidePrice: null
  },
  {
    productName: '红旗渠（天行健）',
    isCheck: 2,
    Category: '全部',
    Category2: '红旗渠',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/36ba89c9c2b84dc3bf2526cafd69120a红旗渠（天行健） (2).png',
    productMoney: '72.0',
    oldName: '红旗渠（天行健）',
    guidePrice: 57.24
  },
  {
    productName: '黄金叶（红南阳）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄金叶',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/cdeeefd18de9472cb3fb730de86c0935黄金叶（红南阳） (2).png',
    productMoney: '143.0',
    oldName: '黄金叶（红南阳）',
    guidePrice: null
  },
  {
    productName: '黄金叶（软黄金）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄金叶',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/1296695e89544b4594462114fc217bc2黄金叶（软黄金） (2).png',
    productMoney: '570.0',
    oldName: '黄金叶（软黄金）',
    guidePrice: null
  },
  {
    productName: '黄金叶（盛世金典）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄金叶',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/4c2366cdf4544c5eba9a8522e1f9e7ee黄金叶（盛世金典） (2).png',
    productMoney: '174.0',
    oldName: '黄金叶（盛世金典）',
    guidePrice: null
  },
  {
    productName: '黄金叶（炫尚）牛仔',
    isCheck: 2,
    Category: '全部',
    Category2: '黄金叶',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/ef35d834ebbc4dcb8f76a52f8c44b279黄金叶（炫尚） (4).png',
    productMoney: '160.0',
    oldName: '黄金叶（炫尚）牛仔',
    guidePrice: null
  },
  {
    productName: '黄金叶（豫香）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄金叶',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/ee7dbd15b84e4cafaeaba88c184e5bb8黄金叶（豫香） (2).png',
    productMoney: '260.0',
    oldName: '黄金叶（豫香）',
    guidePrice: null
  },
  {
    productName: '黄金叶（摩卡）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄金叶',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/c6b5d68d3b4147d08c4a2563690ffaa5黄金叶（摩卡） (2).png',
    productMoney: '300.0',
    oldName: '黄金叶（摩卡）',
    guidePrice: null
  },
  {
    productName: '黄金叶（红火）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄金叶',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/60327ef0cf5445ecb4d3742aea202970黄金叶（红火） (2).png',
    productMoney: '158.0',
    oldName: '黄金叶（红火）',
    guidePrice: null
  },
  {
    productName: '黄金叶（牛仔）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄金叶',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/2946b9145af84a5fa2085925b47c4782黄金叶（牛仔） (2).png',
    productMoney: '205.0',
    oldName: '黄金叶（牛仔）',
    guidePrice: null
  },
  {
    productName: '散花（软蓝）',
    isCheck: 2,
    Category: '全部',
    Category2: '散花',
    Factory: '河南',
    productImg: 'https://yanjiuchacha.com/api/8991aec5c8ec4ff98caa614915856d5b散花（软蓝） (2).png',
    productMoney: '40.0',
    oldName: '散花（软蓝）',
    guidePrice: null
  },
  {
    productName: '荷花',
    isCheck: 1,
    Category: '全部',
    Category2: '河北',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/a979113711eb474f952813e0894e4e0e钻石（荷花）.png',
    productMoney: '317.0',
    oldName: '钻石（荷花）',
    guidePrice: 275.6
  },
  {
    productName: '细支荷花',
    isCheck: 1,
    Category: '全部',
    Category2: '河北',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/652c9594aaae4edf9e135a6ff984eae5钻石（细支荷花）.png',
    productMoney: '370.0',
    oldName: '钻石（细支荷花）',
    guidePrice: 360.4
  },
  {
    productName: '软荷花',
    isCheck: 1,
    Category: '全部',
    Category2: '河北',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/087f953fce644201885f65b1cee8a2eb钻石（软荷花）.png',
    productMoney: '355.0',
    oldName: '钻石（软荷花）',
    guidePrice: 339.2
  },
  {
    productName: '中支荷花',
    isCheck: 1,
    Category: '全部',
    Category2: '河北',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/1b4b028da3f24f61b0a8be28b863525d钻石（双中支荷花）.png',
    productMoney: '380.0',
    oldName: '钻石（双中支荷花）',
    guidePrice: 381.6
  },
  {
    productName: '青山绿水荷花',
    isCheck: 1,
    Category: '全部',
    Category2: '河北',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/3db449f16ada45c485c4b225274d383b钻石（荷花绿水青山）.png',
    productMoney: '710.0',
    oldName: '钻石（荷花绿水青山）',
    guidePrice: 763.2
  },
  {
    productName: '钻石玫瑰二代',
    isCheck: 1,
    Category: '全部',
    Category2: '河北',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/2139d7887f814a58ae0abebb6e05531c.jpg',
    productMoney: '112.0',
    oldName: '钻石（玫瑰二代）',
    guidePrice: 97
  },
  {
    productName: '软绿钻石',
    isCheck: 1,
    Category: '全部',
    Category2: '河北',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/b3229ab1abad4664a4334f2c068117d8钻石（软绿）.png',
    productMoney: '120.0',
    oldName: '钻石（软绿）',
    guidePrice: 121.9
  },
  {
    productName: '钻石（尚风可可）',
    isCheck: 2,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/a25b39b143604665b30ffd52265e4fc2钻石（尚风可可）.png',
    productMoney: '131.0',
    oldName: '钻石（尚风可可）',
    guidePrice: 114.5
  },
  {
    productName: '合红钻石',
    isCheck: 1,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/55f424c3341e4993b092289c42feb92c钻石（硬红）.png',
    productMoney: '98.0',
    oldName: '钻石（硬红）',
    guidePrice: 71
  },
  {
    productName: '经典中支荷花',
    isCheck: 1,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/33e4144a751247d1a539bd2123a82690钻石（荷花经典中支）.png',
    productMoney: '331.0',
    oldName: '钻石（荷花经典中支）',
    guidePrice: 339.2
  },
  {
    productName: '钻石（硬特醇）',
    isCheck: 2,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/0cb7438f08ff45c796b24b605aaa2d20钻石（硬特醇）.png',
    productMoney: '63.0',
    oldName: '钻石（硬特醇）',
    guidePrice: 44.5
  },
  {
    productName: '软红钻石',
    isCheck: 1,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/81aa748599904ec4a0df885db5ead036钻石（软红）.png',
    productMoney: '66.0',
    oldName: '钻石（软红）',
    guidePrice: 47.7
  },
  {
    productName: '金荷花',
    isCheck: 1,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/b0064518f23741b6a1ab905c753eb8aa钻石（金一品荷花）.png',
    productMoney: '695.0',
    oldName: '钻石（金一品荷花）',
    guidePrice: 720.8
  },
  {
    productName: '钻石（迎宾）',
    isCheck: 2,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/799acdead2ad49c6bbf7143ac8655424钻石（迎宾）.png',
    productMoney: '71.0',
    oldName: '钻石（迎宾）',
    guidePrice: 61.5
  },
  {
    productName: '钻石（微时代）',
    isCheck: 2,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/7665fc999c4c4081b66a3b804f7ec845钻石（微时代）.png',
    productMoney: '142.0',
    oldName: '钻石（微时代）',
    guidePrice: 129.3
  },
  {
    productName: '钻石（硬玫瑰一代8mg）',
    isCheck: 2,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/afdd13282c0d4afca24f44a79c57571d钻石（硬玫瑰8mg）.png',
    productMoney: '106.0',
    oldName: '钻石（硬玫瑰一代8mg）',
    guidePrice: 90.1
  },
  {
    productName: '玉兰钻石',
    isCheck: 1,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/b84a54fa4b1049b584c03600cd029ac6钻石（70mm玉兰）.png',
    productMoney: '159.0',
    oldName: '钻石（70mm玉兰）',
    guidePrice: null
  },
  {
    productName: '钻石冬韵中支',
    isCheck: 2,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/87a52e1e5e3347ad9fac4b5b0854bd60.png',
    productMoney: '170.0',
    oldName: '钻石冬韵中支',
    guidePrice: 175
  },
  {
    productName: '钻石莲韵',
    isCheck: 2,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/8536667795d3475f935c32ca82e5acbb.png',
    productMoney: '190.0',
    oldName: ' 钻石莲韵',
    guidePrice: 225
  },
  {
    productName: '钻石（绿石2代）',
    isCheck: 2,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/5a32ec713a53412ba93fa45c9e8eefb0钻石（绿石2代）.png',
    productMoney: '113.0',
    oldName: '钻石（绿石2代）',
    guidePrice: 90.1
  },
  {
    productName: '鸿运钻石',
    isCheck: 1,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/9f91972c31b54c02b942369914934d78钻石（鸿运）.png',
    productMoney: '100.0',
    oldName: '钻石（鸿运）',
    guidePrice: null
  },
  {
    productName: '合蓝钻石',
    isCheck: 1,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/bf930c14e4ae44228012f725aa4d1f24钻石（硬蓝）.png',
    productMoney: '94.0',
    oldName: '钻石（硬蓝）',
    guidePrice: 47.7
  },
  {
    productName: '钻石闪光',
    isCheck: 2,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/f279dd6fe1234f319002685cc029df8a.png',
    productMoney: '112.0',
    oldName: '钻石闪光',
    guidePrice: 129.3
  },
  {
    productName: '钻石（君子中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/04a398ab44d44aad929ae9332515788b钻石（君子中支）.png',
    productMoney: '163.0',
    oldName: '钻石（君子中支）',
    guidePrice: 175
  },
  {
    productName: '钻石（金石）',
    isCheck: 2,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/14e37c1e61f44c3ca293f424af403c95钻石（金石）.png',
    productMoney: '144.0',
    oldName: '钻石（金石）',
    guidePrice: 143.1
  },
  {
    productName: '紫钻石',
    isCheck: 1,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/95974141b45c456ab0c8986cc1d7fdfa钻石（硬玫瑰紫）.png',
    productMoney: '63.0',
    oldName: '钻石（硬玫瑰紫）',
    guidePrice: 90.1
  },
  {
    productName: '钻石（荷花小雅90mm细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/e257cd3ce93b468a88191c2805a53582.jpg',
    productMoney: '575.0',
    oldName: '钻石（荷花小雅90mm细支）',
    guidePrice: 470
  },
  {
    productName: '醇和钻石',
    isCheck: 1,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/1a331ef8a2404bb8a8f427d6f372578f钻石（经典醇和）.png',
    productMoney: '90.0',
    oldName: '钻石（经典醇和）',
    guidePrice: 88
  },
  {
    productName: '钻石（1902中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/f89fae181ef0435481ddb5305bfc3601钻石（1902中支）.png',
    productMoney: '250.0',
    oldName: '钻石（1902中支）',
    guidePrice: 263
  },
  {
    productName: '钻石（西柏坡1949）',
    isCheck: 2,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/a6f49130fe0c48f49ce87577ff48128e钻石（西柏坡1949）.png',
    productMoney: '220.0',
    oldName: '钻石（西柏坡1949）',
    guidePrice: 175
  },
  {
    productName: '平安钻石',
    isCheck: 1,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/e16227d95f8d4e26a57fe1b16ef64386钻石（平安）.png',
    productMoney: '127.0',
    oldName: '钻石（平安）',
    guidePrice: null
  },
  {
    productName: '钻石（红石2代）',
    isCheck: 2,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/134e17a85e8d4705b9c6cb3c287b381f钻石（红石2代）.png',
    productMoney: '105.0',
    oldName: '钻石（红石2代）',
    guidePrice: 57.24
  },
  {
    productName: '钻石（避暑山庄细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/c107e29ff5454edcb8f7bd380998a899钻石（避暑山庄细支）.png',
    productMoney: '255.0',
    oldName: '钻石（避暑山庄细支）',
    guidePrice: 263
  },
  {
    productName: '钻石（大好河山）',
    isCheck: 2,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/a9fc9c6100f2417bbaf41a65a838eb43钻石（大好河山）.png',
    productMoney: '175.0',
    oldName: '钻石（大好河山）',
    guidePrice: 175
  },
  {
    productName: '钻石（大好河山细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/c1256a7582e44920b1621a4fb1f25d99钻石（大好河山细支）.png',
    productMoney: '170.0',
    oldName: '钻石（大好河山细支）',
    guidePrice: 175
  },
  {
    productName: '钻石（扁蓝时尚）',
    isCheck: 2,
    Category: '全部',
    Category2: '钻石',
    Factory: '河北',
    productImg: 'https://yanjiuchacha.com/api/f09a90a3968c4e3e85ff263aed810ca8钻石（扁蓝时尚）.png',
    productMoney: '150.0',
    oldName: '钻石（扁蓝时尚）',
    guidePrice: 167.48
  },
  {
    productName: '中支虫草',
    isCheck: 1,
    Category: '全部',
    Category2: '冬虫夏草',
    Factory: '内蒙',
    productImg: 'https://yanjiuchacha.com/api/a4fe0dd8d56d431a87726fb2cc1363dc.png',
    productMoney: '320.0',
    oldName: '冬虫夏草（双中支）',
    guidePrice: 339.2
  },
  {
    productName: '冬虫夏草（和润）',
    isCheck: 2,
    Category: '全部',
    Category2: '冬虫夏草',
    Factory: '内蒙',
    productImg: 'https://yanjiuchacha.com/api/97983e1b14934c23ac9f9b4df64fd33c.png',
    productMoney: '362.0',
    oldName: '冬虫夏草（和润）',
    guidePrice: 424
  },
  {
    productName: '中支红虫草',
    isCheck: 1,
    Category: '全部',
    Category2: '冬虫夏草',
    Factory: '内蒙',
    productImg: 'https://yanjiuchacha.com/api/0b6e5698ca6240a0a13e1768237a3ad4.png',
    productMoney: '335.0',
    oldName: '冬虫夏草（红中支）',
    guidePrice: 424
  },
  {
    productName: '长丰大青山',
    isCheck: 1,
    Category: '全部',
    Category2: '大青山',
    Factory: '内蒙',
    productImg: 'https://yanjiuchacha.com/api/624b706f88e14fa196b10ffe1e3d86d4.png',
    productMoney: '64.0',
    oldName: '大青山（长丰）',
    guidePrice: 61.5
  },
  {
    productName: '细支金冬虫夏草',
    isCheck: 1,
    Category: '全部',
    Category2: '冬虫夏草',
    Factory: '内蒙',
    productImg: 'https://yanjiuchacha.com/api/b7feed8219254289b001a43c4c58c15d.png',
    productMoney: '555.0',
    oldName: '冬虫夏草（金细）',
    guidePrice: 678.4
  },
  {
    productName: '金冬虫夏草',
    isCheck: 1,
    Category: '全部',
    Category2: '冬虫夏草',
    Factory: '内蒙',
    productImg: 'https://yanjiuchacha.com/api/1088c786ca3a4f26a76847ff66f034dd.png',
    productMoney: '650.0',
    oldName: '冬虫夏草（金）',
    guidePrice: 848
  },
  {
    productName: '冬虫夏草（中支和润）',
    isCheck: 2,
    Category: '全部',
    Category2: '冬虫夏草',
    Factory: '内蒙',
    productImg: 'https://yanjiuchacha.com/api/c82e869c59d449868bacd51e05232e27.png',
    productMoney: '480.0',
    oldName: '冬虫夏草（中支和润）',
    guidePrice: 678.4
  },
  {
    productName: '大青山（硬昭君和亲）',
    isCheck: 2,
    Category: '全部',
    Category2: '大青山',
    Factory: '内蒙',
    productImg: 'https://yanjiuchacha.com/api/4b87bacf09b74b9e9af3f24a8ac13ef0.png',
    productMoney: '138.0',
    oldName: '大青山（硬昭君和亲）',
    guidePrice: 129.3
  },
  {
    productName: '冬虫夏草（黄金盛世）',
    isCheck: 2,
    Category: '全部',
    Category2: '冬虫夏草',
    Factory: '内蒙',
    productImg: 'https://yanjiuchacha.com/api/558a664ae30e4a12bfeb1527a083d31a.png',
    productMoney: '420.0',
    oldName: '冬虫夏草（黄金盛世）',
    guidePrice: 678.4
  },
  {
    productName: '冬虫夏草（银）',
    isCheck: 2,
    Category: '全部',
    Category2: '冬虫夏草',
    Factory: '内蒙',
    productImg: 'https://yanjiuchacha.com/api/ef862492560b44e782295f42c3fca239.png',
    productMoney: '260.0',
    oldName: '冬虫夏草（银）',
    guidePrice: 339.2
  },
  {
    productName: '冬虫夏草（1248）',
    isCheck: 2,
    Category: '全部',
    Category2: '冬虫夏草',
    Factory: '内蒙',
    productImg: 'https://yanjiuchacha.com/api/c298aaf31bc84249b3d129fff0747af2.png',
    productMoney: '420.0',
    oldName: '冬虫夏草（1248）',
    guidePrice: 510
  },
  {
    productName: '冬虫夏草（中支天润）',
    isCheck: 2,
    Category: '全部',
    Category2: '冬虫夏草',
    Factory: '内蒙',
    productImg: 'https://yanjiuchacha.com/api/23e4d335b2bf4d879e70d52b3b5151c2.png',
    productMoney: '510.0',
    oldName: '冬虫夏草（中支天润）',
    guidePrice: null
  },
  {
    productName: '大青山（昭君和亲中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '大青山',
    Factory: '内蒙',
    productImg: 'https://yanjiuchacha.com/api/bb60318c8bc24d72a0c622dc29fdd011.png',
    productMoney: '110.0',
    oldName: '大青山（昭君和亲中支）',
    guidePrice: null
  },
  {
    productName: '冬虫夏草（黄金盛世中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '冬虫夏草',
    Factory: '内蒙',
    productImg: 'https://yanjiuchacha.com/api/d9b759f74dfc4619a592f7f3fff8e66c.png',
    productMoney: '750.0',
    oldName: '冬虫夏草（黄金盛世中支）',
    guidePrice: 848
  },
  {
    productName: '小虫草',
    isCheck: 1,
    Category: '全部',
    Category2: '冬虫夏草',
    Factory: '内蒙',
    productImg: 'https://yanjiuchacha.com/api/67048b26fde64f77acdc59221464b9a6.jpg',
    productMoney: '330.0',
    oldName: '冬虫夏草（天边细支）',
    guidePrice: 263
  },
  {
    productName: '细人民大会堂',
    isCheck: 1,
    Category: '全部',
    Category2: '人民大会堂',
    Factory: '辽宁',
    productImg: 'https://yanjiuchacha.com/api/ce460fbe923841a5b144d4ad5d883f87.png',
    productMoney: '160.0',
    oldName: '人民大会堂（硬红细支/细支典藏版）',
    guidePrice: 139.92
  },
  {
    productName: '中支人民大会堂',
    isCheck: 1,
    Category: '全部',
    Category2: '人民大会堂',
    Factory: '辽宁',
    productImg: 'https://yanjiuchacha.com/api/77bd9bee508c4ebca13f97816ae344c6.png',
    productMoney: '213.0',
    oldName: '人民大会堂（中支）',
    guidePrice: 263
  },
  {
    productName: '人民大会堂（蘭香细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '人民大会堂',
    Factory: '辽宁',
    productImg: 'https://yanjiuchacha.com/api/865d70e1e401448193b4460a52ce390c.png',
    productMoney: '390.0',
    oldName: '人民大会堂（蘭香细支）',
    guidePrice: 455.8
  },
  {
    productName: '中支盛京',
    isCheck: 1,
    Category: '全部',
    Category2: '人民大会堂',
    Factory: '辽宁',
    productImg: 'https://yanjiuchacha.com/api/fcbd04d343bd46fab791457dbf6db6e7.png',
    productMoney: '165.0',
    oldName: '人民大会堂（盛京中支）',
    guidePrice: 175
  },
  {
    productName: '人民大会堂',
    isCheck: 1,
    Category: '全部',
    Category2: '人民大会堂',
    Factory: '辽宁',
    productImg: 'https://yanjiuchacha.com/api/42ffc3312c0f4835a33d974b38226da2.png',
    productMoney: '142.0',
    oldName: '人民大会堂（硬红）',
    guidePrice: 121.9
  },
  {
    productName: '人民大会堂（软红）',
    isCheck: 2,
    Category: '全部',
    Category2: '人民大会堂',
    Factory: '辽宁',
    productImg: 'https://yanjiuchacha.com/api/c9daada1f4004921a91b0064b407ee2c.png',
    productMoney: '255.0',
    oldName: '人民大会堂（软红）',
    guidePrice: 284.08
  },
  {
    productName: '人民大会堂（16细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '人民大会堂',
    Factory: '辽宁',
    productImg: 'https://yanjiuchacha.com/api/503af70dec0e42909dfe2ba4738611ab.png',
    productMoney: '380.0',
    oldName: '人民大会堂（16细支）',
    guidePrice: 263
  },
  {
    productName: '人民大会堂（辉煌）',
    isCheck: 2,
    Category: '全部',
    Category2: '人民大会堂',
    Factory: '辽宁',
    productImg: 'https://yanjiuchacha.com/api/f232df2e389f475e832c19b0ea56108f.png',
    productMoney: '155.0',
    oldName: '人民大会堂（辉煌）',
    guidePrice: 201.4
  },
  {
    productName: '人民大会堂（古瓷细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '人民大会堂',
    Factory: '辽宁',
    productImg: 'https://yanjiuchacha.com/api/cf1b649f9f644c7b8c1a17032241980a.png',
    productMoney: '133.0',
    oldName: '人民大会堂（古瓷细支）',
    guidePrice: 158
  },
  {
    productName: '人民大会堂（盛京细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '人民大会堂',
    Factory: '辽宁',
    productImg: 'https://yanjiuchacha.com/api/5076385258694da296855efc2674a056.png',
    productMoney: '241.0',
    oldName: '人民大会堂（盛京细支）',
    guidePrice: 263
  },
  {
    productName: '人民大会堂（辽宁16）',
    isCheck: 2,
    Category: '全部',
    Category2: '人民大会堂',
    Factory: '辽宁',
    productImg: 'https://yanjiuchacha.com/api/639f5e83c8364482a9eec95edf347052.png',
    productMoney: '485.0',
    oldName: '人民大会堂（辽宁16）',
    guidePrice: null
  },
  {
    productName: '人民大会堂（御廷兰香）',
    isCheck: 2,
    Category: '全部',
    Category2: '人民大会堂',
    Factory: '辽宁',
    productImg: 'https://yanjiuchacha.com/api/87f53c87fbe54dc486d82c982a8a076e.png',
    productMoney: '680.0',
    oldName: '人民大会堂（御廷兰香）',
    guidePrice: null
  },
  {
    productName: '人民大会堂（光耀）',
    isCheck: 2,
    Category: '全部',
    Category2: '人民大会堂',
    Factory: '辽宁',
    productImg: 'https://yanjiuchacha.com/api/65d72794d2ba4d9d9bb439f4ba252e92.jpg',
    productMoney: '750.0',
    oldName: '人民大会堂（光耀）',
    guidePrice: 0
  },
  {
    productName: '777长白山',
    isCheck: 1,
    Category: '全部',
    Category2: '长白山',
    Factory: '吉林',
    productImg: 'https://yanjiuchacha.com/api/f2a48c9ad1a24bcbb002b4f02feb0b5e长白山（777）.png',
    productMoney: '151.0',
    oldName: '长白山（777）',
    guidePrice: 121.9
  },
  {
    productName: '蓝尚迎春',
    isCheck: 1,
    Category: '全部',
    Category2: '长白山',
    Factory: '吉林',
    productImg: 'https://yanjiuchacha.com/api/774ea17d3cc447d2a09c589dd7f7048f长白山（蓝尚）.png',
    productMoney: '170.0',
    oldName: '长白山（蓝尚）',
    guidePrice: 158
  },
  {
    productName: '软长白山',
    isCheck: 1,
    Category: '全部',
    Category2: '长白山',
    Factory: '吉林',
    productImg: 'https://yanjiuchacha.com/api/6b834e20c787473fb1d0a98ae7e52542长白山（软红）.png',
    productMoney: '108.0',
    oldName: '长白山（软红）',
    guidePrice: 90.1
  },
  {
    productName: '中支迎春',
    isCheck: 1,
    Category: '全部',
    Category2: '长白山',
    Factory: '吉林',
    productImg: 'https://yanjiuchacha.com/api/fa534775a5b84c6da5435a75d93e3a5d长白山（迎春中支）.png',
    productMoney: '137.0',
    oldName: '长白山（迎春中支）',
    guidePrice: 88
  },
  {
    productName: '长白山参缘',
    isCheck: 1,
    Category: '全部',
    Category2: '长白山',
    Factory: '吉林',
    productImg: 'https://yanjiuchacha.com/api/eb2a526440bd45449f3b977dc9034e10长白山（人参参缘）.png',
    productMoney: '253.0',
    oldName: '长白山（人参参缘）',
    guidePrice: 263
  },
  {
    productName: '合长白山',
    isCheck: 1,
    Category: '全部',
    Category2: '长白山',
    Factory: '吉林',
    productImg: 'https://yanjiuchacha.com/api/9c824fb1f9924ba2b3117ee08e5de2ed长白山（红）.png',
    productMoney: '94.0',
    oldName: '长白山（红）',
    guidePrice: 66.78
  },
  {
    productName: '长白山（神韵细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '长白山',
    Factory: '吉林',
    productImg: 'https://yanjiuchacha.com/api/0891b1f4f15846c397436062d85a32e4长白山（神韵细支）.png',
    productMoney: '217.0',
    oldName: '长白山（神韵细支）',
    guidePrice: 225
  },
  {
    productName: '圣镜长白山',
    isCheck: 1,
    Category: '全部',
    Category2: '长白山',
    Factory: '吉林',
    productImg: 'https://yanjiuchacha.com/api/f880c603b257468ebaa6b951eeac2c4c长白山（圣境）.png',
    productMoney: '180.0',
    oldName: '长白山（圣境）',
    guidePrice: 175
  },
  {
    productName: '东方神韵',
    isCheck: 1,
    Category: '全部',
    Category2: '长白山',
    Factory: '吉林',
    productImg: 'https://yanjiuchacha.com/api/f32909b501e0400c9f914209dbabb0ca长白山（神韵）.png',
    productMoney: '170.0',
    oldName: '长白山（神韵）',
    guidePrice: 188.68
  },
  {
    productName: '参品长白山',
    isCheck: 1,
    Category: '全部',
    Category2: '长白山',
    Factory: '吉林',
    productImg: 'https://yanjiuchacha.com/api/d398f0fb7e0f498b82d9bdb5938c994b长白山（人参·参品）.png',
    productMoney: '320.0',
    oldName: '长白山（人参·参品）',
    guidePrice: 381.6
  },
  {
    productName: '长白山（百草之王）',
    isCheck: 2,
    Category: '全部',
    Category2: '长白山',
    Factory: '吉林',
    productImg: 'https://yanjiuchacha.com/api/eac4966814ba44188d071eb660fbc652长白山（百草之王）.png',
    productMoney: '715.0',
    oldName: '长白山（百草之王）',
    guidePrice: 678.4
  },
  {
    productName: '长白山（国风中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '长白山',
    Factory: '吉林',
    productImg: 'https://yanjiuchacha.com/api/fe48948b8b254e8db2b068846410bb4f.png',
    productMoney: '190.0',
    oldName: '长白山（国风中支）',
    guidePrice: 201.4
  },
  {
    productName: '长白山（海蓝）',
    isCheck: 2,
    Category: '全部',
    Category2: '长白山',
    Factory: '吉林',
    productImg: 'https://yanjiuchacha.com/api/b5b65fd59ae3447895c2a008be331462长白山（海蓝）.png',
    productMoney: '72.0',
    oldName: '长白山（海蓝）',
    guidePrice: 53
  },
  {
    productName: '合桂花',
    isCheck: 1,
    Category: '全部',
    Category2: '长白山',
    Factory: '吉林',
    productImg: 'https://yanjiuchacha.com/api/092505a7f203427c884cf36b8368d7d0长白山（桂花）.png',
    productMoney: '78.0',
    oldName: '长白山（桂花）',
    guidePrice: 44.5
  },
  {
    productName: '长白山（心驰）',
    isCheck: 2,
    Category: '全部',
    Category2: '长白山',
    Factory: '吉林',
    productImg: 'https://yanjiuchacha.com/api/78da86366de5409ba4892cada07259dd.png',
    productMoney: '176.0',
    oldName: '长白山（心驰）',
    guidePrice: 175
  },
  {
    productName: '长白山（老人参）',
    isCheck: 2,
    Category: '全部',
    Category2: '长白山',
    Factory: '吉林',
    productImg: 'https://yanjiuchacha.com/api/8500a59aced546eea68312ec38398082.png',
    productMoney: '268.0',
    oldName: '长白山（老人参）',
    guidePrice: 263
  },
  {
    productName: '长白山（韵藏天下细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '长白山',
    Factory: '吉林',
    productImg: 'https://yanjiuchacha.com/api/7b0c41bb40f8493a96cbd5e41d12c881长白山（韵藏天下细支）.png',
    productMoney: '230.0',
    oldName: '长白山（韵藏天下细支）',
    guidePrice: 225
  },
  {
    productName: '长白山（人参）',
    isCheck: 2,
    Category: '全部',
    Category2: '长白山',
    Factory: '吉林',
    productImg: 'https://yanjiuchacha.com/api/833a607a351a48eb896951c84d0eb2ae长白山（人参）.png',
    productMoney: '133.0',
    oldName: '长白山（人参）',
    guidePrice: 121.9
  },
  {
    productName: '长白山（揽胜）',
    isCheck: 2,
    Category: '全部',
    Category2: '长白山',
    Factory: '吉林',
    productImg: 'https://yanjiuchacha.com/api/d9ae030189064a2591a82c8ffe3dfad2长白山（揽胜）.png',
    productMoney: '195.0',
    oldName: '长白山（揽胜）',
    guidePrice: 188.68
  },
  {
    productName: '长白山（德容天下）',
    isCheck: 2,
    Category: '全部',
    Category2: '长白山',
    Factory: '吉林',
    productImg: 'https://yanjiuchacha.com/api/7a682fa262434a30ba6468450747b845长白山（德容天下）.png',
    productMoney: '350.0',
    oldName: '长白山（德容天下）',
    guidePrice: 678.4
  },
  {
    productName: '长白山（心归)',
    isCheck: 2,
    Category: '全部',
    Category2: '长白山',
    Factory: '吉林',
    productImg: 'https://yanjiuchacha.com/api/80afc0f8a17c4dae88d1d3a4bc3d0f7b.png',
    productMoney: '132.0',
    oldName: '长白山（心归)',
    guidePrice: 129.3
  },
  {
    productName: '老巴夺',
    isCheck: 1,
    Category: '全部',
    Category2: '哈尔滨',
    Factory: '黑龙江',
    productImg: 'https://yanjiuchacha.com/api/8e2c71a1aa724a64baf21caf632412f5哈尔滨（老巴夺）.png',
    productMoney: '113.0',
    oldName: '哈尔滨（老巴夺）',
    guidePrice: 97
  },
  {
    productName: '中支老巴夺',
    isCheck: 1,
    Category: '全部',
    Category2: '哈尔滨',
    Factory: '黑龙江',
    productImg: 'https://yanjiuchacha.com/api/b4f8f2cb456b4605b85523b53df1bba7哈尔滨（老巴夺红中支）.png',
    productMoney: '165.0',
    oldName: '哈尔滨（老巴夺红中支）',
    guidePrice: 175
  },
  {
    productName: '龙烟（呈祥）',
    isCheck: 2,
    Category: '全部',
    Category2: '龙烟',
    Factory: '黑龙江',
    productImg: 'https://yanjiuchacha.com/api/8bda2f025ebf4ef494f9b1c975af3c18龙烟（呈祥）.png',
    productMoney: '138.0',
    oldName: '龙烟（呈祥）',
    guidePrice: 129.3
  },
  {
    productName: '传奇老巴夺',
    isCheck: 1,
    Category: '全部',
    Category2: '哈尔滨',
    Factory: '黑龙江',
    productImg: 'https://yanjiuchacha.com/api/057dfcf439ae4e1685fc6b2884f05212.png',
    productMoney: '165.0',
    oldName: '龙烟（老巴夺传琦）',
    guidePrice: 225
  },
  {
    productName: '哈尔滨（风尚）',
    isCheck: 2,
    Category: '全部',
    Category2: '哈尔滨',
    Factory: '黑龙江',
    productImg: 'https://yanjiuchacha.com/api/fd1443109bb14c919dc83a5230e976ab哈尔滨（风尚）.png',
    productMoney: '65.0',
    oldName: '哈尔滨（风尚）',
    guidePrice: 61.5
  },
  {
    productName: '哈尔滨(中央大街）',
    isCheck: 2,
    Category: '全部',
    Category2: '哈尔滨(中央大街）',
    Factory: '黑龙江',
    productImg: 'https://yanjiuchacha.com/api/3292f2bfbf6b40aaa92533ed6589791d.png',
    productMoney: '215.0',
    oldName: '哈尔滨(中央大街）',
    guidePrice: 263
  },
  {
    productName: '林海灵芝如意/黄灵芝',
    isCheck: 2,
    Category: '全部',
    Category2: '林海灵芝',
    Factory: '黑龙江',
    productImg: 'https://yanjiuchacha.com/api/83e4f10b02fa4fc68bcc68df777ab759林海灵芝（如意）.png',
    productMoney: '64.0',
    oldName: '林海灵芝如意/黄灵芝',
    guidePrice: 48.5
  },
  {
    productName: '哈尔滨（龙烟金安）',
    isCheck: 2,
    Category: '全部',
    Category2: '哈尔滨',
    Factory: '黑龙江',
    productImg: 'https://yanjiuchacha.com/api/2620e42d44104463aee7ae223a135304哈尔滨（龙烟金安）.png',
    productMoney: '165.0',
    oldName: '哈尔滨（龙烟金安）',
    guidePrice: 185.5
  },
  {
    productName: '龙烟（北国风光）',
    isCheck: 2,
    Category: '全部',
    Category2: '龙烟',
    Factory: '黑龙江',
    productImg: 'https://yanjiuchacha.com/api/7b35cc6fcaf841af99fb0a3616d242b7龙烟（北国风光）.png',
    productMoney: '215.0',
    oldName: '龙烟（北国风光）',
    guidePrice: 263
  },
  {
    productName: '林海灵芝（蓝色经典）',
    isCheck: 2,
    Category: '全部',
    Category2: '林海灵芝',
    Factory: '黑龙江',
    productImg: 'https://yanjiuchacha.com/api/e9acf55db97b43f6a3fdf21ece8070d9林海灵芝（蓝色经典）.png',
    productMoney: '54.0',
    oldName: '林海灵芝（蓝色经典）',
    guidePrice: 38.16
  },
  {
    productName: '龙烟（老巴夺传呈）',
    isCheck: 2,
    Category: '全部',
    Category2: '龙烟',
    Factory: '黑龙江',
    productImg: 'https://yanjiuchacha.com/api/b55bef26bc9c421fa41412b3974ba9cd.png',
    productMoney: '175.0',
    oldName: '龙烟（老巴夺传呈）',
    guidePrice: 175
  },
  {
    productName: '龙烟冰雪',
    isCheck: 2,
    Category: '全部',
    Category2: '龙烟',
    Factory: '黑龙江',
    productImg: 'https://yanjiuchacha.com/api/d7a60ba19d3d40b3a684d5a075d6c434.png',
    productMoney: '410.0',
    oldName: '龙烟冰雪',
    guidePrice: null
  },
  {
    productName: '林海灵芝（硬红）',
    isCheck: 2,
    Category: '全部',
    Category2: '林海灵芝',
    Factory: '黑龙江',
    productImg: 'https://yanjiuchacha.com/api/d40a66561331436082a2357016527c04林海灵芝（硬红）.png',
    productMoney: '50.0',
    oldName: '林海灵芝（硬红）',
    guidePrice: null
  },
  {
    productName: '峡谷柔情',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/9c6bcf8e37384a9a8d3677636bb6f104.png',
    productMoney: '280.0',
    oldName: '黄鹤楼（硬峡谷柔情）',
    guidePrice: 263
  },
  {
    productName: '软蓝',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/3ec137fc807841969a6a766f47a84563.png',
    productMoney: '171.0',
    oldName: '黄鹤楼（软蓝）',
    guidePrice: 164.3
  },
  {
    productName: '奇景楼',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/cfe99c6cdba6451ba9773a54b3768c76.png',
    productMoney: '246.0',
    oldName: '黄鹤楼（硬奇景）',
    guidePrice: 238.5
  },
  {
    productName: '视窗黄鹤楼',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/a05a116b2ffa44b9a9e2e61ed29c7f8e.png',
    productMoney: '390.0',
    oldName: '黄鹤楼（视窗）',
    guidePrice: 300
  },
  {
    productName: '久久平安楼',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/b5ff4c01be2e4a3a9972f8f59985d1c1.png',
    productMoney: '720.0',
    oldName: '黄鹤楼（硬平安）',
    guidePrice: 720.8
  },
  {
    productName: '1916软黄珠',
    isCheck: 2,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/56d54cdcae694d6c8488679d89664d5e.png',
    productMoney: '815.0',
    oldName: '黄鹤楼（1916软黄珠）',
    guidePrice: 848
  },
  {
    productName: '天下名楼',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/9545f7933a144daf87af42d2ad2de548.png',
    productMoney: '145.0',
    oldName: '黄鹤楼（天下名楼）',
    guidePrice: 139.92
  },
  {
    productName: '小支峡谷情',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/95fc5c96f90f4f87a83b8ca75558fe49.png',
    productMoney: '264.0',
    oldName: '黄鹤楼（硬峡谷情细支）',
    guidePrice: 263
  },
  {
    productName: '软1916楼',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/51567ca2bbdb4cc7a029dcdc460a59ae.png',
    productMoney: '828.0',
    oldName: '黄鹤楼（软1916）',
    guidePrice: 848
  },
  {
    productName: '合1916楼',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/a6806a01dec14864b14b8e01942020db.png',
    productMoney: '795.0',
    oldName: '黄鹤楼（硬1916）',
    guidePrice: 848
  },
  {
    productName: '如意1916',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/6740761e9d0e4f2ebec0682da82d3b07.png',
    productMoney: '747.0',
    oldName: '黄鹤楼（硬1916如意）',
    guidePrice: 763.2
  },
  {
    productName: '银紫楼',
    isCheck: 0,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/3471464990ec4c0fbb2abdb0efa367bc.png',
    productMoney: '140.0',
    oldName: '黄鹤楼（银紫）',
    guidePrice: 114.5
  },
  {
    productName: '大峡谷情',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/47ec042a116247e6ae2d179865822b31.png',
    productMoney: '388.0',
    oldName: '黄鹤楼（硬峡谷情）',
    guidePrice: 339.2
  },
  {
    productName: '细15年1916楼',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/32c7631144f44e88a219483a72e53e92.png',
    productMoney: '1100.0',
    oldName: '黄鹤楼（硬15细支）',
    guidePrice: 848
  },
  {
    productName: '8度楼',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/3a39b46464e248e986ddc94d28a80391.png',
    productMoney: '206.0',
    oldName: '黄鹤楼（硬8度）',
    guidePrice: 158
  },
  {
    productName: '无三角合1916',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/406b3c5254aa41e9a1ba52b8f46e6a01.png',
    productMoney: '815.0',
    oldName: '黄鹤楼（硬1916/硬三角）',
    guidePrice: 848
  },
  {
    productName: '雅香金砂龙烟',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/f3253c3ee53c43f4ae30faf6d3be3599.png',
    productMoney: '153.0',
    oldName: '黄鹤楼（硬金砂）',
    guidePrice: 143.1
  },
  {
    productName: '合1916红珠',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/4955136c14dd4a48ac0ceeff205e3375.png',
    productMoney: '810.0',
    oldName: '黄鹤楼硬1916红珠',
    guidePrice: 720.8
  },
  {
    productName: '粗15年1916楼',
    isCheck: 0,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/a3e2af96721341328936569b6e0e9883.png',
    productMoney: '1110.0',
    oldName: '黄鹤楼（硬15）',
    guidePrice: 848
  },
  {
    productName: '黄鹤楼硬1916黄珠',
    isCheck: 2,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/7763d0f6c091406c97b906e247d58fac.png',
    productMoney: '1075.0',
    oldName: '黄鹤楼硬1916黄珠',
    guidePrice: null
  },
  {
    productName: '中支感恩楼',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/0eb016fb84994755a75cfb196f13d203.png',
    productMoney: '240.0',
    oldName: '黄鹤楼（硬感恩中支）',
    guidePrice: 225
  },
  {
    productName: '中支蓝楼',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/50750160babb43a1b31d8c4163d69e33.png',
    productMoney: '187.0',
    oldName: '黄鹤楼（硬蓝中支）',
    guidePrice: 175
  },
  {
    productName: '软珍品楼',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/b168a4ef2c104e1c9f6ff4e23d3bc9a3.png',
    productMoney: '543.0',
    oldName: '黄鹤楼（软珍品）',
    guidePrice: 530
  },
  {
    productName: '合珍品楼',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/c38535ae811e4cd28c85e492e86c41ce.png',
    productMoney: '360.0',
    oldName: '黄鹤楼（硬珍品）',
    guidePrice: 339.2
  },
  {
    productName: '雅香金',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/34eb16c98e064c468c577abc87d42660.png',
    productMoney: '152.0',
    oldName: '黄鹤楼（雅香金）',
    guidePrice: 143.1
  },
  {
    productName: '黄鹤楼（视界）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/4c524da68e5342958b57e39aa6fe3288.png',
    productMoney: '465.0',
    oldName: '黄鹤楼（视界）',
    guidePrice: 466.4
  },
  {
    productName: '中支1916楼',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/30300af3eca6404285fb173102972f29.png',
    productMoney: '960.0',
    oldName: '1916中支',
    guidePrice: 1000
  },
  {
    productName: '中支峡谷情',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/ff23dbe5b8c94316bb73ad60fe5f3a09.png',
    productMoney: '325.0',
    oldName: '黄鹤楼（峡谷情中支）',
    guidePrice: 339.2
  },
  {
    productName: '软雅韵楼',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/b7cda3dc1bfb43df83f1ed8a4ed6d1a3.png',
    productMoney: '243.0',
    oldName: '黄鹤楼（软雅韵）',
    guidePrice: 212
  },
  {
    productName: '红金龙蓝',
    isCheck: 1,
    Category: '全部',
    Category2: '红金龙',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/8b02ce41b84b4c4c983b25a2d679cd40.png',
    productMoney: '131.0',
    oldName: '红金龙（硬蓝爱你）',
    guidePrice: 97
  },
  {
    productName: '嘉禧缘楼',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/e2fb892793eb4232a022ef6d1ea5fc76.png',
    productMoney: '178.0',
    oldName: '黄鹤楼（嘉禧缘）',
    guidePrice: 167.48
  },
  {
    productName: '蓝楼',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/38a659157e554c8f9a362bc78188aa81.png',
    productMoney: '182.0',
    oldName: '黄鹤楼（硬蓝）',
    guidePrice: 175
  },
  {
    productName: '硬红红金龙',
    isCheck: 0,
    Category: '全部',
    Category2: '红金龙',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/7210bfe7bd7043eab4b7b782e583b41d.png',
    productMoney: '194.0',
    oldName: '黄鹤楼（硬红）',
    guidePrice: 190.8
  },
  {
    productName: '红金龙（硬新版）',
    isCheck: 2,
    Category: '全部',
    Category2: '红金龙',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/7cb93ef629f640e1bded6e9623763725.png',
    productMoney: '65.0',
    oldName: '红金龙（硬新版）',
    guidePrice: 44.5
  },
  {
    productName: '黄鹤楼（硬金砂龙烟）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/806a4fd957ad4b87ab7dc352bf2c1684.png',
    productMoney: '153.0',
    oldName: '黄鹤楼（硬金砂龙烟）',
    guidePrice: 129.3
  },
  {
    productName: '黄鹤楼（新版峡谷情）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/0b8f49acd52d4cc8a16408799e3b1709.png',
    productMoney: '395.0',
    oldName: '黄鹤楼（新版峡谷情）',
    guidePrice: 339.2
  },
  {
    productName: '软红金龙',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/ac8bd8a0b33b42afaa107269e5273d55.png',
    productMoney: '228.0',
    oldName: '黄鹤楼（软红）',
    guidePrice: 227.9
  },
  {
    productName: '新中支1916楼',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/1fb9791dc5e8471dba5e5fb218f3a6dd.png',
    productMoney: '1000.0',
    oldName: '黄鹤楼（新版1916中支）',
    guidePrice: 1000
  },
  {
    productName: '大彩楼',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/195986189d2d49c4b71a7ce48d9d88ca.png',
    productMoney: '480.0',
    oldName: '黄鹤楼（硬大彩）',
    guidePrice: 243.8
  },
  {
    productName: '雪之梦9号',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/cbc9a39a21834b5aad892c7199da7415.png',
    productMoney: '665.0',
    oldName: '黄鹤楼（雪之梦9号）',
    guidePrice: null
  },
  {
    productName: '胜景楼',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/6c0b725edb5c4a8593387e61b4ae3048.png',
    productMoney: '300.0',
    oldName: '黄鹤楼（天下胜景）',
    guidePrice: 212
  },
  {
    productName: '合雅韵楼',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/88e113bc5d864e888c72f07de6ffeab7.png',
    productMoney: '240.0',
    oldName: '黄鹤楼（硬雅韵）',
    guidePrice: 212
  },
  {
    productName: '细支珍品楼',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/b18999daec0e47b78776721f5911803b.png',
    productMoney: '400.0',
    oldName: '黄鹤楼（细支珍品）',
    guidePrice: 381.6
  },
  {
    productName: '经典中支',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/1f97aee8fe614e7fa0de48683e6cac86.png',
    productMoney: '370.0',
    oldName: '黄鹤楼（金典中支）',
    guidePrice: 381.6
  },
  {
    productName: '软雪之景',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/8ab1b6c8b70a42879a8672c4e853f147.png',
    productMoney: '145.0',
    oldName: '黄鹤楼（软雪之景）',
    guidePrice: 121.9
  },
  {
    productName: '雪之梦10号',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/8c880a48f41747e79540cbb0cd058e20.png',
    productMoney: '775.0',
    oldName: '黄鹤楼（雪之梦10号）',
    guidePrice: 744
  },
  {
    productName: '小蜜蜂1916楼',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/1b72f75dd996477e9edbbadc401df25d.jpg',
    productMoney: '860.0',
    oldName: '黄鹤楼（新版硬1916小蜜蜂）',
    guidePrice: 848
  },
  {
    productName: '新版合金龙',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/835edbd34b3e47b68efdae7dc81ff6fd.png',
    productMoney: '62.0',
    oldName: '红金龙（硬红龙）',
    guidePrice: null
  },
  {
    productName: '合雪之景楼',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/a7846d20d4de4aa6b1de706b831e8c86.png',
    productMoney: '142.0',
    oldName: '黄鹤楼（硬雪之景）',
    guidePrice: null
  },
  {
    productName: '红金龙（硬神州腾龙）',
    isCheck: 2,
    Category: '全部',
    Category2: '红金龙',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/c59151b063aa4fafa7249498e4477968.png',
    productMoney: '67.0',
    oldName: '红金龙（硬神州腾龙）',
    guidePrice: null
  },
  {
    productName: '雪之梦8号',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/c10024c13b2d448da91564b1c9efa7bb.png',
    productMoney: '210.0',
    oldName: '黄鹤楼（雪之梦8号）',
    guidePrice: 300
  },
  {
    productName: '雪之梦7号',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/29a92706b7654bca8789d78b99e171e6.png',
    productMoney: '970.0',
    oldName: '黄鹤楼（雪之梦7号）',
    guidePrice: null
  },
  {
    productName: '雪之韵2号',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/f62e0de0b9ae4ac8984356e8e5f91c58.png',
    productMoney: '100.0',
    oldName: '黄鹤楼（雪之韵2号）',
    guidePrice: 180
  },
  {
    productName: '硬感恩',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/4411289b4db342ebafe949231c7908ff.png',
    productMoney: '700.0',
    oldName: '黄鹤楼（硬感恩）',
    guidePrice: 720.8
  },
  {
    productName: '红金龙（软精品）',
    isCheck: 2,
    Category: '全部',
    Category2: '红金龙',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/26c1a43107ae4bfba124bc25f7c89f9c.png',
    productMoney: '110.0',
    oldName: '红金龙（软精品）',
    guidePrice: 90.1
  },
  {
    productName: '雪之梦3号',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/34116c9247f945fa93e87a8a5523abd3.png',
    productMoney: '980.0',
    oldName: '黄鹤楼（雪之梦3号）',
    guidePrice: null
  },
  {
    productName: '祝福',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/1c258e4f0ee047589a9580518094c293.png',
    productMoney: '203.0',
    oldName: '黄鹤楼（祝福）',
    guidePrice: 167.48
  },
  {
    productName: '雪之梦6号',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/19dc59f0b6434a51984991fd890b5180.png',
    productMoney: '440.0',
    oldName: '黄鹤楼（雪之梦6号）',
    guidePrice: null
  },
  {
    productName: '红金龙（软蓝九州腾龙）',
    isCheck: 2,
    Category: '全部',
    Category2: '红金龙',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/256a4800559241c98f6b6094c5fa0ddb.png',
    productMoney: '58.0',
    oldName: '红金龙（软蓝九州腾龙）',
    guidePrice: null
  },
  {
    productName: '雪之景9号',
    isCheck: 1,
    Category: '全部',
    Category2: '黄鹤楼',
    Factory: '湖北',
    productImg: 'https://yanjiuchacha.com/api/1c75a33170ae475a8046bb3c689c8ed4.png',
    productMoney: '450.0',
    oldName: '黄鹤楼（雪之景9号）',
    guidePrice: null
  },
  {
    productName: '和天下',
    isCheck: 1,
    Category: '全部',
    Category2: '白沙',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/7d33abe1902044ae9db4c7834de71c9b白沙（和天下）.png',
    productMoney: '826.0',
    oldName: '白沙（和天下）',
    guidePrice: 848
  },
  {
    productName: '细和天下',
    isCheck: 1,
    Category: '全部',
    Category2: '白沙',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/71299ccaac7242c4bb7a9ac6f0c499f2白沙（细支和天下）.png',
    productMoney: '750.0',
    oldName: '白沙（细支和天下）',
    guidePrice: 720.8
  },
  {
    productName: '芙蓉王',
    isCheck: 1,
    Category: '全部',
    Category2: '芙蓉王',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/3c3c125fec8a41cba3088f13791780fc芙蓉王（硬）.png',
    productMoney: '209.0',
    oldName: '芙蓉王（硬）',
    guidePrice: 218.36
  },
  {
    productName: '软和天下',
    isCheck: 1,
    Category: '全部',
    Category2: '白沙',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/f23e577d1df345a5936e2f80edc5f37c白沙（软和天下）.png',
    productMoney: '735.0',
    oldName: '白沙（软和天下）',
    guidePrice: 720.8
  },
  {
    productName: '中支芙蓉王',
    isCheck: 1,
    Category: '全部',
    Category2: '芙蓉王',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/dbab922f76a04e1c9e0662cfe7ba00dd芙蓉王（硬中支）.png',
    productMoney: '248.0',
    oldName: '芙蓉王（硬中支）',
    guidePrice: 263
  },
  {
    productName: '细芙蓉王',
    isCheck: 1,
    Category: '全部',
    Category2: '芙蓉王',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/78cb78353b294d3a90b0c2e10bedc3c3芙蓉王（硬细支）.png',
    productMoney: '211.0',
    oldName: '芙蓉王（硬细支）',
    guidePrice: 225
  },
  {
    productName: '二代沙',
    isCheck: 1,
    Category: '全部',
    Category2: '白沙',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/7edc3643f2644d1eb382dedc41d2181e白沙（精品二代）.png',
    productMoney: '115.0',
    oldName: '白沙（精品二代）',
    guidePrice: 97
  },
  {
    productName: '中支和天下',
    isCheck: 1,
    Category: '全部',
    Category2: '白沙',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/51e9f65218da4175ae2a67c0feddc434白沙（硬和天下双中支）.png',
    productMoney: '790.0',
    oldName: '白沙（硬和天下双中支）',
    guidePrice: 763.2
  },
  {
    productName: '三代沙',
    isCheck: 1,
    Category: '全部',
    Category2: '白沙',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/cc58f6951eae427d8ba2911b49d97c37白沙（硬精品三代）.png',
    productMoney: '134.0',
    oldName: '白沙（硬精品三代）',
    guidePrice: 129.3
  },
  {
    productName: '合白沙',
    isCheck: 1,
    Category: '全部',
    Category2: '白沙',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/fed93540c59d44fdb98737cac4b68954白沙（硬）.png',
    productMoney: '79.0',
    oldName: '白沙（硬）',
    guidePrice: 61.5
  },
  {
    productName: '尊享和天下',
    isCheck: 1,
    Category: '全部',
    Category2: '白沙',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/dbb274072972439e94f9b04ad9930aa8白沙（和天下尊享）.png',
    productMoney: '775.0',
    oldName: '白沙（和天下尊享）',
    guidePrice: 763.2
  },
  {
    productName: '中支尊品和天下',
    isCheck: 1,
    Category: '全部',
    Category2: '和天下尊品中支',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/8d32c30b10714076a68bec26a12890be.png',
    productMoney: '1300.0',
    oldName: '和天下尊品中支',
    guidePrice: 1000
  },
  {
    productName: '细蓝闪带王',
    isCheck: 1,
    Category: '全部',
    Category2: '芙蓉王',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/83e2c413633f4f3fa347928c338f8fa3芙蓉王（蓝）.png',
    productMoney: '260.0',
    oldName: '芙蓉王（蓝）',
    guidePrice: 307.4
  },
  {
    productName: '西湖恋利群',
    isCheck: 1,
    Category: '全部',
    Category2: '利群',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/79a64ab1ddba4b7eb47da3d2527f179c.jpg',
    productMoney: '228.0',
    oldName: '利群（新版西湖恋）',
    guidePrice: 201.4
  },
  {
    productName: '细支尊品和天下',
    isCheck: 1,
    Category: '全部',
    Category2: '白沙',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/aa46d48756bb4be8a5ffc07dd75968dd.png',
    productMoney: '1950.0',
    oldName: '白沙（和天下尊品细支）',
    guidePrice: 848
  },
  {
    productName: '芙蓉王（硬蓝新版）',
    isCheck: 2,
    Category: '全部',
    Category2: '芙蓉王',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/382f6729be3a4fdbabc6bb4745da3778芙蓉王（硬蓝新版）.png',
    productMoney: '246.0',
    oldName: '芙蓉王（硬蓝新版）',
    guidePrice: 300
  },
  {
    productName: '84尊享和天下',
    isCheck: 1,
    Category: '全部',
    Category2: '白沙',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/abc9764c1d9d4bffaf9f82620571ea82白沙（和天下尊享84mm）.png',
    productMoney: '1270.0',
    oldName: '白沙（和天下尊享84mm）',
    guidePrice: 1000
  },
  {
    productName: '白沙（硬新精品二代）',
    isCheck: 2,
    Category: '全部',
    Category2: '白沙',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/6bd117dd5da54d7f9d24e44a46ffce9a白沙（硬新精品二代）.png',
    productMoney: '110.0',
    oldName: '白沙（硬新精品二代）',
    guidePrice: 97
  },
  {
    productName: '精白沙',
    isCheck: 1,
    Category: '全部',
    Category2: '白沙',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/63c9a10278b64581ad4226c3e01baf3b白沙（精品）.png',
    productMoney: '111.0',
    oldName: '白沙（精品）',
    guidePrice: 80
  },
  {
    productName: '软白沙',
    isCheck: 1,
    Category: '全部',
    Category2: '白沙',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/3abd292f1acb4f798eb3626049cb35ad白沙（软）.png',
    productMoney: '89.0',
    oldName: '白沙（软）',
    guidePrice: 61.5
  },
  {
    productName: '红宝石芙蓉王',
    isCheck: 1,
    Category: '全部',
    Category2: '芙蓉王',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/5292c7ae04da42faaad06cb18ee6aa13芙蓉王（硬红宝石）.png',
    productMoney: '222.0',
    oldName: '芙蓉王（硬红宝石）',
    guidePrice: 263
  },
  {
    productName: '领航芙蓉王',
    isCheck: 1,
    Category: '全部',
    Category2: '芙蓉王',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/73d1519f942c4771913748d0bd2b8710芙蓉王（领航）.png',
    productMoney: '410.0',
    oldName: '芙蓉王（领航）',
    guidePrice: 424
  },
  {
    productName: '白沙（软和天下檀香）',
    isCheck: 0,
    Category: '全部',
    Category2: '白沙',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/09d5a97c34b24bd3ae9e50055a53f12f白沙（软和天下檀香）.png',
    productMoney: '2200.0',
    oldName: '白沙（软和天下檀香）',
    guidePrice: null
  },
  {
    productName: '芙蓉王（硬闪带细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '芙蓉王',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/ca84abe170cb44c9b0a3eb8355727b03芙蓉王（硬闪带细支）.png',
    productMoney: '295.0',
    oldName: '芙蓉王（硬闪带细支）',
    guidePrice: 300
  },
  {
    productName: '和天下尊尚',
    isCheck: 2,
    Category: '全部',
    Category2: '和天下',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/224f678f5afb443e9fa985fc4d562866.png',
    productMoney: '33000.0',
    oldName: '和天下尊尚',
    guidePrice: null
  },
  {
    productName: '黄色荣耀中支',
    isCheck: 1,
    Category: '全部',
    Category2: '芙蓉王',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/c4543ddcf0e44f588f6ba0b5be03b949.png',
    productMoney: '300.0',
    oldName: '芙蓉王（荣耀中支）',
    guidePrice: 339.2
  },
  {
    productName: '蓝色荣耀细支',
    isCheck: 1,
    Category: '全部',
    Category2: '芙蓉王',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/c9a016093b3f43668c4cec3f6d6fc0f0.png',
    productMoney: '303.0',
    oldName: '芙蓉王（荣耀细支）',
    guidePrice: 339.2
  },
  {
    productName: '天天向上',
    isCheck: 1,
    Category: '全部',
    Category2: '白沙',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/a7434f0e8b314bcc88692aa994b09751白沙（天天向上）.png',
    productMoney: '135.0',
    oldName: '白沙（天天向上）',
    guidePrice: 129.3
  },
  {
    productName: '芙蓉王（锦绣细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '芙蓉王',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/2852e55b9d8f4b979d0cf31fe4fce122.png',
    productMoney: '750.0',
    oldName: '芙蓉王（锦绣细支）',
    guidePrice: 424
  },
  {
    productName: '芙蓉王（软蓝）',
    isCheck: 2,
    Category: '全部',
    Category2: '芙蓉王',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/59832a787c1243e58da6dc312349a391芙蓉王（软蓝）.png',
    productMoney: '385.0',
    oldName: '芙蓉王（软蓝）',
    guidePrice: 477
  },
  {
    productName: '和气生财',
    isCheck: 1,
    Category: '全部',
    Category2: '白沙粗支和气生财',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/0b333b306ccb465585f76ea0570c8a2b白沙（和钻石）.png',
    productMoney: '450.0',
    oldName: '白沙粗支和气生财',
    guidePrice: 477
  },
  {
    productName: '鸿运当头沙',
    isCheck: 1,
    Category: '全部',
    Category2: '白沙',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/0ff2e1d005044d958520b82373198726白沙（红运当头）.png',
    productMoney: '134.0',
    oldName: '白沙（红运当头）',
    guidePrice: 143.1
  },
  {
    productName: '蓝尚沙',
    isCheck: 1,
    Category: '全部',
    Category2: '白沙',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/70a99a37ce934750bdfaaaf2b3607cc6白沙（尚品蓝）.png',
    productMoney: '130.0',
    oldName: '白沙（尚品蓝）',
    guidePrice: 143.1
  },
  {
    productName: '白沙硬细支和气生财',
    isCheck: 2,
    Category: '全部',
    Category2: '白沙',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/6ea1470f77b34db2b0009d869ea0191c白沙（硬细支和气生财）.png',
    productMoney: '415.0',
    oldName: '白沙硬细支和气生财',
    guidePrice: 424
  },
  {
    productName: '匠心手作',
    isCheck: 1,
    Category: '全部',
    Category2: '芙蓉王',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/b7b89a1ef49d4902bf5ac460a6b74094芙蓉王（匠心手作）.png',
    productMoney: '735.0',
    oldName: '芙蓉王（匠心手作）',
    guidePrice: 678.4
  },
  {
    productName: '短王',
    isCheck: 1,
    Category: '全部',
    Category2: '芙蓉王短支',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/7185d7b65fac4c74990540df092185d6芙蓉王（硬75mm）.png',
    productMoney: '205.0',
    oldName: '芙蓉王短支',
    guidePrice: 225
  },
  {
    productName: '沁爽',
    isCheck: 1,
    Category: '全部',
    Category2: '芙蓉王(沁爽)',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/8a30248a9b6e4aa7aa82bcba2cc06903.jpg',
    productMoney: '520.0',
    oldName: '芙蓉王(沁爽)',
    guidePrice: 263
  },
  {
    productName: '软珍品白沙',
    isCheck: 1,
    Category: '全部',
    Category2: '白沙',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/a6f0a9761cd2421c881b3e21f01cb275白沙（珍品）.png',
    productMoney: '230.0',
    oldName: '白沙（珍品）',
    guidePrice: 263
  },
  {
    productName: '细红闪带王',
    isCheck: 1,
    Category: '全部',
    Category2: '芙蓉王',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/1dbb800f42214d96b86fd0f05e7365e1芙蓉王（硬红带细支）.png',
    productMoney: '256.0',
    oldName: '芙蓉王（硬红带细支）',
    guidePrice: 263
  },
  {
    productName: '绽放芙蓉王',
    isCheck: 1,
    Category: '全部',
    Category2: '芙蓉王',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/98749400603947109b18fe1f19576492.jpg',
    productMoney: '670.0',
    oldName: '芙蓉王（绽放）',
    guidePrice: 300
  },
  {
    productName: '短蓝闪带王',
    isCheck: 1,
    Category: '全部',
    Category2: '芙蓉王',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/391e2291b31942e493ca9a3d0fa7389c芙蓉王（硬闪带75mm）.png',
    productMoney: '370.0',
    oldName: '芙蓉王（硬闪带短支）',
    guidePrice: 339.2
  },
  {
    productName: '白沙（尚品白）',
    isCheck: 2,
    Category: '全部',
    Category2: '白沙',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/6de62749a43d45c68048f9442193bffa白沙（尚品白）.png',
    productMoney: '126.0',
    oldName: '白沙（尚品白）',
    guidePrice: null
  },
  {
    productName: '芙蓉王（硬蓝细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '芙蓉王',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/b83ccb03f643439fb70799f7304bfed8芙蓉王（硬蓝细支）.png',
    productMoney: '285.0',
    oldName: '芙蓉王（硬蓝细支）',
    guidePrice: 300
  },
  {
    productName: '芙蓉（软红）',
    isCheck: 2,
    Category: '全部',
    Category2: '芙蓉王',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/0ddff263bff0489eb2932b339c528759.jpg',
    productMoney: '69.0',
    oldName: '芙蓉（软红）',
    guidePrice: 19.08
  },
  {
    productName: '红闪带王',
    isCheck: 1,
    Category: '全部',
    Category2: '芙蓉王硬红带/红闪带',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/3bdf22ecf5e44e338a5393ecd74fa6c9芙蓉王（硬红带）.png',
    productMoney: '255.0',
    oldName: '芙蓉王硬红带/红闪带',
    guidePrice: 263
  },
  {
    productName: '钻石芙蓉王',
    isCheck: 1,
    Category: '全部',
    Category2: '芙蓉王',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/f27036922d74423da3c554d1e57bd4a0芙蓉王（钻石）.png',
    productMoney: '725.0',
    oldName: '芙蓉王（钻石）',
    guidePrice: 720.8
  },
  {
    productName: '王之荣耀',
    isCheck: 1,
    Category: '全部',
    Category2: '芙蓉王',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/25ac926950c14d39bb997ab7dcc745aa芙蓉王（王之荣耀）.png',
    productMoney: '350.0',
    oldName: '芙蓉王（王之荣耀）',
    guidePrice: null
  },
  {
    productName: '绿环保白沙',
    isCheck: 1,
    Category: '全部',
    Category2: '白沙',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/66cac266f2af440c867b39b6355a5d67白沙（绿和）.png',
    productMoney: '121.0',
    oldName: '白沙（绿和）',
    guidePrice: 76.32
  },
  {
    productName: '芙蓉王（锦绣）',
    isCheck: 2,
    Category: '全部',
    Category2: '芙蓉王',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/ceb8c7f774964738a9a4e10588dd5efd.jpg',
    productMoney: '845.0',
    oldName: '芙蓉王（锦绣）',
    guidePrice: 500
  },
  {
    productName: '白沙（硬白细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '白沙',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/50c893abd27f476aa0f6d3293bf442f1白沙（硬白细支）.png',
    productMoney: '225.0',
    oldName: '白沙（硬白细支）',
    guidePrice: 185.5
  },
  {
    productName: '白沙瑞中支',
    isCheck: 1,
    Category: '全部',
    Category2: '白沙',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/aff91ee0fba94ef7b62be2f3dbcc1b45.png',
    productMoney: '164.0',
    oldName: '白沙（瑞中支）',
    guidePrice: 175
  },
  {
    productName: '软相思鸟',
    isCheck: 1,
    Category: '全部',
    Category2: '相思鸟',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/4a4021752e6c42a0959f00c1c453e466相思鸟（软）.png',
    productMoney: '61.0',
    oldName: '相思鸟（软）',
    guidePrice: 19.08
  },
  {
    productName: '白沙（硬红运当头中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '白沙',
    Factory: '湖南',
    productImg: 'https://yanjiuchacha.com/api/94a6860f4eaa4020b9dd398a8da8e250白沙（硬红运当头中支）.png',
    productMoney: '250.0',
    oldName: '白沙（硬红运当头中支）',
    guidePrice: null
  },
  {
    productName: '炫赫门南京',
    isCheck: 1,
    Category: '全部',
    Category2: '南京',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/4ecc3e50d80046f4aaa6f78aa7d508e6南京（炫赫门）.png',
    productMoney: '192.0',
    oldName: '南京（炫赫门）',
    guidePrice: 155
  },
  {
    productName: '雨花石',
    isCheck: 1,
    Category: '全部',
    Category2: '南京',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/ed8ae7b9985c4927bd189a5731e6a5d6南京（雨花石）.png',
    productMoney: '448.0',
    oldName: '南京（雨花石）',
    guidePrice: 455.8
  },
  {
    productName: '小95京',
    isCheck: 1,
    Category: '全部',
    Category2: '南京',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/cec3c9fee963444ea5c8995a51497866南京（细支九五）.png',
    productMoney: '725.0',
    oldName: '南京（细支九五）',
    guidePrice: 678.4
  },
  {
    productName: '软95京',
    isCheck: 1,
    Category: '全部',
    Category2: '南京',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/13a88552abdb4836879128553b37b6b4南京（软九五）.png',
    productMoney: '725.0',
    oldName: '南京（软九五）',
    guidePrice: 720.8
  },
  {
    productName: '十二钗',
    isCheck: 1,
    Category: '全部',
    Category2: '南京',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/95f3330b2d7349c8a01c570f3ea3e54f南京（金陵十二钗烤烟）.png',
    productMoney: '248.0',
    oldName: '南京（金陵十二钗烤烟）',
    guidePrice: 243.8
  },
  {
    productName: '红杉树',
    isCheck: 1,
    Category: '全部',
    Category2: '苏烟',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/5f84a12bcb6c4d70bd6c7bd31f48ad56苏烟（五星红杉树）.png',
    productMoney: '191.0',
    oldName: '苏烟（五星红杉树）',
    guidePrice: 188.68
  },
  {
    productName: '薄荷十二钗',
    isCheck: 1,
    Category: '全部',
    Category2: '南京',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/281019aae37c46faba8cefe11c3c936f南京（金陵十二钗薄荷）.png',
    productMoney: '210.0',
    oldName: '南京（金陵十二钗薄荷）',
    guidePrice: 188.68
  },
  {
    productName: '红南京',
    isCheck: 1,
    Category: '全部',
    Category2: '南京',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/b21671d303154fd4bbe4720b409323ef南京（红）.png',
    productMoney: '145.0',
    oldName: '南京（红）',
    guidePrice: 114.5
  },
  {
    productName: '95京',
    isCheck: 1,
    Category: '全部',
    Category2: '南京',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/3486b8b3a0ee426190346b93e17b696c南京（九五）.png',
    productMoney: '690.0',
    oldName: '南京（九五）',
    guidePrice: 848
  },
  {
    productName: '大观园南京',
    isCheck: 1,
    Category: '全部',
    Category2: '南京',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/0749e5955cc1444aad0ef5ec0d5df085南京（大观园爆冰）.png',
    productMoney: '260.0',
    oldName: '南京（大观园爆冰）',
    guidePrice: 263
  },
  {
    productName: '苏烟（软金砂）',
    isCheck: 2,
    Category: '全部',
    Category2: '苏烟',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/40bd26dbf840436d97cb88b37ef2732e苏烟（软金砂）.png',
    productMoney: '350.0',
    oldName: '苏烟（软金砂）',
    guidePrice: 381.6
  },
  {
    productName: '雨花石3mg',
    isCheck: 1,
    Category: '全部',
    Category2: '南京',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/b3440ce9bc6e41b8a753236c96c5a93c.png',
    productMoney: '585.0',
    oldName: '南京（雨花石3毫克）',
    guidePrice: 551.2
  },
  {
    productName: '炫彩小南京',
    isCheck: 1,
    Category: '全部',
    Category2: '南京',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/a181e6721c3e4d4d9a5eaf89f1ac5bf4南京（炫赫门炫彩）.png',
    productMoney: '225.0',
    oldName: '南京（炫赫门炫彩）',
    guidePrice: 225
  },
  {
    productName: '梦都小南京',
    isCheck: 1,
    Category: '全部',
    Category2: '南京',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/fbd87cb8f980450bb88104b4b49aaf0d南京（梦都） (2).png',
    productMoney: '183.0',
    oldName: '南京（梦都）',
    guidePrice: null
  },
  {
    productName: '中支彩苏烟',
    isCheck: 1,
    Category: '全部',
    Category2: '苏烟',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/5653d9e4fdc44dd281af4e427c9822e7苏烟（彩中）.png',
    productMoney: '345.0',
    oldName: '苏烟（彩中）',
    guidePrice: 381.6
  },
  {
    productName: '佳品南京',
    isCheck: 1,
    Category: '全部',
    Category2: '南京',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/509f282f748846b383b1da401ab1bb54南京（佳品）.png',
    productMoney: '165.0',
    oldName: '南京（佳品）',
    guidePrice: 129.32
  },
  {
    productName: '中支五星',
    isCheck: 1,
    Category: '全部',
    Category2: '苏烟',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/3981dfb88d9649e99c555b1f34a950a3苏烟（五星红中）.png',
    productMoney: '215.0',
    oldName: '苏烟（五星红中）',
    guidePrice: 225
  },
  {
    productName: '南京（梦都）升级版',
    isCheck: 2,
    Category: '全部',
    Category2: '南京',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/1f012b1802f4454fa20ac76c7bf70c9c南京（梦都）.png',
    productMoney: '191.0',
    oldName: '南京（梦都）升级版',
    guidePrice: 175
  },
  {
    productName: '南京（精品）',
    isCheck: 2,
    Category: '全部',
    Category2: '南京',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/6c80fd3f1ff14727a71f6b88634b33df南京（精品）.png',
    productMoney: '193.0',
    oldName: '南京（精品）',
    guidePrice: 182.32
  },
  {
    productName: '红杉树（软五星）',
    isCheck: 2,
    Category: '全部',
    Category2: '苏烟',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/674cc5dc80e943f385614c920b033928.png',
    productMoney: '184.0',
    oldName: '红杉树（软五星）',
    guidePrice: null
  },
  {
    productName: '南京（紫树）',
    isCheck: 2,
    Category: '全部',
    Category2: '南京',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/5a1227a1b34b4736ba5f5f663555e49c南京（紫树）.png',
    productMoney: '115.0',
    oldName: '南京（紫树）',
    guidePrice: 66.78
  },
  {
    productName: '中支多彩苏烟',
    isCheck: 1,
    Category: '全部',
    Category2: '苏烟',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/e3509ccfedc945a58c9bd7addc65f6a0.png',
    productMoney: '280.0',
    oldName: '苏烟（多彩中支）',
    guidePrice: 300
  },
  {
    productName: '苏烟（七星）',
    isCheck: 2,
    Category: '全部',
    Category2: '苏烟',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/a4fdaabcd91f433584ba7e2f358c8f84苏烟（七星）.png',
    productMoney: '234.0',
    oldName: '苏烟（七星）',
    guidePrice: 243.8
  },
  {
    productName: '南京（金砂）',
    isCheck: 2,
    Category: '全部',
    Category2: '南京',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/d7b5d2ed492c4d579e0f33f902e8c9ae南京（金砂）.png',
    productMoney: '172.0',
    oldName: '南京（金砂）',
    guidePrice: 132.5
  },
  {
    productName: '苏烟（晶彩中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '苏烟',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/9496c1b199ff4c11afebbdb7425c9cfa苏烟（晶彩中支）.png',
    productMoney: '500.0',
    oldName: '苏烟（晶彩中支）',
    guidePrice: 583
  },
  {
    productName: '南京（红楼卷新版）',
    isCheck: 2,
    Category: '全部',
    Category2: '南京',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/efdec96e8d1e497f94d02b9271e142dd.png',
    productMoney: '260.0',
    oldName: '南京（红楼卷新版）',
    guidePrice: 339.2
  },
  {
    productName: '五星苏烟',
    isCheck: 1,
    Category: '全部',
    Category2: '红杉树',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/a948b4ec612b4f909cac87b267bc0d40.png',
    productMoney: '190.0',
    oldName: '红杉树（五星）',
    guidePrice: null
  },
  {
    productName: '苏烟（金砂2）',
    isCheck: 2,
    Category: '全部',
    Category2: '苏烟',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/84810b91ef834dadbc216564f27c45d1苏烟（金砂2）.png',
    productMoney: '655.0',
    oldName: '苏烟（金砂2）',
    guidePrice: 742
  },
  {
    productName: '铂金苏烟',
    isCheck: 1,
    Category: '全部',
    Category2: '苏烟',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/013100c222ba48729f05377b7de89b31苏烟（铂晶）.png',
    productMoney: '2250.0',
    oldName: '苏烟（铂晶）',
    guidePrice: 678.4
  },
  {
    productName: '大观园南京',
    isCheck: 1,
    Category: '全部',
    Category2: '南京',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/b94df5167cee46ffbe46833638675edd南京（大观园）.png',
    productMoney: '385.0',
    oldName: '南京（大观园）',
    guidePrice: 339.2
  },
  {
    productName: '红楼卷南京',
    isCheck: 1,
    Category: '全部',
    Category2: '南京',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/b378beb9e6394b3391a61bc33024fd31南京（红楼卷）.png',
    productMoney: '650.0',
    oldName: '南京（红楼卷）',
    guidePrice: 551.2
  },
  {
    productName: '南京（细支九五3mg）',
    isCheck: 2,
    Category: '全部',
    Category2: '南京',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/aa8bcec602fd495fa0987f4dfb74d715.jpg',
    productMoney: '1360.0',
    oldName: '南京（细支九五3mg）',
    guidePrice: 1000
  },
  {
    productName: '苏烟（沉香）',
    isCheck: 2,
    Category: '全部',
    Category2: '苏烟',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/ede8992191fc42c0a37cc9f1315346be苏烟（沉香）.png',
    productMoney: '940.0',
    oldName: '苏烟（沉香）',
    guidePrice: 720.8
  },
  {
    productName: '南京（硬金星）',
    isCheck: 2,
    Category: '全部',
    Category2: '南京',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/19e864720a9e41558bd0b2b3e8595c3e南京（硬金星）.png',
    productMoney: '195.0',
    oldName: '南京（硬金星）',
    guidePrice: 143.1
  },
  {
    productName: '南京（十二钗中式混合型）',
    isCheck: 2,
    Category: '全部',
    Category2: '南京',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/565d6185f91a4d25acfcbfe336055420南京（十二钗中式混合型）.png',
    productMoney: '190.0',
    oldName: '南京（十二钗中式混合型）',
    guidePrice: null
  },
  {
    productName: '南京（84mm金陵十二钗）',
    isCheck: 2,
    Category: '全部',
    Category2: '南京',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/7909221cda374adb8fdab06b67955339南京（84mm金陵十二钗）.png',
    productMoney: '248.0',
    oldName: '南京（84mm金陵十二钗）',
    guidePrice: null
  },
  {
    productName: '南京（五星）',
    isCheck: 2,
    Category: '全部',
    Category2: '南京',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/fadcdf1f882549a6b1233c75d3a7b274南京（五星）.png',
    productMoney: '195.0',
    oldName: '南京（五星）',
    guidePrice: null
  },
  {
    productName: '苏烟（东渡）',
    isCheck: 2,
    Category: '全部',
    Category2: '苏烟',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/dbff70072cc74783a5e8e41ddd59797f苏烟（东渡）.png',
    productMoney: '300.0',
    oldName: '苏烟（东渡）',
    guidePrice: null
  },
  {
    productName: '苏烟（甜韵）',
    isCheck: 2,
    Category: '全部',
    Category2: '苏烟',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/ec83a0df85524d509732f7e3d8bcfe81苏烟（甜韵）.png',
    productMoney: '262.0',
    oldName: '苏烟（甜韵）',
    guidePrice: 263
  },
  {
    productName: '南京（紫晶）',
    isCheck: 2,
    Category: '全部',
    Category2: '南京',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/c2de4d5070ae483cb15ee916e9c90955南京（紫晶）.png',
    productMoney: '98.0',
    oldName: '南京（紫晶）',
    guidePrice: 0
  },
  {
    productName: '南京（红华西）',
    isCheck: 2,
    Category: '全部',
    Category2: '南京',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/375a92a827f24813a1cb9f9fd9f95022南京（红华西）.png',
    productMoney: '220.0',
    oldName: '南京（红华西）',
    guidePrice: 121.9
  },
  {
    productName: '苏烟（好风光）',
    isCheck: 2,
    Category: '全部',
    Category2: '苏烟',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/20af72cb73e745a192f80f5c0302496c苏烟（好风光）.png',
    productMoney: '380.0',
    oldName: '苏烟（好风光）',
    guidePrice: null
  },
  {
    productName: '苏烟（水韵）',
    isCheck: 2,
    Category: '全部',
    Category2: '苏烟',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/d2239096a8194a78bbe230292d5af873苏烟（水韵）.png',
    productMoney: '310.0',
    oldName: '苏烟（水韵）',
    guidePrice: null
  },
  {
    productName: '苏烟（灵韵细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '苏烟',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/7ea0be1f81d548608ae372d690bf91ee苏烟（灵韵细支）.png',
    productMoney: '505.0',
    oldName: '苏烟（灵韵细支）',
    guidePrice: null
  },
  {
    productName: '苏烟（格局）',
    isCheck: 2,
    Category: '全部',
    Category2: '苏烟',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/a5d9a4c7793646edb399c0ef6ff1b332苏烟（格局）.png',
    productMoney: '480.0',
    oldName: '苏烟（格局）',
    guidePrice: null
  },
  {
    productName: '一品梅（淡黄）',
    isCheck: 2,
    Category: '全部',
    Category2: '一品梅',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/830291ee69af41ed8de9f7844605acdb一品梅（淡黄）.png',
    productMoney: '63.0',
    oldName: '一品梅（淡黄）',
    guidePrice: null
  },
  {
    productName: '一品梅（佳品）',
    isCheck: 2,
    Category: '全部',
    Category2: '一品梅',
    Factory: '江苏',
    productImg: 'https://yanjiuchacha.com/api/0c91b59550e648bf801de859f8cb44fc一品梅（佳品）.png',
    productMoney: '80.0',
    oldName: '一品梅（佳品）',
    guidePrice: null
  },
  {
    productName: '新制皖烟',
    isCheck: 1,
    Category: '全部',
    Category2: '黄山新制皖烟/普皖',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/4217c917d00f4583abbb9cf912beb568黄山（新制皖烟）.png',
    productMoney: '145.0',
    oldName: '黄山新制皖烟/普皖',
    guidePrice: 121.9
  },
  {
    productName: '细支微商',
    isCheck: 1,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/88e292e15c3c420f8f995239e4d47634黄山（徽商新概念细支）.png',
    productMoney: '342.0',
    oldName: '黄山（徽商新概念细支）',
    guidePrice: 339.2
  },
  {
    productName: '细支红方印',
    isCheck: 1,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/d65b23fd510643238c7937309ebbec18黄山（红方印细支）.png',
    productMoney: '180.0',
    oldName: '黄山（红方印细支）',
    guidePrice: 175
  },
  {
    productName: '印象黄山',
    isCheck: 1,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/b735fc9e13a64f33b75ab0338a7d6117黄山（印象一品）.png',
    productMoney: '83.0',
    oldName: '黄山（印象一品）',
    guidePrice: 66.8
  },
  {
    productName: '黄山（小红方印）中支',
    isCheck: 2,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/7958471067a94ac9a23a7476a330d374黄山（小红方印）中支.png',
    productMoney: '196.0',
    oldName: '黄山（小红方印）中支',
    guidePrice: 185.5
  },
  {
    productName: '黄一品',
    isCheck: 1,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/5c18f5615cbf4a5dbb23831c10687a2a黄山（新一品）.png',
    productMoney: '77.0',
    oldName: '黄山（新一品）',
    guidePrice: 61.5
  },
  {
    productName: '粗微商',
    isCheck: 1,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/ffa22a7ca03945ff83b84d0bb55a7856黄山（徽商新概念）.png',
    productMoney: '325.0',
    oldName: '黄山（徽商新概念）',
    guidePrice: 339.2
  },
  {
    productName: '软记忆黄山',
    isCheck: 1,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/0cd56148bfe74809a083d936ca2165b4黄山（记忆）.png',
    productMoney: '133.0',
    oldName: '黄山（记忆）',
    guidePrice: 121.9
  },
  {
    productName: '金皖黄山',
    isCheck: 1,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/7c12fe2755b84cc5aa586ac6394eee2e黄山（金皖烟）.png',
    productMoney: '236.0',
    oldName: '黄山（金皖烟）',
    guidePrice: 248.04
  },
  {
    productName: '黄山（徽商新视界细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/c07922b29a2f472ea672a00742ada8d9黄山（徽商新视界细支）.png',
    productMoney: '675.0',
    oldName: '黄山（徽商新视界细支）',
    guidePrice: 678.4
  },
  {
    productName: '黄山（徽商新概念双中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/63ec18b154c6457383f85821babdcf74黄山（徽商新概念双中支）.png',
    productMoney: '360.0',
    oldName: '黄山（徽商新概念双中支）',
    guidePrice: 381.6
  },
  {
    productName: '大红方印',
    isCheck: 1,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/c300db0fa600402a8c1e4f43af3a1e8b黄山（大红方印）.png',
    productMoney: '245.0',
    oldName: '黄山（大红方印）',
    guidePrice: 275.6
  },
  {
    productName: '合记忆黄山',
    isCheck: 1,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/b3a2e857986b45e683a3ebedca16c100.png',
    productMoney: '100.0',
    oldName: '黄山（硬记忆）',
    guidePrice: 88
  },
  {
    productName: '细支黑马',
    isCheck: 1,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/0e4958bcd72c4f3f95db2adb5bd4670c黄山（黑马细支）.png',
    productMoney: '215.0',
    oldName: '黄山（黑马细支）',
    guidePrice: 225
  },
  {
    productName: '黄山（徽商新视界）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/2876d559f5e94e0ebadca07a3a2b85aa黄山（徽商新视界）.png',
    productMoney: '660.0',
    oldName: '黄山（徽商新视界）',
    guidePrice: 678.4
  },
  {
    productName: '黄山（中国画细支）新版',
    isCheck: 2,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/aa05a73a649d46a4a08072c5fbb45ca7黄山（中国画细支）新版.png',
    productMoney: '143.0',
    oldName: '黄山（中国画细支）新版',
    guidePrice: 129.3
  },
  {
    productName: '金中支红方印',
    isCheck: 1,
    Category: '全部',
    Category2: ' 黄山红方印金中支',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/205e4609b51545e8a59bfcc2dbb39b12.png',
    productMoney: '290.0',
    oldName: ' 黄山红方印金中支',
    guidePrice: 339
  },
  {
    productName: '中支红方印',
    isCheck: 1,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/8fa7fe2bf104474fa27fdfbe99120cb2黄山（红方印新中支）.png',
    productMoney: '193.0',
    oldName: '黄山（红方印新中支）',
    guidePrice: 263
  },
  {
    productName: '大黄山',
    isCheck: 1,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/d88318a53fe046d8867e80358ebd198b黄山（大黄山）.png',
    productMoney: '153.0',
    oldName: '黄山（大黄山）',
    guidePrice: 139.92
  },
  {
    productName: '细支中国画',
    isCheck: 1,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/7e9462b2c08242b8bfda68f82a84f32a黄山（中国画细支）.png',
    productMoney: '142.0',
    oldName: '黄山（中国画细支）',
    guidePrice: 129.3
  },
  {
    productName: '中支天都',
    isCheck: 1,
    Category: '全部',
    Category2: ' 黄山天都中支',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/1ab027ed780542deba44e44f381e951a.png',
    productMoney: '650.0',
    oldName: ' 黄山天都中支',
    guidePrice: 848
  },
  {
    productName: '微风皖韵',
    isCheck: 1,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/4df9e340cff44020be7da41691714992黄山（徽风皖韵）.png',
    productMoney: '170.0',
    oldName: '黄山（徽风皖韵）',
    guidePrice: 175
  },
  {
    productName: '黄山（金皖细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/964813bcbebe416e8c43574a679f150e黄山（金皖细支）.png',
    productMoney: '257.0',
    oldName: '黄山（金皖细支）',
    guidePrice: 263
  },
  {
    productName: '黄山（天高云淡细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/bbb66449f35941b08f2444aecaa609d7.png',
    productMoney: '1050.0',
    oldName: '黄山（天高云淡细支）',
    guidePrice: 678
  },
  {
    productName: '小国粹',
    isCheck: 1,
    Category: '全部',
    Category2: '王冠',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/8bda6273d2634d829f79681b4e6a8197王冠（小国粹）.png',
    productMoney: '330.0',
    oldName: '王冠（小国粹）',
    guidePrice: 567.5
  },
  {
    productName: '中支高山流水',
    isCheck: 1,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/6b28bd9b49ce4b12a2a25d6703ee1ea0黄山（高山流水中支）.png',
    productMoney: '450.0',
    oldName: '黄山（高山流水中支）',
    guidePrice: 510
  },
  {
    productName: '黄山（红皖烟）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/06ecce4481f746b1ab398ba92d15a7a2黄山（红皖烟）.png',
    productMoney: '172.0',
    oldName: '黄山（红皖烟）',
    guidePrice: 169.6
  },
  {
    productName: '都宝悠酷中支',
    isCheck: 2,
    Category: '全部',
    Category2: '都宝悠酷中支',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/f9a39e1162ed4070855e305480ba6236都宝（悠酷中支）.png',
    productMoney: '146.0',
    oldName: '都宝悠酷中支',
    guidePrice: 175
  },
  {
    productName: '蓝色假日',
    isCheck: 1,
    Category: '全部',
    Category2: '王冠',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/8e9a8763c589441eaa34d3421b4aa7f0王冠（蓝色假日）.png',
    productMoney: '465.0',
    oldName: '王冠（蓝色假日）',
    guidePrice: 755
  },
  {
    productName: '黄山（硬天都）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/23332ef952154479b0a97befa8a38f58黄山（硬天都）.png',
    productMoney: '660.0',
    oldName: '黄山（硬天都）',
    guidePrice: 678.4
  },
  {
    productName: '梅兰竹菊',
    isCheck: 1,
    Category: '全部',
    Category2: '王冠',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/dea0debe83234ea2ae6b9cc12b326944王冠（梅兰竹菊）.png',
    productMoney: '320.0',
    oldName: '王冠（梅兰竹菊）',
    guidePrice: 576
  },
  {
    productName: '假日黄金海岸',
    isCheck: 1,
    Category: '全部',
    Category2: '王冠',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/fa17722d609340d8ad186d8379e332d6王冠（假日黄金海岸）.png',
    productMoney: '315.0',
    oldName: '王冠（假日∙黄金海岸）',
    guidePrice: 420
  },
  {
    productName: '黄山（皖烟中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/b0562a85630b4d25b6de4cf873531016.png',
    productMoney: '245.0',
    oldName: '黄山（皖烟中支）',
    guidePrice: 300
  },
  {
    productName: '黄山（红方印前店后坊中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/7a5e2202f04444299d5c26720af581c2黄山（红方印前店后坊中支）.png',
    productMoney: '350.0',
    oldName: '黄山（红方印前店后坊中支）',
    guidePrice: 339
  },
  {
    productName: '黄山（七星皖烟）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/979f3722fa3147c4ad522a1ccd4fc2d3黄山（七星皖烟）.png',
    productMoney: '270.0',
    oldName: '黄山（七星皖烟）',
    guidePrice: 300
  },
  {
    productName: '黄山（国宾迎客松）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/bab57db214ab452eaa1cfe885fe53fa5黄山（国宾迎客松）.png',
    productMoney: '192.0',
    oldName: '黄山（国宾迎客松）',
    guidePrice: 190.8
  },
  {
    productName: '王冠（原味9号）',
    isCheck: 2,
    Category: '全部',
    Category2: '王冠',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/f01b1c40baa0408ca813e55d231da316王冠（原味9号）.png',
    productMoney: '45.0',
    oldName: '王冠（原味9号）',
    guidePrice: null
  },
  {
    productName: '国粹',
    isCheck: 1,
    Category: '全部',
    Category2: '王冠',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/2f46b2243bd44e89a92a0d1b7f9fd971王冠（国粹）.png',
    productMoney: '650.0',
    oldName: '王冠（国粹）',
    guidePrice: 1080
  },
  {
    productName: '王冠（原味9号塑嘴）',
    isCheck: 2,
    Category: '全部',
    Category2: '王冠',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/d54e725bfa504b92b5441ee44836af5e王冠（原味9号塑嘴）.png',
    productMoney: '20.0',
    oldName: '王冠（原味9号塑嘴）',
    guidePrice: 60
  },
  {
    productName: '黄山（硬）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/cbca8071f74845aebc7f4a42c0b4ccd8黄山（硬）.png',
    productMoney: '114.0',
    oldName: '黄山（硬）',
    guidePrice: 93.28
  },
  {
    productName: '黄山（嘉宾迎客松）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/10a2416257cd4e1f917d4406eec64a51黄山（嘉宾迎客松）.png',
    productMoney: '86.0',
    oldName: '黄山（嘉宾迎客松）',
    guidePrice: 71
  },
  {
    productName: '黄山（天都）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/20b78846313348faaa604b3b548ea26e黄山（天都）.png',
    productMoney: '645.0',
    oldName: '黄山（天都）',
    guidePrice: 720.8
  },
  {
    productName: '黄山（中国画）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄山',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/3300c36b08cf4f8fa39e77ecf3241db5黄山（中国画）.png',
    productMoney: '128.0',
    oldName: '黄山（中国画）',
    guidePrice: null
  },
  {
    productName: '黄山七星金皖',
    isCheck: 2,
    Category: '全部',
    Category2: '黄山七星金皖',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/43b502e7c25c485ab697b8bf1a4b2650.png',
    productMoney: '214.0',
    oldName: '黄山七星金皖',
    guidePrice: 300
  },
  {
    productName: '国粹风度',
    isCheck: 1,
    Category: '全部',
    Category2: '王冠',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/5dc1768a6dea481d934e66dbc9e7f260王冠（国粹风度）.png',
    productMoney: '310.0',
    oldName: '王冠（国粹风度）',
    guidePrice: 520
  },
  {
    productName: '国粹满堂彩',
    isCheck: 1,
    Category: '全部',
    Category2: '王冠',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/91bf4f898148465a935051d2be33667d.png',
    productMoney: '200.0',
    oldName: '王冠（国粹满堂彩）（雪茄）',
    guidePrice: 340
  },
  {
    productName: '回味迎客松',
    isCheck: 1,
    Category: '全部',
    Category2: '黄山松',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/113851203ca5402ba295833662988523黄山松（回味迎客松）.png',
    productMoney: '135.0',
    oldName: '黄山松（回味迎客松）',
    guidePrice: 120
  },
  {
    productName: '铝2支',
    isCheck: 1,
    Category: '全部',
    Category2: '王冠',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/b502639323c94d9d93cc42de28646f54王冠（铝2支全叶卷）.png',
    productMoney: '110.0',
    oldName: '王冠（铝2支全叶卷）',
    guidePrice: null
  },
  {
    productName: '原味2号',
    isCheck: 1,
    Category: '全部',
    Category2: '王冠原味2号',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/c0d01c04034643d5ade100302303f3dc.png',
    productMoney: '17.0',
    oldName: '王冠原味2号（雪茄)',
    guidePrice: null
  },
  {
    productName: '国粹名角',
    isCheck: 1,
    Category: '全部',
    Category2: '王冠',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/beb25d1b9b404cfebdfa8782aa78655d王冠（国粹名角）.png',
    productMoney: '255.0',
    oldName: '王冠（国粹名角）',
    guidePrice: null
  },
  {
    productName: '王冠（智者010十支）',
    isCheck: 2,
    Category: '全部',
    Category2: '王冠',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/5cf5767753034c3ca6810266f5ec5a63王冠（智者010十支）.png',
    productMoney: '920.0',
    oldName: '王冠（智者010十支）',
    guidePrice: null
  },
  {
    productName: '王冠（茶马古道）',
    isCheck: 2,
    Category: '全部',
    Category2: '王冠',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/d9f01bb5b3ab4ff4831caa6c6abce904王冠（茶马古道）.png',
    productMoney: '340.0',
    oldName: '王冠（茶马古道）',
    guidePrice: null
  },
  {
    productName: '王冠20支',
    isCheck: 1,
    Category: '全部',
    Category2: '王冠',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/bc90b151124647999e54c43fc2983ba2王冠（20支）.png',
    productMoney: '100.0',
    oldName: '王冠（20支）',
    guidePrice: null
  },
  {
    productName: '王冠（加勒比）',
    isCheck: 2,
    Category: '全部',
    Category2: '王冠',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/5f183390dd6f4cd48d04a44f5b6da2ea王冠（加勒比）.png',
    productMoney: '180.0',
    oldName: '王冠（加勒比）',
    guidePrice: null
  },
  {
    productName: '都宝（新）',
    isCheck: 2,
    Category: '全部',
    Category2: '都宝',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/1fa322b7b6da4f7293a4b45f5da25dc2都宝（新）.png',
    productMoney: '110.0',
    oldName: '都宝（新）',
    guidePrice: 53
  },
  {
    productName: '王冠（10支装全叶卷）',
    isCheck: 2,
    Category: '全部',
    Category2: '王冠',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/79ddee6ec888495b9067bcc8868cfc9a王冠（10支装全叶卷）.png',
    productMoney: '330.0',
    oldName: '王冠（10支装全叶卷）',
    guidePrice: null
  },
  {
    productName: '王冠（智者010五支）',
    isCheck: 2,
    Category: '全部',
    Category2: '王冠',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/4d453cd15cb345dcb24f42209923f0db王冠（智者010五支）.png',
    productMoney: '480.0',
    oldName: '王冠（智者010五支）',
    guidePrice: null
  },
  {
    productName: '王冠（古建三绝25支）',
    isCheck: 2,
    Category: '全部',
    Category2: '王冠',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/584be2d7f79d4e18bfe1b614317d63d3王冠（古建三绝25支）.png',
    productMoney: '365.0',
    oldName: '王冠（古建三绝25支）',
    guidePrice: null
  },
  {
    productName: '假日阳光',
    isCheck: 1,
    Category: '全部',
    Category2: '王冠',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/b293be65aeeb41d98f990a148978b6ec.png',
    productMoney: '110.0',
    oldName: '王冠（假日阳光）（雪茄)',
    guidePrice: 144
  },
  {
    productName: '渡江',
    isCheck: 2,
    Category: '全部',
    Category2: '渡江',
    Factory: '安徽',
    productImg: 'https://yanjiuchacha.com/api/76b74b546c4f42429f5541e4fcf81a23渡江（黄软）.png',
    productMoney: '73.0',
    oldName: '渡江',
    guidePrice: null
  },
  {
    productName: '腾王阁紫光',
    isCheck: 1,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/935a921194454fb681e42140839e21b6金圣（滕王阁·紫光）.png',
    productMoney: '139.0',
    oldName: '金圣（滕王阁·紫光）',
    guidePrice: 129.3
  },
  {
    productName: '合腾王阁',
    isCheck: 1,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/9b011d13ae894a8cb427230f55911114金圣（硬滕王阁）.png',
    productMoney: '117.0',
    oldName: '金圣（硬滕王阁）',
    guidePrice: 88
  },
  {
    productName: '金圣青瓷',
    isCheck: 1,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/81257c0161cb48b898a653870d888a0d金圣（青瓷）.png',
    productMoney: '135.0',
    oldName: '金圣（青瓷）',
    guidePrice: 158
  },
  {
    productName: '中支中国红',
    isCheck: 1,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/24011c32628541919c06e3fc878799e9金圣（圣地中国红中支）.png',
    productMoney: '390.0',
    oldName: '金圣（圣地中国红中支）',
    guidePrice: 470
  },
  {
    productName: '金圣吉品',
    isCheck: 1,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/09a0a57755d74048ac779a254afed4ca金圣（吉品）.png',
    productMoney: '216.0',
    oldName: '金圣（吉品）',
    guidePrice: 219.42
  },
  {
    productName: '金圣庐山',
    isCheck: 1,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/a650185006634ee1af5961d45265e5b2金圣（庐山）.png',
    productMoney: '71.0',
    oldName: '金圣（庐山）',
    guidePrice: 61.5
  },
  {
    productName: '智圣出山',
    isCheck: 1,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/6da75ae3a80349bc90b57dd3d05b607e金圣（智圣出山）.png',
    productMoney: '615.0',
    oldName: '金圣（智圣出山）',
    guidePrice: 720.8
  },
  {
    productName: '中支青瓷',
    isCheck: 1,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/58905a455dcb4aac88fea05f3122eabd金圣（青瓷中支）.png',
    productMoney: '168.0',
    oldName: '金圣（青瓷中支）',
    guidePrice: 175
  },
  {
    productName: '国瓷金圣',
    isCheck: 1,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/aa013e7071744f0184c96825c24ee760金圣（智圣出山·国瓷）.png',
    productMoney: '750.0',
    oldName: '金圣（智圣出山·国瓷）',
    guidePrice: 763.2
  },
  {
    productName: '小支腾王阁',
    isCheck: 1,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/9d216774b1564c50af320ab4ca55e6de金圣（滕王阁细支）.png',
    productMoney: '183.0',
    oldName: '金圣（滕王阁细支）',
    guidePrice: 175
  },
  {
    productName: '更上一层楼',
    isCheck: 1,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/040e013118da4d45bbb6a95fbfbc3e69金圣（滕王阁·更上一层楼）.png',
    productMoney: '130.0',
    oldName: '金圣（滕王阁·更上一层楼）',
    guidePrice: 121.9
  },
  {
    productName: '金圣（硬红瑞香）',
    isCheck: 2,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/9f9007e9e8f540ce9a7741e58ef32fa5金圣（硬红瑞香）.png',
    productMoney: '268.0',
    oldName: '金圣（硬红瑞香）',
    guidePrice: 300
  },
  {
    productName: '金新版中国红',
    isCheck: 1,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/dfdb270cc9d143c2a2c82abd6e1edb2a金圣（硬中国红）.png',
    productMoney: '282.0',
    oldName: '金圣（硬圣地中国红）',
    guidePrice: 339.2
  },
  {
    productName: '金圣（智圣出山·国味）',
    isCheck: 2,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/e4d00d54e8834a629132f13d75809c6f金圣（智圣出山·国味）.png',
    productMoney: '405.0',
    oldName: '金圣（智圣出山·国味）',
    guidePrice: 424
  },
  {
    productName: '长天腾王阁',
    isCheck: 1,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/2922d170b7f24684b225ae39f30f3f8d金圣（滕王阁长天）.png',
    productMoney: '225.0',
    oldName: '金圣（滕王阁长天）',
    guidePrice: 225
  },
  {
    productName: '十二生肖金圣',
    isCheck: 1,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/fd24b0ed2b1c4eec9b5dd360325ed5dc金圣（硬红十二生肖）.png',
    productMoney: '77.0',
    oldName: '金圣（硬红十二生肖）',
    guidePrice: 61.5
  },
  {
    productName: '金圣（16支圣地中国红）',
    isCheck: 2,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/a71b03a6225f43cea27541af1dcb99dc金圣（圣地中国红）.png',
    productMoney: '580.0',
    oldName: '金圣（16支圣地中国红）',
    guidePrice: 678.4
  },
  {
    productName: '黑智胜出山方金中支',
    isCheck: 1,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/cda614cfa98c4bd1a520215d5b42900d.png',
    productMoney: '755.0',
    oldName: '金圣（智圣出山金中支）',
    guidePrice: null
  },
  {
    productName: '金圣（本草瑞香）',
    isCheck: 2,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/e5495d4055e9428b8ee7d43f2c2b456e金圣（本草瑞香）.png',
    productMoney: '272.0',
    oldName: '金圣（本草瑞香）',
    guidePrice: 275.6
  },
  {
    productName: '庐山（新）',
    isCheck: 2,
    Category: '全部',
    Category2: '庐山',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/f8814c3e0b35465da6fad5ace64dcaab庐山（新）.png',
    productMoney: '71.0',
    oldName: '庐山（新）',
    guidePrice: null
  },
  {
    productName: '金圣金吉',
    isCheck: 1,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/d3c931dad98747b59ae0366309d2ef7c金圣（金吉）.png',
    productMoney: '200.0',
    oldName: '金圣（金吉）',
    guidePrice: 225
  },
  {
    productName: '软白金圣',
    isCheck: 1,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/c30c7db1d389418582dc0d35f0dcb378金圣（软）.png',
    productMoney: '78.0',
    oldName: '金圣（软）',
    guidePrice: null
  },
  {
    productName: '黑智胜出山长条双中支',
    isCheck: 1,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/8bcb581387cd4dcbad19f1943ae86aed.png',
    productMoney: '350.0',
    oldName: '金圣（智圣出山双中支）',
    guidePrice: null
  },
  {
    productName: '合白金圣',
    isCheck: 1,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/15166a4bc0f74a059912fda8a9fee3fd金圣（硬）.png',
    productMoney: '115.0',
    oldName: '金圣（硬）',
    guidePrice: 114.5
  },
  {
    productName: '金圣（庐山有滋有味）',
    isCheck: 2,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/1edcb0c56d2c49c3b7ae936ef4de4b6e金圣（庐山有滋有味）.png',
    productMoney: '69.0',
    oldName: '金圣（庐山有滋有味）',
    guidePrice: null
  },
  {
    productName: '合红金圣',
    isCheck: 1,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/9c2b12eb92274287b4dad8c98ecf118c金圣（硬红）.png',
    productMoney: '76.0',
    oldName: '金圣（硬红）',
    guidePrice: 66.78
  },
  {
    productName: '精品庐山',
    isCheck: 1,
    Category: '全部',
    Category2: '庐山',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/e41c6e41cf8344c6b882996ab06024c6庐山（精品）.png',
    productMoney: '70.0',
    oldName: '庐山（精品）',
    guidePrice: null
  },
  {
    productName: '金圣（吉品中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/db948acc8a1246ae9ea9e7acde18a817.png',
    productMoney: '255.0',
    oldName: '金圣（吉品中支）',
    guidePrice: 263
  },
  {
    productName: '赣金圣',
    isCheck: 1,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/7b916bc47fbc4dd38f75a46613010579金圣（赣）.png',
    productMoney: '130.0',
    oldName: '金圣（赣）',
    guidePrice: 121.9
  },
  {
    productName: '金圣（智圣出山细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/da99b1aea7db4303b2a92412e726b6f8.png',
    productMoney: '815.0',
    oldName: '金圣（智圣出山细支）',
    guidePrice: 0
  },
  {
    productName: '软红金圣',
    isCheck: 1,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/548cdfb7b0dc431c8d67aa0e3ecf0361金圣（软红）.png',
    productMoney: '86.0',
    oldName: '金圣（软红）',
    guidePrice: null
  },
  {
    productName: '鸿运庐山',
    isCheck: 1,
    Category: '全部',
    Category2: '庐山',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/10a34901f67b49a4ba5796724a81d369庐山（鸿运）.png',
    productMoney: '54.0',
    oldName: '庐山（鸿运）',
    guidePrice: null
  },
  {
    productName: '金圣（原生工坊）',
    isCheck: 2,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/31696a3ae0804d0ca066ea06dcf67721金圣（原生工坊）.png',
    productMoney: '300.0',
    oldName: '金圣（原生工坊）',
    guidePrice: null
  },
  {
    productName: '大红运庐山',
    isCheck: 1,
    Category: '全部',
    Category2: '庐山',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/8010da4746bf48ec9fcede0b75398329庐山（大红运）.png',
    productMoney: '72.0',
    oldName: '庐山（大红运）',
    guidePrice: 38.16
  },
  {
    productName: '金圣（典藏花开富贵）',
    isCheck: 2,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/c8e3afe6ab974c2392b942941c88bee3金圣（典藏花开富贵）.png',
    productMoney: '320.0',
    oldName: '金圣（典藏花开富贵）',
    guidePrice: 318
  },
  {
    productName: '庐山（银）',
    isCheck: 2,
    Category: '全部',
    Category2: '庐山',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/b85bc26f1b9049f099d7df86e563e2da庐山（银）.png',
    productMoney: '58.0',
    oldName: '庐山（银）',
    guidePrice: null
  },
  {
    productName: '金圣（炫彩）',
    isCheck: 2,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/5f8e8463dc534223be910cd899f163d0金圣（炫彩）.png',
    productMoney: '220.0',
    oldName: '金圣（炫彩）',
    guidePrice: null
  },
  {
    productName: '赣（红金）',
    isCheck: 2,
    Category: '全部',
    Category2: '赣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/8900344a330d41968dfe2f4612829be4赣（红金）.png',
    productMoney: '129.0',
    oldName: '赣（红金）',
    guidePrice: null
  },
  {
    productName: '细支青瓷',
    isCheck: 1,
    Category: '全部',
    Category2: '金圣',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/a180d5b509e649b391be1ded8d532233.jpg',
    productMoney: '135.0',
    oldName: '金圣（青瓷细支）',
    guidePrice: 0
  },
  {
    productName: '黄精庐山',
    isCheck: 1,
    Category: '全部',
    Category2: '庐山',
    Factory: '江西',
    productImg: 'https://yanjiuchacha.com/api/41590f2c64a045a29323cd517b05b874庐山（黄精品）.png',
    productMoney: '67.0',
    oldName: '庐山（黄精品）',
    guidePrice: null
  },
  {
    productName: '新利群',
    isCheck: 1,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/f920a483c41445239d1021c814d37994利群（新版）.png',
    productMoney: '174.0',
    oldName: '利群（新版）',
    guidePrice: 143.1
  },
  {
    productName: '软阳光利群',
    isCheck: 1,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/c7af4e3a38e540b99ba28b196b5d1d70.png',
    productMoney: '323.0',
    oldName: '利群（软阳光软长嘴）',
    guidePrice: 318
  },
  {
    productName: '小休闲利群',
    isCheck: 1,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/23d3bc3a28884768a6ba0c43d9e6e352利群（休闲细支）.png',
    productMoney: '985.0',
    oldName: '利群（休闲细支）',
    guidePrice: 848
  },
  {
    productName: '合长嘴利群',
    isCheck: 1,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/5d95fa613b1642338f22938f4f07e5aa利群（长嘴）.png',
    productMoney: '215.0',
    oldName: '利群（长嘴）',
    guidePrice: 190.8
  },
  {
    productName: '楼外楼利群',
    isCheck: 1,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/a694ed2331364ba1875f16bbe94a20a6利群（楼外楼）.png',
    productMoney: '198.0',
    oldName: '利群（楼外楼）',
    guidePrice: 175
  },
  {
    productName: '软蓝利',
    isCheck: 1,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/be8881eb58184e3194d20dc4fb6e4ad6利群（软蓝）.png',
    productMoney: '165.0',
    oldName: '利群（软蓝）',
    guidePrice: 159
  },
  {
    productName: '西子利群',
    isCheck: 1,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/d11e8d097c4d4d839a9d92adb2ea78d7利群（西子阳光）.png',
    productMoney: '286.0',
    oldName: '利群（西子阳光）',
    guidePrice: 275.6
  },
  {
    productName: '软嘴长利群',
    isCheck: 1,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/97b6d26c62f2416790cf3af044e82ae8利群（软红长嘴）.png',
    productMoney: '226.0',
    oldName: '利群（软红长嘴）',
    guidePrice: 190.8
  },
  {
    productName: '夜西湖利群',
    isCheck: 1,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/9a14c583b3e6495985005f394bca3f3e利群（夜·西湖）.png',
    productMoney: '179.0',
    oldName: '利群（夜西湖）',
    guidePrice: 158
  },
  {
    productName: '江南韵利群',
    isCheck: 1,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/e4b31f792a9c442eb9442fe2b87ee671利群（江南韵）.png',
    productMoney: '272.0',
    oldName: '利群（江南韵）',
    guidePrice: 225
  },
  {
    productName: '中支阳光尊',
    isCheck: 1,
    Category: '全部',
    Category2: '利群阳光尊中支',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/fdb07387ae994b7e833daaa709fb1964.png',
    productMoney: '381.0',
    oldName: '利群阳光尊中支',
    guidePrice: 339.2
  },
  {
    productName: '利群（硬）',
    isCheck: 2,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/ec92a3f8c40a41e38c9f6ed003b9e296利群（硬）.png',
    productMoney: '260.0',
    oldName: '利群（硬）',
    guidePrice: 228.96
  },
  {
    productName: '蓝利群',
    isCheck: 1,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/636b6ccbed7c4a6d9bda8dd903661a42利群（蓝天）.png',
    productMoney: '213.0',
    oldName: '利群（蓝天）',
    guidePrice: 143.1
  },
  {
    productName: '旧版云端利群',
    isCheck: 1,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/a38d29eb3aac4a4790caeac97a038b8e利群（休闲云端）.png',
    productMoney: '1500.0',
    oldName: '利群（休闲云端老版）',
    guidePrice: 848
  },
  {
    productName: '休闲利群',
    isCheck: 1,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/08510952ade0462b876dd5b22c6f8fe9利群（休闲）.png',
    productMoney: '685.0',
    oldName: '利群（休闲）',
    guidePrice: 720.8
  },
  {
    productName: '利群金红利',
    isCheck: 1,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/9ecf0323322a4229a1ed0cae3740cd8a利群（红利）.png',
    productMoney: '815.0',
    oldName: '利群（红利）',
    guidePrice: 848
  },
  {
    productName: '山外山利群',
    isCheck: 1,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/11e34536c80842c08961d539ee8cf1af利群（山外山）.png',
    productMoney: '265.0',
    oldName: '利群（山外山）',
    guidePrice: 263
  },
  {
    productName: '西湖恋利群',
    isCheck: 1,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/727495365b764c829c7c4d3b5e25c28d利群（西湖恋）.png',
    productMoney: '280.0',
    oldName: '利群（西湖恋）',
    guidePrice: 201.4
  },
  {
    productName: '中支橙利群',
    isCheck: 1,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/8f26478d51204fa18975f92e3d17234d利群（阳光橙中支）.png',
    productMoney: '400.0',
    oldName: '利群（阳光橙中支）',
    guidePrice: 424
  },
  {
    productName: '中支阳光青利群',
    isCheck: 1,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/1ebd436bbfef4993b0ad51053e77e53c.png',
    productMoney: '287.0',
    oldName: '利群（阳光青中支）',
    guidePrice: 263
  },
  {
    productName: '合阳光利群',
    isCheck: 1,
    Category: '全部',
    Category2: '利群硬阳光',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/0b2e8d9e89be4954a6ad1365e895e5ff.png',
    productMoney: '395.0',
    oldName: '利群硬阳光',
    guidePrice: 424
  },
  {
    productName: '细支阳光尊',
    isCheck: 1,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/6fe5c4f4ec6c4f7aad6b301a8dfe267a.png',
    productMoney: '425.0',
    oldName: '利群（阳光尊细支）',
    guidePrice: 424
  },
  {
    productName: '天外天利群',
    isCheck: 1,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/e17983e5e83b459581de11021ffbe71e利群（天外天）.png',
    productMoney: '505.0',
    oldName: '利群（天外天）',
    guidePrice: 510
  },
  {
    productName: '利群（软金色阳光）',
    isCheck: 2,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/c03b5f9331494b8d8e7c67848f6dc58f利群（软金色阳光）.png',
    productMoney: '425.0',
    oldName: '利群（软金色阳光）',
    guidePrice: 455.8
  },
  {
    productName: '利群（硬山外山）',
    isCheck: 2,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/80c93c1fb04f4a56af79f1b347b8d67c.jpg',
    productMoney: '281.0',
    oldName: '利群（硬山外山）',
    guidePrice: 263
  },
  {
    productName: '二代利群',
    isCheck: 1,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/8f7ef84efda04350948a65381e84e79e利群（新二代）.png',
    productMoney: '195.0',
    oldName: '利群（新二代）',
    guidePrice: 143.1
  },
  {
    productName: '利群（老版）',
    isCheck: 2,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/a757d555bc654be39cc63a6324dd7fdb利群（老版）.png',
    productMoney: '275.0',
    oldName: '利群（老版）',
    guidePrice: null
  },
  {
    productName: '利群（薄荷）',
    isCheck: 2,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/e41f30cccbe84ca6892c701305ecca04利群（薄荷）.png',
    productMoney: '190.0',
    oldName: '利群（薄荷）',
    guidePrice: 185.5
  },
  {
    productName: '紫雄狮双叶',
    isCheck: 1,
    Category: '全部',
    Category2: '雄狮',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/3f2989a376d047e1a8c98fea84c0bc30雄狮（硬）.png',
    productMoney: '78.0',
    oldName: '雄狮（紫）',
    guidePrice: null
  },
  {
    productName: '利群（休闲金中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/7c390ed1113c4213ae37e694fc8a7ed9.jpg',
    productMoney: '1500.0',
    oldName: '利群（休闲金中支）',
    guidePrice: 848
  },
  {
    productName: '雄狮（红老版）',
    isCheck: 1,
    Category: '全部',
    Category2: '雄狮',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/294b5a688dc147bea76b27c79df5aec2雄狮（红老版）.png',
    productMoney: '84.0',
    oldName: '雄狮（红老版）',
    guidePrice: 0
  },
  {
    productName: '雄狮（薄荷）',
    isCheck: 1,
    Category: '全部',
    Category2: '雄狮',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/a6ed0fd996784b759ecaec39822cfd60雄狮（薄荷）.png',
    productMoney: '98.0',
    oldName: '雄狮（薄荷）',
    guidePrice: null
  },
  {
    productName: '利群（神州）',
    isCheck: 2,
    Category: '全部',
    Category2: '利群',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/ab510d7b21944292ab27eae319b0bedc利群（神州）.png',
    productMoney: '290.0',
    oldName: '利群（神州）',
    guidePrice: null
  },
  {
    productName: '大红鹰（软蓝）',
    isCheck: 2,
    Category: '全部',
    Category2: '大红鹰',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/4980d4a738444f61a3fb7ef5462355b7大红鹰（软蓝）.png',
    productMoney: '145.0',
    oldName: '大红鹰（软蓝）',
    guidePrice: 93.28
  },
  {
    productName: '红雄狮双叶',
    isCheck: 1,
    Category: '全部',
    Category2: '雄狮',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/6baf01f7a3bc41dca014ca99e73c495e雄狮（红）.png',
    productMoney: '78.0',
    oldName: '雄狮（红）',
    guidePrice: null
  },
  {
    productName: '大红鹰（新安江软蓝）',
    isCheck: 2,
    Category: '全部',
    Category2: '大红鹰',
    Factory: '浙江',
    productImg: 'https://yanjiuchacha.com/api/33aa84d70c544f23aac6045f0c7b6191大红鹰（新安江软蓝）.png',
    productMoney: '160.0',
    oldName: '大红鹰（新安江软蓝）',
    guidePrice: null
  },
  {
    productName: '公司合中华',
    isCheck: 1,
    Category: '全部',
    Category2: '中华',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/24348d26b0ef4d4fbdec77fbc6cc7f1e中华（硬）.png',
    productMoney: '365.0',
    oldName: '中华（硬）',
    guidePrice: 381.6
  },
  {
    productName: '金双支中华',
    isCheck: 1,
    Category: '全部',
    Category2: '中华',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/6dc6c39721ba4e81b9cc3632abfccaa3中华（双中支）.png',
    productMoney: '483.0',
    oldName: '中华（双中支）',
    guidePrice: 424
  },
  {
    productName: '中华（软）',
    isCheck: 2,
    Category: '全部',
    Category2: '中华',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/bdf7d3a0c940445eaba90052ff1d9910中华（软）.png',
    productMoney: '554.0',
    oldName: '中华（软）',
    guidePrice: 583
  },
  {
    productName: '方合金中华',
    isCheck: 1,
    Category: '全部',
    Category2: '中华',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/fdf8d1d24a9640e192931649a53e9e84中华（金中支）.png',
    productMoney: '770.0',
    oldName: '中华（金中支）',
    guidePrice: 678.4
  },
  {
    productName: '细支普中',
    isCheck: 1,
    Category: '全部',
    Category2: '中华',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/389f8a386c1544d5811e9ea6b6ff3f6b中华（细支）.png',
    productMoney: '446.0',
    oldName: '中华（细支）',
    guidePrice: 424
  },
  {
    productName: '3头软牡丹',
    isCheck: 1,
    Category: '全部',
    Category2: '牡丹',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/ff8d1908c10c4d48a4b9eddb4615fb99牡丹（软）.png',
    productMoney: '164.0',
    oldName: '牡丹（软）',
    guidePrice: 121.9
  },
  {
    productName: '329软中华',
    isCheck: 1,
    Category: '全部',
    Category2: '中华',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/8661b909fc534c3d915bd5253f6c96ff中华（软）.png',
    productMoney: '580.0',
    oldName: '中华（软）329',
    guidePrice: 583
  },
  {
    productName: '333软牡丹',
    isCheck: 1,
    Category: '全部',
    Category2: '牡丹333',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/03bb58eed9524f5bb0ac5e1ed218556a.png',
    productMoney: '178.0',
    oldName: '牡丹333',
    guidePrice: 114.5
  },
  {
    productName: '330软中华',
    isCheck: 1,
    Category: '全部',
    Category2: '中华',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/4f96da827443434d9f782858dc489701中华（软）.png',
    productMoney: '563.0',
    oldName: '中华（软）330',
    guidePrice: 583
  },
  {
    productName: '328软中华',
    isCheck: 1,
    Category: '全部',
    Category2: '中华',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/2cc7cd13f1194261bc833ea7305809b6中华（软）.png',
    productMoney: '565.0',
    oldName: '中华（软）328',
    guidePrice: 583
  },
  {
    productName: '中支红牡丹',
    isCheck: 1,
    Category: '全部',
    Category2: '牡丹',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/d64f43096b6044d78e06bbba4616ab5b牡丹（红中支）.png',
    productMoney: '238.0',
    oldName: '牡丹（红中支）',
    guidePrice: 175
  },
  {
    productName: '金细支中华',
    isCheck: 1,
    Category: '全部',
    Category2: '中华',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/097b38cd89c14a7f9276d87fba4bd9e3中华（金细支）.png',
    productMoney: '660.0',
    oldName: '中华（金细支）',
    guidePrice: 678.4
  },
  {
    productName: '330软牡丹',
    isCheck: 1,
    Category: '全部',
    Category2: '牡丹330',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/8f5c409ac9834510ad0173672ff3863f.png',
    productMoney: '167.0',
    oldName: '牡丹330',
    guidePrice: 114.5
  },
  {
    productName: '中支冰耀中南海',
    isCheck: 1,
    Category: '全部',
    Category2: '中南海',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/db8a951feb9a46de956b82683fbfc2581_自定义px_2021-12-14+15_37_50 (2).png',
    productMoney: '213.0',
    oldName: '中南海（冰耀中支）',
    guidePrice: 201.4
  },
  {
    productName: '中支乌牡丹',
    isCheck: 1,
    Category: '全部',
    Category2: '牡丹',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/9e80983fd7584b40968c4a0bca52d492牡丹（蓝中支）.png',
    productMoney: '288.0',
    oldName: '牡丹（蓝中支）',
    guidePrice: 263
  },
  {
    productName: '方合中华',
    isCheck: 1,
    Category: '全部',
    Category2: '中华',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/2428f9ae3d0d4c3fb2ca8a2cf1d1e095中华（全开式）.png',
    productMoney: '370.0',
    oldName: '中华（全开式）',
    guidePrice: 381.6
  },
  {
    productName: '软前门',
    isCheck: 1,
    Category: '全部',
    Category2: '大前门',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/0c4cee3825d34d95b958c04b8773b061大前门（软）.png',
    productMoney: '90.0',
    oldName: '大前门（软）',
    guidePrice: 53
  },
  {
    productName: '大熊猫硬经典',
    isCheck: 1,
    Category: '全部',
    Category2: '熊猫',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/ee588039776f4499b7d8bdb3a479d07e熊猫（硬经典）.png',
    productMoney: '985.0',
    oldName: '熊猫（硬经典）',
    guidePrice: 848
  },
  {
    productName: '短前门',
    isCheck: 1,
    Category: '全部',
    Category2: '大前门',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/3e654a2a40c5447782d42a1c0a469d30大前门（短支）.png',
    productMoney: '180.0',
    oldName: '大前门（短支）',
    guidePrice: 158
  },
  {
    productName: '金短支中华',
    isCheck: 1,
    Category: '全部',
    Category2: '中华',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/d8f622b58c424fa6ad0fd334a8644028中华（金短支）.png',
    productMoney: '630.0',
    oldName: '中华（金短支）',
    guidePrice: 678.4
  },
  {
    productName: '红双喜上海双喜',
    isCheck: 2,
    Category: '全部',
    Category2: '双喜',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/ab9810754fc44b2c8ab28face760ef41.png',
    productMoney: '112.0',
    oldName: '红双喜上海双喜',
    guidePrice: 80
  },
  {
    productName: '中南海（典8）',
    isCheck: 2,
    Category: '全部',
    Category2: '中南海',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/56e554df099c465d88e269216576d57e中南海（典8）.png',
    productMoney: '95.0',
    oldName: '中南海（典8）',
    guidePrice: 97
  },
  {
    productName: '5克中南海',
    isCheck: 1,
    Category: '全部',
    Category2: '中南海',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/d85f4abd83714f629fa6eae884724055中南海（5mg）.png',
    productMoney: '89.0',
    oldName: '中南海（5mg）',
    guidePrice: 114.5
  },
  {
    productName: '中南海（5mg细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '中南海',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/7106d981aa3448839dc58192987c7f06中南海（5mg细支）.png',
    productMoney: '108.0',
    oldName: '中南海（5mg细支）',
    guidePrice: 121.9
  },
  {
    productName: '熊猫（典藏中支）硬盒',
    isCheck: 2,
    Category: '全部',
    Category2: '熊猫',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/042795df2b1a4e6982f2bb51f1956b12.jpg',
    productMoney: '2600.0',
    oldName: '熊猫（典藏中支）硬盒',
    guidePrice: 1000
  },
  {
    productName: '红双喜（硬8mg）',
    isCheck: 2,
    Category: '全部',
    Category2: '双喜',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/412a423282144acb9b5e588d64abc5ef.png',
    productMoney: '95.0',
    oldName: '红双喜（硬8mg）',
    guidePrice: 88
  },
  {
    productName: '熊猫（新版硬经典）',
    isCheck: 2,
    Category: '全部',
    Category2: '熊猫',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/9dadf0e36e3d42caa1611a7f2d812b0b.jpg',
    productMoney: '890.0',
    oldName: '熊猫（新版硬经典）',
    guidePrice: 848
  },
  {
    productName: '中支恒大',
    isCheck: 1,
    Category: '全部',
    Category2: '恒大',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/45d1fe26c1a242ee8a56fb09fe5dc8db恒大（硬中支）.png',
    productMoney: '205.0',
    oldName: '恒大（硬中支）',
    guidePrice: 201.4
  },
  {
    productName: '凤凰（细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '凤凰',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/8e884c5ca795410d8bb8f18403d9d486.png',
    productMoney: '307.0',
    oldName: '凤凰（细支）',
    guidePrice: 300
  },
  {
    productName: '细支凤凰牡丹',
    isCheck: 1,
    Category: '全部',
    Category2: '牡丹',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/993e62cc43a349deb61f0aa45a64251b.png',
    productMoney: '325.0',
    oldName: '牡丹（凤凰细支）',
    guidePrice: 300
  },
  {
    productName: '红双喜（硬上海）',
    isCheck: 2,
    Category: '全部',
    Category2: '双喜',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/b6d01b9d860d430bbefb003066403842红双喜（硬上海）.png',
    productMoney: '132.0',
    oldName: '红双喜（硬上海）',
    guidePrice: 93.28
  },
  {
    productName: '典5中南海',
    isCheck: 1,
    Category: '全部',
    Category2: '中南海',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/1775ba76f23243df86927cb80542fa53中南海（典5）.png',
    productMoney: '102.0',
    oldName: '中南海（典5）',
    guidePrice: 143.1
  },
  {
    productName: '牡丹飞马',
    isCheck: 1,
    Category: '全部',
    Category2: '牡丹',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/1ec14e4842544c08af43aeda7a7d3db2牡丹（飞马）.png',
    productMoney: '139.0',
    oldName: '牡丹（飞马）',
    guidePrice: 129.3
  },
  {
    productName: '恒大（烟魁1949中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '恒大',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/642558576b164fc29a44b7cb68fb241c恒大（烟魁1949中支）.png',
    productMoney: '425.0',
    oldName: '恒大（烟魁1949中支）',
    guidePrice: 551.2
  },
  {
    productName: '8g中南海',
    isCheck: 1,
    Category: '全部',
    Category2: '中南海',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/1d7c2e82dcf44afb9426c450a1e0e719中南海（8mg）.png',
    productMoney: '87.0',
    oldName: '中南海（8mg）',
    guidePrice: 88
  },
  {
    productName: '金装8g中南海',
    isCheck: 1,
    Category: '全部',
    Category2: '中南海',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/540e2773b9db4e42b4b52840e3b0c63e中南海（金装8mg）.png',
    productMoney: '83.0',
    oldName: '中南海（金装8mg）',
    guidePrice: 80
  },
  {
    productName: '硬12支中华',
    isCheck: 1,
    Category: '全部',
    Category2: '中华',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/2dc1eabe6d6d4755be52a8eb14024c38中华（12支硬）.png',
    productMoney: '220.0',
    oldName: '中华（12支硬）',
    guidePrice: null
  },
  {
    productName: '硬5支中华',
    isCheck: 1,
    Category: '全部',
    Category2: '中华',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/7e07461fa5ec464ba7ff594492f81e96中华（5支硬盒）.png',
    productMoney: '120.0',
    oldName: '中华（5支硬盒）',
    guidePrice: null
  },
  {
    productName: '细支硬合金牡丹',
    isCheck: 1,
    Category: '全部',
    Category2: '牡丹',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/d976530b5b1b4d009cd57288c59e4aca牡丹（金细支）.png',
    productMoney: '255.0',
    oldName: '牡丹（金细支）',
    guidePrice: 225
  },
  {
    productName: '中华（金短支）新版',
    isCheck: 2,
    Category: '全部',
    Category2: '中华',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/a745c98116aa43339c6d175b9780ced6.jpg',
    productMoney: '655.0',
    oldName: '中华（金短支）新版',
    guidePrice: 678.4
  },
  {
    productName: '中华（细支3毫克）',
    isCheck: 2,
    Category: '全部',
    Category2: '中华',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/3a0c2a25082b485883d96c0d1263c683.jpg',
    productMoney: '3050.0',
    oldName: '中华（细支3毫克）',
    guidePrice: 0
  },
  {
    productName: '牡丹（青柠细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '牡丹',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/9cd3b625715a4923b34943901638fb1f牡丹（青柠细支）.png',
    productMoney: '255.0',
    oldName: '牡丹（青柠细支）',
    guidePrice: 225
  },
  {
    productName: '软中虎年329',
    isCheck: 2,
    Category: '全部',
    Category2: '中华',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/ca93ab428981490e9965d4a623eaee7f.png',
    productMoney: '700.0',
    oldName: '软中虎年329',
    guidePrice: null
  },
  {
    productName: '中南海（软精品）',
    isCheck: 2,
    Category: '全部',
    Category2: '中南海',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/7f43ea3a29fc4c8f97a841972c82e781中南海（软精品）.png',
    productMoney: '227.0',
    oldName: '中南海（软精品）',
    guidePrice: 228.96
  },
  {
    productName: '牡丹（软蓝）',
    isCheck: 2,
    Category: '全部',
    Category2: '牡丹',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/c7ab22b4868343458d178552a21f1668牡丹（软蓝）.png',
    productMoney: '288.0',
    oldName: '牡丹（软蓝）',
    guidePrice: 275.6
  },
  {
    productName: '牡丹（紫凤凰）',
    isCheck: 2,
    Category: '全部',
    Category2: '牡丹',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/78bda03540df409cbb219bed07c69fbc.png',
    productMoney: '295.0',
    oldName: '牡丹（紫凤凰）',
    guidePrice: 300
  },
  {
    productName: '短支金牡丹',
    isCheck: 1,
    Category: '全部',
    Category2: '牡丹',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/fb956046b5b84debb8f10b5eda35b981牡丹（金短支）.png',
    productMoney: '248.0',
    oldName: '牡丹（金短支）',
    guidePrice: 225
  },
  {
    productName: '合前门',
    isCheck: 1,
    Category: '全部',
    Category2: '大前门',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/71dec3f6090b45ef9b557905b73cb7b0大前门（硬）.png',
    productMoney: '121.0',
    oldName: '大前门（硬）',
    guidePrice: null
  },
  {
    productName: '恒大（记忆1949中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '恒大',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/2bf1f42e2e594d6786ce78ad791d8941恒大（记忆1949中支）.png',
    productMoney: '265.0',
    oldName: '恒大（记忆1949中支）',
    guidePrice: 263
  },
  {
    productName: '3g中南海',
    isCheck: 1,
    Category: '全部',
    Category2: '中南海',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/47c6f3ae81044860a7151837e935ac4f中南海（3mg）.png',
    productMoney: '120.0',
    oldName: '中南海（3mg）',
    guidePrice: 190.8
  },
  {
    productName: '中华（软蛇年）',
    isCheck: 2,
    Category: '全部',
    Category2: '中华',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/e409652b3e314a43b3ea7ab74b2af6e3.png',
    productMoney: '635.0',
    oldName: '中华（软蛇年）',
    guidePrice: 583
  },
  {
    productName: '恒大（烟魁1919）',
    isCheck: 2,
    Category: '全部',
    Category2: '恒大',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/8f23618689a644ea89b95e0a16f2895e恒大（烟魁1919）.png',
    productMoney: '720.0',
    oldName: '恒大（烟魁1919）',
    guidePrice: null
  },
  {
    productName: '红双喜（江山精品）',
    isCheck: 2,
    Category: '全部',
    Category2: '双喜',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/37cb3663eb1046f39b8c139371604b0f红双喜（江山精品）.png',
    productMoney: '97.0',
    oldName: '红双喜（江山精品）',
    guidePrice: null
  },
  {
    productName: '恒大（全开式烟魁）',
    isCheck: 2,
    Category: '全部',
    Category2: '恒大',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/dcd89ef6368444df87a31b8be0df2233恒大（全开式烟魁）.png',
    productMoney: '540.0',
    oldName: '恒大（全开式烟魁）',
    guidePrice: 720.8
  },
  {
    productName: '中南海（特高）',
    isCheck: 2,
    Category: '全部',
    Category2: '中南海',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/8bc8c24bdfa64a0d8fc4c451c450f6c4中南海（特高）.png',
    productMoney: '153.0',
    oldName: '中南海（特高）',
    guidePrice: 155
  },
  {
    productName: '红双喜（硬晶派）',
    isCheck: 2,
    Category: '全部',
    Category2: '双喜',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/3c07384fd2ac4c3185923a2d95386631红双喜（硬晶派）.png',
    productMoney: '190.0',
    oldName: '红双喜（硬晶派）',
    guidePrice: null
  },
  {
    productName: '红双喜（硬百顺）',
    isCheck: 2,
    Category: '全部',
    Category2: '双喜',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/d836bd68935a49f4b62e30c38acdaef0红双喜（硬百顺）.png',
    productMoney: '116.0',
    oldName: '红双喜（硬百顺）',
    guidePrice: null
  },
  {
    productName: '中华（硬蛇年）',
    isCheck: 2,
    Category: '全部',
    Category2: '中华',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/298a4448e9bc4c33a4d523015f8ed5c7.png',
    productMoney: '395.0',
    oldName: '中华（硬蛇年）',
    guidePrice: 381.6
  },
  {
    productName: '恒大小记忆',
    isCheck: 2,
    Category: '全部',
    Category2: '恒大小记忆',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/248a0ee31675475d8e8e3946d8fd7a1a恒大（记忆1949）.png',
    productMoney: '265.0',
    oldName: '恒大小记忆',
    guidePrice: null
  },
  {
    productName: '中南海（酷爽风尚）',
    isCheck: 2,
    Category: '全部',
    Category2: '中南海',
    Factory: '上海',
    productImg: 'https://yanjiuchacha.com/api/7ef02ddd06f04dd39f2ed1e4b96fcd73中南海（酷爽风尚）.png',
    productMoney: '176.0',
    oldName: '中南海（酷爽风尚）',
    guidePrice: 175
  },
  {
    productName: '中南海（双中支1mg）',
    isCheck: 2,
    Category: '全部',
    Category2: '中南海',
    Factory: '北京',
    productImg: 'https://yanjiuchacha.com/api/86d4278f58fc411784d66ed10eee0ee5.png',
    productMoney: '320.0',
    oldName: '中南海（双中支1mg）',
    guidePrice: 339.2
  },
  {
    productName: '中南海（双中支北京）',
    isCheck: 2,
    Category: '全部',
    Category2: '中南海',
    Factory: '北京',
    productImg: 'https://yanjiuchacha.com/api/8080eb81686b469fb4898b1ab5ce7784.png',
    productMoney: '330.0',
    oldName: '中南海（双中支北京）',
    guidePrice: 339.2
  },
  {
    productName: '恒大蓝金中支',
    isCheck: 1,
    Category: '全部',
    Category2: '恒大',
    Factory: '天津',
    productImg: 'https://yanjiuchacha.com/api/cb1d8119966e4dd88edbbf6678206851.png',
    productMoney: '213.0',
    oldName: '恒大（蓝金中支）',
    guidePrice: 225
  },
  {
    productName: '天子金',
    isCheck: 1,
    Category: '全部',
    Category2: '天子',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/c9711203562c45b6a13eeca35dd17435.png',
    productMoney: '162.0',
    oldName: '天子（金）',
    guidePrice: 167.48
  },
  {
    productName: '中支天子',
    isCheck: 1,
    Category: '全部',
    Category2: '天子',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/c775310547864bb98b5683bf9343d5ca.png',
    productMoney: '199.0',
    oldName: '天子（中支）',
    guidePrice: 201.4
  },
  {
    productName: '细千里江山',
    isCheck: 1,
    Category: '全部',
    Category2: '天子',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/277b93ea34924158b6b2430f9ca8a67a.png',
    productMoney: '250.0',
    oldName: '天子（千里江山细支）',
    guidePrice: 263
  },
  {
    productName: '天子珍龙凤',
    isCheck: 2,
    Category: '全部',
    Category2: '天子',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/e92f0c4fe8bd462ab2de597d447e0d55.png',
    productMoney: '180.0',
    oldName: '天子（硬珍品龙凤呈祥）',
    guidePrice: 180.2
  },
  {
    productName: '朝天门',
    isCheck: 1,
    Category: '全部',
    Category2: '龙凤呈祥',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/f853b055646a45e9bdbefd544724fba3.png',
    productMoney: '61.0',
    oldName: '龙凤呈祥（鸿运朝天门）',
    guidePrice: 36
  },
  {
    productName: '天子（千里江山）',
    isCheck: 2,
    Category: '全部',
    Category2: '天子',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/b298c6d89d4145f7b493268023b1675c.png',
    productMoney: '435.0',
    oldName: '天子（千里江山）',
    guidePrice: 300
  },
  {
    productName: '天子黄中支',
    isCheck: 1,
    Category: '全部',
    Category2: '天子',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/157027ba021841969f3a4de5d851fbdc.png',
    productMoney: '315.0',
    oldName: '天子（黄中支）',
    guidePrice: 339.2
  },
  {
    productName: '珍龙凤',
    isCheck: 1,
    Category: '全部',
    Category2: '龙凤呈祥',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/e20c3e0dc02e47748f7f64766dfa5336.png',
    productMoney: '179.0',
    oldName: '龙凤呈祥（硬珍品）',
    guidePrice: 180.2
  },
  {
    productName: '畅行天下',
    isCheck: 1,
    Category: '全部',
    Category2: '龙凤呈祥',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/bb769054a7764e0a8d9f0bbd04582b7f.png',
    productMoney: '75.0',
    oldName: '龙凤呈祥（畅行天下）',
    guidePrice: 44.5
  },
  {
    productName: '魅力朝天门',
    isCheck: 1,
    Category: '全部',
    Category2: '龙凤呈祥',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/21cd1fc87702470c9005a90bebeb5242.png',
    productMoney: '113.0',
    oldName: '龙凤呈祥（软魅力朝天门）',
    guidePrice: 88
  },
  {
    productName: '龙凤呈祥（遇见）',
    isCheck: 2,
    Category: '全部',
    Category2: '龙凤呈祥',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/4a39c636605d4f01ad5092a49a95bcfd.png',
    productMoney: '142.0',
    oldName: '龙凤呈祥（遇见）',
    guidePrice: 129.3
  },
  {
    productName: '天子（中国心中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '天子',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/6a04a089dae14cdd9702f887f635c255.png',
    productMoney: '360.0',
    oldName: '天子（中国心中支）',
    guidePrice: 381.6
  },
  {
    productName: '天子（中支重庆印象）',
    isCheck: 2,
    Category: '全部',
    Category2: '天子',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/adfba8dfa38c418c8191d99d768725b4.png',
    productMoney: '280.0',
    oldName: '天子（中支重庆印象）',
    guidePrice: 300
  },
  {
    productName: '龙凤呈祥（硬世纪朝天门）',
    isCheck: 2,
    Category: '全部',
    Category2: '龙凤呈祥',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/0e7ccbfd07a04d56b3815b84f70f827b.png',
    productMoney: '82.0',
    oldName: '龙凤呈祥（硬世纪朝天门）',
    guidePrice: null
  },
  {
    productName: '天子（C位）',
    isCheck: 2,
    Category: '全部',
    Category2: '天子',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/a60674fe1cd241fcb0a81aace540b82c.png',
    productMoney: '160.0',
    oldName: '天子（C位）',
    guidePrice: 175
  },
  {
    productName: '佳品龙凤呈祥',
    isCheck: 1,
    Category: '全部',
    Category2: '龙凤佳品',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/c6d08a5a573e4d6380cfb6292144ecab.png',
    productMoney: '76.0',
    oldName: '龙凤佳品',
    guidePrice: null
  },
  {
    productName: '龙凤呈祥',
    isCheck: 1,
    Category: '全部',
    Category2: '龙凤呈祥',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/ed9df56e0de44c2181833fcd7aeba3b9.png',
    productMoney: '139.0',
    oldName: '龙凤呈祥（硬）',
    guidePrice: null
  },
  {
    productName: '天子（重庆20年）',
    isCheck: 2,
    Category: '全部',
    Category2: '天子',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/9358c9183bf74dcb88ad250c52ecd0d5.png',
    productMoney: '825.0',
    oldName: '天子（重庆20年）',
    guidePrice: 848
  },
  {
    productName: '龙凤呈祥（金·遇见）',
    isCheck: 2,
    Category: '全部',
    Category2: '龙凤呈祥',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/345675808dd24b6d9b1e0aaf2c69390f.png',
    productMoney: '130.0',
    oldName: '龙凤呈祥（金·遇见）',
    guidePrice: null
  },
  {
    productName: '天子（软黄）',
    isCheck: 2,
    Category: '全部',
    Category2: '天子',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/7d32ef229607449187868a8a36a9a92d.png',
    productMoney: '345.0',
    oldName: '天子（软黄）',
    guidePrice: 392.2
  },
  {
    productName: '天子（传奇细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '天子',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/ad95ba31ddb54f31935945acb476db78.png',
    productMoney: '405.0',
    oldName: '天子（传奇细支）',
    guidePrice: 424
  },
  {
    productName: '天子（小天子）',
    isCheck: 2,
    Category: '全部',
    Category2: '天子',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/6f955664d74d4d56b63d41039b8ce634.png',
    productMoney: '225.0',
    oldName: '天子（小天子）',
    guidePrice: 275.6
  },
  {
    productName: '天子（五粮香30年）',
    isCheck: 2,
    Category: '全部',
    Category2: '天子',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/65234117512443cfa068311ff475ddeb.png',
    productMoney: '400.0',
    oldName: '天子（五粮香30年）',
    guidePrice: null
  },
  {
    productName: '观天下',
    isCheck: 1,
    Category: '全部',
    Category2: '天子',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/d3dd8620e5204e72b5e0a58817345ce8.png',
    productMoney: '655.0',
    oldName: '天子（观天下）',
    guidePrice: null
  },
  {
    productName: '天子(白桃C位MINI)',
    isCheck: 2,
    Category: '全部',
    Category2: '天子(白桃C位MINI)',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/2fc5269d50834f3badc355397187689c.jpg',
    productMoney: '200.0',
    oldName: '天子(白桃C位MINI)',
    guidePrice: 201.4
  },
  {
    productName: '花开富贵',
    isCheck: 1,
    Category: '全部',
    Category2: '龙凤呈祥',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/5e92907ca5e14ab18252f8ebc96e5c9e.png',
    productMoney: '118.0',
    oldName: '龙凤呈祥（花开富贵）',
    guidePrice: 88
  },
  {
    productName: '天子（壹号）',
    isCheck: 2,
    Category: '全部',
    Category2: '天子',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/12c66a32d43b49b5ab26bddf8455a470天子（壹号）.png',
    productMoney: '890.0',
    oldName: '天子（壹号）',
    guidePrice: 678.4
  },
  {
    productName: '天子（重庆红）',
    isCheck: 2,
    Category: '全部',
    Category2: '天子',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/8e69a9bb5bcd42bc815374961c0d1c58.png',
    productMoney: '186.0',
    oldName: '天子（重庆红）',
    guidePrice: null
  },
  {
    productName: '天子（金如意细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '天子',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/d45a8f73b038442fbbd2fb99f2046da9.png',
    productMoney: '265.0',
    oldName: '天子（金如意细支）',
    guidePrice: null
  },
  {
    productName: '龙凤呈祥（硬遇见）',
    isCheck: 2,
    Category: '全部',
    Category2: '龙凤呈祥',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/e13a93b4a30e4de3b61d4e257ef56edf.png',
    productMoney: '89.0',
    oldName: '龙凤呈祥（硬遇见）',
    guidePrice: null
  },
  {
    productName: '龙凤呈祥（百年好合）',
    isCheck: 2,
    Category: '全部',
    Category2: '龙凤呈祥',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/458a4ac046634db387f92befe73fc89e.png',
    productMoney: '110.0',
    oldName: '龙凤呈祥（百年好合）',
    guidePrice: 129.3
  },
  {
    productName: '龙凤呈祥（好运来）',
    isCheck: 2,
    Category: '全部',
    Category2: '龙凤呈祥',
    Factory: '重庆',
    productImg: 'https://yanjiuchacha.com/api/f0e18dded77c401d940b942edc546aec.png',
    productMoney: '135.0',
    oldName: '龙凤呈祥（好运来）',
    guidePrice: 143.1
  },
  {
    productName: '合1906喜',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/b89149ab745347d8a9d8c54385fb34c9双喜（硬经典1906）.png',
    productMoney: '153.0',
    oldName: '双喜（硬经典1906）',
    guidePrice: 150.52
  },
  {
    productName: '软经典',
    isCheck: 0,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/36d61c134ed14b9dae2a0faecf2b299c双喜（软经典）.png',
    productMoney: '119.0',
    oldName: '双喜（软经典）',
    guidePrice: 97
  },
  {
    productName: '金花悦喜',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/9036901bc08048f983af40ff15a699c3.png',
    productMoney: '139.0',
    oldName: '双喜（花悦）',
    guidePrice: 139.92
  },
  {
    productName: '双喜莲香',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/b2a87d5abd994189a0615fc8452a5568双喜（莲香）.png',
    productMoney: '141.0',
    oldName: '双喜（莲香）',
    guidePrice: 129.3
  },
  {
    productName: '合经典',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/f44171874f1b4798b47628206ddef968双喜（硬经典）.png',
    productMoney: '140.0',
    oldName: '双喜（硬经典）',
    guidePrice: 129.3
  },
  {
    productName: '双喜（硬金五叶神）',
    isCheck: 2,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/e12c37dd31474224951bd677af5aae7f双喜（硬金五叶神）.png',
    productMoney: '126.0',
    oldName: '双喜（硬金五叶神）',
    guidePrice: 121.9
  },
  {
    productName: '世纪经典',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/1e40c0a3173f40eca3b9d03615ffb861双喜（硬世纪经典）.png',
    productMoney: '171.0',
    oldName: '双喜（硬世纪经典）',
    guidePrice: 201.4
  },
  {
    productName: '红五叶',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/cd308f4223144c0d9d4c93df4972a06c双喜（硬红五叶神）.png',
    productMoney: '165.0',
    oldName: '双喜（硬红五叶神）',
    guidePrice: 184.44
  },
  {
    productName: '软1906喜',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/e2e04587b39645958f2b8438960feb65双喜（软经典1906）.png',
    productMoney: '154.0',
    oldName: '双喜（软经典1906）',
    guidePrice: 143.1
  },
  {
    productName: '新盒双喜',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/5225013ef70c437ba4256ebae82bcca7双喜（硬）.png',
    productMoney: '110.0',
    oldName: '双喜（硬）',
    guidePrice: null
  },
  {
    productName: '勿忘我',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜勿忘我',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/cd5a971423fc444db9b5f9e31eaf39f4.png',
    productMoney: '166.0',
    oldName: '双喜勿忘我',
    guidePrice: 175
  },
  {
    productName: '双喜（红玫王软蓝）',
    isCheck: 2,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/e4200e4e70374cc78891c85f54977724双喜（红玫王软蓝）.png',
    productMoney: '104.0',
    oldName: '双喜（红玫王软蓝）',
    guidePrice: 114.5
  },
  {
    productName: '软五叶',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/713b96f48b5f4c5fac1e77ef7fbdef1f双喜（软红五叶神）.png',
    productMoney: '253.0',
    oldName: '双喜（软红五叶神）',
    guidePrice: 286.2
  },
  {
    productName: '双喜（软）',
    isCheck: 2,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/bf57a1ef9c4947d2aab1317d27532282双喜（软）.png',
    productMoney: '120.0',
    oldName: '双喜（软）',
    guidePrice: 75
  },
  {
    productName: '金01喜',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/d62542235190404c9d69bbeef42d1287双喜（金01）.png',
    productMoney: '140.0',
    oldName: '双喜（金01）',
    guidePrice: 129.3
  },
  {
    productName: '盒南洋',
    isCheck: 1,
    Category: '全部',
    Category2: '红双喜南洋经典',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/2d3d0ceb98cb4a0fa175a9508e6f8374.png',
    productMoney: '92.0',
    oldName: '红双喜南洋经典',
    guidePrice: null
  },
  {
    productName: '双喜（红玫王硬蓝）',
    isCheck: 2,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/94954f8c79344c99b4172bdf8116d0a0双喜（红玫王硬蓝）.png',
    productMoney: '127.0',
    oldName: '双喜（红玫王硬蓝）',
    guidePrice: 143.1
  },
  {
    productName: '01盒双喜',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/b14cdb52a5ad4dd18c42ffc68448e615双喜（硬01）.png',
    productMoney: '134.0',
    oldName: '双喜（硬01）',
    guidePrice: 121.9
  },
  {
    productName: '百年经典双',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/516c8bbce6d541dd8e3ded333278d4e7双喜（百年经典）.png',
    productMoney: '140.0',
    oldName: '双喜（百年经典）',
    guidePrice: 175
  },
  {
    productName: '红双喜（龙凤）',
    isCheck: 2,
    Category: '全部',
    Category2: '红双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/8632793cb1964240ab3fc3b40902cf5e.png',
    productMoney: '103.0',
    oldName: '红双喜（龙凤）',
    guidePrice: 93.28
  },
  {
    productName: '中支春天',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/2506528bed684102a56a6e419dae9a4a双喜（春天中支）.png',
    productMoney: '240.0',
    oldName: '双喜（春天中支）',
    guidePrice: 263
  },
  {
    productName: '金樽五叶神',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/b07451d978414b6a91e352e29f311b84双喜（五叶神金尊）.png',
    productMoney: '186.0',
    oldName: '双喜（五叶神金尊）',
    guidePrice: null
  },
  {
    productName: '椰树（硬）',
    isCheck: 2,
    Category: '全部',
    Category2: '椰树',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/7a44c2496e1d4b70b542abd54a5da167椰树（硬）.png',
    productMoney: '82.0',
    oldName: '椰树（硬）',
    guidePrice: null
  },
  {
    productName: '双喜（红邮喜）',
    isCheck: 2,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/a7b63258c75d4a50a5589f81550682c1双喜（红邮喜）.png',
    productMoney: '228.0',
    oldName: '双喜（红邮喜）',
    guidePrice: 263
  },
  {
    productName: '红双喜百年龙凤',
    isCheck: 2,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/463e0ecb54174327b4c795a0d39b19eb.png',
    productMoney: '185.0',
    oldName: '红双喜百年龙凤',
    guidePrice: 218.81
  },
  {
    productName: '经典工坊',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/9fc055b052a644568975f5ff1b7483b3双喜（经典工坊）.png',
    productMoney: '158.0',
    oldName: '双喜（经典工坊）',
    guidePrice: 185.5
  },
  {
    productName: '小国喜',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/0cf2081038404fe8bfb24c3c4de39114双喜（国喜细支）.png',
    productMoney: '260.0',
    oldName: '双喜（国喜细支）',
    guidePrice: 300
  },
  {
    productName: '01软双喜',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/eb2b77ad7cea43d09a6f4a2dab47e2da双喜（软01）.png',
    productMoney: '119.0',
    oldName: '双喜（软01）',
    guidePrice: null
  },
  {
    productName: '春天咖路里',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/ba372389142d476a99c36c65063795eb.jpg',
    productMoney: '232.0',
    oldName: '双喜（春天 咖路里）',
    guidePrice: 225
  },
  {
    productName: '小支春天',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/1b3dfb38ae544841ade73994ea6d1afd双喜（春天细支）.png',
    productMoney: '200.0',
    oldName: '双喜（春天细支）',
    guidePrice: 225
  },
  {
    productName: '盛世双喜',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/a4a4977fb28a4177a8d9a5ec6f2c78bd双喜（盛世）.png',
    productMoney: '138.0',
    oldName: '双喜（盛世）',
    guidePrice: 180.2
  },
  {
    productName: '公司6克南',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/d060ae3c2d354ae68f801c9b703068b1.png',
    productMoney: '112.0',
    oldName: '红双喜6mg',
    guidePrice: 93.28
  },
  {
    productName: '红玫王',
    isCheck: 1,
    Category: '全部',
    Category2: '红玫王',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/ccc94dfcd13147848775f36694fb4dbf.png',
    productMoney: '127.0',
    oldName: '红玫王（硬）',
    guidePrice: null
  },
  {
    productName: '红玫（硬金）',
    isCheck: 2,
    Category: '全部',
    Category2: '红玫',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/bef42550ee4a493cb02add097cc86eb8红玫（硬金）.png',
    productMoney: '95.0',
    oldName: '红玫（硬金）',
    guidePrice: null
  },
  {
    productName: '魅影春天',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/08198ff8bc9f4674a048fb0091e7dd7e.png',
    productMoney: '430.0',
    oldName: '双喜（春天魅影）',
    guidePrice: 424
  },
  {
    productName: '新邮喜',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/7ddceee7fbc04b08afe3ac51d089f119双喜（邮喜）.png',
    productMoney: '227.0',
    oldName: '双喜（邮喜）',
    guidePrice: null
  },
  {
    productName: '公司大喜',
    isCheck: 1,
    Category: '全部',
    Category2: '南洋大喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/3b4c01940ac6443b8e5d61dd4cf710a3.png',
    productMoney: '130.0',
    oldName: '南洋大喜',
    guidePrice: null
  },
  {
    productName: '紫红玫王',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/5516880924e94d3c8b682970df59ae53双喜（硬紫红玫王）.png',
    productMoney: '204.0',
    oldName: '双喜（硬紫红玫王）',
    guidePrice: 201.4
  },
  {
    productName: '双喜（金国喜细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/ae8164dc193b4336aac160d12a1fc63a双喜（金国喜细支）.png',
    productMoney: '500.0',
    oldName: '双喜（金国喜细支）',
    guidePrice: 678.4
  },
  {
    productName: '黑逸品喜',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/b1c400da330b4f5ab6bdef02a33efd72双喜（硬逸品）.png',
    productMoney: '300.0',
    oldName: '双喜（硬逸品）',
    guidePrice: 455.8
  },
  {
    productName: '传奇双喜',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/8b256d4858d3467fa12d550b7f695ad0双喜（传奇）.png',
    productMoney: '158.0',
    oldName: '双喜（传奇）',
    guidePrice: null
  },
  {
    productName: '大国喜',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/e09c899034d2469a8153c4a5e480736b双喜（大国喜）.png',
    productMoney: '775.0',
    oldName: '双喜（大国喜）',
    guidePrice: null
  },
  {
    productName: '软国际双喜',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/fc22b55ac0a644a5af594450c1f6c645双喜（软国际）.png',
    productMoney: '72.0',
    oldName: '双喜（软国际）',
    guidePrice: 57.24
  },
  {
    productName: '和喜',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/b9336102ea814eaeabfa8b3052d40746双喜（和喜）.png',
    productMoney: '152.0',
    oldName: '双喜（和喜）',
    guidePrice: 129.3
  },
  {
    productName: '双喜（硬国际）',
    isCheck: 2,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/51b1cfd20a3947ad9609ca7b8dbfeab2双喜（硬国际）.png',
    productMoney: '131.0',
    oldName: '双喜（硬国际）',
    guidePrice: null
  },
  {
    productName: '双喜（百年经典）新版',
    isCheck: 2,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/2129266d8e874e95bbf42c20427b2bd0.png',
    productMoney: '141.0',
    oldName: '双喜（百年经典）新版',
    guidePrice: null
  },
  {
    productName: '春天自在',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '广东',
    productImg: 'https://yanjiuchacha.com/api/d68b6339c33b40c3869c520afe94f61c.jpg',
    productMoney: '240.0',
    oldName: '双喜（春天·自在）',
    guidePrice: 263
  },
  {
    productName: '细真龙凌云',
    isCheck: 1,
    Category: '全部',
    Category2: '真龙',
    Factory: '广西',
    productImg: 'https://yanjiuchacha.com/api/1443b72e92d24921a67c47ae08fc67c0真龙（凌云）.png',
    productMoney: '155.0',
    oldName: '真龙（凌云）',
    guidePrice: 121.9
  },
  {
    productName: '真龙（美人香草·刘三姐）',
    isCheck: 2,
    Category: '全部',
    Category2: '真龙',
    Factory: '广西',
    productImg: 'https://yanjiuchacha.com/api/ccbca4d333df406fb3b365b70c4fcdb8真龙（美人香草·刘三姐）.png',
    productMoney: '175.0',
    oldName: '真龙（美人香草·刘三姐）',
    guidePrice: 167.48
  },
  {
    productName: '真龙起源',
    isCheck: 1,
    Category: '全部',
    Category2: '真龙',
    Factory: '广西',
    productImg: 'https://yanjiuchacha.com/api/d6d76a3396574b299754ff547af1bbd2真龙（起源）.png',
    productMoney: '214.0',
    oldName: '真龙（起源）',
    guidePrice: 212
  },
  {
    productName: '真龙海韵',
    isCheck: 1,
    Category: '全部',
    Category2: '真龙',
    Factory: '广西',
    productImg: 'https://yanjiuchacha.com/api/a46a389f9c1e448abbe799a0b67ba511真龙（海韵）.png',
    productMoney: '430.0',
    oldName: '真龙（海韵）',
    guidePrice: 445.2
  },
  {
    productName: '真龙娇子',
    isCheck: 1,
    Category: '全部',
    Category2: '真龙',
    Factory: '广西',
    productImg: 'https://yanjiuchacha.com/api/4cd2e11b31d7421cb3ac8d0cd68112dd真龙（娇子）.png',
    productMoney: '87.0',
    oldName: '真龙（娇子）',
    guidePrice: 47.7
  },
  {
    productName: '真龙（刘三姐）',
    isCheck: 2,
    Category: '全部',
    Category2: '真龙',
    Factory: '广西',
    productImg: 'https://yanjiuchacha.com/api/31bb681043604e6d884168ef87872903真龙（刘三姐）.png',
    productMoney: '215.0',
    oldName: '真龙（刘三姐）',
    guidePrice: 225
  },
  {
    productName: '中支真龙海韵',
    isCheck: 1,
    Category: '全部',
    Category2: '真龙',
    Factory: '广西',
    productImg: 'https://yanjiuchacha.com/api/1612b66e1b5b4279a64e01d31979b2f8真龙（海韵中支）.png',
    productMoney: '315.0',
    oldName: '真龙（海韵中支）',
    guidePrice: 339.2
  },
  {
    productName: '轩云真龙',
    isCheck: 1,
    Category: '全部',
    Category2: '真龙',
    Factory: '广西',
    productImg: 'https://yanjiuchacha.com/api/f4f1c93a30654eaca173488d05cf8af7真龙（轩云）.png',
    productMoney: '158.0',
    oldName: '真龙（轩云）',
    guidePrice: 143.1
  },
  {
    productName: '中支真龙凌云',
    isCheck: 1,
    Category: '全部',
    Category2: '真龙',
    Factory: '广西',
    productImg: 'https://yanjiuchacha.com/api/7e62ecedb14a4a55be33d3225f3e9bcf真龙（中支凌云）.png',
    productMoney: '208.0',
    oldName: '真龙（中支凌云）',
    guidePrice: 225
  },
  {
    productName: '祥云真龙',
    isCheck: 1,
    Category: '全部',
    Category2: '真龙',
    Factory: '广西',
    productImg: 'https://yanjiuchacha.com/api/7cfc393e17b147cbafe39171187a3c02真龙（软祥云）.png',
    productMoney: '125.0',
    oldName: '真龙（软祥云）',
    guidePrice: 114.48
  },
  {
    productName: '真龙（软娇子）',
    isCheck: 2,
    Category: '全部',
    Category2: '真龙',
    Factory: '广西',
    productImg: 'https://yanjiuchacha.com/api/b6733dd38f5e4d8a8087429b41eff7da真龙（软娇子）.png',
    productMoney: '77.0',
    oldName: '真龙（软娇子）',
    guidePrice: 57.24
  },
  {
    productName: '合珍品真龙',
    isCheck: 1,
    Category: '全部',
    Category2: '真龙',
    Factory: '广西',
    productImg: 'https://yanjiuchacha.com/api/837d9e4dbdb948778cb23ccbc0ffb958真龙（珍品）.png',
    productMoney: '116.0',
    oldName: '真龙（珍品）',
    guidePrice: null
  },
  {
    productName: '真龙（致青春）',
    isCheck: 2,
    Category: '全部',
    Category2: '真龙',
    Factory: '广西',
    productImg: 'https://yanjiuchacha.com/api/920946b41e474c77b2897d3771d39696真龙（致青春）.png',
    productMoney: '124.0',
    oldName: '真龙（致青春）',
    guidePrice: 114.48
  },
  {
    productName: '真龙（软海韵）',
    isCheck: 2,
    Category: '全部',
    Category2: '真龙',
    Factory: '广西',
    productImg: 'https://yanjiuchacha.com/api/876c7d96e9d6453dbf8d7a2272c8a3c9真龙（软海韵）.png',
    productMoney: '540.0',
    oldName: '真龙（软海韵）',
    guidePrice: 583
  },
  {
    productName: '细支真龙海韵',
    isCheck: 1,
    Category: '全部',
    Category2: '真龙',
    Factory: '广西',
    productImg: 'https://yanjiuchacha.com/api/13b3732822b043da8015b489656c32ec真龙（海韵细支）.png',
    productMoney: '480.0',
    oldName: '真龙（海韵细支）',
    guidePrice: 510
  },
  {
    productName: '真龙（甲天下中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '真龙',
    Factory: '广西',
    productImg: 'https://yanjiuchacha.com/api/decee786e1b54201b634c32316042a09真龙（甲天下中支）.png',
    productMoney: '780.0',
    oldName: '真龙（甲天下中支）',
    guidePrice: null
  },
  {
    productName: '真龙（硬凌云）',
    isCheck: 2,
    Category: '全部',
    Category2: '真龙',
    Factory: '广西',
    productImg: 'https://yanjiuchacha.com/api/255d9f4ca0f84421a58b581880128357真龙（硬凌云）.png',
    productMoney: '145.0',
    oldName: '真龙（硬凌云）',
    guidePrice: 155
  },
  {
    productName: '真龙（巴马天成）',
    isCheck: 2,
    Category: '全部',
    Category2: '真龙',
    Factory: '广西',
    productImg: 'https://yanjiuchacha.com/api/cc4ff5fc33b34bc585132d6afc22d38c真龙（巴马天成）.png',
    productMoney: '680.0',
    oldName: '真龙（巴马天成）',
    guidePrice: 720.8
  },
  {
    productName: '真龙（甲天下）新版',
    isCheck: 2,
    Category: '全部',
    Category2: '真龙',
    Factory: '广西',
    productImg: 'https://yanjiuchacha.com/api/85a150b745804e0eb00438928e6a0b71.png',
    productMoney: '655.0',
    oldName: '真龙（甲天下）新版',
    guidePrice: null
  },
  {
    productName: '锦绣真龙',
    isCheck: 1,
    Category: '全部',
    Category2: '真龙',
    Factory: '广西',
    productImg: 'https://yanjiuchacha.com/api/9c12fefccc7940d7915a2099625bd7de真龙（锦绣）.png',
    productMoney: '105.0',
    oldName: '真龙（锦绣）',
    guidePrice: 88
  },
  {
    productName: '真龙（神韵）',
    isCheck: 2,
    Category: '全部',
    Category2: '真龙',
    Factory: '广西',
    productImg: 'https://yanjiuchacha.com/api/59432fb77c524a4e99f15cb84a731eee真龙（神韵）.png',
    productMoney: '395.0',
    oldName: '真龙（神韵）',
    guidePrice: null
  },
  {
    productName: '真龙（经典红）',
    isCheck: 2,
    Category: '全部',
    Category2: '真龙',
    Factory: '广西',
    productImg: 'https://yanjiuchacha.com/api/4337997708df497a9e2c68686d2ce510真龙（经典红）.png',
    productMoney: '107.0',
    oldName: '真龙（经典红）',
    guidePrice: 97
  },
  {
    productName: '甲天下（山水）',
    isCheck: 2,
    Category: '全部',
    Category2: '甲天下',
    Factory: '广西',
    productImg: 'https://yanjiuchacha.com/api/780d24ecffc645f0a8b25eca804cf2d9甲天下（山水）.png',
    productMoney: '40.0',
    oldName: '甲天下（山水）',
    guidePrice: null
  },
  {
    productName: '跨越贵烟',
    isCheck: 1,
    Category: '全部',
    Category2: '贵烟',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/18da0755bbb64ef09cdfc00d0d692493贵烟（跨越）.png',
    productMoney: '214.0',
    oldName: '贵烟（跨越）',
    guidePrice: 201.4
  },
  {
    productName: '长征果',
    isCheck: 1,
    Category: '全部',
    Category2: '黄果树',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/0a057dfcbfbb424c93d0e89c0606cf12黄果树（长征）.png',
    productMoney: '80.0',
    oldName: '黄果树（长征）',
    guidePrice: 61.5
  },
  {
    productName: '30年国酒',
    isCheck: 1,
    Category: '全部',
    Category2: '贵烟',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/23d5cf6c7c46476fab3e49ee2d3253a1贵烟（国酒香30）.png',
    productMoney: '635.0',
    oldName: '贵烟（国酒香30）',
    guidePrice: 720.8
  },
  {
    productName: '贵烟萃',
    isCheck: 1,
    Category: '全部',
    Category2: '贵烟',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/4acb52c7c48147efbff88c643f7eaa0c贵烟（萃）.png',
    productMoney: '172.0',
    oldName: '贵烟（萃）',
    guidePrice: 158
  },
  {
    productName: '贵烟红中支',
    isCheck: 1,
    Category: '全部',
    Category2: '贵烟',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/ac39bfe735aa449da2dd77847e931427贵烟（红中支）.png',
    productMoney: '259.0',
    oldName: '贵烟（红中支）',
    guidePrice: 263
  },
  {
    productName: '贵烟金百合',
    isCheck: 1,
    Category: '全部',
    Category2: '贵烟',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/8ba6c6e431f34c518707c117c4ba4bbc贵烟（金百合）.png',
    productMoney: '95.0',
    oldName: '贵烟（金百合）',
    guidePrice: 88
  },
  {
    productName: '细30年国酒',
    isCheck: 1,
    Category: '全部',
    Category2: '贵烟',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/fb19c7d5f33540718b8cf5d14712f68c贵烟（细支国酒香30）.png',
    productMoney: '670.0',
    oldName: '贵烟（细支国酒香30）',
    guidePrice: 678.4
  },
  {
    productName: '贵烟福中支',
    isCheck: 1,
    Category: '全部',
    Category2: '贵烟',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/d76a08f1af8e4592a1b9f6edf948786f贵烟（福中支）.png',
    productMoney: '355.0',
    oldName: '贵烟（福中支）',
    guidePrice: 424
  },
  {
    productName: '贵烟（硬黄精品）',
    isCheck: 2,
    Category: '全部',
    Category2: '贵烟',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/fac7900ceb774fd4bf97bb93ffb56b92贵烟（硬黄精品）.png',
    productMoney: '138.0',
    oldName: '贵烟（硬黄精品）',
    guidePrice: 114.5
  },
  {
    productName: '新贵烟',
    isCheck: 1,
    Category: '全部',
    Category2: '贵烟',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/21b8efaa453641cea3a7432e1c970ec2贵烟（新贵）.png',
    productMoney: '109.0',
    oldName: '贵烟（新贵）',
    guidePrice: 93.28
  },
  {
    productName: '佳品黄果树',
    isCheck: 1,
    Category: '全部',
    Category2: '黄果树',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/ab1d035616454d0c8319c6678078ddef黄果树（佳品）.png',
    productMoney: '78.0',
    oldName: '黄果树（佳品）',
    guidePrice: null
  },
  {
    productName: '贵烟喜贵',
    isCheck: 1,
    Category: '全部',
    Category2: '贵烟',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/7a3f373982da421bad214bea0c236414.png',
    productMoney: '118.0',
    oldName: '贵烟（新喜贵）',
    guidePrice: 129.3
  },
  {
    productName: '贵烟行者',
    isCheck: 1,
    Category: '全部',
    Category2: '贵烟',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/065c9e3a884b44319b6162f160d040971_自定义px_2021-12-14+15_35_13 (2).png',
    productMoney: '230.0',
    oldName: '贵烟（行者）',
    guidePrice: 225
  },
  {
    productName: '贵烟老喜贵',
    isCheck: 1,
    Category: '全部',
    Category2: '贵烟',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/23b4db2e5e6d4563bc972326da50691f.png',
    productMoney: '152.0',
    oldName: '贵烟（老喜贵）',
    guidePrice: 143.1
  },
  {
    productName: '贵烟山水',
    isCheck: 1,
    Category: '全部',
    Category2: '贵烟山水',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/1b57bd85bbbb463592229258ae564d6d.png',
    productMoney: '230.0',
    oldName: '贵烟山水',
    guidePrice: 225
  },
  {
    productName: '黄果树（精品）',
    isCheck: 2,
    Category: '全部',
    Category2: '黄果树',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/67d4221d7f934633be506a836d6e254d黄果树（精品）.png',
    productMoney: '139.0',
    oldName: '黄果树（精品）',
    guidePrice: null
  },
  {
    productName: '贵烟（贵中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '贵烟',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/3593e779aafc40929af16b26b4f74379贵烟（贵中支）.png',
    productMoney: '305.0',
    oldName: '贵烟（贵中支）',
    guidePrice: null
  },
  {
    productName: '喜贵福贵',
    isCheck: 1,
    Category: '全部',
    Category2: '贵烟',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/81d8447b1b1d4cdebacf7d73590a1677贵烟（福）.png',
    productMoney: '403.0',
    oldName: '贵烟（福）',
    guidePrice: 424
  },
  {
    productName: '贵烟生肖',
    isCheck: 2,
    Category: '全部',
    Category2: '贵烟',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/02e4f4f4b1e74bfeb1881cba99b96a56.png',
    productMoney: '150.0',
    oldName: '贵烟生肖',
    guidePrice: 175
  },
  {
    productName: '贵烟（硬高遵）',
    isCheck: 2,
    Category: '全部',
    Category2: '贵烟',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/5569184d0b504e72b20c92917274ec9a贵烟（硬高遵）.png',
    productMoney: '238.0',
    oldName: '贵烟（硬高遵）',
    guidePrice: 233.2
  },
  {
    productName: '贵烟（100）',
    isCheck: 2,
    Category: '全部',
    Category2: '贵烟',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/9c7d632997204cc1b7365d87329ca592.png',
    productMoney: '820.0',
    oldName: '贵烟（100）',
    guidePrice: 848
  },
  {
    productName: '贵烟魔力',
    isCheck: 1,
    Category: '全部',
    Category2: '贵烟',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/f83528ccc6d24f9c98f8f0f9ab1b99d4贵烟（魔力）.png',
    productMoney: '430.0',
    oldName: '贵烟（魔力）',
    guidePrice: null
  },
  {
    productName: '贵烟（软高遵）',
    isCheck: 2,
    Category: '全部',
    Category2: '贵烟',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/45ab9343d27149dca0ba25e9b70cfe8c贵烟（软高遵）.png',
    productMoney: '298.0',
    oldName: '贵烟（软高遵）',
    guidePrice: 318
  },
  {
    productName: '软果树',
    isCheck: 1,
    Category: '全部',
    Category2: '黄果树',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/3077d4be49294d6381b1ddc4dc8390c3黄果树（软）.png',
    productMoney: '73.0',
    oldName: '黄果树（软）',
    guidePrice: null
  },
  {
    productName: '15年国酒',
    isCheck: 1,
    Category: '全部',
    Category2: '贵烟',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/b9f661f906b242b09c3da3433fa42cde贵烟（国酒香15）.png',
    productMoney: '555.0',
    oldName: '贵烟（国酒香15）',
    guidePrice: null
  },
  {
    productName: '佳品黄果树',
    isCheck: 1,
    Category: '全部',
    Category2: '黄果树',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/1c191bb0f04c4a3f9b36f8cbaeef443e黄果树（蓝佳品）.png',
    productMoney: '92.0',
    oldName: '黄果树（蓝佳品）',
    guidePrice: 61.5
  },
  {
    productName: '贵烟（硬小国酒香）',
    isCheck: 2,
    Category: '全部',
    Category2: '贵烟',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/fac629ae295d47bdb7f02d7f8b23f319贵烟（硬小国酒香）.png',
    productMoney: '340.0',
    oldName: '贵烟（硬小国酒香）',
    guidePrice: 360.4
  },
  {
    productName: '细贵烟行者',
    isCheck: 1,
    Category: '全部',
    Category2: '贵烟',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/5c2aea631129481d9afaabe33f3f91f4贵烟（行者）.png',
    productMoney: '255.0',
    oldName: '贵烟（细支行者）',
    guidePrice: null
  },
  {
    productName: '贵烟（盛世）',
    isCheck: 2,
    Category: '全部',
    Category2: '贵烟',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/6c7790362d984821a201866cedf3cf5c贵烟（盛世）.png',
    productMoney: '650.0',
    oldName: '贵烟（盛世）',
    guidePrice: null
  },
  {
    productName: '软遵义',
    isCheck: 1,
    Category: '全部',
    Category2: '遵义',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/c30df1969ff74fcc959e79cb9a9e6bb2遵义（软）.png',
    productMoney: '68.0',
    oldName: '遵义（软）',
    guidePrice: 44.5
  },
  {
    productName: '贵烟（行者梦）',
    isCheck: 2,
    Category: '全部',
    Category2: '贵烟',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/dfd8a7bdc0474763b1d0876be1ee7992贵烟（行者梦）.png',
    productMoney: '320.0',
    oldName: '贵烟（行者梦）',
    guidePrice: null
  },
  {
    productName: '贵烟（蓝色的爱）',
    isCheck: 2,
    Category: '全部',
    Category2: '贵烟',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/1d2cb3c519de412d9e9d076a64acc0e2贵烟（蓝色的爱）.png',
    productMoney: '192.0',
    oldName: '贵烟（蓝色的爱）',
    guidePrice: 188.68
  },
  {
    productName: '贵烟（印第安火种）',
    isCheck: 2,
    Category: '全部',
    Category2: '贵烟',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/224ee4d3b3384ec3ac53f8aca06f8ec7贵烟（印第安火种）.png',
    productMoney: '720.0',
    oldName: '贵烟（印第安火种）',
    guidePrice: null
  },
  {
    productName: '贵烟玉液1号/小玉液',
    isCheck: 2,
    Category: '全部',
    Category2: '贵烟玉液1号/小玉液',
    Factory: '贵州',
    productImg: 'https://yanjiuchacha.com/api/aee7c6ea51c6446081affe68b515d039贵烟（玉液1号）.png',
    productMoney: '200.0',
    oldName: '贵烟玉液1号/小玉液',
    guidePrice: null
  },
  {
    productName: '云烟（紫）',
    isCheck: 2,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/271240b1817a4eb8b6969c435ad6cc3f.png',
    productMoney: '118.0',
    oldName: '云烟（紫）',
    guidePrice: 114.5
  },
  {
    productName: '软玉溪',
    isCheck: 1,
    Category: '全部',
    Category2: '玉溪',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/74e7ae33df3043b5a71161ec3e100e08.png',
    productMoney: '196.0',
    oldName: '玉溪（软）',
    guidePrice: 201.4
  },
  {
    productName: '小云龙',
    isCheck: 1,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/f8c5fb058fb34d77965665690762d3c9.png',
    productMoney: '133.0',
    oldName: '云烟（细支云龙）',
    guidePrice: 129.3
  },
  {
    productName: '珍软云',
    isCheck: 1,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/a2694935c5ee40b680549956055cbba2.png',
    productMoney: '201.0',
    oldName: '云烟（软珍品）',
    guidePrice: 206.7
  },
  {
    productName: '软大重九',
    isCheck: 1,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/1a2df1f7cd28457a80144dfc1f0311c5.png',
    productMoney: '690.0',
    oldName: '云烟（软大重九）',
    guidePrice: 720.8
  },
  {
    productName: '百年塔山',
    isCheck: 1,
    Category: '全部',
    Category2: '红塔山',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/6d4dfb11084d44419083bf8036cfcec8.png',
    productMoney: '110.0',
    oldName: '红塔山（硬经典100）',
    guidePrice: 97
  },
  {
    productName: '家园猫',
    isCheck: 1,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/d674c23e3f9949fcad0ad440389db012.png',
    productMoney: '158.0',
    oldName: '云烟（小熊猫家园）',
    guidePrice: 158
  },
  {
    productName: '乌金刚',
    isCheck: 1,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/4ecb9ae6015d46ae96e64aa2b48bd872.png',
    productMoney: '440.0',
    oldName: '云烟（黑金刚印象）',
    guidePrice: 466.4
  },
  {
    productName: '小重九',
    isCheck: 1,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/b7ca86ae547442f8ae05b4ff7c9b87b9.png',
    productMoney: '838.0',
    oldName: '云烟（细支大重九）',
    guidePrice: 848
  },
  {
    productName: '云烟（云龙）',
    isCheck: 2,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/1517fd546f9040cf895f99fa9a7883a3.png',
    productMoney: '135.0',
    oldName: '云烟（云龙）',
    guidePrice: null
  },
  {
    productName: '软塔山',
    isCheck: 1,
    Category: '全部',
    Category2: '红塔山',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/dd8979eb01914b8c95515778cce3a066.png',
    productMoney: '96.0',
    oldName: '红塔山（软经典1956）',
    guidePrice: 80
  },
  {
    productName: '合塔山',
    isCheck: 1,
    Category: '全部',
    Category2: '红塔山',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/19977dab0b744c10aad04d88a8043013.png',
    productMoney: '95.0',
    oldName: '红塔山（硬经典）',
    guidePrice: 75
  },
  {
    productName: '玉溪（银鑫中支）',
    isCheck: 1,
    Category: '全部',
    Category2: '玉溪',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/7cead6fcf14f4a3e8391f8f7df97f238.png',
    productMoney: '226.0',
    oldName: '玉溪（鑫中支）',
    guidePrice: 225
  },
  {
    productName: '合云龙',
    isCheck: 2,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/7b797acdbc0c4eec8cda97fe0e1af19e.png',
    productMoney: '138.0',
    oldName: '云烟（硬云龙）',
    guidePrice: 129.3
  },
  {
    productName: '中支青小重九',
    isCheck: 1,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/7a03c9991b55446d800746c49d330c4f.png',
    productMoney: '435.0',
    oldName: '云烟（中支小重九）',
    guidePrice: 424
  },
  {
    productName: '钓鱼台（细蓝钓）',
    isCheck: 0,
    Category: '全部',
    Category2: '钓鱼台',
    Factory: '云南',
    productImg:
      'https://yanjiuchacha.com/api/cfd3e9cd0cad4964b3a48d9dd0b02428钓鱼台（84mm细支）_自定义px_2021-11-25+15_10_27.png',
    productMoney: '406.0',
    oldName: '钓鱼台（细蓝钓）',
    guidePrice: 424
  },
  {
    productName: '中支大重九',
    isCheck: 1,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/a1d630cd931c403f8429d5f46a3c479d.png',
    productMoney: '825.0',
    oldName: '云烟（中支大重九）',
    guidePrice: 848
  },
  {
    productName: '和谐玉溪',
    isCheck: 1,
    Category: '全部',
    Category2: '玉溪',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/e46ae43980764e03bf77a5a3e7d6b8f6.png',
    productMoney: '325.0',
    oldName: '玉溪（硬和谐）',
    guidePrice: 339.2
  },
  {
    productName: '中支境界玉溪',
    isCheck: 1,
    Category: '全部',
    Category2: '玉溪中支境界',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/04e3a1c9d004499cbc630df4d980c40f.png',
    productMoney: '460.0',
    oldName: '玉溪中支境界',
    guidePrice: 510
  },
  {
    productName: '软红河',
    isCheck: 1,
    Category: '全部',
    Category2: '红河',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/cee627da1a1c49d6b5284b230103cf79.png',
    productMoney: '70.0',
    oldName: '红河（软甲）',
    guidePrice: 57.2
  },
  {
    productName: '玉溪（硬）',
    isCheck: 2,
    Category: '全部',
    Category2: '玉溪',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/978aeba5b621483eaeea9bcbb589d8ee.png',
    productMoney: '190.0',
    oldName: '玉溪（硬）',
    guidePrice: 199.28
  },
  {
    productName: '玉溪创客',
    isCheck: 1,
    Category: '全部',
    Category2: '玉溪',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/f96a068f37da45edbd8c51f66df92d71.png',
    productMoney: '179.0',
    oldName: '玉溪（创客）',
    guidePrice: 175
  },
  {
    productName: '中支和谐玉溪',
    isCheck: 1,
    Category: '全部',
    Category2: '玉溪',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/2387f9c0ee184c3ab10d5ea38d0f7d2c.png',
    productMoney: '315.0',
    oldName: '玉溪（中支和谐）',
    guidePrice: 339.2
  },
  {
    productName: '9+1重九',
    isCheck: 1,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/4e43c95dfa9340c38f0e9157638bd325.png',
    productMoney: '670.0',
    oldName: '云烟（9+1大重九）',
    guidePrice: 720.8
  },
  {
    productName: '云烟（福）',
    isCheck: 2,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/2e63fb03092a4cf8ba3f97e261974fca.png',
    productMoney: '124.0',
    oldName: '云烟（福）',
    guidePrice: 107.06
  },
  {
    productName: '尚善玉溪',
    isCheck: 1,
    Category: '全部',
    Category2: '玉溪',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/e6c389e22dd149bbb389c7228de27974.png',
    productMoney: '240.0',
    oldName: '玉溪（软尚善）',
    guidePrice: 243.8
  },
  {
    productName: '云烟云端',
    isCheck: 1,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/c2d2c26f951645cba36f98929d9bf087.png',
    productMoney: '900.0',
    oldName: '云烟（云端）',
    guidePrice: 848
  },
  {
    productName: '红梅（软黄）',
    isCheck: 0,
    Category: '全部',
    Category2: '红梅',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/6c1a5b92d1b44acea349ae770b574887.png',
    productMoney: '74.0',
    oldName: '红梅（软黄）',
    guidePrice: null
  },
  {
    productName: '云烟（流金印象细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/2fe37b0192c04d8d85510d0a97848a42.png',
    productMoney: '395.0',
    oldName: '云烟（流金印象细支）',
    guidePrice: 381.6
  },
  {
    productName: '云烟（小云端）',
    isCheck: 2,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/4bf7b3a73bc840b08d3bdfafeca4b2ad.png',
    productMoney: '560.0',
    oldName: '云烟（小云端）',
    guidePrice: 678.4
  },
  {
    productName: '中支金腰带',
    isCheck: 1,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/280750f3470240d5b97069738a381dde.png',
    productMoney: '260.0',
    oldName: '云烟（中支金腰带）',
    guidePrice: 263
  },
  {
    productName: '新时代塔',
    isCheck: 1,
    Category: '全部',
    Category2: '红塔山',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/ee197c3423104355a5845703613ab743.png',
    productMoney: '102.0',
    oldName: '红塔山（新时代）',
    guidePrice: 88
  },
  {
    productName: '初心玉溪',
    isCheck: 1,
    Category: '全部',
    Category2: '玉溪',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/16a4bf938d234da1b7aeae5b99e327f2.png',
    productMoney: '180.0',
    oldName: '玉溪（初心）',
    guidePrice: 175
  },
  {
    productName: '玉溪（细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '玉溪',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/28cff602620449a682546fb2a4ee9600.png',
    productMoney: '255.0',
    oldName: '玉溪（细支）',
    guidePrice: 263
  },
  {
    productName: '玉溪（双中支翡翠）',
    isCheck: 2,
    Category: '全部',
    Category2: '玉溪',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/f013113e84bc45c7addb7420adcdd347.png',
    productMoney: '845.0',
    oldName: '玉溪（双中支翡翠）',
    guidePrice: 848
  },
  {
    productName: '粗苁蓉',
    isCheck: 1,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/a86831ab88ac492fab4fdb37e11f863e.png',
    productMoney: '115.0',
    oldName: '云烟（硬苁蓉）',
    guidePrice: 114.5
  },
  {
    productName: '中支钓鱼台',
    isCheck: 1,
    Category: '全部',
    Category2: '钓鱼台',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/a01eaae05e134bc5ad7845aa89e6c41f.png',
    productMoney: '405.0',
    oldName: '钓鱼台（中支）',
    guidePrice: 424
  },
  {
    productName: '软世纪风熊猫',
    isCheck: 1,
    Category: '全部',
    Category2: '红河',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/7316e5eceb2548fa921f3ee39aad0f3e.png',
    productMoney: '105.0',
    oldName: '红河（小熊猫世纪风）',
    guidePrice: 84.8
  },
  {
    productName: '红塔山（大经典1956）',
    isCheck: 2,
    Category: '全部',
    Category2: '红塔山',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/35832bd1aafe4a7db5f91a3fa94e89f1.png',
    productMoney: '95.0',
    oldName: '红塔山（大经典1956）',
    guidePrice: 88
  },
  {
    productName: '中支云烟云端',
    isCheck: 1,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/7a89c01929784d4cb993532a37426332.png',
    productMoney: '730.0',
    oldName: '云烟（中支云端）',
    guidePrice: 848
  },
  {
    productName: '云烟（盛世小熊猫）',
    isCheck: 2,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/433a4254d82440838642039ef6cec68a.png',
    productMoney: '210.0',
    oldName: '云烟（盛世小熊猫）',
    guidePrice: 240
  },
  {
    productName: '云烟（软紫）',
    isCheck: 2,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/62ac8bbc5b9a4e40ba883808182d43da.png',
    productMoney: '103.0',
    oldName: '云烟（软紫）',
    guidePrice: 90.1
  },
  {
    productName: '软如意云烟',
    isCheck: 1,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/e6cc66c9cbb840918b0a3c69aab4cf42.png',
    productMoney: '103.0',
    oldName: '云烟（软如意）',
    guidePrice: 93.28
  },
  {
    productName: '细支初心玉溪',
    isCheck: 1,
    Category: '全部',
    Category2: '玉溪',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/239d34858fbf41edbae0214b1e394cb0.png',
    productMoney: '225.0',
    oldName: '玉溪（细支初心）',
    guidePrice: 225
  },
  {
    productName: '玉溪（细支清香世家）',
    isCheck: 2,
    Category: '全部',
    Category2: '玉溪',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/b09ce9cf32044e85a90174df02715a93.png',
    productMoney: '223.0',
    oldName: '玉溪（细支清香世家）',
    guidePrice: 225
  },
  {
    productName: '细支珍云烟',
    isCheck: 1,
    Category: '全部',
    Category2: '云烟细支金腰带/细支珍品',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/b431144794de42a596994b932c4027e4.png',
    productMoney: '218.0',
    oldName: '云烟细支金腰带/细支珍品',
    guidePrice: 225
  },
  {
    productName: '细支翡翠',
    isCheck: 1,
    Category: '全部',
    Category2: '玉溪',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/8b1363c356dc473c9f448f9529961f1d.png',
    productMoney: '690.0',
    oldName: '玉溪（细支翡翠）',
    guidePrice: 678.4
  },
  {
    productName: '云烟（小重九）细支',
    isCheck: 2,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/f719d7a200c44ef895c9dc81d6fec766.png',
    productMoney: '450.0',
    oldName: '云烟（小重九）细支',
    guidePrice: 424
  },
  {
    productName: '红河（硬甲）',
    isCheck: 2,
    Category: '全部',
    Category2: '红河',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/3b7fb9da891c4caa9be468326ed85032.png',
    productMoney: '81.0',
    oldName: '红河（硬甲）',
    guidePrice: null
  },
  {
    productName: '中支阿诗玛',
    isCheck: 1,
    Category: '全部',
    Category2: '玉溪',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/267cd951f5c34047b087753e2cb5e7ed.png',
    productMoney: '275.0',
    oldName: '玉溪（中支阿诗玛）',
    guidePrice: 263
  },
  {
    productName: '细支传奇塔山',
    isCheck: 1,
    Category: '全部',
    Category2: '红塔山',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/f1c925e183d24390ab39a34dd6ddc505.png',
    productMoney: '155.0',
    oldName: '红塔山（传奇细支）',
    guidePrice: 129.3
  },
  {
    productName: '云烟（印象）',
    isCheck: 2,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/e42ac8109af5445582d172f5a4b9f27e.png',
    productMoney: '500.0',
    oldName: '云烟（印象）',
    guidePrice: 561.8
  },
  {
    productName: '云烟（印象烟庄）',
    isCheck: 2,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/0345f00f210045c6b18cc7e852e8108a.png',
    productMoney: '350.0',
    oldName: '云烟（印象烟庄）',
    guidePrice: 360.4
  },
  {
    productName: '云烟（苁蓉和悦）',
    isCheck: 2,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/977b105bc9d44d6c8a8d5181d9d7e528.png',
    productMoney: '220.0',
    oldName: '云烟（苁蓉和悦）',
    guidePrice: 201.4
  },
  {
    productName: '高配玉溪',
    isCheck: 1,
    Category: '全部',
    Category2: '玉溪',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/0bbc81267e54438ea4cbc72e6fbb8656.png',
    productMoney: '204.0',
    oldName: '玉溪（高配版）',
    guidePrice: 201.4
  },
  {
    productName: '玉溪阿诗玛',
    isCheck: 1,
    Category: '全部',
    Category2: '玉溪',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/0fb716ab85d74cfeab4aca5e6d242d79.png',
    productMoney: '181.0',
    oldName: '玉溪（阿诗玛）',
    guidePrice: 175
  },
  {
    productName: '云烟（大云端中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/8bd196a90d384d4f9b265b48a26e9300.png',
    productMoney: '730.0',
    oldName: '云烟（大云端中支）',
    guidePrice: null
  },
  {
    productName: '红河（硬）',
    isCheck: 2,
    Category: '全部',
    Category2: '红河',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/e1fd0690b29542359f17a4b92d635a75.png',
    productMoney: '79.0',
    oldName: '红河（硬）',
    guidePrice: 61.5
  },
  {
    productName: '云烟（软礼印象）',
    isCheck: 2,
    Category: '全部',
    Category2: '云烟',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/9c63165a5ecb4bc8960441fac596cb13.png',
    productMoney: '635.0',
    oldName: '云烟（软礼印象）',
    guidePrice: 720.8
  },
  {
    productName: '红河（软88）',
    isCheck: 2,
    Category: '全部',
    Category2: '红河',
    Factory: '云南',
    productImg: 'https://yanjiuchacha.com/api/617fad3318eb4b3b8a19d0c345863f98.png',
    productMoney: '120.0',
    oldName: '红河（软88）',
    guidePrice: 84.8
  },
  {
    productName: '娇子',
    isCheck: 1,
    Category: '全部',
    Category2: '娇子',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/029be28c89164412a31fb42d83e1f1a5娇子（宽窄好运）.png',
    productMoney: '228.0',
    oldName: '娇子（宽窄好运）',
    guidePrice: 263
  },
  {
    productName: '细宽窄鸿运',
    isCheck: 1,
    Category: '全部',
    Category2: '娇子',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/54ec8dfb16524b98a681bfcbb4084c70娇子（宽窄好运细支）.png',
    productMoney: '202.0',
    oldName: '娇子（宽窄好运细支）',
    guidePrice: 225
  },
  {
    productName: 'X娇子',
    isCheck: 1,
    Category: '全部',
    Category2: '娇子',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/bdc0239273e34816a902a65a2623004d娇子（X）.png',
    productMoney: '101.0',
    oldName: '娇子（X）',
    guidePrice: 97
  },
  {
    productName: '细格调娇子',
    isCheck: 1,
    Category: '全部',
    Category2: '娇子',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/d46a0f5723e940bc8c930f17660ffc2c娇子（格调细支）.png',
    productMoney: '144.0',
    oldName: '娇子（格调细支）',
    guidePrice: 129.3
  },
  {
    productName: '娇子（宽窄五粮浓香中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '娇子',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/39d40b1cb538481189fc2f8afd353ab7娇子（宽窄五粮浓香中支）.png',
    productMoney: '410.0',
    oldName: '娇子（宽窄五粮浓香中支）',
    guidePrice: 424
  },
  {
    productName: '长城（醇雅薄荷）',
    isCheck: 2,
    Category: '全部',
    Category2: '长城',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/e119527dfa4544319fd225d6ae4a8252长城（醇雅薄荷）.png',
    productMoney: '163.0',
    oldName: '长城（醇雅薄荷）',
    guidePrice: 158.4
  },
  {
    productName: '中支宽窄平安',
    isCheck: 1,
    Category: '全部',
    Category2: '娇子',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/e395304571d64d078cadd37a37c846f4娇子（宽窄平安中支）.png',
    productMoney: '314.0',
    oldName: '娇子（宽窄平安中支）',
    guidePrice: 339.2
  },
  {
    productName: '蓝时代娇子',
    isCheck: 1,
    Category: '全部',
    Category2: '娇子',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/fd8cfcfc56c9432a883affeb02cc6073娇子（蓝时代）.png',
    productMoney: '61.0',
    oldName: '娇子（蓝时代）',
    guidePrice: 53
  },
  {
    productName: '娇子（宽窄·五粮醇香）',
    isCheck: 2,
    Category: '全部',
    Category2: '娇子',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/0063fb950b58452da850336b8ba656c6娇子（宽窄·五粮醇香）.png',
    productMoney: '170.0',
    oldName: '娇子（宽窄·五粮醇香）',
    guidePrice: 201.4
  },
  {
    productName: '中支醇香宽窄',
    isCheck: 1,
    Category: '全部',
    Category2: '娇子',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/bf0a134ee59247a1867b72640116ac5a娇子（宽窄醇香中支）.png',
    productMoney: '186.0',
    oldName: '娇子（宽窄醇香中支）',
    guidePrice: 202.5
  },
  {
    productName: '娇子（时代阳光）',
    isCheck: 2,
    Category: '全部',
    Category2: '娇子',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/a1df70e5918946cbb9009e9cbd190647娇子（时代阳光）.png',
    productMoney: '87.0',
    oldName: '娇子（时代阳光）',
    guidePrice: 66.78
  },
  {
    productName: '细逍遥宽窄',
    isCheck: 1,
    Category: '全部',
    Category2: '娇子',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/f7e2b031042d489991f014f8dc744cfc娇子（宽窄逍遥细支）.png',
    productMoney: '670.0',
    oldName: '娇子（宽窄逍遥细支）',
    guidePrice: 763.2
  },
  {
    productName: '娇子（软阳光）',
    isCheck: 2,
    Category: '全部',
    Category2: '娇子',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/7ec5ea59c21a49939b279560bbd14969娇子（软阳光）.png',
    productMoney: '100.0',
    oldName: '娇子（软阳光）',
    guidePrice: 88
  },
  {
    productName: '娇子（五粮浓香细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '娇子',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/27166ff267b34203b3be6b5bb3dced45娇子（五粮浓香细支）.png',
    productMoney: '270.0',
    oldName: '娇子（五粮浓香细支）',
    guidePrice: 300
  },
  {
    productName: '中支宽窄逍遥',
    isCheck: 1,
    Category: '全部',
    Category2: '娇子宽窄逍遥中支',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/8b2a9cab0b3e481c8d56899688157f07.png',
    productMoney: '610.0',
    oldName: '娇子宽窄逍遥中支',
    guidePrice: 763.2
  },
  {
    productName: '宽窄逍遥',
    isCheck: 1,
    Category: '全部',
    Category2: '娇子',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/373c04e09a6d424088dca82f803ec039娇子（宽窄逍遥）.png',
    productMoney: '570.0',
    oldName: '娇子（宽窄逍遥）',
    guidePrice: 678.4
  },
  {
    productName: '红格调娇子',
    isCheck: 1,
    Category: '全部',
    Category2: '娇子',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/532016ebd1af4819b1f281b93e3bdeea娇子（格调红）.png',
    productMoney: '95.0',
    oldName: '娇子（格调红）',
    guidePrice: 88
  },
  {
    productName: '软宽窄平安',
    isCheck: 1,
    Category: '全部',
    Category2: '娇子',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/51521eed4a92470db235fb5cde9dd471娇子（软宽窄平安）.png',
    productMoney: '246.0',
    oldName: '娇子（软宽窄平安）',
    guidePrice: 300
  },
  {
    productName: '金天下秀',
    isCheck: 1,
    Category: '全部',
    Category2: '天下秀',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/5a2b2185a206444d8d3e329177e43a20天下秀（金）.png',
    productMoney: '73.0',
    oldName: '天下秀（金）',
    guidePrice: null
  },
  {
    productName: '长城（醇雅奶香）',
    isCheck: 2,
    Category: '全部',
    Category2: '长城',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/d4129d260983480bb728bb1be00f8bae长城（醇雅奶香）.png',
    productMoney: '113.0',
    oldName: '长城（醇雅奶香）',
    guidePrice: null
  },
  {
    productName: '宽窄如意',
    isCheck: 1,
    Category: '全部',
    Category2: '娇子',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/e856af58c49c4c5a98998eae591bb960娇子（宽窄如意）.png',
    productMoney: '350.0',
    oldName: '娇子（宽窄如意）',
    guidePrice: 381.6
  },
  {
    productName: '娇子（宽窄国宝）',
    isCheck: 2,
    Category: '全部',
    Category2: '娇子',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/5d3cb158768142cdbfbcbfafaa2a995e娇子（宽窄熊猫之恋）.png',
    productMoney: '990.0',
    oldName: '娇子（宽窄国宝）',
    guidePrice: 678.4
  },
  {
    productName: '娇子（宽窄平安）',
    isCheck: 2,
    Category: '全部',
    Category2: '娇子',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/9e3ec76c42c5438589aa2726f55c1e22娇子（宽窄平安）.png',
    productMoney: '295.0',
    oldName: '娇子（宽窄平安）',
    guidePrice: null
  },
  {
    productName: '揽胜3号',
    isCheck: 1,
    Category: '全部',
    Category2: '长城',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/29db1caf175341f1a0373a6ac1b849bd长城（揽胜3号经典）.png',
    productMoney: '570.0',
    oldName: '长城（揽胜3号经典）',
    guidePrice: 600
  },
  {
    productName: '盛世五号',
    isCheck: 1,
    Category: '全部',
    Category2: '长城',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/b82980c029004622bc2c5d4f837d960a长城（盛世5号）.png',
    productMoney: '155.0',
    oldName: '长城（盛世5号）',
    guidePrice: 230
  },
  {
    productName: '奇迹132',
    isCheck: 1,
    Category: '全部',
    Category2: '长城',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/207413264e5543c4bff006979098b4d7长城（132奇迹）.png',
    productMoney: '560.0',
    oldName: '长城（132奇迹）',
    guidePrice: 640
  },
  {
    productName: '红色132',
    isCheck: 1,
    Category: '全部',
    Category2: '长城',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/b0f53aecd72a43a2985aa18fdb1a99a9长城（红色132）.png',
    productMoney: '100.0',
    oldName: '长城（红色132）',
    guidePrice: 150
  },
  {
    productName: '娇子（硬宽窄逍遥）',
    isCheck: 2,
    Category: '全部',
    Category2: '娇子',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/f714e51f7436466c903013488919be40娇子（硬宽窄逍遥）.png',
    productMoney: '565.0',
    oldName: '娇子（硬宽窄逍遥）',
    guidePrice: 678.4
  },
  {
    productName: '兰娇子',
    isCheck: 1,
    Category: '全部',
    Category2: '娇子',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/300afb3150874e4daf0251d9247d11d9娇子（蓝）.png',
    productMoney: '120.0',
    oldName: '娇子（蓝）',
    guidePrice: 114.48
  },
  {
    productName: '毛氏2号',
    isCheck: 1,
    Category: '全部',
    Category2: '长城',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/6007800f87de4bf3b1ef2f09d00f946a长城（毛氏雪茄2号）.png',
    productMoney: '1200.0',
    oldName: '长城（毛氏雪茄2号）',
    guidePrice: null
  },
  {
    productName: '娇子(宽窄如意细支)',
    isCheck: 2,
    Category: '全部',
    Category2: '娇子(宽窄如意细支)',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/71011b3a84764dd1b8e03acff98fdbe8.png',
    productMoney: '332.0',
    oldName: '娇子(宽窄如意细支)',
    guidePrice: 381.6
  },
  {
    productName: '娇子（宽窄1024）',
    isCheck: 2,
    Category: '全部',
    Category2: '娇子',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/ec8c37ef17c24457a8b1c7d6a58c0182.png',
    productMoney: '520.0',
    oldName: '娇子（宽窄1024）',
    guidePrice: 678.4
  },
  {
    productName: '娇子清甜香',
    isCheck: 1,
    Category: '全部',
    Category2: '娇子',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/87779f7150084fbc88e4427094d64284娇子（宽窄清甜香）.png',
    productMoney: '500.0',
    oldName: '娇子（宽窄清甜香）',
    guidePrice: 678.4
  },
  {
    productName: '秘制132',
    isCheck: 1,
    Category: '全部',
    Category2: '长城',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/c9a30ebddf4c435f9f39b9e59878fb7e长城（10支132秘制）.png',
    productMoney: '630.0',
    oldName: '长城（10支132秘制）',
    guidePrice: 900
  },
  {
    productName: '迷你咖啡',
    isCheck: 1,
    Category: '全部',
    Category2: '长城',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/872c8e0263de45af9c3dd7e943b44774长城（迷你咖啡）.png',
    productMoney: '65.0',
    oldName: '长城（迷你咖啡）',
    guidePrice: null
  },
  {
    productName: '揽胜1号',
    isCheck: 1,
    Category: '全部',
    Category2: '长城',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/58e3b3ae6f4c47b3adb28010bac1a640长城（揽胜1号）.png',
    productMoney: '1040.0',
    oldName: '长城（揽胜1号）',
    guidePrice: null
  },
  {
    productName: '伟大的胜利',
    isCheck: 1,
    Category: '全部',
    Category2: '长城',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/20163f1c96ce44e6832e21626df7b3f4长城（胜利）.png',
    productMoney: '1525.0',
    oldName: '长城（胜利）',
    guidePrice: null
  },
  {
    productName: '盛世六号',
    isCheck: 1,
    Category: '全部',
    Category2: '长城',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/d732a572813d49d9ac7cc17c89d430dc长城（盛世6号）.png',
    productMoney: '570.0',
    oldName: '长城（盛世6号）',
    guidePrice: null
  },
  {
    productName: '迷你原味',
    isCheck: 1,
    Category: '全部',
    Category2: '长城',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/3ca606563850425ab27a8c64d6085999长城（迷你原味）.png',
    productMoney: '65.0',
    oldName: '长城（迷你原味）',
    guidePrice: null
  },
  {
    productName: '长城GJ6',
    isCheck: 1,
    Category: '全部',
    Category2: '长城(GJ6号)',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/3aededd1e77f48308ea10f72e92d4496.png',
    productMoney: '948.0',
    oldName: '长城(GJ6号)（雪茄）',
    guidePrice: 1200
  },
  {
    productName: '记忆132',
    isCheck: 1,
    Category: '全部',
    Category2: '长城',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/72562b5085b743aab72946a60e103c19长城（132记忆）.png',
    productMoney: '895.0',
    oldName: '长城（132记忆）',
    guidePrice: null
  },
  {
    productName: '长城（迷你香草）新版',
    isCheck: 2,
    Category: '全部',
    Category2: '长城雪茄',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/d116276a89bf4a4cbf6eac3bd45ed8fb长城（迷你香草）新版.png',
    productMoney: '50.0',
    oldName: '长城（迷你香草）新版',
    guidePrice: null
  },
  {
    productName: '长城（生肖版）',
    isCheck: 2,
    Category: '全部',
    Category2: '长城雪茄',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/662aa63cb2694c25a249bd2d456086b8长城（生肖版）.png',
    productMoney: '970.0',
    oldName: '长城（生肖版）',
    guidePrice: 1120
  },
  {
    productName: '长城（132原味）',
    isCheck: 2,
    Category: '全部',
    Category2: '长城雪茄',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/fb567bf15f4d42dfb04bf1e5de01e0ad长城（132原味）.png',
    productMoney: '200.0',
    oldName: '长城（132原味）',
    guidePrice: null
  },
  {
    productName: '娇子（宽窄自在）',
    isCheck: 2,
    Category: '全部',
    Category2: '娇子',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/f0bd1259c8b44657b9805fc2546a54ea娇子（宽窄自在）.png',
    productMoney: '770.0',
    oldName: '娇子（宽窄自在）',
    guidePrice: 848
  },
  {
    productName: '娇子（宽窄如意保湿版）',
    isCheck: 2,
    Category: '全部',
    Category2: '娇子',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/f2057cfce0764dacb902449ccec89b88娇子（宽窄如意保湿版）.png',
    productMoney: '360.0',
    oldName: '娇子（宽窄如意保湿版）',
    guidePrice: null
  },
  {
    productName: '迷你香草',
    isCheck: 1,
    Category: '全部',
    Category2: '长城',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/1522e591b5874336b084a22513d4b7da长城（迷你香草）.png',
    productMoney: '70.0',
    oldName: '长城（迷你香草）',
    guidePrice: null
  },
  {
    productName: '长城（国之韵1918）（雪茄）',
    isCheck: 2,
    Category: '全部',
    Category2: '长城雪茄',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/727e6d7697054dd2af1a940ae91bafdd.png',
    productMoney: '260.0',
    oldName: '长城（国之韵1918）（雪茄）',
    guidePrice: null
  },
  {
    productName: '长城（大号铝管5支）',
    isCheck: 2,
    Category: '全部',
    Category2: '长城雪茄',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/304d863c591046499c89cbbfdda7dc6d长城（大号铝管5支）.png',
    productMoney: '160.0',
    oldName: '长城（大号铝管5支）',
    guidePrice: null
  },
  {
    productName: '天下秀（红）',
    isCheck: 2,
    Category: '全部',
    Category2: '天子',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/7b5c15da5d3b4d9ca12c19bb2f4f4da5天下秀（红）.png',
    productMoney: '64.0',
    oldName: '天下秀（红）',
    guidePrice: null
  },
  {
    productName: '长城（国礼1号）',
    isCheck: 2,
    Category: '全部',
    Category2: '长城雪茄',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/38d38923c72c464c9c64a8568f224cb4长城（国礼1号）.png',
    productMoney: '3100.0',
    oldName: '长城（国礼1号）',
    guidePrice: null
  },
  {
    productName: '长城（GL·1978）',
    isCheck: 2,
    Category: '全部',
    Category2: '长城雪茄',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/d2a381cc94a1456e9ca9ee4a020230b1长城（GL·1978）.png',
    productMoney: '1350.0',
    oldName: '长城（GL·1978）',
    guidePrice: null
  },
  {
    productName: '长城（132益川老坊3）',
    isCheck: 2,
    Category: '全部',
    Category2: '长城雪茄',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/254db166a4c1400b91ae36fd3d790d62.png',
    productMoney: '1350.0',
    oldName: '长城（132益川老坊3）',
    guidePrice: null
  },
  {
    productName: '长城（软传奇）',
    isCheck: 2,
    Category: '全部',
    Category2: '长城雪茄',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/a1596ffdb1824b4f820a52b37c1c0b1f长城（软传奇）.png',
    productMoney: '248.0',
    oldName: '长城（软传奇）',
    guidePrice: null
  },
  {
    productName: '娇子（宽窄吉祥）',
    isCheck: 2,
    Category: '全部',
    Category2: '娇子',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/fd8f54287ff84613b6c8119ee07cb084娇子（宽窄吉祥）.png',
    productMoney: '460.0',
    oldName: '娇子（宽窄吉祥）',
    guidePrice: 510
  },
  {
    productName: '长城（金南极）',
    isCheck: 2,
    Category: '全部',
    Category2: '长城雪茄',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/d88ae33ba4dd41d9a4f22d6820e13d7f长城（金南极）.png',
    productMoney: '35.0',
    oldName: '长城（金南极）',
    guidePrice: 175
  },
  {
    productName: '盛世三号',
    isCheck: 1,
    Category: '全部',
    Category2: '长城',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/4543915255c445f885bd3a7ec77e5f1e长城（盛世3号）.png',
    productMoney: '280.0',
    oldName: '长城（盛世3号）',
    guidePrice: null
  },
  {
    productName: '传奇三号',
    isCheck: 1,
    Category: '全部',
    Category2: '长城',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/424dbd4b9b8746069bc55b136711f3e2长城（传奇3号）.png',
    productMoney: '610.0',
    oldName: '长城（传奇3号）',
    guidePrice: null
  },
  {
    productName: '醇味132',
    isCheck: 1,
    Category: '全部',
    Category2: '长城',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/002461f05d7e41c7b34ca62869d43996长城（132醇味）.png',
    productMoney: '380.0',
    oldName: '长城（132醇味）',
    guidePrice: null
  },
  {
    productName: '长城3号',
    isCheck: 1,
    Category: '全部',
    Category2: '长城',
    Factory: '四川',
    productImg: 'https://yanjiuchacha.com/api/06e69da9a70347f3aed3a8e164f5ed03长城（3号）.png',
    productMoney: '92.0',
    oldName: '长城（3号）',
    guidePrice: null
  },
  {
    productName: '中支黑兰州',
    isCheck: 1,
    Category: '全部',
    Category2: '兰州',
    Factory: '甘肃',
    productImg: 'https://yanjiuchacha.com/api/14f6405eb83442e7a1a8ad2a81a5b9fe兰州（黑中支）.png',
    productMoney: '276.0',
    oldName: '兰州（黑中支）',
    guidePrice: 263
  },
  {
    productName: '黑珍品兰州',
    isCheck: 1,
    Category: '全部',
    Category2: '兰州',
    Factory: '甘肃',
    productImg: 'https://yanjiuchacha.com/api/4a20f863f7754134a6f1e5c7d3eca66a兰州（硬珍品）.png',
    productMoney: '178.0',
    oldName: '兰州（硬珍品）',
    guidePrice: 152.64
  },
  {
    productName: '精品兰州',
    isCheck: 1,
    Category: '全部',
    Category2: '兰州',
    Factory: '甘肃',
    productImg: 'https://yanjiuchacha.com/api/59d6688bc8e74cfab7c37d94c8f19ef6兰州（硬精品）.png',
    productMoney: '80.0',
    oldName: '兰州（硬精品）',
    guidePrice: 65.72
  },
  {
    productName: '兰州硬黄',
    isCheck: 1,
    Category: '全部',
    Category2: '兰州',
    Factory: '甘肃',
    productImg: 'https://yanjiuchacha.com/api/f69fa23bc3ad45d18092a99330c10874兰州（硬黄）.png',
    productMoney: '68.0',
    oldName: '兰州（硬黄）',
    guidePrice: 40.5
  },
  {
    productName: '如意兰州',
    isCheck: 1,
    Category: '全部',
    Category2: '兰州',
    Factory: '甘肃',
    productImg: 'https://yanjiuchacha.com/api/30c8ba6dccea4770976d796f28042799兰州（硬如意）.png',
    productMoney: '111.0',
    oldName: '兰州（硬如意）',
    guidePrice: 93.28
  },
  {
    productName: '合蓝兰州',
    isCheck: 1,
    Category: '全部',
    Category2: '兰州',
    Factory: '甘肃',
    productImg: 'https://yanjiuchacha.com/api/031fd6e850dc4e12bc155636b73e091b兰州（硬蓝）.png',
    productMoney: '65.0',
    oldName: '兰州（硬蓝）',
    guidePrice: 53
  },
  {
    productName: '16支兰州',
    isCheck: 1,
    Category: '全部',
    Category2: '兰州',
    Factory: '甘肃',
    productImg: 'https://yanjiuchacha.com/api/535c5bbfb8cd4bc399252e2a57b67f4a兰州（吉祥16支）.png',
    productMoney: '185.0',
    oldName: '兰州（吉祥16支）',
    guidePrice: 169.6
  },
  {
    productName: '兰州（小青支）',
    isCheck: 2,
    Category: '全部',
    Category2: '兰州',
    Factory: '甘肃',
    productImg: 'https://yanjiuchacha.com/api/30bd53e8fbf84f3e8f2348fb20649428兰州（小青支）.png',
    productMoney: '210.0',
    oldName: '兰州（小青支）',
    guidePrice: 201.4
  },
  {
    productName: '兰州（细支珍品）',
    isCheck: 2,
    Category: '全部',
    Category2: '兰州',
    Factory: '甘肃',
    productImg: 'https://yanjiuchacha.com/api/abebcaf3ca844f62b4f385e9ea1a94e2兰州（细支珍品）.png',
    productMoney: '170.0',
    oldName: '兰州（细支珍品）',
    guidePrice: 158
  },
  {
    productName: '兰州（桥）',
    isCheck: 2,
    Category: '全部',
    Category2: '兰州',
    Factory: '甘肃',
    productImg: 'https://yanjiuchacha.com/api/11e1e67dcb1348c0a89085c9e60cfd24兰州（桥）.png',
    productMoney: '202.0',
    oldName: '兰州（桥）',
    guidePrice: 185.5
  },
  {
    productName: '兰州中支飞天花径',
    isCheck: 2,
    Category: '全部',
    Category2: '兰州',
    Factory: '甘肃',
    productImg: 'https://yanjiuchacha.com/api/8468b8a0cca84a0c89635ac80d715fda.png',
    productMoney: '280.0',
    oldName: '兰州中支飞天花径',
    guidePrice: null
  },
  {
    productName: '兰州（硬吉祥）',
    isCheck: 2,
    Category: '全部',
    Category2: '兰州',
    Factory: '甘肃',
    productImg: 'https://yanjiuchacha.com/api/0079db1ee47c48bfa80cdf5eaeaf0f3b兰州（硬吉祥）.png',
    productMoney: '220.0',
    oldName: '兰州（硬吉祥）',
    guidePrice: 219.42
  },
  {
    productName: '兰州（细支飞天梦）',
    isCheck: 2,
    Category: '全部',
    Category2: '兰州',
    Factory: '甘肃',
    productImg: 'https://yanjiuchacha.com/api/1a430ee4db654d19b759a5fccce89cd6兰州（细支飞天梦）.png',
    productMoney: '410.0',
    oldName: '兰州（细支飞天梦）',
    guidePrice: 424
  },
  {
    productName: '兰州（飞天）',
    isCheck: 2,
    Category: '全部',
    Category2: '兰州',
    Factory: '甘肃',
    productImg: 'https://yanjiuchacha.com/api/7083d59b8d3b4c8c80fc6cc54430c116兰州（飞天）.png',
    productMoney: '470.0',
    oldName: '兰州（飞天）',
    guidePrice: 501.38
  },
  {
    productName: '兰州硬红',
    isCheck: 1,
    Category: '全部',
    Category2: '兰州',
    Factory: '甘肃',
    productImg: 'https://yanjiuchacha.com/api/00c65ac424a34a4fa5c9662eb5a82e31兰州（硬红）.png',
    productMoney: '60.0',
    oldName: '兰州（硬红）',
    guidePrice: null
  },
  {
    productName: '兰州（中支飞天梦）',
    isCheck: 2,
    Category: '全部',
    Category2: '兰州',
    Factory: '甘肃',
    productImg: 'https://yanjiuchacha.com/api/d9c0f9df8d584759bb75c60e100e9e12兰州（中支飞天梦）.png',
    productMoney: '500.0',
    oldName: '兰州（中支飞天梦）',
    guidePrice: 510
  },
  {
    productName: '兰州软黄',
    isCheck: 1,
    Category: '全部',
    Category2: '兰州',
    Factory: '甘肃',
    productImg: 'https://yanjiuchacha.com/api/96e855a183df4af6b69d22d12c46a718兰州（软黄）.png',
    productMoney: '60.0',
    oldName: '兰州（软黄）',
    guidePrice: null
  },
  {
    productName: '兰州（青藏1号）',
    isCheck: 2,
    Category: '全部',
    Category2: '兰州',
    Factory: '甘肃',
    productImg: 'https://yanjiuchacha.com/api/291f759b88e6426196ab9413863f3ed6兰州（青藏1号）.png',
    productMoney: '1000.0',
    oldName: '兰州（青藏1号）',
    guidePrice: null
  },
  {
    productName: '兰州（中支飞天明珠）',
    isCheck: 2,
    Category: '全部',
    Category2: '兰州',
    Factory: '甘肃',
    productImg: 'https://yanjiuchacha.com/api/c617a71b365f43c2b7e828a62cb7d623.png',
    productMoney: '780.0',
    oldName: '兰州（中支飞天明珠）',
    guidePrice: null
  },
  {
    productName: '中支纯境狼',
    isCheck: 1,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/d930a615f40c415ba70585053b3e7eba七匹狼（纯境中支）.png',
    productMoney: '190.0',
    oldName: '七匹狼（纯境中支）',
    guidePrice: 143.1
  },
  {
    productName: '中支银狼',
    isCheck: 1,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/a0d0161727e04056bff8a7567f340477七匹狼（银中支）.png',
    productMoney: '214.0',
    oldName: '七匹狼（银中支）',
    guidePrice: 201.4
  },
  {
    productName: '软银狼',
    isCheck: 1,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/70f0c10fced145f0a9495190e0a26a51七匹狼（软灰）.png',
    productMoney: '184.0',
    oldName: '七匹狼（软灰）',
    guidePrice: 185.5
  },
  {
    productName: '锋芒七匹狼',
    isCheck: 1,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/e88b825b149c43e2b8917871f5f1398e七匹狼（锋芒）.png',
    productMoney: '172.0',
    oldName: '七匹狼（锋芒）',
    guidePrice: 158
  },
  {
    productName: '合红狼',
    isCheck: 1,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/a69e1de533ac4c39b1f782993627e106七匹狼（红）.png',
    productMoney: '147.0',
    oldName: '七匹狼（红）',
    guidePrice: 132.5
  },
  {
    productName: '合蓝狼',
    isCheck: 1,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/e283653c875d40d5be8423c8fbf233ed七匹狼（蓝）.png',
    productMoney: '89.0',
    oldName: '七匹狼（蓝）',
    guidePrice: 66.78
  },
  {
    productName: '豪情狼',
    isCheck: 1,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/6ed3abe14076413ba23f231f2a148a63七匹狼（豪情）.png',
    productMoney: '90.0',
    oldName: '七匹狼（豪情）',
    guidePrice: 53
  },
  {
    productName: '合白狼',
    isCheck: 1,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/787c9c6d43d34526b95af97b07064b83七匹狼（白）.png',
    productMoney: '95.0',
    oldName: '七匹狼（白）',
    guidePrice: 80
  },
  {
    productName: '金桥冰爆',
    isCheck: 1,
    Category: '全部',
    Category2: '金桥',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/72a5308bddb447919d89415d403a36ae金桥（冰爆）.png',
    productMoney: '160.0',
    oldName: '金桥（冰爆）',
    guidePrice: 175
  },
  {
    productName: '七匹狼（蓝带中支/英伦奶香）',
    isCheck: 2,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/7fff38d58462486680ad8ef4de90e611七匹狼（蓝带中支）.png',
    productMoney: '151.0',
    oldName: '七匹狼（蓝带中支/英伦奶香）',
    guidePrice: 114.5
  },
  {
    productName: '七匹狼（锋芒）新版',
    isCheck: 2,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/82f0445d745d4a7292dc41b5c3905123.png',
    productMoney: '158.0',
    oldName: '七匹狼（锋芒）新版',
    guidePrice: 158
  },
  {
    productName: '蓝钻狼',
    isCheck: 1,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/5e3f072b14ad48b7a3f177ff4d24117f七匹狼（蓝钻）.png',
    productMoney: '121.0',
    oldName: '七匹狼（蓝钻）',
    guidePrice: 114.5
  },
  {
    productName: '七匹狼（古田金中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/ec5c0dc6843f4e0ea370094e8d808720七匹狼（古田金中支）.png',
    productMoney: '386.0',
    oldName: '七匹狼（古田金中支）',
    guidePrice: 424
  },
  {
    productName: '中支观海狼',
    isCheck: 1,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/daf4b7dfd5a748c398526bcd2a272233七匹狼（观海中支）.png',
    productMoney: '257.0',
    oldName: '七匹狼（观海中支）',
    guidePrice: 263
  },
  {
    productName: '古田金中支',
    isCheck: 1,
    Category: '全部',
    Category2: '古田',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/de4ae7ef19044a4b9188d034d8840880古田（金中支）.png',
    productMoney: '402.0',
    oldName: '古田（金中支）',
    guidePrice: 424
  },
  {
    productName: '鼓浪扬帆',
    isCheck: 1,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/5b700651e9714122b5f3cb1359700dcb七匹狼（鼓浪扬帆）.png',
    productMoney: '203.0',
    oldName: '七匹狼（鼓浪扬帆）',
    guidePrice: 201.4
  },
  {
    productName: '豪运狼',
    isCheck: 1,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/81a316b561a649629cb25088eba09884七匹狼（豪运）.png',
    productMoney: '108.0',
    oldName: '七匹狼（豪运）',
    guidePrice: 85.86
  },
  {
    productName: '七匹狼（古田红军灰）',
    isCheck: 2,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/96863a24496d4e5889cde0ae50925e46七匹狼（古田红军灰）.png',
    productMoney: '260.0',
    oldName: '七匹狼（古田红军灰）',
    guidePrice: 263
  },
  {
    productName: '七匹狼（豪迈）',
    isCheck: 2,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/fe461dc2d58e482f84ea3cb16bb1978b七匹狼（豪迈）.png',
    productMoney: '103.0',
    oldName: '七匹狼（豪迈）',
    guidePrice: 93.28
  },
  {
    productName: '星辰大海双中支',
    isCheck: 1,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/6a87add380c948e3be27ff00ce028ffb七匹狼（星辰大海双中支）.png',
    productMoney: '320.0',
    oldName: '七匹狼（星辰大海双中支）',
    guidePrice: 339.2
  },
  {
    productName: '七匹狼（金砖中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/8ef3b37d3b914359813b69d480078185七匹狼（金砖中支）.png',
    productMoney: '735.0',
    oldName: '七匹狼（金砖中支）',
    guidePrice: 763.2
  },
  {
    productName: '金桥（蓝牌）',
    isCheck: 2,
    Category: '全部',
    Category2: '金桥',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/9e9f26452f0540359a64e2978c711813金桥（蓝牌）.png',
    productMoney: '155.0',
    oldName: '金桥（蓝牌）',
    guidePrice: 175
  },
  {
    productName: '七匹狼（纯尚）',
    isCheck: 2,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/b354a1b76aa84d97bc308729d0b6cd27七匹狼（纯尚）.png',
    productMoney: '203.0',
    oldName: '七匹狼（纯尚）',
    guidePrice: 185.5
  },
  {
    productName: '七匹狼（古田金细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/1a84e5f844a0406f9ab0c00e06671ed8七匹狼（古田金细支）.png',
    productMoney: '262.0',
    oldName: '七匹狼（古田金细支）',
    guidePrice: 263
  },
  {
    productName: '软红狼',
    isCheck: 2,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/0946e077bcc3450fb5c6b1d2086a8a7e七匹狼（软红）.png',
    productMoney: '152.0',
    oldName: '七匹狼（软红）',
    guidePrice: 143.1
  },
  {
    productName: '纯雅狼',
    isCheck: 2,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/5e5ae8665e134d9f80ce59fb66844824七匹狼（纯雅）.png',
    productMoney: '146.0',
    oldName: '七匹狼（纯雅）',
    guidePrice: 143.1
  },
  {
    productName: '七匹狼（金砖细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/7377d8148e6a4a8392ead5c24cff5fba七匹狼（金砖细支）.png',
    productMoney: '555.0',
    oldName: '七匹狼（金砖细支）',
    guidePrice: 582
  },
  {
    productName: '乘风狼',
    isCheck: 1,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/2a6e2c1d09e1447e9ef5c5bbc73698eb七匹狼（乘风启航）.png',
    productMoney: '212.0',
    oldName: '七匹狼（乘风启航）',
    guidePrice: 225
  },
  {
    productName: '金砖狼',
    isCheck: 1,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/f0a14200edde47098b7622913a7361ec七匹狼（金砖时代）.png',
    productMoney: '395.0',
    oldName: '七匹狼（金砖时代）',
    guidePrice: 424
  },
  {
    productName: '七匹狼（尚品）',
    isCheck: 2,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/49d018871a7942969dd1a485c560b30f七匹狼（尚品）.png',
    productMoney: '265.0',
    oldName: '七匹狼（尚品）',
    guidePrice: 275.6
  },
  {
    productName: '七匹狼（古田成功中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/f2922a806937429cacd61af494166850.png',
    productMoney: '600.0',
    oldName: '七匹狼（古田成功中支 ）',
    guidePrice: 678.4
  },
  {
    productName: '金桥（软）',
    isCheck: 2,
    Category: '全部',
    Category2: '金桥',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/428fa2e5e0af4ee28d9145ac0c70ad1a金桥（软）.png',
    productMoney: '90.0',
    oldName: '金桥（软）',
    guidePrice: null
  },
  {
    productName: '土楼1575',
    isCheck: 1,
    Category: '全部',
    Category2: '土楼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/5c5231e4f14d4467beca12f39987f357土楼（1575）.png',
    productMoney: '175.0',
    oldName: '土楼（1575）',
    guidePrice: 175
  },
  {
    productName: '七匹狼（大通仙）',
    isCheck: 2,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/997d95bdaa5f4bfb9c9b6685e6375afb七匹狼（大通仙）.png',
    productMoney: '670.0',
    oldName: '七匹狼（大通仙）',
    guidePrice: 720.8
  },
  {
    productName: '七匹狼（金砂）',
    isCheck: 2,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/3c6d090e3462405e816069a6b216e97f七匹狼（金砂）.png',
    productMoney: '110.0',
    oldName: '七匹狼（金砂）',
    guidePrice: 88
  },
  {
    productName: '七匹狼（厦门）',
    isCheck: 2,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/79bd3589b62c4ef8a6e85f4b43feb58f七匹狼（厦门）.png',
    productMoney: '210.0',
    oldName: '七匹狼（厦门）',
    guidePrice: 228.96
  },
  {
    productName: '古田红金灰',
    isCheck: 1,
    Category: '全部',
    Category2: '古田',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/c75aeb9a0868453abacdb974705b5b76古田（红军灰）.png',
    productMoney: '255.0',
    oldName: '古田（红军灰）',
    guidePrice: 263
  },
  {
    productName: '七匹狼（通运）',
    isCheck: 2,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/dcec55066a8c4060aaf4334631768e41七匹狼（通运）.png',
    productMoney: '215.0',
    oldName: '七匹狼（通运）',
    guidePrice: 201.4
  },
  {
    productName: '金桥（双爆）',
    isCheck: 2,
    Category: '全部',
    Category2: '金桥',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/a08d0d34524b4ea68a1443c86034da0a金桥（双爆）.png',
    productMoney: '240.0',
    oldName: '金桥（双爆）',
    guidePrice: null
  },
  {
    productName: '石狮（软富健）',
    isCheck: 2,
    Category: '全部',
    Category2: '石狮',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/09efe27e32ba4a23ab872392e65099d8石狮（软富健）.png',
    productMoney: '52.0',
    oldName: '石狮（软富健）',
    guidePrice: null
  },
  {
    productName: '土楼（1575金中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '土楼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/f36e69fb1e094b40b64eeb86ea2fdf69土楼（1575金中支）.png',
    productMoney: '310.0',
    oldName: '土楼（1575金中支）',
    guidePrice: null
  },
  {
    productName: '金桥（英伦奶香）',
    isCheck: 2,
    Category: '全部',
    Category2: '金桥',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/3cf0478aee1040e1a05e06ed33315b6b金桥（英伦奶香）.png',
    productMoney: '139.0',
    oldName: '金桥（英伦奶香）',
    guidePrice: null
  },
  {
    productName: '七匹狼（纯翠）',
    isCheck: 2,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/69055a8865184e9184d69fb686142d8e七匹狼（纯翠）.png',
    productMoney: '155.0',
    oldName: '七匹狼（纯翠）',
    guidePrice: null
  },
  {
    productName: '七匹狼（古田）',
    isCheck: 2,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/1db5f31cef264254ad75fd0bfc1fdcd4七匹狼（古田）.png',
    productMoney: '80.0',
    oldName: '七匹狼（古田）',
    guidePrice: null
  },
  {
    productName: '土楼（1575冰抹茶）',
    isCheck: 2,
    Category: '全部',
    Category2: '土楼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/9bb70e09383545da912fad0c9815d9d3土楼（1575冰抹茶）.png',
    productMoney: '270.0',
    oldName: '土楼（1575冰抹茶）',
    guidePrice: null
  },
  {
    productName: '古田（光芒）',
    isCheck: 2,
    Category: '全部',
    Category2: '古田',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/ccc4f1105bd9408abd73f821e3740a9b古田（光芒）.png',
    productMoney: '235.0',
    oldName: '古田（光芒）',
    guidePrice: 263
  },
  {
    productName: '七匹狼(小金砖)',
    isCheck: 2,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/29c571227805412b8741235c242fa44f.jpg',
    productMoney: '765.0',
    oldName: '七匹狼(小金砖)',
    guidePrice: 424
  },
  {
    productName: '石狮平安',
    isCheck: 1,
    Category: '全部',
    Category2: '石狮',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/9568940b48474c49ab95d0e50163b29f石狮（平安）.png',
    productMoney: '86.0',
    oldName: '石狮（平安）',
    guidePrice: null
  },
  {
    productName: '七匹狼（1575）',
    isCheck: 2,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/0d21bfb53f5e4b72a30b11e6862f924b.png',
    productMoney: '170.0',
    oldName: '七匹狼（1575）',
    guidePrice: 175
  },
  {
    productName: '七匹狼（古田成功细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '七匹狼',
    Factory: '福建',
    productImg: 'https://yanjiuchacha.com/api/75f94b695133454baa9f2c368bf021c5.png',
    productMoney: '570.0',
    oldName: '七匹狼（古田成功细支）',
    guidePrice: 678.4
  },
  {
    productName: '细支三沙',
    isCheck: 1,
    Category: '全部',
    Category2: '三沙',
    Factory: '海南',
    productImg: 'https://yanjiuchacha.com/api/466f7664bf524504b27d43f2a09ef251三沙（细支）.png',
    productMoney: '195.0',
    oldName: '三沙（细支）',
    guidePrice: 225
  },
  {
    productName: '宝岛三沙',
    isCheck: 1,
    Category: '全部',
    Category2: '宝岛',
    Factory: '海南',
    productImg: 'https://yanjiuchacha.com/api/48d237bb773048ae9b9278676a1415a8宝岛（三沙）.png',
    productMoney: '138.0',
    oldName: '宝岛（三沙）',
    guidePrice: 158
  },
  {
    productName: '三沙（中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '三沙',
    Factory: '海南',
    productImg: 'https://yanjiuchacha.com/api/8de2c4f0c6a045fa8cf9ccf6205f1c00三沙（中支）.png',
    productMoney: '200.0',
    oldName: '三沙（中支）',
    guidePrice: 225
  },
  {
    productName: '宝岛（一品沉香）',
    isCheck: 2,
    Category: '全部',
    Category2: '宝岛',
    Factory: '海南',
    productImg: 'https://yanjiuchacha.com/api/1e117bd065174ce181132a386737612b宝岛（一品沉香）.png',
    productMoney: '645.0',
    oldName: '宝岛（一品沉香）',
    guidePrice: null
  },
  {
    productName: '三沙（椰王绿）',
    isCheck: 2,
    Category: '全部',
    Category2: '三沙',
    Factory: '海南',
    productImg: 'https://yanjiuchacha.com/api/fa3ec85303784e31ab1965fb9f0184a7三沙（椰王绿）.png',
    productMoney: '158.0',
    oldName: '三沙（椰王绿）',
    guidePrice: null
  },
  {
    productName: '晶彩日子',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '深圳',
    productImg: 'https://yanjiuchacha.com/api/3c7cc265ab10442483a086aedc55a9f8双喜（好日子晶彩）.png',
    productMoney: '148.0',
    oldName: '双喜（好日子晶彩）',
    guidePrice: 158
  },
  {
    productName: '软珍日',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '深圳',
    productImg: 'https://yanjiuchacha.com/api/82dfacbb7de0446bad71fe7316b50f7a双喜（软珍品好日子）.png',
    productMoney: '131.0',
    oldName: '双喜（软珍品好日子）',
    guidePrice: 137.8
  },
  {
    productName: '精日子',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '深圳',
    productImg: 'https://yanjiuchacha.com/api/bead0f6d544e4607927932a4c60224e2双喜（硬精品好日子）.png',
    productMoney: '120.0',
    oldName: '双喜（硬精品好日子）',
    guidePrice: 93.28
  },
  {
    productName: '祥和日子',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '深圳',
    productImg: 'https://yanjiuchacha.com/api/1863c44c4f234da9b63b939fe411bf9a双喜（硬祥和好日子）.png',
    productMoney: '140.0',
    oldName: '双喜（硬祥和好日子）',
    guidePrice: 121.9
  },
  {
    productName: '祥云日子',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '深圳',
    productImg: 'https://yanjiuchacha.com/api/9a42e156e3464ebdb895df0af9441245双喜（硬祥云好日子）.png',
    productMoney: '154.0',
    oldName: '双喜（硬祥云好日子）',
    guidePrice: 185.5
  },
  {
    productName: '金樽日子',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '深圳',
    productImg: 'https://yanjiuchacha.com/api/5b084219fded4b25a112741bd8ca2fb2双喜（硬金樽好日子）.png',
    productMoney: '235.0',
    oldName: '双喜（硬金樽好日子）',
    guidePrice: 243.8
  },
  {
    productName: '吉祥日',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '深圳',
    productImg: 'https://yanjiuchacha.com/api/a138c3238c8849b480075574ecf494aa双喜（硬吉祥好日子）.png',
    productMoney: '120.0',
    oldName: '双喜（硬吉祥好日子）',
    guidePrice: 66.78
  },
  {
    productName: '细金樽日子',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '深圳',
    productImg: 'https://yanjiuchacha.com/api/9a1c888c344246c59272fde04738250f双喜（金樽好日子细支）.png',
    productMoney: '268.0',
    oldName: '双喜（金樽好日子细支）',
    guidePrice: 263
  },
  {
    productName: '城市之光',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '深圳',
    productImg: 'https://yanjiuchacha.com/api/cbb6b8523705463394f892664685e12f双喜（好日子城市之光）.png',
    productMoney: '180.0',
    oldName: '双喜（好日子城市之光）',
    guidePrice: 201.4
  },
  {
    productName: '欢乐颂好日子',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '深圳',
    productImg: 'https://yanjiuchacha.com/api/dd6d9d9e98834784be32bf59bd19a571双喜（好日子欢乐颂）.png',
    productMoney: '173.0',
    oldName: '双喜（好日子欢乐颂）',
    guidePrice: 175
  },
  {
    productName: '细支晶彩日子',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '深圳',
    productImg: 'https://yanjiuchacha.com/api/ede02bf9a76346e4a6f7f878d6bd96d3双喜（好日子晶彩细支）.png',
    productMoney: '287.0',
    oldName: '双喜（好日子晶彩细支）',
    guidePrice: null
  },
  {
    productName: '万象好日子',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '深圳',
    productImg: 'https://yanjiuchacha.com/api/ddb94a40cc2e4388b0342a290ffe1138双喜（万象好日子）.png',
    productMoney: '265.0',
    oldName: '双喜（万象好日子）',
    guidePrice: 263
  },
  {
    productName: '方大盛世日',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '深圳',
    productImg: 'https://yanjiuchacha.com/api/2a4acb1fb4cf4fc08d0e19914cb79c3a双喜（大盛世好日子）.png',
    productMoney: '675.0',
    oldName: '双喜（大盛世好日子）',
    guidePrice: null
  },
  {
    productName: '方小盛世日',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '深圳',
    productImg: 'https://yanjiuchacha.com/api/5549334275c94aef884214e443ee6017双喜（细支盛世好日子）.png',
    productMoney: '590.0',
    oldName: '双喜（细支盛世好日子）',
    guidePrice: null
  },
  {
    productName: '如意日',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '深圳',
    productImg: 'https://yanjiuchacha.com/api/367cb7463f4c40079a53308bdb27bc79双喜（软如意好日子）.png',
    productMoney: '102.0',
    oldName: '双喜（软如意好日子）',
    guidePrice: null
  },
  {
    productName: '锦绣日',
    isCheck: 1,
    Category: '全部',
    Category2: '双喜',
    Factory: '深圳',
    productImg: 'https://yanjiuchacha.com/api/41dec632f84a43d2a48ff6857d5959f9双喜（软锦绣好日子）.png',
    productMoney: '180.0',
    oldName: '双喜（软锦绣好日子）',
    guidePrice: null
  },
  {
    productName: '双冰三五',
    isCheck: 1,
    Category: '全部',
    Category2: '三五',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/aa2a5c121938459588ca2766c795007f三五（双冰）.png',
    productMoney: '245.0',
    oldName: '三五（双冰）',
    guidePrice: 234.88
  },
  {
    productName: '三五（金锐）',
    isCheck: 2,
    Category: '全部',
    Category2: '三五',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/4dbec9d102674e71baf821c27232f56d三五（金锐）.png',
    productMoney: '177.0',
    oldName: '三五（金锐）',
    guidePrice: 137.8
  },
  {
    productName: '公司冰炫555',
    isCheck: 1,
    Category: '全部',
    Category2: '三五',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/17d1a812ee5c41838f2dd422c08e928c三五（冰炫细支）.png',
    productMoney: '252.0',
    oldName: '三五（冰炫细支）',
    guidePrice: 216.05
  },
  {
    productName: '万宝路冰爵',
    isCheck: 1,
    Category: '全部',
    Category2: '万宝路',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/463b3cacc58f4b619e4f2283197d1a89万宝路（硬冰爵）.png',
    productMoney: '210.0',
    oldName: '万宝路（硬冰爵）',
    guidePrice: 225
  },
  {
    productName: '公司软红万',
    isCheck: 1,
    Category: '全部',
    Category2: '万宝路',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/e17e768769e4478cb7d87ff0389a2b12.png',
    productMoney: '163.0',
    oldName: '万宝路（软红）',
    guidePrice: 143.1
  },
  {
    productName: '万宝路（软红2.0）',
    isCheck: 2,
    Category: '全部',
    Category2: '万宝路',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/779ceeef34be4a9ba1c66003894a90cd万宝路（软红2.0）.png',
    productMoney: '152.0',
    oldName: '万宝路（软红2.0）',
    guidePrice: 143.1
  },
  {
    productName: '公司爱喜幻变',
    isCheck: 1,
    Category: '全部',
    Category2: '爱喜',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/d3f01f69ce0944ba8e69a710bc8a8dba爱喜（幻变）.png',
    productMoney: '235.0',
    oldName: '爱喜（幻变）',
    guidePrice: 207.97
  },
  {
    productName: '公司健牌紫冰',
    isCheck: 1,
    Category: '全部',
    Category2: '建牌',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/6c2b645f77444c5cb1c9c2e4b9ddc921建牌（薄荷紫冰1）.png',
    productMoney: '248.0',
    oldName: '建牌（薄荷紫冰1）',
    guidePrice: 231.64
  },
  {
    productName: '三五（金）',
    isCheck: 2,
    Category: '全部',
    Category2: '三五',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/ad16730cc6b242db94c8c0f34e0acd96.jpg',
    productMoney: '260.0',
    oldName: '三五（金）',
    guidePrice: 137.8
  },
  {
    productName: '公司合红万',
    isCheck: 1,
    Category: '全部',
    Category2: '万宝路',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/7ba5fd373632498f8cbbf3d1370bb5de万宝路（硬红）.png',
    productMoney: '173.0',
    oldName: '万宝路（硬红）',
    guidePrice: null
  },
  {
    productName: '细支老版三五',
    isCheck: 1,
    Category: '全部',
    Category2: '三五',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/50e8f40478894fa5b457d07485d436ff.png',
    productMoney: '212.0',
    oldName: '三五（老版细支）',
    guidePrice: 212.28
  },
  {
    productName: '爱喜（薄荷）',
    isCheck: 2,
    Category: '全部',
    Category2: '爱喜',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/36cb3521532d46af9352e2cd99199270.png',
    productMoney: '192.0',
    oldName: '爱喜（薄荷）',
    guidePrice: 196.24
  },
  {
    productName: '公司七星蓝莓',
    isCheck: 1,
    Category: '全部',
    Category2: '七星蓝莓',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/64bd72ab46d34349a26bbe387c9f2117.png',
    productMoney: '330.0',
    oldName: '七星蓝莓（晶选）',
    guidePrice: 264.22
  },
  {
    productName: '威斯（小熊猫）',
    isCheck: 2,
    Category: '全部',
    Category2: '威斯',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/4d13232867f64ed3a4a50b6c60b0724c威斯（小熊猫）.png',
    productMoney: '110.0',
    oldName: '威斯（小熊猫）',
    guidePrice: 88
  },
  {
    productName: '公司健牌中空4',
    isCheck: 1,
    Category: '全部',
    Category2: '建牌',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/58c70ef7f49f44a18e04e406b70ada44建牌（薄荷中空4）.png',
    productMoney: '228.0',
    oldName: '建牌（薄荷中空4）',
    guidePrice: 234.91
  },
  {
    productName: '金天越555',
    isCheck: 1,
    Category: '全部',
    Category2: '三五',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/5bb878f30c6047f68c94fb0b26789c94.jpg',
    productMoney: '157.0',
    oldName: '三五（金天越）',
    guidePrice: 119.09
  },
  {
    productName: '三五国际版',
    isCheck: 1,
    Category: '全部',
    Category2: '三五',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/53ee23959dd64bcbb31b69c2b77ca5fa三五（国际）.png',
    productMoney: '225.0',
    oldName: '三五（国际）',
    guidePrice: null
  },
  {
    productName: '万宝路硬金',
    isCheck: 2,
    Category: '全部',
    Category2: '万宝路',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/7e9b5f83881f4205abf033549fa05c19万宝路（软金） .png',
    productMoney: '190.0',
    oldName: '万宝路（硬金）',
    guidePrice: 188.68
  },
  {
    productName: '公司经典555',
    isCheck: 1,
    Category: '全部',
    Category2: '三五',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/c75f75e356d74cc08552232707887c44三五（经典）.png',
    productMoney: '242.0',
    oldName: '三五（经典）',
    guidePrice: 257.2
  },
  {
    productName: '红双喜（爱国绿中支）',
    isCheck: 2,
    Category: '全部',
    Category2: '红双喜',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/b129603ca31c44c890cb4ec06bd3d65c.png',
    productMoney: '153.0',
    oldName: '红双喜（爱国绿中支）',
    guidePrice: 225.73
  },
  {
    productName: '七星（风蓝）',
    isCheck: 2,
    Category: '全部',
    Category2: '七星',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/9721c950103341f585fbffb9ea623a03七星（风蓝）.png',
    productMoney: '240.0',
    oldName: '七星（风蓝）',
    guidePrice: 233.44
  },
  {
    productName: '七星天蓝',
    isCheck: 1,
    Category: '全部',
    Category2: '七星',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/f31c1bcccbe04daf9038d8ba1c4f1c57七星（天蓝）.png',
    productMoney: '235.0',
    oldName: '七星（天蓝）',
    guidePrice: 233.44
  },
  {
    productName: '宝亨宝珠',
    isCheck: 1,
    Category: '全部',
    Category2: '宝亨',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/7ef57d73cfda4228b657d4eb4da49fb0宝亨（宝珠）.png',
    productMoney: '215.0',
    oldName: '宝亨（宝珠）',
    guidePrice: 207.94
  },
  {
    productName: '公司软白万',
    isCheck: 1,
    Category: '全部',
    Category2: '万宝路',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/687eaf919ea3457abf14dab24642ce35万宝路（软白）.png',
    productMoney: '165.0',
    oldName: '万宝路（软白）',
    guidePrice: null
  },
  {
    productName: '三五（国际版）',
    isCheck: 2,
    Category: '全部',
    Category2: '三五',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/d3f25a0a408b4f3ebe141e2b4b2aff68.jpg',
    productMoney: '225.0',
    oldName: '三五（国际版）',
    guidePrice: 254.92
  },
  {
    productName: '公司合白万',
    isCheck: 1,
    Category: '全部',
    Category2: '万宝路',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/263e9c8f00284c3ab66084ab2647b630万宝路（硬白）.png',
    productMoney: '190.0',
    oldName: '万宝路（硬白）',
    guidePrice: null
  },
  {
    productName: '天越三五',
    isCheck: 1,
    Category: '全部',
    Category2: '三五',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/63b9ee2bb7204422a08888e64e7be976.jpg',
    productMoney: '170.0',
    oldName: '三五（天越）',
    guidePrice: 192.5
  },
  {
    productName: '硬银辉',
    isCheck: 1,
    Category: '全部',
    Category2: '七星',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/e2714e423feb4b7ab0029e979edd76261_自定义px_2021-12-14+15_54_01 (2).png',
    productMoney: '380.0',
    oldName: '七星（银辉）',
    guidePrice: 288
  },
  {
    productName: '万宝路硬金3.0',
    isCheck: 1,
    Category: '全部',
    Category2: '万宝路',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/d1c14c47600047e2bf0c371e8b29101e.jpg',
    productMoney: '191.0',
    oldName: '万宝路（硬金3.0）',
    guidePrice: 201.4
  },
  {
    productName: '万宝路（软金）',
    isCheck: 2,
    Category: '全部',
    Category2: '万宝路',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/ee129a47506947ea9a7fd1305079fb571_自定义px_2021-12-14+16_05_06 (2).png',
    productMoney: '165.0',
    oldName: '万宝路（软金）',
    guidePrice: 167.48
  },
  {
    productName: '七星（晶选赤玉）',
    isCheck: 2,
    Category: '全部',
    Category2: '七星',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/016d662b1f6d4e7782b097e3c06f063a.png',
    productMoney: '310.0',
    oldName: '七星（晶选赤玉）',
    guidePrice: 347.43
  },
  {
    productName: '七星蓝莓细支（晶选细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '七星',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/b6b5cc61b8e244ac836088e54a6e3f5e.png',
    productMoney: '370.0',
    oldName: '七星蓝莓细支（晶选细支）',
    guidePrice: null
  },
  {
    productName: '公司蓝爱喜',
    isCheck: 1,
    Category: '全部',
    Category2: '爱喜',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/218c07d00ec440afb5d28a0cb1137c7e爱喜（蓝）.png',
    productMoney: '190.0',
    oldName: '爱喜（蓝）',
    guidePrice: 122.8
  },
  {
    productName: '建牌（细HD白1）',
    isCheck: 2,
    Category: '全部',
    Category2: '建牌',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/ef633a63fcce417a9047299981ad4f42建牌（HD白1）.png',
    productMoney: '185.0',
    oldName: '建牌（细HD白1）',
    guidePrice: 221.07
  },
  {
    productName: '公司七星1克',
    isCheck: 1,
    Category: '全部',
    Category2: '七星',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/b050c9b5d39a4cbbb6dfd34bff673310七星（一毫克）.png',
    productMoney: '235.0',
    oldName: '七星（一毫克）',
    guidePrice: 153.7
  },
  {
    productName: '七星（晶选黄）',
    isCheck: 2,
    Category: '全部',
    Category2: '七星',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/daf51dac15f34bb58d41ddbfbeb75026七星（晶选黄）.png',
    productMoney: '360.0',
    oldName: '七星（晶选黄）',
    guidePrice: 212
  },
  {
    productName: '三五（弘 新版）',
    isCheck: 2,
    Category: '全部',
    Category2: '三五',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/1d41e9f836164fcba7119dd1105f1735三五（弘 新版）.png',
    productMoney: '265.0',
    oldName: '三五（弘 新版）',
    guidePrice: 255.46
  },
  {
    productName: '建牌（薄荷细支1）',
    isCheck: 2,
    Category: '全部',
    Category2: '建牌',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/04974c34ee9648068910178888c796fa建牌（薄荷细支1）.png',
    productMoney: '225.0',
    oldName: '建牌（薄荷细支1）',
    guidePrice: 158
  },
  {
    productName: '大卫杜夫（尼加拉瓜托罗）',
    isCheck: 2,
    Category: '全部',
    Category2: '大卫杜夫',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/b3c0232aefa041eeb9202618f289957c大卫杜夫（尼加拉瓜托罗）.png',
    productMoney: '2200.0',
    oldName: '大卫杜夫（尼加拉瓜托罗）',
    guidePrice: null
  },
  {
    productName: '爱喜双爆倍享',
    isCheck: 1,
    Category: '全部',
    Category2: '爱喜',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/5a3cfbb77acd4a45aee88b8d60f7b301.jpg',
    productMoney: '265.0',
    oldName: '爱喜（幻变倍享）',
    guidePrice: 234.05
  },
  {
    productName: '爱喜（幻变1毫克紫）',
    isCheck: 2,
    Category: '全部',
    Category2: '爱喜',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/83932ffcf48e4adbb93588269be2b655.jpg',
    productMoney: '265.0',
    oldName: '爱喜（幻变1毫克紫）',
    guidePrice: 207.97
  },
  {
    productName: '三五（星空细支）',
    isCheck: 2,
    Category: '全部',
    Category2: '三五',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/a5f103d854f14872a6d286aa517b0042三五（星空细支）.png',
    productMoney: '240.0',
    oldName: '三五（星空细支）',
    guidePrice: 139.79
  },
  {
    productName: '建牌（HD1）',
    isCheck: 2,
    Category: '全部',
    Category2: '建牌',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/5703d3d9d77e4ca485d3de3da8fc85ac建牌（HD1）.png',
    productMoney: '270.0',
    oldName: '建牌（HD1）',
    guidePrice: 159
  },
  {
    productName: '三五（弘博新版）',
    isCheck: 2,
    Category: '全部',
    Category2: '三五',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/e9f1cf4f7477453198da6562a21ce26f三五（弘博新版）.png',
    productMoney: '245.0',
    oldName: '三五（弘博新版）',
    guidePrice: 255.46
  },
  {
    productName: '三五（银尚）',
    isCheck: 2,
    Category: '全部',
    Category2: '三五',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/5e0ff7f92aa940a4b7d1be5dbf9ad820三五（银尚）.png',
    productMoney: '240.0',
    oldName: '三五（银尚）',
    guidePrice: null
  },
  {
    productName: '爱喜（金）',
    isCheck: 2,
    Category: '全部',
    Category2: '爱喜',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/19c3300011eb46878cb921c7e80c5842爱喜（金）.png',
    productMoney: '215.0',
    oldName: '爱喜（金）',
    guidePrice: null
  },
  {
    productName: '三五（XIGUAN）',
    isCheck: 2,
    Category: '全部',
    Category2: '三五',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/3aa4bdb9b74c46d3ad8ec746661731231_自定义px_2021-12-14+15_49_55 (2).png',
    productMoney: '175.0',
    oldName: '三五（XIGUAN）',
    guidePrice: 219.8
  },
  {
    productName: '建牌（HD8）',
    isCheck: 2,
    Category: '全部',
    Category2: '建牌',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/0f549cf45bb84fdc9925f6a38763a186建牌（HD8）.png',
    productMoney: '260.0',
    oldName: '建牌（HD8）',
    guidePrice: 159
  },
  {
    productName: '建牌（HD4）',
    isCheck: 2,
    Category: '全部',
    Category2: '建牌',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/359b47f8420c4ee6951cc8564ed81d02建牌（HD4）.png',
    productMoney: '250.0',
    oldName: '建牌（HD4）',
    guidePrice: 199.92
  },
  {
    productName: '百乐门（普通）',
    isCheck: 2,
    Category: '全部',
    Category2: '百乐门',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/aef4c34a7b584220bc22f7491f9fe985百乐门（普通）.png',
    productMoney: '315.0',
    oldName: '百乐门（普通）',
    guidePrice: null
  },
  {
    productName: '七星（晶选黄1）',
    isCheck: 2,
    Category: '全部',
    Category2: '七星',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/8da19496c6bf4489b897a13602b6cdd5七星（晶选黄1）.png',
    productMoney: '345.0',
    oldName: '七星（晶选黄1）',
    guidePrice: 212
  },
  {
    productName: '宝亨（莫吉托）',
    isCheck: 2,
    Category: '全部',
    Category2: '宝亨',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/853966a1693740528cb10e66a86b0735宝亨（莫吉托）.png',
    productMoney: '230.0',
    oldName: '宝亨（莫吉托）',
    guidePrice: null
  },
  {
    productName: '红双喜（南洋1905）',
    isCheck: 2,
    Category: '全部',
    Category2: '红双喜',
    Factory: '进口',
    productImg: 'https://yanjiuchacha.com/api/1f89ffde3e4d4039a75ff56d2a3fa94f.png',
    productMoney: '150.0',
    oldName: '红双喜（南洋1905）',
    guidePrice: 211.38
  },
  {
    productName: '景泰典蓝',
    isCheck: 1,
    Category: '全部',
    Category2: '阿里山',
    Factory: '港澳台',
    productImg: 'https://yanjiuchacha.com/api/a7e2ca6d71564952808e09c39d77ce0a阿里山（硬景泰典蓝）.png',
    productMoney: '168.0',
    oldName: '阿里山（硬景泰典蓝）',
    guidePrice: 235.32
  },
  {
    productName: '阿里山（天韵）',
    isCheck: 2,
    Category: '全部',
    Category2: '阿里山',
    Factory: '港澳台',
    productImg: 'https://yanjiuchacha.com/api/8361ad81701c467cbd8f05506e4ea806阿里山（天韵）.png',
    productMoney: '110.0',
    oldName: '阿里山（天韵）',
    guidePrice: 132.5
  },
  {
    productName: '大华（黄金眼）',
    isCheck: 2,
    Category: '全部',
    Category2: '大华',
    Factory: '港澳台',
    productImg: 'https://yanjiuchacha.com/api/6db8d23b6a964226ab15d78c4527c22f大华（黄金眼）.png',
    productMoney: '200.0',
    oldName: '大华（黄金眼）',
    guidePrice: 217.74
  },
  {
    productName: '大华（开元）',
    isCheck: 2,
    Category: '全部',
    Category2: '大华',
    Factory: '港澳台',
    productImg: 'https://yanjiuchacha.com/api/f85c9922e5664fb3bb085c4790eb533f大华（开元）.png',
    productMoney: '170.0',
    oldName: '大华（开元）',
    guidePrice: 245
  },
  {
    productName: '阿里山宝相花',
    isCheck: 1,
    Category: '全部',
    Category2: '阿里山',
    Factory: '港澳台',
    productImg: 'https://yanjiuchacha.com/api/47c4fbd296c24212bd3c9a091838a52c阿里山（宝相花）.png',
    productMoney: '200.0',
    oldName: '阿里山（宝相花）',
    guidePrice: 319.98
  },
  {
    productName: '大华（开元陈皮爆珠）',
    isCheck: 2,
    Category: '全部',
    Category2: '大华',
    Factory: '港澳台',
    productImg: 'https://yanjiuchacha.com/api/fd707a439e854f05852a49942febbaff大华（开元陈皮爆珠）.png',
    productMoney: '210.0',
    oldName: '大华（开元陈皮爆珠）',
    guidePrice: null
  },
  {
    productName: '大华（大时代硬包烟）',
    isCheck: 2,
    Category: '全部',
    Category2: '大华',
    Factory: '港澳台',
    productImg: 'https://yanjiuchacha.com/api/28ab76ae63fd45a59a63feb785e0a68a.jpg',
    productMoney: '145.0',
    oldName: '大华（大时代硬包烟）',
    guidePrice: 143.1
  },
  {
    productName: '紫气东来（祥瑞）',
    isCheck: 2,
    Category: '全部',
    Category2: '紫气东来',
    Factory: '山西',
    productImg: 'https://yanjiuchacha.com/api/5fcc25986f31445f855f06746980553f紫气东来（祥瑞）.png',
    productMoney: '190.0',
    oldName: '紫气东来（祥瑞）',
    guidePrice: 275.6
  },
  {
    productName: '紫气东来（吉祥天下）',
    isCheck: 2,
    Category: '全部',
    Category2: '紫气东来',
    Factory: '山西',
    productImg: 'https://yanjiuchacha.com/api/4b68fe35fae14296a4c85e796cf9c626.jpg',
    productMoney: '115.0',
    oldName: '紫气东来（吉祥天下）',
    guidePrice: 201.4
  },
  {
    productName: '紫气东来（汾清香）',
    isCheck: 2,
    Category: '全部',
    Category2: '紫气东来',
    Factory: '山西',
    productImg: 'https://yanjiuchacha.com/api/17a3a89f67f843beabe09f3ffb793f76紫气东来（汾清香）.png',
    productMoney: '300.0',
    oldName: '紫气东来（汾清香）',
    guidePrice: 424
  },
  {
    productName: '紫气东来（在天）',
    isCheck: 2,
    Category: '全部',
    Category2: '紫气东来',
    Factory: '山西',
    productImg: 'https://yanjiuchacha.com/api/3b427222d3104c4c956bfe8ac21edda7.png',
    productMoney: '290.0',
    oldName: '紫气东来（在天）',
    guidePrice: 424
  }
]
