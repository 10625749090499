import api from '@/api/modules/user.js'
// import api2 from '@/api/modules/members.js'
import Cookies from 'vue-cookies'

const state = {
  token: Cookies.get('token') || '',
  info: JSON.parse(localStorage.getItem('info') || null),
  // count1: 0,
  // count2: 0,
  // count3: 0,
  title: ''
}

const mutations = {
  // 初始化数据
  RESET_STATE: state => {
    Cookies.remove('token')
    localStorage.removeItem('info')

    state.token = ''
    state.info = ''
  },
  // 存储token
  SET_TOKEN: (state, token) => {
    state.token = token
    Cookies.set('token', token, '1m')
  },
  // 存储info
  SET_INFO: (state, info) => {
    state.info = info
    localStorage.setItem('info', JSON.stringify(info))
  },
  // SET_COUNT: (state, data) => {
  //   state.count1 = data.storeCount
  //   state.count2 = data.articleCount
  //   state.count3 = data.imageCount
  // },
  SET_TITLE: (state, data) => {
    state.title = data
  }
}

const actions = {
  // 获取用户信息
  getInfo ({ commit, state }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const { data: res } = await api.get({ Id: state.info.Id })
        // console.log('更新用户信息', res)

        commit('SET_TOKEN', res.Token)
        commit('SET_INFO', res)
        resolve(res)
      } catch (e) {
        reject(e)
      }
    })
  },

  // 登录
  login ({ commit }, data) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const { data: res } = await api.login(data)
        console.log('登录成功', res)

        commit('SET_TOKEN', res.Token)
        commit('SET_INFO', res)
        resolve()
      } catch (e) {
        reject(e)
      }
    })
  },

  // 审核数量
  // getCount ({ commit }, data) {
  //   // eslint-disable-next-line no-async-promise-executor
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const { data } = await api2.auditcount()
  //       // console.log('获取审核数量', data)
  //       commit('SET_COUNT', data)
  //       resolve(data)
  //     } catch (e) {
  //       reject(e)
  //     }
  //   })
  // },

  setTitle ({ commit }, data) {
    commit('SET_TITLE', data)
  },

  // 登出
  async logout ({ commit }, data) {
    data && (await api.logout())

    // 清缓存
    commit('RESET_STATE')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
