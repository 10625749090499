<!--
 * @Description: 报价上传
-->
<template>
  <div>
    <div class="TableTop">
      <div class="TableTop_tit">
        <span>{{ name }}</span>
        <span style="margin-left: 10px;">{{ date }}</span>
      </div>

      <div class="TableTop_btn">
        {{ DraftsDate }}
        <el-button v-if="getDraftsBtnFlag" type="primary" plain size="small" @click="$emit('draftVisible')"
          >载入草稿</el-button
        >
        <el-button v-if="editDraftsBtnFlag" type="primary" size="small" @click="editDrafts()">保存草稿</el-button>
        <el-button type="success" size="small" @click="uploadDialogVisible = true">更新</el-button>
      </div>
    </div>

    <div class="divTable" :style="{ 'width': `${num * 210 + 40}px` }">
      <div class="numIndex">
        <div class="order" v-for="item in row" :key="item">
          {{ item }}
        </div>
      </div>

      <div class="ulTables">
        <ul class="ulTable" v-for="(item, index) in allList" :key="item.Id">
          <i
            v-if="iconFlag"
            class="el-icon-menu"
            style="line-height: 24px; cursor: pointer;"
            slot="reference"
            @click.stop="startSort(item, index)"
          >
          </i>

          <!-- <li class="reference_price" :style="{ display: index === current ? 'block' : 'none' }" v-if="item['LS']">
            <span style="color: blue;">参考值:</span>
            <span style="color: red;" v-if="item['LS'].Type === 'ZSJ' && (item.ZSJPrice || item.ZSJPrice === 0)">
              {{ item.ZSJPrice + item['LS'].priceDifference }}
            </span>
            <span style="color: red;" v-else-if="item['LS'].Type === 'SZ' && (item.SZPrice || item.ZSJPrice === 0)">
              {{ item.SZPrice + item['LS'].priceDifference }}
            </span>
            <span style="color: red;" v-else-if="item['LS'].Type === 'SZ2' && (item.SZPrice2 || item.ZSJPrice === 0)">
              {{ item.SZPrice2 + item['LS'].priceDifference }}
            </span>
          </li> -->

          <li
            class="other_price"
            :style="{ display: index === current ? 'block' : 'none' }"
            v-if="item[delType[0]] || item[delType[1]] || item[delType[2]]"
          >
            <span v-for="(items, index) in delType" :key="index">
              <template v-if="item[items]">
                <span style="color: blue;">{{ nameList[items] }}:</span>
                <span style="color: red;">{{ item[items] }}</span>
              </template>
            </span>
          </li>

          <li class="title" :class="index === current ? 'red' : ''">
            {{ item.Name }}
          </li>

          <el-input
            class="price"
            v-model="item.Price"
            size="medium"
            :label="item.Name"
            :placeholder="item[type]"
            :ref="'mobile' + parseInt(index / num) + (index % num)"
            @keyup.native.right="keyRight(parseInt(index / num), index % num, num)"
            @keyup.native.down="keyDown(parseInt(index / num), index % num, num)"
            @keyup.native.up="keyUp(parseInt(index / num), index % num, num)"
            @focus="onFocus(parseInt(index / num), index % num, num)"
            @keyup.native.left="keyLeft(parseInt(index / num), index % num, num)"
            @keyup.native.enter="keyDown(parseInt(index / num), index % num, num)"
          >
          </el-input>

          <li>{{ item.Order }},{{ item.Column }}</li>

          <li style="color: red; font-size: 12px;" v-if="item.Price && Math.abs(item.Price - item[type]) > 9">
            {{ item.Price - item[type] }}
          </li>
        </ul>
      </div>
    </div>

    <el-dialog
      title="编辑位置"
      :visible.sync="visible"
      width="400px"
      :modal-append-to-body="true"
      :close-on-click-modal="false"
      @close="editCancel()"
    >
      <el-form label-width="auto" ref="ruleForm">
        <el-form-item label="名称">
          <el-input v-model="listItem.Name" disabled></el-input>
        </el-form-item>
        <el-form-item label="插入行">
          <el-input v-model="Order" placeholder="请输入插入行的位置"></el-input>
        </el-form-item>
        <el-form-item label="插入列">
          <el-input v-model="Column" placeholder="请输入插入列的位置"></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <el-image style="width: 100px;" :src="listItem.Image" :preview-src-list="[listItem.Image]" alt=""></el-image>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="changeIsOk()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'amendmentOffer',
  props: {
    activeName: {
      type: String,
      default: '1'
    },
    allList: {
      type: Array,
      default: () => []
    },
    num: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      default: 'LSPrice'
    },
    name: {
      type: String,
      required: true
    },
    date: {
      type: String,
      default: ''
    },
    DraftsDate: {
      type: String,
      default: ''
    },
    getDraftsBtnFlag: {
      type: Boolean,
      default: false
    },
    editDraftsBtnFlag: {
      type: Boolean,
      default: false
    },
    iconFlag: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      current: 0,

      typeList: ['LSPrice', 'ZSJPrice', 'SZPrice', 'SZPrice2'],
      nameList: {
        LSPrice: '流',
        ZSJPrice: '珠',
        SZPrice: 'A',
        SZPrice2: 'B'
      },

      visible: false,
      listItem: {},
      Order: 0,
      Column: 0,
      sortingArr: [],
      sortIndex: null
    }
  },

  computed: {
    delType () {
      const arr = []
      this.typeList.forEach(item => {
        if (item !== this.type) arr.push(item)
      })
      return arr
    },

    row () {
      return Math.ceil(this.allList.length / this.num)
    },

    remainder () {
      return this.allList.length % this.num
    }
  },

  methods: {
    firstFocus () {
      this.$refs.mobile00[0].focus()
    },

    startSort (name, index) {
      // console.log(name.Name, name, index, this.allList[index])
      this.listItem = name
      this.Order = name.Order
      this.Column = name.Column
      this.sortIndex = index === 0 ? 0 : index || null
      this.visible = true
    },

    addSort (item, Order, Column) {
      console.log(item, Order, Column)
      this.listItem = item
      this.Order = Order
      this.Column = Column

      this.changeIsOk()
    },

    editCancel () {
      this.visible = false
    },

    changeIsOk () {
      const list = []
      let allList = this.$globalFun.deepClone(this.allList)
      const o1 = parseInt((this.sortIndex - 0) / this.num) + 1
      const c1 = ((this.sortIndex - 0) % this.num) + 1
      // console.log(this.Order, this.Column, o1, c1, this.sortIndex, this.allList)

      if (this.sortIndex || this.sortIndex === 0) {
        // console.log(allList[this.sortIndex])
        allList.splice(this.sortIndex, 1)
      }

      const sindex = (this.Order - 1) * this.num + (this.Column - 1)
      console.log('11111', sindex)

      const orders = 'Order' + (this.activeName === '1' ? '' : this.activeName)
      const columns = 'Column' + (this.activeName === '1' ? '' : this.activeName)
      // console.log('11111', orders, columns)

      allList.forEach(item => {
        let index = 0

        if (this.sortIndex || this.sortIndex === 0) {
          if (item[orders] > o1 && item[columns] === c1) {
            item[orders] -= 1
          }
        }
        if (item[orders] >= this.Order - 0 && item[columns] === this.Column - 0) {
          item[orders] += 1
        }
        index = (item[orders] - 1) * this.num + (item[columns] - 1)

        list[index] = item
      })
      this.listItem[orders] = this.Order - 0
      this.listItem[columns] = this.Column - 0
      allList.splice(sindex, 0, this.listItem)

      console.log(this.listItem, allList)

      allList = this.sortList(allList)

      this.visible = false

      const obj = []
      allList.forEach(item => {
        obj.push({
          name: item.Name,
          order: item[orders],
          column: item[columns]
        })
      })

      this.Order = 0
      this.Column = 0
      this.sortingArr = obj
      // console.log('软红钻石', this.sortingArr)

      this.$emit('changeIsOk', {
        list: allList,
        sortingArr: this.sortingArr
      })
    },

    // 排序
    sortList (tlist) {
      // console.log('tlist', tlist)
      const arr = []
      const noSortArr = []
      const orderArr = ['Order', 'Order2', 'Order3', 'Order4']

      tlist.forEach(item => {
        const orders = 'Order' + (this.activeName === '1' ? '' : this.activeName)
        const columns = 'Column' + (this.activeName === '1' ? '' : this.activeName)

        orderArr.forEach((item, index) => {
          if (item === orders) {
            orderArr.splice(index, 1)
          }
        })

        const order = item[orders]
        const column = item[columns]

        const index = (order - 1) * this.num + (column - 1)

        if (index >= 0) {
          arr[index] = item
        } else {
          if (!item[orderArr[0]] && !item[orderArr[1]] && !item[orderArr[2]]) {
            noSortArr.push(item)
          }
        }
      })

      const list = arr.filter(item => {
        return item
      })

      return list
    },

    // 输入框获得焦点
    onFocus (order, column, num) {
      // console.log(order, column)
      this.current = order * num + column
    },
    // 上
    keyUp (order, column, num) {
      let nextRef = 'mobile' + (order - 1) + column

      if (!this.$refs[nextRef] || this.$refs[nextRef].length === 0) {
        if (this.remainder > 0 && column > this.remainder) {
          nextRef = 'mobile' + (this.row - 2) + (column - 1)

          if (!this.$refs[nextRef] || this.$refs[nextRef].length === 0) return

          this.current = (this.row - 2) * num + (column - 1)
        } else {
          nextRef = 'mobile' + (this.row - 1) + (column - 1)

          if (!this.$refs[nextRef]) return

          this.current = (this.row - 1) * num + (column - 1)
        }
      } else {
        this.current = (order - 1) * num + column
      }
      this.$refs[nextRef][0].focus()
    },
    // 下
    keyDown (order, column, num) {
      let nextRef = 'mobile' + (order + 1) + column

      if (!this.$refs[nextRef] || this.$refs[nextRef].length === 0) {
        nextRef = 'mobile0' + (column + 1)

        if (!this.$refs[nextRef] || this.$refs[nextRef].length === 0) return

        this.current = column + 1
      } else {
        this.current = (order + 1) * num + column
      }

      this.$refs[nextRef][0].focus()
    },
    // 左
    keyLeft (order, column, num) {
      let nextRef = 'mobile' + order + (column - 1)
      // console.log(order, column)

      if (!this.$refs[nextRef] || this.$refs[nextRef].length === 0) {
        nextRef = 'mobile' + (order - 1) + (num - 1).toString()

        if (!this.$refs[nextRef] || this.$refs[nextRef].length === 0) return

        this.current = (order - 1) * num + (num - 1)
        // console.log(this.current)
      } else {
        this.current = order * num + (column - 1)
        // console.log(this.current)
      }
      this.$refs[nextRef][0].focus()
    },
    // 右
    keyRight (order, column, num) {
      let nextRef = 'mobile' + order + (column + 1)
      if (!this.$refs[nextRef] || this.$refs[nextRef].length === 0) {
        nextRef = 'mobile' + (order + 1) + '0'

        if (!this.$refs[nextRef] || this.$refs[nextRef].length === 0) return

        this.current = (order + 1) * num
        // console.log(this.current)
      } else {
        this.current = order * num + (column + 1)
        // console.log(this.current)
      }
      this.$refs[nextRef][0].focus()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .price > input.el-input__inner {
  color: #e33ae6 !important;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
}
::v-deep .price > input::-webkit-outer-spin-button,
::v-deep .price > input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep .price > input[type='number'] {
  -moz-appearance: textfield;
}
.TableTop {
  display: flex;
  margin-top: 10px;
  .TableTop_tit {
    width: 200px;
  }
  .TableTop_btn {
    flex: 2;
    margin-left: 300px;
  }
}
.paixu {
  margin-top: 12px;
  display: flex;
  .paixu_btn {
    margin-left: 20px;
    margin-top: 12px;
  }
}
.divTable {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  .numIndex {
    width: 40px;
    .order {
      text-align: center;
      color: blue;
      height: 24px;
      line-height: 24px;
    }
  }
  .ulTables {
    flex: 2;
    display: flex;
    flex-wrap: wrap;
  }
  .ulTable {
    width: 210px;
    height: 24px;
    line-height: 24px;
    display: flex;
    position: relative;

    .reference_price {
      height: 24px;
      padding: 0 4px;
      background-color: #efefef;
      display: none;
      position: absolute;
      top: -48px;
      left: 0;
    }

    .other_price {
      // width: 100%;
      height: 24px;
      padding: 0 4px;
      background-color: #efefef;
      display: none;
      position: absolute;
      top: -24px;
      left: 0;
    }

    li.red:hover {
      overflow: inherit;
    }
    .title {
      width: 43%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-left: 5px;
      font-size: 13px;
    }
    .price {
      width: 35%;
    }
    .red {
      width: 45%;
      color: red;
      font-weight: bold;
      font-size: 14px;
    }
    .red:hover {
      overflow: none;
    }
  }
}
.titltTab {
  width: 100%;
  border-bottom: 2px solid #cccccc;
  display: flex;
  text-align: center;
  font-size: 14px;
  .titltTab_item {
    margin: 0 20px;
    cursor: pointer;
  }
  .titltTab_item:hover {
    color: #409eff;
  }
}
.blue {
  color: #409eff;
  padding-bottom: 10px;
  border-bottom: 2px solid #409eff;
}
</style>
