<!--
 * @Description: 商品发布
-->
<template>
  <div>
    <el-table ref="TableListRef" class="TableList" border :data="List" v-loading="loading">
      <el-table-column prop="Category1" label="分类" align="center">
        <template slot-scope="scope">
          {{ scope.row.Category1 }}
        </template>
      </el-table-column>

      <el-table-column prop="Name" label="名称" align="center">
        <template slot-scope="scope">
          {{ scope.row.Name }}
        </template>
      </el-table-column>

      <el-table-column prop="InPrice" label="进货价" align="center">
        <template slot-scope="scope">
          {{ scope.row.InPrice }}
        </template>
      </el-table-column>

      <el-table-column prop="Price" label="零售价" align="center">
        <template slot-scope="scope">
          {{ scope.row.Price }}
        </template>
      </el-table-column>

      <el-table-column prop="Date" label="添加时间" align="center">
        <template slot-scope="scope">
          {{ new Date(scope.row.Date) | parseTime }}
        </template>
      </el-table-column>

      <el-table-column prop="Image" label="产品图片" align="center">
        <template slot-scope="scope">
          <el-image
            style="width: 40px; height: 40px"
            :src="scope.row.DetailImage.split(',')[0]"
            fit="contain"
            v-if="scope.row.DetailImage"
            :preview-src-list="[scope.row.DetailImage.split(',')[0]]"
          ></el-image>
          <el-image
            style="width: 40px; height: 40px"
            :src="scope.row.Image"
            fit="contain"
            :preview-src-list="[scope.row.Image]"
            v-else
          ></el-image>
        </template>
      </el-table-column>
    </el-table>

    <div class="fenye1">
      <!-- 分页 -->
      <el-pagination
        class="TablePagination"
        background
        @current-change="handleCurrentChange"
        :current-page="pageindex"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GoodsRelease',
  props: {
    merchantsInfo: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      List: [],

      pageindex: 1,

      total: 0,

      loading: false
    }
  },
  created () {
    this.getList()
  },
  computed: {},
  mounted () {},
  methods: {
    async getList (pageindex = 1) {
      this.loading = true
      this.List = []
      const { data: res } = await this.$api.release.goodsList({
        category1: '',
        loginName: this.merchantsInfo.LoginName,
        pageindex,
        pagesize: 10,
        type: 2,
        status: 1
      })
      console.log('商户产品库', res)
      this.List = res.list
      this.total = res.count
      this.loading = false
    },

    handleCurrentChange (newPage) {
      this.pageindex = newPage
      this.List = []
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
      this.getList(newPage)
    }
  }
}
</script>

<style lang="scss" scoped></style>
