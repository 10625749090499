<!--
 * @Description: 富文本编辑器
-->
<template>
  <vue-tinymce :setup="setup" :setting="setting" />
</template>

<script>
export default {
  name: 'tinymce',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      hasChange: false,
      // 编辑器配置
      setting: {
        language: 'zh_CN',
        min_height: 600,
        max_height: 780,
        autoresize_bottom_margin: 10,
        placeholder: '从这里开始编辑文案，图文段落分明，排版清晰，避免使用水印或露出广告！', // 占位文本
        branding: false, // 去水印
        statusbar: false, // 隐藏底部状态栏
        // 指定需加载的插件
        plugins: `hr lists charmap print anchor pagebreak searchreplace insertdatetime
           table emoticons toc image imagetools quickbars indent2em 
           lineheight paste autoresize link preview`,
        // 菜单栏配置
        menubar: 'file edit insert view format table',
        // 工具栏配置
        toolbar_mode: 'sliding',
        toolbar:
          'undo redo | formatselect fontsizeselect backcolor forecolor bold italic underline emoticons | removeformat toc searchreplace | alignleft aligncenter alignright alignjustify | outdent indent indent2em lineheight | numlist bullist | table link | image',
        // 字体配置
        fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
        font_formats:
          '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;',
        // 快速工具栏配置
        quickbars_selection_toolbar: 'removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor',
        quickbars_insert_toolbar: false,
        // 选中图片时的快捷栏
        quickbars_image_toolbar: 'alignleft aligncenter alignright | imageoptions',
        // 配置右键关联菜单
        contextmenu: '',
        // 在编辑器中屏蔽浏览器本身的右键菜单
        contextmenu_never_use_native: true,
        // 配置复制(可带上样式)
        paste_webkit_styles: 'all',
        // 配置word
        paste_word_valid_elements: '*[*]',
        paste_convert_word_fake_lists: false,
        // 禁用自动转化URL
        convert_urls: false,

        // 数据双向绑定
        init_instance_callback: editor => {
          if (this.value !== '') {
            editor.setContent(this.value)
          }

          editor.on('Change KeyUp SetContent', () => {
            // console.log('触发')
            this.hasChange = true
            this.$emit('eventTinymce', editor.getContent())
          })
        },

        // 强行把图片删除
        paste_preprocess: (plugin, args) => {
          const imgReg = /<img.*?(?:>|\/>)/gi // 匹配图片中的img标签
          args.content = args.content.replace(imgReg, '')
          // console.log(args.value)
        },

        // 图片/视频上传逻辑
        // http://tinymce.ax-z.cn/configure/file-image-upload.php#images_dataimg_filter
        file_picker_types: 'image',
        file_picker_callback: (callback, value, meta) => {
          this.upload(callback, value, meta, this)
        }
      }
    }
  },
  methods: {
    // 编辑器自定义元素
    setup (editor) {
      // console.log('editor', editor)
      // 富文本的id
      this.tinymceId = editor.id
      this.$emit('tinymceId', editor.id)
    },

    // 上传
    upload (callback, value, meta, that) {
      const input = document.createElement('input') // 创建一个隐藏的input
      input.setAttribute('type', 'file')
      // 根据控件类型限制
      input.setAttribute('accept', 'image/*')

      input.onchange = async function () {
        const file = this.files[0] // 选取第一个文件
        console.log('file', file)

        const formData = new FormData()
        formData.append('file', file, file.name)
        const { data: res } = await that.$api.index.uploadimage(formData)
        // console.log('res', res)

        // 将url显示在弹框输入框中
        callback(res)
      }
      // 触发点击
      input.click()
    }
  },
  watch: {
    // 监听 value
    value (val) {
      if (!this.hasChange) {
        window.tinymce.get(this.tinymceId).setContent(val)
      }
    }
  }
}
</script>

<style scoped></style>
