<!--
 * @Description: 新增
-->
<template>
  <Editor :type="0" />
</template>

<script>
import Editor from './components/editor'

export default {
  name: 'articleAdd',
  components: {
    Editor
  },
  data () {
    return {}
  },
  created () {},
  methods: {}
}
</script>

<style lang="scss" scoped>

</style>
