// 报价 offer
import http from '../request.js'

export default {
  // 导入Excel生成每日报价单-酒
  importExcelWine: (data) => {
    return http({
      url: '/api/alcoholoffer/importAlcohol',
      headers: { 'Content-Type': 'multipart/form-data' },
      data
    })
  },
  // 导入Excel生成每日报价单-烟
  importExcelSmoke: (data) => {
    return http({
      url: '/api/alcoholoffer/importTobaccol',
      headers: { 'Content-Type': 'multipart/form-data' },
      data
    })
  },
  // 同步数据
  updatedata: (data) => {
    return http({
      url: '/api/alcoholoffer/updatedata',
      data
    })
  },

  /* 报价广告 */
  // 获取列表
  getList: (data) => {
    return http({
      url: '/api/notice/list_server',
      data
    })
  },

  // 获取详情
  getInfo: (data) => {
    return http({
      url: '/api/notice/get',
      data
    })
  },

  // 编辑
  edit: (data) => {
    return http({
      url: '/api/notice/edit',
      data
    })
  },

  // 修改状态
  edit_status: (data) => {
    return http({
      url: '/api/notice/edit_status',
      data
    })
  },

  // 获取烟酒报价列表
  list: (data) => {
    return http({
      url: '/api/alcoholoffer/list',
      data
    })
  },

  // 添加数据
  addData: (data) => {
    return http({
      url: '/api/alcoholoffer/add',
      data
    })
  },

  // 酒添加数据
  wineAddData: (data) => {
    return http({
      url: '/api/alcoholoffer/addAlcohol',
      data
    })
  },

  // 查看数据
  listServer: (data) => {
    return http({
      url: '/api/alcoholoffer/list_server',
      data
    })
  },

  // 修改数据
  editData: (data) => {
    return http({
      url: '/api/alcoholoffer/edit',
      data
    })
  },

  // 保存草稿
  editDrafts: (data) => {
    return http({
      url: '/api/product/editDrafts',
      data
    })
  },

  // 获取草稿
  getDrafts: (data) => {
    return http({
      url: '/api/product/getDrafts',
      data
    })
  },

  // 修改排序
  updateSort: (data) => {
    return http({
      url: '/api/alcoholoffer/updateSort',
      data
    })
  },

  // 查看差价
  priceDifference: (data) => {
    return http({
      url: 'api/alcoholoffer/priceDifference',
      data
    })
  },

  // 同步报价
  synchronization: (data) => {
    return http({
      url: 'api/alcoholoffer/copy',
      data
    })
  },

  // 保存条形码
  saveBarCode: (data) => {
    return http({
      url: 'api/alcoholoffer/saveBarCode',
      data
    })
  },
  // 批量保存条形码状态
  saveBarCodeStatus: (data) => {
    return http({
      url: 'api/alcoholoffer/saveBarCodeStatus',
      data
    })
  },

  // 获取条形码列表
  barCodeList: (data) => {
    return http({
      url: 'api/alcoholoffer/barCodeList',
      data
    })
  }
}
