import Vue from 'vue'
import VueRouter from 'vue-router'
/* Layout */
import Layout from '@/layout'
// import store from '../store'

Vue.use(VueRouter)

/**
 * 注意: 子菜单只在路径子时出现。长度在= 1
 *
 * hidden: true                   如果设置为true，项目将不会显示在侧边栏中(默认为false)
 * alwaysShow: true               如果设置为true，将始终显示根菜单
 *                                如果没有设置 alwaysShow, 当项目有多个子路径时,它将变成嵌套模式，否则不会显示根菜单
 *
 * redirect: noRedirect           如果设置了noRedirect将不会在面包屑中重定向
 * name:'router-name'             the name is used by <keep-alive> (必须设置! !)
 * meta : {
    title: 'title'               在侧边栏和面包屑中显示的名称
    icon: 'svg-name'/'el-icon-x' 图标显示在侧边栏中
    noCache: true                如果设置为true，则被 <keep-alive> 缓存(默认 false)
    breadcrumb: false            如果设置为false，项目将隐藏在面包屑中(默认为true)
    activeMenu: '/example/list'  如果设置路径，侧边栏将突出显示您设置的路径
  }
 */

const routes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },
  {
    path: '/test',
    component: () => import('@/views/offer/test'),
    hidden: true
  },
  /* 跳转外部链接 */
  // {
  //   path: '/ExternalLink',
  //   component: Layout,
  //   children: [
  //     {
  //       // path: 'https://panjiachen.gitee.io/vue-element-admin-site/zh/',
  //       path: '',
  //       meta: { title: '茶酒趣', icon: 'link' }
  //     }
  //   ]
  // },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/index'),
        meta: { title: '主页', icon: 'el-icon-s-home' },
        hidden: true
      },
      {
        path: 'editPassword',
        name: 'editPassword',
        component: () => import('@/views/login/editPassword'),
        meta: { title: '修改密码' },
        hidden: true
      }
    ]
  },
  /* 栏目管理 */
  {
    path: '/column',
    component: Layout,
    redirect: '/column/index',
    permissions: true,
    meta: {
      title: '栏目管理',
      icon: 'el-icon-menu'
    },
    children: [
      {
        path: 'index',
        name: 'columnIndex',
        component: () => import('@/views/column/index'),
        meta: {
          title: '栏目管理',
          icon: 'el-icon-menu'
        }
      }
    ]
  },
  /* 资讯 */
  {
    path: '/article',
    component: Layout,
    redirect: '/article/index',
    permissions: true,
    meta: {
      title: '资讯文章管理',
      icon: 'el-icon-tickets'
    },
    children: [
      {
        path: 'index',
        name: 'articleIndex',
        component: () => import('@/views/article/index'),
        meta: {
          title: '资讯文章管理',
          icon: 'el-icon-tickets'
        }
      },
      {
        path: 'add',
        name: 'articleAdd',
        component: () => import('@/views/article/add'),
        meta: {
          title: '新增文章',
          activeMenu: '/article/index'
        },
        hidden: true
      },
      {
        path: 'edit',
        name: 'articleEdit',
        component: () => import('@/views/article/edit'),
        meta: {
          title: '编辑文章',
          activeMenu: 'article/index'
        },
        hidden: true
      }
    ]
  },
  /* 轮播图 */
  {
    path: '/advertising',
    component: Layout,
    redirect: '/advertising/index',
    permissions: true,
    meta: {
      title: '轮播图管理',
      icon: 'el-icon-menu'
    },
    children: [
      {
        path: 'index',
        name: 'advertisingIndex',
        component: () => import('@/views/advertising/index'),
        meta: {
          title: '轮播图管理',
          icon: 'el-icon-menu'
        }
      }
    ]
  },
  /* 报价 */
  {
    path: '/offer',
    component: Layout,
    redirect: '/offer/index',
    permissions: true,
    meta: {
      title: '报价管理',
      icon: 'el-icon-data-line'
    },
    children: [
      {
        path: 'index',
        name: 'offerIndex',
        component: () => import('@/views/offer/index'),
        meta: {
          title: '上传报价'
        }
      },
      {
        path: 'announcement',
        name: 'offerAnnouncement',
        component: () => import('@/views/offer/announcement'),
        meta: {
          title: '报价公告'
        }
      },
      {
        path: 'amendment',
        name: 'amendmentIndex',
        component: () => import('@/views/offer/amendmentIndex'),
        meta: {
          title: '今日报价'
        }
      },
      // {
      //   path: 'wineOffer',
      //   name: 'wineOfferIndex',
      //   component: () => import('@/views/offer/wineOfferIndex'),
      //   meta: {
      //     title: '今日酒市'
      //   }
      // },
      {
        path: 'offerView',
        name: 'offerViewIndex',
        component: () => import('@/views/offer/offerViewIndex'),
        meta: {
          title: '过往行情'
        }
      },
      {
        path: 'DHQPrice',
        name: 'DHQPriceIndex',
        component: () => import('@/views/offer/DHQPrice'),
        meta: {
          title: '大数据'
        }
      }
    ]
  },
  /* 会员 */
  {
    path: '/members',
    component: Layout,
    redirect: '/members/index',
    permissions: true,
    meta: {
      title: '会员管理',
      icon: 'el-icon-s-custom'
    },
    children: [
      {
        path: 'index',
        name: 'membersIndex',
        component: () => import('@/views/members/index'),
        meta: {
          title: '会员列表'
        }
      },
      {
        path: 'access',
        name: 'accessIndex',
        component: () => import('@/views/members/access'),
        meta: {
          title: '会员访问'
        }
      },
      {
        path: 'h5Jump',
        name: 'h5JumpIndex',
        component: () => import('@/views/members/h5Jump'),
        meta: {
          title: 'H5跳转'
        }
      },
      {
        path: 'topUpWater',
        name: 'topUpWaterIndex',
        component: () => import('@/views/members/topUpWater'),
        meta: {
          title: '充值流水管理'
        }
      },
      {
        path: 'package',
        name: 'packageIndex',
        component: () => import('@/views/members/package'),
        meta: {
          title: '套餐管理'
        }
      }
    ]
  },
  /* 订单 */
  {
    path: '/order',
    component: Layout,
    redirect: '/order/order',
    permissions: true,
    meta: {
      title: '积分商城',
      icon: 'el-icon-s-custom'
    },
    children: [
      {
        path: 'order',
        name: 'orderIndex',
        component: () => import('@/views/order/order'),
        meta: {
          title: '订单列表'
        }
      },
      {
        path: 'goods',
        name: 'goodsIndex',
        component: () => import('@/views/order/goods'),
        meta: {
          title: '商品列表'
        }
      }
    ]
  },
  /* 佣金管理 */
  {
    path: '/commission',
    component: Layout,
    redirect: '/commission/commission',
    permissions: true,
    meta: {
      title: '佣金管理',
      icon: 'el-icon-s-custom'
    },
    children: [
      {
        path: 'commission',
        name: 'commissionIndex',
        component: () => import('@/views/commission/commission'),
        meta: {
          title: '佣金提现管理'
        }
      },
      {
        path: 'commissionRecord',
        name: 'commissionRecordIndex',
        component: () => import('@/views/commission/commissionRecord'),
        meta: {
          title: '佣金记录'
        }
      }
    ]
  },
  /* 商圈管理 */
  {
    path: '/businessManagement',
    component: Layout,
    redirect: '/businessManagement/index',
    permissions: true,
    meta: {
      title: '商圈管理',
      icon: 'el-icon-s-custom'
    },
    children: [
      {
        path: 'index',
        name: 'businessManagementIndex',
        component: () => import('@/views/businessManagement/index'),
        meta: {
          title: '商户管理'
        }
      },
      {
        path: 'releaseGoods',
        name: 'releaseGoodsIndex',
        component: () => import('@/views/businessManagement/releaseGoods'),
        meta: {
          title: '商品发布'
        }
      },
      {
        path: 'release',
        name: 'releaseIndex',
        component: () => import('@/views/businessManagement/release'),
        meta: {
          title: '文章发布'
        }
      },
      {
        path: 'release2',
        name: 'release2Index',
        component: () => import('@/views/businessManagement/release2'),
        meta: {
          title: '免费发布'
        }
      },
      {
        path: 'album',
        name: 'albumIndex',
        component: () => import('@/views/businessManagement/album'),
        meta: {
          title: '相册管理'
        }
      }
      // {
      //   path: 'recommended',
      //   name: 'recommendedIndex',
      //   component: () => import('@/views/businessManagement/recommended'),
      //   meta: {
      //     title: '推荐管理'
      //   }
      // }
    ]
  },
  /* 产品管理 */
  {
    path: '/product',
    component: Layout,
    redirect: '/product/index',
    permissions: true,
    meta: {
      title: '产品管理',
      icon: 'el-icon-menu'
    },
    children: [
      {
        path: 'index',
        name: 'productIndex',
        component: () => import('@/views/product/index'),
        meta: {
          title: '产品管理',
          icon: 'el-icon-menu'
        }
      }
    ]
  },
  /* 商品库管理 */
  {
    path: '/storeGoods',
    component: Layout,
    redirect: '/storeGoods/index',
    permissions: true,
    meta: {
      title: '商品库管理',
      icon: 'el-icon-menu'
    },
    children: [
      {
        path: 'index',
        name: 'storeGoodsIndex',
        component: () => import('@/views/storeGoods/index'),
        meta: {
          title: '商品库管理',
          icon: 'el-icon-menu'
        }
      }
    ]
  },
  /* 条码库管理 */
  {
    path: '/code',
    component: Layout,
    redirect: '/code/index',
    permissions: true,
    meta: {
      title: '条码库管理',
      icon: 'el-icon-menu'
    },
    children: [
      {
        path: 'index',
        name: 'codeIndex',
        component: () => import('@/views/code/index'),
        meta: {
          title: '条码列表'
        }
      },
      {
        path: 'status',
        name: 'statusIndex',
        component: () => import('@/views/code/status'),
        meta: {
          title: '开单导出列表'
        }
      }
    ]
  },
  /* 图片上传管理 */
  {
    path: '/pictureUpload',
    component: Layout,
    redirect: '/pictureUpload/index',
    permissions: true,
    meta: {
      title: '图片上传管理',
      icon: 'el-icon-menu'
    },
    children: [
      {
        path: 'index',
        name: 'pictureUploadIndex',
        component: () => import('@/views/pictureUpload/index'),
        meta: {
          title: '图片上传管理',
          icon: 'el-icon-menu'
        }
      }
    ]
  },
  /* 后台总管 */
  {
    path: '/management',
    component: Layout,
    redirect: '/management/index',
    permissions: true,
    meta: {
      title: '后台总管',
      icon: 'el-icon-s-custom'
    },
    children: [
      {
        path: 'account',
        name: 'accountIndex',
        component: () => import('@/views/management/account.vue'),
        meta: {
          title: '后台账号管理'
        }
      },
      {
        path: 'index',
        name: 'managementIndex',
        component: () => import('@/views/management/index.vue'),
        meta: {
          title: '会员管理'
        }
      }
    ]
  },
  // 404页面必须放在最后
  { path: '*', redirect: '/404', hidden: true }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   console.log(to.path.split('/'))

//   const isLogin = store.state.user.token
//   const info = store.state.user.info || { Categorys: false }

//   if (to.path === '/login') return next()

//   if (!isLogin && to.path !== '/login') return next('login')

//   if (!info.Categorys) return next('login')

//   try {
//     router.options.routes.forEach(item => {
//       if (to.path.split('/')[1] === 'home' || to.path.split('/')[1] === '') throw Error('主页')

//       if (item.path.indexOf(to.path.split('/')[1]) > -1) {
//         if (info.Categorys.indexOf(item.meta.title) > -1) throw Error(item.meta.title)
//         else {
//           throw Error('home')
//         }
//       }
//     })
//   } catch (e) {
//     console.log(e.message)
//     if (e.message === 'home') {
//       next('home')
//     } else {
//       next()
//     }
//   }
// })

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}

export default router
