// 产品管理 product
import http from '../request.js'

export default {
  /* 商圈列表 */
  articleList: data => {
    return http({
      url: '/api/userarticle/article_list_server',
      data
    })
  },

  /* 商圈列表 */
  articleAudit: data => {
    return http({
      url: '/api/userarticle/article_audit',
      data
    })
  },

  /* 商圈列表 */
  articleAdd: data => {
    return http({
      url: '/api/userarticle/article_add',
      data
    })
  },

  /* 商品列表 */
  goodsList: data => {
    return http({
      url: '/api/goods/list_server',
      data
    })
  },

  goodsSave: data => {
    return http({
      url: '/api/goods/save',
      data
    })
  },

  /* 商圈列表 */
  articleEdit: data => {
    return http({
      url: '/api/userarticle/article_edit',
      data
    })
  },

  /* 商圈列表 */
  articleRecommend: data => {
    return http({
      url: '/api/userarticle/article_recommend',
      data
    })
  },

  /* 修改浏览数 */
  articleBrowseCount: data => {
    return http({
      url: '/api/userarticle/article_browseCount',
      data
    })
  },

  /* 获取关注 */
  getStoreSupportList: data => {
    return http({
      url: '/api/userarticle/mystoresupport_list',
      data
    })
  },

  /* 获取粉丝 */
  getStoreFansList: data => {
    return http({
      url: '/api/userarticle/myfans_list',
      data
    })
  }
}
