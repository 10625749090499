<template>
  <div>
    <div class="TableTop">
      <!-- 搜索 -->
      <div class="TableSearch">
        <el-form @submit.native.prevent>
          <el-form-item label="日期" class="form-item-b0">
            <el-input v-model="date" placeholder="今日日期" size="small"></el-input>
          </el-form-item>

          <el-form-item label="地区：" class="form-item-b0">
            <el-select v-model="search" allow-create filterable placeholder="请选择地区">
              <el-option v-for="item in areaList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="TableSearch_btn">
          <el-button type="primary" size="small" @click="getList"> 查询 </el-button>
          <el-button type="primary" size="small" @click="updateAreaPrice"> 更新当前区域 </el-button>
          <el-button type="primary" size="small" @click="updatePrice"> 昨日价格更新全部 </el-button>
          <el-button type="success" size="small" @click="updateDraftsPrice"> 更新草稿箱 </el-button>
          <span style="margin-left: 6px">{{ hasDrafts ? '有草稿 可更新草稿箱' : '' }}</span>
        </div>
      </div>
    </div>

    <!-- 倒计时 -->
    <div class="count_down" v-if="time > 0">上传倒计时---{{ time }}秒</div>

    <!-- 表格 -->
    <el-table ref="TableListRef" class="TableList" border :data="selectList">
      <el-table-column prop="productName" label="名称" align="center"></el-table-column>

      <el-table-column prop="oldName" label="酒茶查名称" align="center"></el-table-column>

      <el-table-column prop="price" label="价格" align="center" width="200px">
        <template slot-scope="scope">
          <el-input v-model="scope.row.price" size="mini" placeholder="请输入价格"></el-input>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" width="140" fixed="right">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onEditPrice(scope.row)"> 修改价格 </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { dataList } from './js/DHQOfferData.js'
console.log(dataList)

export default {
  name: 'DHQPrice',
  data() {
    return {
      date: this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}'),

      search: '陕西',

      list: [],

      hasDrafts: false,

      time: 0,
      isUpdate: false
    }
  },

  async created() {
    console.log(this.date)
    this.getList()

    const { data } = await this.$api.offer.getDrafts({
      type: 6
    })
    console.log(data)
    if (data) {
      this.hasDrafts = !!data.Content
    } else {
      this.hasDrafts = false
    }
  },

  computed: {
    areaList() {
      let areaList = []

      this.list.forEach((item) => {
        if (!areaList.includes(item.Factory)) {
          areaList.push(item.Factory)
        }
      })

      areaList = areaList.map((item) => {
        return {
          label: item,
          value: item
        }
      })

      return areaList
    },

    selectList() {
      const list = this.list.filter((item) => {
        return item.Factory === this.search
      })

      return list
    }
  },

  methods: {
    async getList() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      const { data } = await this.$api.offer.list({
        category1: '全部',
        date: this.date,
        pageindex: 1,
        type: 6
      })
      console.log(data)

      const list = []
      data.list.forEach((item) => {
        list.push(...item.foods)
      })
      console.log(list)

      const priceMap = {}

      list.forEach((item) => {
        priceMap[item.Title] = item.DHQValue || 0
      })

      this.list = []

      dataList.forEach((item) => {
        this.list.push({
          ...item,
          price: priceMap[item.productName] || 0
        })
      })

      loading.close()
    },

    async onEditPrice(data) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      this.isUpdate = true
      this.countDown(40)

      try {
        console.log(data)
        const price = Number(data.price)

        if (isNaN(price)) {
          return this.$message.error('请输入正确的价格')
        }

        if (!data.productName) {
          return this.$message.error('名称错误')
        }

        const priceStr = `${data.productName},${price}`
        await this.$api.offer.addData({
          priceStr,
          Date: this.date,
          type: '6'
        })
        this.$message.success('修改成功')
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }

      loading.close()
      this.isUpdate = false
    },

    async updatePrice() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      const date = this.$globalFun.parseTime(new Date().getTime() - 3600 * 1000 * 24, '{y}-{m}-{d}')
      console.log(date)

      const { data } = await this.$api.offer.list({
        category1: '全部',
        date,
        pageindex: 1,
        type: 6
      })
      console.log(data)

      const list = []
      data.list.forEach((item) => {
        list.push(...item.foods)
      })
      console.log(list)

      const priceMap = {}

      list.forEach((item) => {
        priceMap[item.Title] = item.DHQValue || 0
      })

      let str = ''
      for (const key in priceMap) {
        str += `${key},${priceMap[key]}|`
      }
      console.log(str)

      console.log(this.date)

      try {
        await this.$api.offer.addData({
          priceStr: str,
          Date: this.date,
          type: '6'
        })
        this.$message.success('修改成功')
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }

      this.getList()

      loading.close()
    },

    async updateAreaPrice() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.isUpdate = true
      this.countDown(40)

      let str = ''
      this.selectList.forEach((item) => {
        console.log(`${item.productName},${item.price}`)
        str += `${item.productName},${item.price}|`
      })

      try {
        await this.$api.offer.addData({
          priceStr: str,
          Date: this.date,
          type: '6'
        })
        this.$message.success('修改成功')
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }

      this.getList()

      loading.close()
      this.isUpdate = false
    },

    async updateDraftsPrice() {
      this.$alert('是否更新', '提示', {
        confirmButtonText: '确定',
        callback: async (action) => {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          this.isUpdate = true
          this.countDown(40)

          const { data } = await this.$api.offer.getDrafts({
            type: 6
          })
          console.log(data)
          if (!data) {
            loading.close()
            return this.$message.error('暂无草稿')
          }

          const { Content } = data

          if (!Content) {
            loading.close()
            this.isUpdate = false
            return this.$message.error('暂无草稿')
          }

          await this.$api.offer.addData({
            priceStr: Content,
            Date: this.date,
            type: '6'
          })

          await this.$api.offer.editDrafts({
            type: '6',
            content: ''
          })

          loading.close()
          this.isUpdate = false

          // this.$message.success('修改成功')
          this.$alert('更新成功', '提示', {
            confirmButtonText: '确定',
            callback: () => {
              this.getList()
            }
          })
        }
      })
    },

    countDown(time, timeout = 1000) {
      console.log(time)
      this.time = time
      if (time > 0 && this.isUpdate) {
        setTimeout(() => {
          this.countDown(time - 1)
        }, timeout)
      } else {
        this.time = 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.count_down {
  padding: 10px 20px;

  font-size: 32px;

  background-color: #fff;
  border-radius: 10px;

  position: fixed;
  top: 10%;
  left: 40%;

  transform: translateX(-50%);

  z-index: 999999999;
}
</style>
