<!--
 * @Description: 会员管理
-->
<template>
  <div>
    <div class="TableTop">
      <!-- 搜索 -->
      <div class="TableSearch">
        <el-form :model="query" @submit.native.prevent>
          <!-- 用户Id -->
          <el-form-item label="状态" class="form-item-b0">
            <el-select v-model="query.Status" placeholder="请选择状态">
              <el-option label="全部" :value="null"></el-option>
              <el-option label="未采纳" :value="1"></el-option>
              <el-option label="已采纳" :value="2"></el-option>
            </el-select>
            <!-- <el-input v-model="query.Status" placeholder="请选择状态" size="small" clearable
              @keyup.native.enter="getList('place')"></el-input> -->
          </el-form-item>
          <!-- 产品Id -->
          <el-form-item label="产品名称" class="form-item-b0">
            <el-input v-model="query.Name" placeholder="请输入产品名称" size="small" clearable @keyup.native.enter="getList('place')"></el-input>
          </el-form-item>
        </el-form>

        <div class="TableSearch_btn">
          <el-button type="primary" size="small" @click="getList('place')">筛选</el-button>
          <el-button size="small" @click="init()">重置</el-button>
        </div>
      </div>
    </div>
    <!-- 表格 -->
    <el-table ref="TableListRef" class="TableList" border :data="list" v-loading="loading">
      <!-- 用户Id -->
      <el-table-column prop="LoginName" label="手机号" align="center">
        <template slot-scope="scope">
          <div>
            {{ scope.row.LoginName }}
          </div>
          <template>
            <span v-if="ifOverdue(scope.row.VipTime)" style="color: #f00;">VIP已过期</span>
            <span v-else>(VIP {{ scope.row.VipTime | remainingDays }}天)</span>
          </template>
        </template>
      </el-table-column>

      <!-- 产品Id -->
      <el-table-column prop="ProductId" label="产品Id" align="center"></el-table-column>

      <!-- 产品名称 -->
      <el-table-column prop="ProductName" label="产品名称" align="center"></el-table-column>

      <!-- 添加时间 -->
      <el-table-column prop="CreateTime" label="添加时间" align="center">
        <template slot-scope="scope" v-if="scope.row.CreateTime">
          {{ new Date(scope.row.CreateTime) | parseTime('{y}-{m}-{d}') }}
        </template>
      </el-table-column>

      <!-- 状态 -->
      <el-table-column prop="Status" label="状态" align="center">
        <template slot-scope="scope">
          <span v-if="!scope.row.Status || scope.row.Status === 1">未采纳</span>
          <span v-else style="color: #f00;">已采纳</span>
        </template>
      </el-table-column>

      <!-- 上传图片 -->
      <!-- <el-table-column prop="ImgUrl" label="上传图片" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.ImgUrl" style="display: flex; align-items: center; justify-content: center;">
            <div v-for="item in scope.row.ImgUrl.split(',')" :key="item">
              <el-image style="width: 40px; height: 40px; margin-left: 6px;"
                :src="item.substring(0, item.indexOf('&') > -1?item.indexOf('&'):item.length)" fit="contain"
                :preview-src-list="[item.substring(0, item.indexOf('&'))]"
              ></el-image>
            </div>
          </div>
        </template>
      </el-table-column> -->
      <el-table-column prop="ImgUrl" label="上传图片" align="center" width="300">
        <template slot-scope="scope">
          <div style="display: flex; align-items: center; justify-content: center;">
            <div v-for="(item, index) in scope.row.imgList" :key="index">
              <div style="width: 42px; height: 42px; margin-left: 6px; border: 1px solid #333333;" v-if="item.index == 0">
                <el-image style="width: 40px; height: 40px;" :src="item.text" fit="contain"
                :preview-src-list="[item.text]"></el-image>
              </div>
              <div v-else style="width: 42px; height: 42px; margin-left: 6px; border: 1px solid #333333;">
                <div>{{ item.index }}</div>
                <div>{{ item.text }}</div>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>

      <!-- 操作 -->
      <el-table-column label="操作" align="center" width="140" fixed="right">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onEditInfo(scope.row)">
            操作
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="fenye">
      <el-pagination class="TablePagination" background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.pageindex" :page-sizes="[20, 40, 60, 100]" :page-size="query.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </div>

    <!-- 商户管理 -->
    <el-dialog :visible.sync="auditDialog" class="FormDialogBox" width="680px" title="查看上传图片" :close-on-click-modal="false" append-to-body @close="auditCancel()">
      <el-form label-width="auto" :model="editForm" ref="ruleForm">

        <el-form-item label="手机号">
          {{ editForm.LoginName }}
        </el-form-item>

        <el-form-item label="产品名称">
          {{ editForm.ProductName }}
        </el-form-item>

        <el-form-item label="上传图片" v-if="editForm.ImgUrl">
          <div style="display: flex;">
            <div v-for="(item, index) in editForm.imgList" :key="index">
              <div style="width: 42px; height: 42px; margin-left: 6px; border: 1px solid #333333;" v-if="item.index == 0">
                <el-image style="width: 40px; height: 40px;" :src="item.text" fit="contain"
                :preview-src-list="[item.text]"></el-image>
              </div>
              <div v-else style="width: 42px; height: 42px; margin-left: 6px; border: 1px solid #333333; font-size: 12px;
                line-height: 21px; text-align: center;">
                <div>{{ item.index }}</div>
                <div>{{ item.text }}</div>
              </div>
            </div>
          </div>
          <!-- <el-image style="width: 100px; height: 100px; margin-left: 6px;" v-for="item in editForm.ImgUrl.split(',')" :key="item"
            :src="item.substring(0, item.indexOf('&') > -1?item.indexOf('&'):item.length)" fit="contain"
            :preview-src-list="[item.substring(0, item.indexOf('&'))]"></el-image> -->
        </el-form-item>

        <el-form-item label="状态">
          <el-radio v-model="editForm.Status" :label="1">未采纳</el-radio>
          <el-radio v-model="editForm.Status" :label="2">已采纳</el-radio>
        </el-form-item>

        <el-form-item label="备注">
          <el-input v-model="editForm.Remark" placeholder="备注"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="auditDialog = false">取 消</el-button>
        <el-button type="primary" @click="editDetermine()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'membersIndex',
  data () {
    return {
      query: {
        pageindex: 1,
        pagesize: 20,
        // Status: null,
        Name: ''
      },

      list: [],

      loading: false,
      total: 0,

      editForm: {},
      auditDialog: false,

      noImgList: [
        {
          text: '正面图',
          index: 0
        },
        {
          text: '背面图',
          index: 1
        },
        {
          text: '条码图',
          index: 2
        },
        {
          text: '其他',
          index: 3
        },
        {
          text: '其他',
          index: 4
        },
        {
          text: '其他',
          index: 5
        }
      ]
    }
  },
  created () {
    this.getList('place')
  },
  methods: {
    // 初始化
    init () {
      this.query.Status = null
      this.query.Name = ''

      this.getList('place')
    },

    // 获取列表
    async getList (place) {
      // 重置页数
      if (place) {
        this.query.pageindex = 1
        // 滚动条回到顶部
        this.$nextTick(() => {
          this.$refs.TableListRef.bodyWrapper.scrollTop = 0
        })
      }

      console.log(this.query)

      const { data: res } = await this.$api.product.userUploadImg(this.query)
      // console.log('获取图片上传列表', res)

      res.list.forEach(item => {
        const list = item.ImgUrl.split(',')
        const arr = [
          {
            text: '正面图',
            index: 1
          },
          {
            text: '背面图',
            index: 2
          },
          {
            text: '条码图',
            index: 3
          },
          {
            text: '其他',
            index: 4
          },
          {
            text: '其他',
            index: 5
          },
          {
            text: '其他',
            index: 6
          }
        ]
        list.forEach((e, i) => {
          const index = e.split('&')[1]
          arr[index].index = 0
          arr[index].text = e.split('&')[0]
        })
        item.imgList = arr
      })

      console.log('获取图片上传列表', res)

      this.loading = false
      this.list = res.list
      this.total = res.count
    },

    auditCancel () {
      this.auditDialog = false
      this.editForm = {}
    },

    onEditInfo (item) {
      const form = this.$globalFun.deepClone(item)
      this.editForm = form
      this.auditDialog = true
      console.log(this.editForm)
    },

    async editDetermine () {
      console.log(this.editForm)

      const { data: res } = await this.$api.product.userSaveImg(this.editForm)
      console.log('修改状态', res)

      this.$message.success('修改成功')
      this.getList()
      this.editForm = {}
      this.auditDialog = false
    },

    // 每页显示条数和当前页码
    handleSizeChange (newSize) {
      this.query.pagesize = newSize
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    },
    handleCurrentChange (newPage) {
      this.query.pageindex = newPage
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    },

    // 判断是否过期
    ifOverdue (time) {
      // console.log('time', time)
      const nowDate = new Date()
      const date = new Date(time).getTime() - nowDate.getTime()

      return date < 0
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
