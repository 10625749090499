<!--
 * @Description: 会员管理
-->
<template>
  <div>
    <div class="TableTop">
      <!-- 搜索 -->
      <div class="TableSearch">
        <el-form :model="query" @submit.native.prevent>
          <el-form-item label="类型：" class="form-item-b0">
            <el-select v-model="query.search" placeholder="请选择类型">
              <el-option label="H5跳转" value="H5跳转"></el-option>
              <el-option label="编辑报价单" value="编辑报价单"></el-option>
              <el-option label="保存条码信息" value="保存条码信息"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="时间：" class="form-item-b0">
            <el-date-picker
              v-model="beginDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>

        <div class="TableSearch_btn">
          <el-button type="primary" size="small" @click="getList('place')">筛选</el-button>
          <el-button size="small" @click="init()">重置</el-button>
        </div>

        <div style="margin-left: 20px; font-size: 36px;">
          {{ total }}
        </div>
      </div>
    </div>

    <!-- 表格 -->
    <el-table ref="TableListRef" class="TableList" border :data="list" v-loading="loading">
      <el-table-column label="序" align="center" type="index" min-width="50"></el-table-column>

      <el-table-column prop="LoginName" label="手机号" align="center"></el-table-column>

      <el-table-column prop="Date" label="访问时间" align="center">
        <template slot-scope="scope">
          {{ new Date(scope.row.Date) | parseTime('{y}-{m}-{d} {h}:{i}:{s}') }}
        </template>
      </el-table-column>

    </el-table>

    <!-- 分页 -->
    <div class="fenye">
      <el-pagination
        class="TablePagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageindex"
        :page-sizes="[40, 60, 100]"
        :page-size="query.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'h5Jump',
  data () {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },

      list: [],
      total: 0,

      loading: true,

      beginDate: [
        this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}'),
        this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}')
      ],

      query: {
        pageindex: 1,
        pagesize: 40,
        search: 'H5跳转',
        beginTime: '',
        endTime: ''
      }
    }
  },

  created () {
    this.init()
  },

  computed: {},

  methods: {
    // 初始化
    init () {
      this.query.search = 'H5跳转'

      this.getList('place')
    },

    // 获取列表
    async getList (place, day) {
      // 重置页数
      if (place) {
        this.query.pageindex = 1
        // 滚动条回到顶部
        this.$nextTick(() => {
          this.$refs.TableListRef.bodyWrapper.scrollTop = 0
        })
      }

      if (!this.beginDate || !this.beginDate.length) {
        this.query.beginTime = this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}')
        this.query.endTime = this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}')
      } else {
        this.query.beginTime = this.beginDate[0]
        this.query.endTime = this.beginDate[1]
      }
      console.log(this.query)

      const { data: res } = await this.$api.user.h5Jump(this.query)
      console.log('获取列表', res)

      this.list = res.list
      this.total = res.count
      this.loading = false
    },

    // 每页显示条数和当前页码
    handleSizeChange (newSize) {
      this.query.pagesize = newSize
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    },
    handleCurrentChange (newPage) {
      this.query.pageindex = newPage
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table {
  font-size: 12px !important;
}
</style>
