// 主页接口
import http from '../request.js'

export default {
  // 上传图片
  uploadimage: data => {
    return http({
      headers: { 'Content-Type': 'multipart/form-data' },
      url: '/api/current/uploadimage',
      data
    })
  }

  // // post
  // update: data => {
  //   return http({
  //     // baseURL: 'http://baidu.com/', // 直接覆盖 baseURL
  //     // headers: { 'Content-Type': 'multipart/form-data' }, // 修改headers
  //     url: '/api/update',
  //     data
  //   })
  // },

  // // get
  // getInfo: params => {
  //   return http({
  //     method: 'get',
  //     url: '/api/list',
  //     params
  //   })
  // },

  // // 导出
  // export: data => {
  //   return http({
  //     url: '/api/export',
  //     data,
  //     responseType: 'arraybuffer'
  //   })
  // }
}
