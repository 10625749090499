<!--
 * @Description: 报价管理
-->
<template>
  <div>
    <div class="topTitle">*上传完报价后，请点击同步！</div>

    <div class="box" style="margin-bottom: 20px">
      <span class="title">酒报价：</span>
      <el-upload
        ref="importExcelWineRef"
        action=""
        :on-change="handleChange1"
        :limit="1"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
        :auto-upload="false"
        :show-file-list="false"
      >
        <el-button type="primary"> 上传<i class="el-icon-upload el-icon--right"></i> </el-button>
      </el-upload>
      <el-upload
        action=""
        :on-change="imgChange1"
        :limit="1"
        :auto-upload="false"
        :show-file-list="true"
        list-type="picture"
      >
        <el-button type="primary" style="margin-left: 20px">
          上传图片<i class="el-icon-upload el-icon--right"></i>
        </el-button>
      </el-upload>
    </div>

    <div style="margin-bottom: 40px">
      最新酒报价表：
      <a style="color: blue" href="https://api.chajiuqu.com/uploadFile/alcohol.xlsx">点击下载</a>
    </div>

    <!-- <div class="box">
      <span class="title">烟报价：</span>
      <el-upload
        ref="importExcelSmokeRef"
        action=""
        :on-change="handleChange2"
        :limit="1"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
        :auto-upload="false"
        :show-file-list="false"
      >
        <el-button type="danger"> 上传<i class="el-icon-upload el-icon--right"></i> </el-button>
      </el-upload>
      <el-upload
        action=""
        :on-change="imgChange2"
        :limit="1"
        :auto-upload="false"
        :show-file-list="true"
        list-type="picture"
      >
        <el-button type="danger" style="margin-left: 20px;"> 上传图片<i class="el-icon-upload el-icon--right"></i> </el-button>
      </el-upload>
    </div> -->

    <el-button type="warning" @click="onSynchronousData">立即同步数据</el-button>

    <el-button type="primary" @click="onSynchronousData2">立即同步至潮汕酒肆</el-button>
  </div>
</template>

<script>
export default {
  name: 'offerIndex',
  data() {
    return {}
  },
  created() {},
  methods: {
    // 上传
    handleChange1(file) {
      // console.log('file1', file)
      this.onUpload(1, file.raw)
    },
    handleChange2(file) {
      // console.log('file2', file)
      this.onUpload(2, file.raw)
    },
    imgChange1(file) {
      console.log(file)
    },
    imgChange2(file) {
      console.log(file)
    },

    async onUpload(type, file) {
      // type 1:酒 2:烟
      const loading = this.$loading({
        lock: true,
        text: '上传中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      try {
        const form = new FormData()
        form.append('file', file)

        if (type === 1) {
          await this.$api.offer.importExcelWine(form)
        } else {
          await this.$api.offer.importExcelSmoke(form)
        }

        this.$alert(`${type === 1 ? '酒报价' : '烟报价'}上传成功`, '提示', {
          confirmButtonText: '确定'
        })
      } catch (error) {
        console.log('上传失败', error)
        this.$message.error('上传失败')
      }

      loading.close()

      if (type === 1) {
        this.$refs.importExcelWineRef.clearFiles()
      } else {
        this.$refs.importExcelSmokeRef.clearFiles()
      }
    },

    // 同步
    async onSynchronousData() {
      await this.$api.offer.updatedata()

      this.$alert('同步成功', '提示', {
        confirmButtonText: '确定'
      })
    },

    async onSynchronousData2() {
      console.log('同步数据')
      const loading = this.$loading({
        lock: true,
        text: '正在同步数据',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      try {
        const { data } = await this.$api.offer.synchronization({
          type: 99
        })
        console.log('同步成功', data)
      } catch (error) {
        this.$message.success('同步失败, 请重试')
      } finally {
        loading.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.topTitle {
  margin-bottom: 40px;
  font-size: 16px;
  color: #f00;
}

.box {
  display: flex;
  margin-bottom: 80px;

  .title {
    font-weight: bold;
    font-size: 36px;
    color: #000;
  }
}
</style>
