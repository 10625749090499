<!--
 * @Description: 发布历史
-->
<template>
  <div>
    <el-table ref="TableListRef" class="TableList" border :data="List" v-loading="loading">
      <el-table-column prop="Title" label="发布内容" align="center">
        <template slot-scope="scope">
          <div>
            {{ scope.row.Title && scope.row.Title.slice(0, 10) }}
            <span v-if="scope.row.Title && scope.row.Title.length > 10">...</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="IsRecommend" label="是否推荐/同省" align="center">
        <template slot-scope="scope">
          <template>
            <div v-if="scope.row.IsRecommend">推荐: {{ scope.row.IsRecommend ? '是' : '' }}</div>
            <div v-if="scope.row.IsProvince === 2">
              {{ scope.row.Province }}: {{ scope.row.IsProvince === 2 ? '同省' : '' }}
            </div>
          </template>
        </template>
      </el-table-column>

      <el-table-column prop="IsProvince" label="同省申请" align="center">
        <template slot-scope="scope">
          <template>
            <span>{{ scope.row.IsProvince === 1 ? '申请(' + scope.row.Province + ')' : '' }}</span>
          </template>
        </template>
      </el-table-column>

      <el-table-column prop="ReleaseTime" label="发布时间" align="center">
        <template slot-scope="scope">
          <template>
            <span>{{ new Date(scope.row.ReleaseTime) | parseTime }}</span>
          </template>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="fenye1">
      <el-pagination
        class="TablePagination"
        background
        @current-change="handleCurrentChange"
        :current-page="pageindex"
        :page-size="20"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReleaseList',
  props: {
    type: {
      type: String,
      default: '1'
    },
    StoreName: {
      type: String,
      required: true
    },
    merchantsInfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      List: [],

      pageindex: 1,

      total: 0,

      loading: false
    }
  },
  created () {
    this.getList()
  },
  methods: {
    async getList (pageindex = 1) {
      this.loading = true
      const { data: res } = await this.$api.release.articleList({
        pageindex,
        name: this.StoreName,
        status: 2
      })
      this.List = res.list
      this.total = res.count
      this.loading = false
    },

    handleCurrentChange (newPage) {
      this.pageindex = newPage
      this.List = []
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
      this.getList(newPage)
    }
  }
}
</script>

<style lang="scss" scoped></style>
