<!--
 * @Description: 会员管理
-->
<template>
  <div>
    <div class="TableTop">
      <!-- 搜索 -->
      <div class="TableSearch">
        <!-- <div class="TableSearch_btn">
          <el-button type="danger" size="small" @click="getList('place', 1)">删除</el-button>
          <el-button type="primary" size="small" @click="getList('place', 2)">提审中</el-button>
          <el-button type="success" size="small" @click="getList('place', 3)">审核通过</el-button>
          <el-button type="warning" size="small" @click="getList('place', 4)">审核退回</el-button>
        </div>-->

        <el-form :model="query" @submit.native.prevent>
          <el-form-item label class="form-item-b0">
            <el-button
              :type="checkBtnCurrent == 1 ? 'danger' : ''"
              size="small"
              @click="getList('place', 1)"
            >删除</el-button>
            <el-button
              :type="checkBtnCurrent == 2 ? 'primary' : ''"
              size="small"
              @click="getList('place', 2)"
            >提审中</el-button>
            <el-button
              :type="checkBtnCurrent == 3 ? 'success' : ''"
              size="small"
              @click="getList('place', 3)"
            >审核通过</el-button>
            <el-button
              :type="checkBtnCurrent == 4 ? 'warning' : ''"
              size="small"
              @click="getList('place', 4)"
            >审核退回</el-button>
          </el-form-item>

          <el-form-item label="是否推荐：" class="form-item-b0">
            <el-select v-model="query.isRecommended" placeholder="请选择状态">
              <el-option label="是" :value="true"></el-option>
              <el-option label="否" :value="false"></el-option>
              <el-option label="取消" :value="null"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="是否同省：" class="form-item-b0">
            <el-select v-model="query.isProvince" placeholder="请选择状态">
              <el-option label="是" :value="2"></el-option>
              <el-option label="申请中" :value="1"></el-option>
              <el-option label="全部" :value="null"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="商户名：" class="form-item-b0">
            <el-input v-model="query.name" placeholder="商户名称"></el-input>
          </el-form-item>

          <el-form-item label="省份：" class="form-item-b0">
            <el-select
              v-model="queryProvince"
              clearable
              @change="handleChange1"
              filterable
              style="width: 100%;"
            >
              <el-option
                v-for="item in regionOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="城市：" class="form-item-b0">
            <el-select
              v-model="queryCity"
              clearable
              @change="handleChange2"
              filterable
              style="width: 100%;"
            >
              <el-option
                v-for="item in regionOptions1"
                :key="item.value"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="发布时间：" class="form-item-b0">
            <el-date-picker
              v-model="beginDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="近期" class="form-item-b0">
            <span
              :class="current === 1 ? 'blue' : ''"
              style="margin-left: 20px; cursor: pointer;"
              @click="getList('place', null, 7)"
            >7天</span>
            <span
              :class="current === 2 ? 'blue' : ''"
              style="margin: 0 20px; cursor: pointer;"
              @click="getList('place', null, 10)"
            >10天</span>
            <span
              :class="current === 3 ? 'blue' : ''"
              style="cursor: pointer;"
              @click="getList('place', null, 30)"
            >30天</span>
          </el-form-item>
        </el-form>
        <div class="TableSearch_btn">
          <el-button type="primary" size="small" @click="getList('place')">筛选</el-button>
          <el-button size="small" @click="init()">重置</el-button>
        </div>
      </div>
    </div>
    <!-- 表格 -->
    <el-table ref="TableListRef" class="TableList" border :data="list" v-loading="loading">
      <!-- <el-table-column prop="Id" label="产品Id" align="center"></el-table-column> -->

      <el-table-column prop="LoginName" label="手机号" align="center"></el-table-column>

      <!-- <el-table-column prop="Type" label="类型" align="center">
        <template slot-scope="scope">
          <template>
            <span>{{ tabList[scope.row.Type] }}</span>
          </template>
        </template>
      </el-table-column>-->

      <el-table-column prop="StoreName" label="商铺名称" align="center">
        <template slot-scope="scope">
          <div
            style="cursor: pointer;"
            @click.stop="query.name = scope.row.StoreName"
          >{{ scope.row.StoreName || (scope.row.StoreAuditStatus == 2?'':'未认证') }}</div>
        </template>
      </el-table-column>

      <el-table-column prop="Title" label="发布内容" align="center">
        <template slot-scope="scope">
          <div>
            {{ scope.row.Title?scope.row.Title.slice(0, 10) : '--' }}
            <span
              v-if="scope.row.Title.length > 10"
            >...</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop="IsRecommend"
        label="是否推荐/同省"
        align="center"
        v-if="checkBtnCurrent !== 2"
      >
        <template slot-scope="scope">
          <template>
            <div v-if="scope.row.IsRecommend">推荐: {{ scope.row.IsRecommend ? '是' : '' }}</div>
            <div
              v-if="scope.row.IsProvince === 2"
            >{{ scope.row.Province }}: {{ scope.row.IsProvince === 2 ? '同省' : '' }}</div>
          </template>
        </template>
      </el-table-column>

      <el-table-column prop="IsProvince" label="同省申请" align="center" v-if="checkBtnCurrent !== 2">
        <template slot-scope="scope">
          <template>
            <span>{{ scope.row.IsProvince === 1 ? '申请(' + scope.row.Province + ')' : '' }}</span>
          </template>
        </template>
      </el-table-column>

      <el-table-column prop="ReleaseTime" label="发布时间" align="center">
        <template slot-scope="scope">
          <template>
            <span>{{ new Date(scope.row.ReleaseTime) | parseTime }}</span>
          </template>
        </template>
      </el-table-column>

      <el-table-column prop="UpdateTime" label="修改时间" align="center">
        <template slot-scope="scope">
          <template>
            <span>{{ new Date(scope.row.UpdateTime) | parseTime }}</span>
          </template>
        </template>
      </el-table-column>

      <!-- <el-table-column prop="Integral" label="商铺类型" align="center">
        <template slot-scope="scope">
          <template>
            <span>{{ scope.row.Type }}</span>
          </template>
        </template>
      </el-table-column>-->
      <el-table-column label="操作" align="center" width="140" fixed="right">
        <template slot-scope="scope">
          <el-button
            :type="Status[scope.row.Status].type"
            size="mini"
            @click="onEditInfo(scope.row)"
          >{{ Status[scope.row.Status].text }}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="fenye">
      <el-pagination
        class="TablePagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageindex"
        :page-sizes="[20, 40, 60, 100]"
        :page-size="query.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <!-- 商户管理 -->
    <el-dialog
      :visible.sync="editPhoneDialog"
      class="FormDialogBox"
      width="680px"
      title="发布内容"
      top="5vh"
      :close-on-click-modal="false"
      @close="editPhoneCancel()"
    >
      <div class="tabs">
        <el-tabs v-model="activeName" stretch @tab-click="handleClick">
          <el-tab-pane label="发布" name="发布"></el-tab-pane>
          <el-tab-pane label="发布历史" name="发布历史"></el-tab-pane>
          <el-tab-pane label="商户资料" name="商户资料"></el-tab-pane>
          <el-tab-pane label="留言" name="留言"></el-tab-pane>
          <el-tab-pane label="关注" name="关注"></el-tab-pane>
          <el-tab-pane label="粉丝" name="粉丝"></el-tab-pane>
        </el-tabs>
      </div>

      <div style="min-height: 600px;" v-if="activeName === '发布'">
        <el-form label-width="auto" :model="editForm" ref="ruleForm">
          <div style="display: flex;">
            <el-form-item label="上推荐">
              <el-switch
                style="margin-top: -3px;"
                v-model="isRecommend"
                active-color="#409EFF"
                inactive-color="#e4e4e4"
              ></el-switch>
            </el-form-item>

            <el-form-item label="同省">
              <el-switch
                style="margin-top: -3px;"
                v-model="isProvince"
                active-color="#409EFF"
                inactive-color="#e4e4e4"
              ></el-switch>
              ({{ (editForm.Province || '') + (editForm.City || '') + (editForm.Area || '') }})
            </el-form-item>
          </div>

          <el-form-item label="商铺类型">
            <el-radio v-model="editForm.StoreType" label="行情商">行情商</el-radio>
            <el-radio v-model="editForm.StoreType" label="酒商">酒商</el-radio>
            <el-radio v-model="editForm.StoreType" label="酒厂">酒厂</el-radio>
            <el-radio v-model="editForm.StoreType" label="茶商">茶商</el-radio>
            <el-radio v-model="editForm.StoreType" label="茶厂">茶厂</el-radio>
            <el-radio v-model="editForm.StoreType" label="其他">其他</el-radio>
          </el-form-item>

          <el-form-item label="修改浏览数">
            <el-input v-model="editForm.BrowseCount" placeholder="修改浏览数"></el-input>
          </el-form-item>

          <el-form-item label="发布内容">
            <el-input
              v-model="editForm.Title"
              type="textarea"
              maxlength="1000"
              show-word-limit
              :autosize="{ minRows: 4, maxRows: 6 }"
              placeholder="发布内容"
            ></el-input>
          </el-form-item>

          <el-form-item label="发布图片">
            <el-upload
              ref="upload"
              action="https://api.chajiuqu.com/api/current/uploadimage"
              list-type="picture-card"
              :file-list="myImage"
              :limit="12"
              :on-success="handleAvatarSuccess"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <!-- <el-input style="margin-top: 10px;" v-model="sorting" placeholder="图片排序"></el-input> -->
          </el-form-item>

          <el-form-item label="图片排序">
            <div style="display: flex; flex-wrap: wrap;">
              <div v-for="(item, index) in myImage" :key="index">
                <!-- v-dragging="{ item: item.url, list: myImage, group: 'item' }"> -->
                <el-image
                  :src="item.url"
                  style="width: 50px; height: 50px; margin-left: 10px; display: block;"
                ></el-image>
                <el-input
                  v-model="sortingObj[index]"
                  style="width: 50px; margin: 10px 0 10px 10px;"
                ></el-input>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="审核">
            <el-input v-model="editForm.Status" placeholder="审核结果" disabled></el-input>
            <el-radio v-model="editForm.Status" :label="1">审核中</el-radio>
            <el-radio v-model="editForm.Status" :label="2">审核通过</el-radio>
            <el-radio v-model="editForm.Status" :label="3">审核退回</el-radio>
            <el-radio v-model="editForm.Status" :label="0">删除</el-radio>
          </el-form-item>

          <el-form-item label="审核内容">
            <el-input type="textarea" v-model="editForm.AuditContent"></el-input>
          </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="editPhoneDialog = false">取 消</el-button>
          <el-button type="primary" @click="editDetermine()">确 认</el-button>
        </div>
      </div>

      <div style="min-height: 600px;" v-else-if="activeName === '发布历史'">
        <el-table
          ref="TableListRef1"
          class="TableList"
          border
          :data="fabulist"
          v-loading="loading"
          v-if="activeName === '发布历史'"
        >
          <el-table-column prop="Title" label="发布内容" align="center" v-if="activeName === '发布历史'">
            <template slot-scope="scope">
              <div>
                {{ scope.row.Title ? scope.row.Title.slice(0, 10) : '--' }}
                <span
                  v-if="scope.row.Title && scope.row.Title.length > 10"
                >...</span>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="IsRecommend"
            label="是否推荐/同省"
            align="center"
            v-if="activeName === '发布历史'"
          >
            <template slot-scope="scope">
              <template>
                <div v-if="scope.row.IsRecommend">推荐: {{ scope.row.IsRecommend ? '是' : '' }}</div>
                <div
                  v-if="scope.row.IsProvince === 2"
                >{{ scope.row.Province }}: {{ scope.row.IsProvince === 2 ? '同省' : '' }}</div>
              </template>
            </template>
          </el-table-column>

          <el-table-column
            prop="IsProvince"
            label="同省申请"
            align="center"
            v-if="activeName === '发布历史'"
          >
            <template slot-scope="scope">
              <template>
                <span>{{ scope.row.IsProvince === 1 ? '申请(' + scope.row.Province + ')' : '' }}</span>
              </template>
            </template>
          </el-table-column>

          <el-table-column
            prop="ReleaseTime"
            label="发布时间"
            align="center"
            v-if="activeName === '发布历史'"
          >
            <template slot-scope="scope">
              <template>
                <span>{{ new Date(scope.row.ReleaseTime) | parseTime }}</span>
              </template>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页 -->
        <div class="fenye1">
          <el-pagination
            class="TablePagination"
            background
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
            :current-page="goodsPageindex"
            :page-size="20"
            layout="total, sizes, prev, pager, next, jumper"
            :total="blistLength"
          ></el-pagination>
        </div>
      </div>

      <div style="min-height: 600px;" v-else-if="activeName === '关注' && personalList">
        <FocusAndFans :loading="loading" :UserId="editForm.UserId"></FocusAndFans>
      </div>

      <div style="min-height: 600px;" v-else-if="activeName === '粉丝' && personalList">
        <FocusAndFans :loading="loading" type="1" :UserId="editForm.UserId"></FocusAndFans>
      </div>

      <div style="min-height: 600px;" v-else-if="activeName === '留言'">
        <MessageList :merchantsInfo="merchantsInfo" type="1" :loading="loading"></MessageList>
      </div>

      <template v-else>
        <MessageList ref="InfoDialog" :merchantsInfo="merchantsInfo"></MessageList>

        <div slot="footer" class="dialog-footer">
          <el-button @click="editPhoneDialog = false">取 消</el-button>
          <el-button type="primary" @click="editDetermine">确 认</el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisible">
      <!-- <img height="100%" :src="dialogImageUrl" alt=""> -->
      <el-image style="width: 60%; display: block; margin: 0 auto;" :src="dialogImageUrl"></el-image>
    </el-dialog>
  </div>
</template>

<script>
import provinceData from '@/utils/province.js'
import cityData from '@/utils/city.js'
import city1 from '@/utils/city1.js'

export default {
  name: 'membersIndex',
  data() {
    return {
      // beginDate: [this.$globalFun.parseTime(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 7), '{y}-{m}-{d}'),
      //   this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}')],
      beginDate: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      dialogImageUrl: '',
      dialogVisible: false,
      imageUrl: [],
      list: [],
      loading: true,
      queryProvince: '',
      queryCity: '',
      query: {
        pageindex: 1,
        status: 1,
        storeAuditStatus: 2,
        name: '',
        area: ''
      },
      total: 0,
      // 修改手机号
      editPhoneDialog: false,
      editForm: {
        Phone: ''
      },
      tabList: ['关注', '推荐', '行情', '酒品', '茶叶', '其他'],
      // tabList: ['行情', '酒商', '酒厂', '茶商', '茶厂', '其他'],
      // 省市区
      region: null,
      // regionOptions: regionArr,
      regionOptions: provinceData,
      regionOptions1: city1,
      regionOptions2: cityData,
      regionOptions3: city1,
      Status: [
        {
          Status: 0,
          type: 'danger',
          text: '删除'
        },
        {
          Status: 1,
          type: 'primary',
          text: '审核'
        },
        {
          Status: 2,
          type: 'success',
          text: '通过'
        },
        {
          Status: 3,
          type: 'warning',
          text: '退回'
        }
      ],
      // 是否推荐
      isRecommend: false,
      isProvince: 0,
      isT: true,
      current: 1,
      checkBtnCurrent: 2,
      browseCount: 0,
      storeType: '',
      activeName: '发布',
      merchantsInfo: {},
      blistLength: 0,
      goodsPageindex: 1,
      fabulist: [],
      sorting: '',
      sortingObj: [],
      personalList: [],
      personalLength: [],
      personalPageindex: 1
    }
  },
  created() {
    this.getList('place', 2)
    // this.init()
  },
  computed: {
    myImage: function() {
      const arr = []
      if (this.editForm.image) {
        this.editForm.image.forEach((item, index) => {
          arr.push({
            url: item.Image,
            index: index
          })
        })
      }
      return arr
    }
  },
  mounted() {
    this.$dragging.$on('dragged', ({ value }) => {
      console.log(value.item)
      console.log(value.list)
      console.log(value.group)
    })
  },
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleAvatarSuccess(res, file, fileList) {
      // console.log(res)
      // console.log(fileList)
      this.imageUrl = fileList
      console.log(this.imageUrl)
    },
    handleRemove(file, fileList) {
      this.imageUrl = fileList
      console.log(this.imageUrl)
      // console.log(file, fileList)
    },
    // 初始化
    init() {
      this.query.status = 1
      this.LoginDate = []
      this.beginDate = []
      this.query.status = null
      delete this.query.isRecommended
      delete this.query.isProvince
      this.query.name = ''
      this.regionOptions1 = this.regionOptions3
      this.getList('place')
    },
    async handleClick(tab) {
      console.log(this.activeName)
    },
    // 获取列表
    async getList(place, status, day) {
      // 重置页数
      if (place) {
        this.query.pageindex = 1
        // 滚动条回到顶部
        this.$nextTick(() => {
          this.$refs.TableListRef.bodyWrapper.scrollTop = 0
        })
      }
      if (!this.beginDate || !this.beginDate.length) {
        delete this.query.beginTime
        delete this.query.endTime
      } else {
        this.query.beginTime = this.beginDate[0]
        this.query.endTime = this.beginDate[1]
      }
      if (status) {
        this.query.status = status - 1
        this.checkBtnCurrent = status
      }
      if (day) {
        this.query.endTime = this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}')
        this.query.beginTime = this.$globalFun.parseTime(
          new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * day),
          '{y}-{m}-{d}'
        )
        if (day === 7) {
          this.current = 1
        }
        if (day === 10) {
          this.current = 2
        }
        if (day === 30) {
          this.current = 3
        }
      } else {
        this.current = 0
      }
      this.query.storeAuditStatus = 2
      console.log('this.query', this.query)
      const { data: res } = await this.$api.release.articleList(this.query)
      if (this.query.status === 0) {
        res.list = res.list.filter(item => {
          return item.Status === 0
        })
      }
      console.log('获取商圈列表', res)
      this.loading = false
      this.list = res.list
      this.total = res.count
    },
    // 每页显示条数和当前页码
    handleSizeChange(newSize) {
      this.query.pagesize = newSize
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    },
    handleCurrentChange(newPage) {
      this.query.pageindex = newPage
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    },
    handleSizeChange1(newSize) {},
    async handleCurrentChange1(newPage) {
      this.goodsPageindex = newPage
      const { data: res1 } = await this.$api.release.articleList({
        pageindex: this.goodsPageindex,
        name: this.editForm.StoreName,
        status: 2
      })
      console.log(res1)
      this.fabulist = res1.list
      this.blistLength = res1.count
      this.$nextTick(() => {
        this.$refs.TableListRe1.bodyWrapper.scrollTop = 0
      })
    },
    // 状态改变
    async editStatus(item) {
      console.log('状态改变', item)
      try {
        const form = {
          listid: [item.Id],
          status: item.Status
        }
        await this.$api.members.editMembersStatus(form)
        this.$message.success('状态改变成功')
        this.getList()
      } catch (e) {
        this.getList()
      }
    },
    // 审核商户信息
    async onEditInfo(item) {
      const form = this.$globalFun.deepClone(item)
      this.editForm = form

      this.imageUrl = []
      this.editPhoneDialog = true
      this.isRecommend = !!this.editForm.IsRecommend
      this.isProvince = this.editForm.IsProvince === 2
      this.browseCount = this.editForm.BrowseCount
      this.storeType = this.editForm.StoreType

      console.log(this.editForm)
      const { data: res } = await this.$api.members.getStoreList({
        search: this.editForm.LoginName
      })
      this.merchantsInfo = res.list[0]
      console.log('获取商户资料', this.merchantsInfo)

      const { data: res1 } = await this.$api.release.articleList({
        pageindex: 1,
        name: this.editForm.StoreName,
        status: 2
      })
      console.log(res1)
      this.fabulist = res1.list
      this.blistLength = res1.count
    },
    editPhoneCancel() {
      // console.log('对话框关闭')
      this.editForm = {}
      this.merchantsInfo = {}
      this.isRecommend = false
      this.isProvince = null
      this.browseCount = 0
      this.storeType = ''
      this.activeName = '发布'
      this.sortingObj = {}
    },
    async editDetermine() {
      console.log('this.editForm1', this.sortingObj, this.imageUrl)
      let url = ''
      const form = {
        Id: this.editForm.Id,
        title: this.editForm.Title
      }
      if (this.imageUrl.length > 0) {
        const dt = JSON.stringify(this.sortingObj) === '{}'
        console.log(dt)
        if (this.sortingObj['0']) {
          const imgArr = []
          for (const key in this.sortingObj) {
            console.log(key - 0, this.sortingObj[key] - 1, this.imageUrl[this.sortingObj[key] - 1])
            imgArr[this.sortingObj[key] - 1] = this.imageUrl[key - 0]
          }
          this.imageUrl = imgArr
        }
        this.imageUrl.forEach(item => {
          if (item.response) {
            url = item.response.data
          } else {
            url = item.url
          }
          if (!form.imgurls) {
            form.imgurls = url
          } else {
            form.imgurls += ',' + url
          }
        })
      } else {
        const arr = []
        const arrs = []
        for (const i in this.sortingObj) {
          if (arr.indexOf(this.sortingObj[i]) > -1) {
            this.$message.error('排序重复')
            return
          }
          arr.push(this.sortingObj[i])
          arrs.push(i)
        }
        if (arr.length > 0 && arr.length === this.editForm.image.length) {
          const imgArr = []
          for (const key in this.sortingObj) {
            console.log(key - 0, this.sortingObj[key] - 1, this.editForm.image[this.sortingObj[key] - 1])
            imgArr[this.sortingObj[key] - 1] = this.editForm.image[key - 0]
          }
          this.editForm.image = imgArr
        } else if (arr.length > 0 && arr.length === 1) {
          const obj = this.editForm.image[arrs[0] - 0]
          this.editForm.image.splice(arrs[0] - 0, 1)
          this.editForm.image.unshift(obj)
        }
        this.editForm.image.forEach(item => {
          if (!form.imgurls) {
            form.imgurls = item.Image
          } else {
            form.imgurls += ',' + item.Image
          }
        })
      }
      console.log('form', this.sorting, form)
      await this.$api.release.articleEdit(form)
      // console.log('修改结果1', res)
      await this.$api.release.articleRecommend({
        Id: this.editForm.Id,
        IsRecommend: this.isRecommend,
        IsProvince: this.isProvince ? 2 : this.editForm.IsProvince === 1 ? 1 : 0
      })
      // console.log('修改结果2', res2)
      if (this.browseCount - 0 !== this.editForm.BrowseCount - 0) {
        await this.$api.release.articleBrowseCount({
          Id: this.editForm.Id,
          browseCount: this.editForm.BrowseCount - 0
        })
      }
      if (this.storeType !== this.editForm.StoreType) {
        await this.$api.members.editStore({
          Id: this.editForm.UserId,
          StoreType: this.editForm.StoreType,
          IsServer: true
        })
      }
      const { data: res } = await this.$api.release.articleAudit({
        id: this.editForm.Id,
        status: this.editForm.Status,
        auditContent: this.editForm.AuditContent
      })
      console.log('审核结果', res)
      this.$message.success('修改成功')
      this.getList()
      this.editPhoneDialog = false
    },
    // 地区选择
    handleChange(e) {
      console.log('e', e)
      this.editForm.Province = e[0]
      this.editForm.City = e[1]
      this.editForm.Area = e[2]
    },
    // 地区选择
    handleChange1(e) {
      console.log('e', e, this.regionOptions)
      if (e) {
        this.queryProvince = this.regionOptions[e - 1].label
        this.regionOptions1 = this.regionOptions2[e - 1]
        this.queryCity = null
        this.query.area = this.queryProvince
        console.log('e', e, this.query.area)
      } else {
        this.queryCity = null
        this.query.area = null
        this.regionOptions1 = this.regionOptions3
      }
    },
    handleChange2(e) {
      console.log('e', e)
      if (e) {
        this.query.area = e
        console.log('e', e, this.query.area)
      } else {
        if (this.queryProvince) {
          this.query.area = this.queryProvince
        } else {
          this.query.area = null
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.auditImg {
  display: flex;
  .block {
    margin-right: 25px;
    span {
      display: block;
      width: 120px;
      text-align: center;
    }
    img {
      width: 120px;
    }
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.releaseImg {
  display: flex;
  flex-wrap: wrap;
  div {
    width: 200px;
    height: 150px;
    margin-left: 20px;
  }
}

.tabs {
  background-color: #fff;
  position: sticky;
  top: -30px;
  z-index: 9;
}

.merchants_info {
  min-height: 600px;

  .merchants_info_item {
    padding: 10px;
    color: #606266;
    font-size: 14px;
    line-height: 40px;
    font-weight: bold;
    display: flex;
  }
}
</style>
