<!--
 * @Description: 商户资料
-->
<template>
  <div>
    <div class="merchants_info" v-if="type === '0'">
      <div class="merchants_info_item">
        <div style="width: 80px; margin-right: 20px; color: #909399;">登录手机号</div>
        <span>{{ merchantsInfo.LoginName }}</span>
        <span>(VIP{{ merchantsInfo.VipTime | remainingDays }}天)</span>
        <span>(认证{{ merchantsInfo.StoreVipTime | remainingDays }}天)</span>
      </div>

      <div style="display: flex;">
        <div class="merchants_info_item" style="flex: 1;">
          <div style="width: 80px; color: #909399;">商家关注</div>
          {{ merchantsInfo.CollectionCount || 0 }}
        </div>

        <div class="merchants_info_item" style="flex: 1;">
          <div style="width: 80px; color: #909399;">商家粉丝</div>
          {{ merchantsInfo.FollowCount || 0 }}
        </div>

        <div class="merchants_info_item" style="flex: 1;">
          <div style="width: 80px; color: #909399;">商家发布</div>
          {{ merchantsInfo.ArticleCount || 0 }}
        </div>
      </div>

      <div class="merchants_info_item">
        <div style="width: 80px; margin-right: 20px; color: #909399;">商家手机号</div>
        {{ merchantsInfo.StorePhone || '暂无认证' }}
      </div>

      <div class="merchants_info_item">
        <div style="width: 80px; margin-right: 20px; color: #909399;">商铺头像</div>
        <el-image
          style="width: 100px;"
          :src="merchantsInfo.StoreHeadImage"
          :preview-src-list="[merchantsInfo.StoreHeadImage]"
          alt
        ></el-image>
      </div>

      <div class="merchants_info_item">
        <div style="width: 80px; margin-right: 20px; color: #909399;">商铺名称</div>
        {{ merchantsInfo.StoreName || '暂无认证' }}
      </div>

      <div class="merchants_info_item">
        <div style="width: 80px; margin-right: 20px; color: #909399;">认证名称</div>
        {{ merchantsInfo.Name || '暂无认证' }}
      </div>

      <div class="merchants_info_item">
        <div style="width: 80px; margin-right: 20px; color: #909399;">商铺地区</div>
        {{ merchantsInfo.Province + merchantsInfo.City + merchantsInfo.Area || '暂无认证' }}
      </div>

      <div class="merchants_info_item">
        <div style="width: 80px; margin-right: 20px; color: #909399;">商铺地址</div>
        {{ merchantsInfo.StoreAddress || '暂无认证' }}
      </div>

      <div class="merchants_info_item">
        <div style="width: 80px; margin-right: 20px; color: #909399;">营业执照</div>
        <el-image
          style="width: 100px; height: 100px"
          :src="merchantsInfo.StoreLicenseImage"
          fit="fill"
          :preview-src-list="[merchantsInfo.StoreLicenseImage]"
        ></el-image>
      </div>
    </div>

    <div style="min-height: 600px;" v-if="type === '1'">
      <el-table class="TableList" border :data="SupportList" v-loading="loading">
        <el-table-column prop="Title" label="标题" align="center" v-if="type === '1' && !merchants">
          <template slot-scope="scope">
            <span
              v-if="scope.row.ArticleId"
            >{{ scope.row.Title?scope.row.Title.slice(0, 30) : '--' }}</span>
            <span>{{ scope.row.GoodsName?scope.row.GoodsName.slice(0, 30) : '--' }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="Title" label="标题" align="center" v-if="type === '1' && merchants">
          <template slot-scope="scope">{{ scope.row.Title?scope.row.Title.slice(0, 30) : '--' }}</template>
        </el-table-column>

        <el-table-column
          prop="GoodsName"
          label="商品"
          align="center"
          v-if="type === '1' && merchants"
        >
          <template
            slot-scope="scope"
          >{{ scope.row.GoodsName?scope.row.GoodsName.slice(0, 30) : '--' }}</template>
        </el-table-column>

        <el-table-column prop="StoreName" label="留言商户" align="center" v-if="type === '1'">
          <template slot-scope="scope">{{ scope.row.StoreName || '--' }}</template>
        </el-table-column>

        <el-table-column prop="SupportTitle" label="留言内容" align="center" v-if="type === '1'">
          <template slot-scope="scope">{{ scope.row.SupportTitle || '--' }}</template>
        </el-table-column>

        <el-table-column prop="Date" label="留言时间" align="center" v-if="type === '1'">
          <template slot-scope="scope">{{ new Date(scope.row.Date) | parseTime }}</template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageList',
  props: {
    merchants: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: '0'
    },
    releaseType: {
      type: String,
      default: '0'
    },
    loading: {
      type: Boolean,
      default: false
    },
    merchantsInfo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  created() {},
  computed: {
    SupportList() {
      console.log(this.merchantsInfo.SupportList)
      if (this.merchants) return this.merchantsInfo.SupportList

      const arr = this.merchantsInfo.SupportList.filter(item => {
        return this.releaseType === '0' ? item.ArticleId : item.GoodsId
      })
      console.log(arr)
      return arr
    }
  },
  methods: {
    determine() {
      console.log('determine')
    },

    closeDialog() {
      console.log('closeDialog')
      this.$emit('closeDialog')
    }
  }
}
</script>

<style lang="scss" scoped>
.merchants_info {
  min-height: 600px;

  .merchants_info_item {
    padding: 10px;
    color: #606266;
    font-size: 14px;
    line-height: 40px;
    font-weight: bold;
    display: flex;
  }
}
</style>
