<!--
 * @Description: 充值记录列表
-->
<template>
  <div style="min-height: 500px">
    <el-table ref="TableListRef" class="TableList" border :data="List" v-loading="loading">
      <el-table-column prop="LoginName" label="手机号码" align="center"></el-table-column>

      <el-table-column prop="Remark" label="会员套餐" align="center"></el-table-column>

      <el-table-column prop="Price" label="充值金额" align="center">
        <template slot-scope="scope">
          {{ scope.row.Price }}
        </template>
      </el-table-column>

      <el-table-column prop="GiveIntegral" label="积分" align="center"></el-table-column>

      <el-table-column prop="VipTime" label="会员剩余时间" align="center" width="240" v-if="vipTime">
        <template slot-scope="scope">
          <template>
            <span v-if="ifOverdue(scope.row.VipTime)" style="color: #f00">VIP已过期</span>
            <span v-else>VIP {{ scope.row.VipTime | remainingDays }}天</span>
          </template>
          <!-- ({{ new Date(scope.row.VipTime) | parseTime('{y}-{m}-{d}') }}) -->
        </template>
      </el-table-column>

      <el-table-column prop="CreateDate" label="支付日期" align="center">
        <template slot-scope="scope">
          {{ new Date(scope.row.CreateDate) | parseTime('{y}-{m}-{d}') }}
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="paging">
      <el-pagination
        class="TablePagination"
        background
        @current-change="handleCurrentChange"
        :current-page="query.pageindex"
        :page-size="20"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopUpList',
  props: {
    merchantsInfo: {
      type: Object,
      required: true
    },

    vipTime: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      List: [],

      query: {
        pageindex: 1,
        pagesize: 20,
        search: '',
        status: 1
      },

      total: 0,

      loading: false
    }
  },
  created() {
    this.query.search = this.merchantsInfo.LoginName
    console.log(this.query.search, this.merchantsInfo)
    this.getList()
  },
  methods: {
    async getList() {
      this.loading = true
      const { data: res } = await this.$api.members.getTopUpWaterList(this.query)
      console.log('充值记录', res)
      this.List = res.list
      this.total = res.count
      this.loading = false
    },

    handleCurrentChange(newPage) {
      this.query.pageindex = newPage
      this.List = []
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
      this.getList()
    },
    // 判断是否过期
    ifOverdue(time) {
      // console.log('time', time)
      const nowDate = new Date()
      const date = new Date(time).getTime() - nowDate.getTime()

      return date < 0
    }
  }
}
</script>

<style lang="scss" scoped></style>
