var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('div',[_c('el-form',{ref:"formRef",staticClass:"demo-ruleForm",attrs:{"model":_vm.form,"rules":_vm.rules,"label-width":"auto"}},[_c('el-form-item',{attrs:{"label":"文章标题","prop":"Title"}},[_c('el-input',{attrs:{"placeholder":"请输入文章标题"},model:{value:(_vm.form.Title),callback:function ($$v) {_vm.$set(_vm.form, "Title", $$v)},expression:"form.Title"}})],1),_c('el-form-item',{attrs:{"label":"排序","prop":"Sort"}},[_c('el-input',{attrs:{"placeholder":"请输入排序"},model:{value:(_vm.form.Sort),callback:function ($$v) {_vm.$set(_vm.form, "Sort", $$v)},expression:"form.Sort"}})],1),_c('el-form-item',{attrs:{"label":"栏目","prop":"Category"}},[_c('el-cascader',{staticStyle:{"width":"100%"},attrs:{"options":_vm.columnList,"show-all-levels":false,"props":{
            multiple: true,
            emitPath: false,
            value: 'Id',
            label: 'Title',
            children: 'Subitem'
          },"placeholder":"请选择栏目","clearable":""},model:{value:(_vm.form.Category),callback:function ($$v) {_vm.$set(_vm.form, "Category", $$v)},expression:"form.Category"}})],1),_c('el-form-item',{attrs:{"label":"是否置顶","prop":"Top"}},[_c('el-switch',{attrs:{"active-value":1,"inactive-value":0},model:{value:(_vm.form.Top),callback:function ($$v) {_vm.$set(_vm.form, "Top", $$v)},expression:"form.Top"}}),_c('span',{staticStyle:{"color":"red","margin-left":"20px"}},[_vm._v("*显示在列表最顶部")])],1),_c('el-form-item',{attrs:{"label":"是否推荐","prop":"Recommend"}},[_c('el-switch',{attrs:{"active-value":1,"inactive-value":0},model:{value:(_vm.form.Recommend),callback:function ($$v) {_vm.$set(_vm.form, "Recommend", $$v)},expression:"form.Recommend"}}),_c('span',{staticStyle:{"color":"red","margin-left":"20px"}},[_vm._v("*显示在首页推荐栏目下的列表")])],1),_c('el-form-item',{attrs:{"label":"是否登录可见","prop":"LoginShow"}},[_c('el-switch',{attrs:{"active-value":1,"inactive-value":0},model:{value:(_vm.form.LoginShow),callback:function ($$v) {_vm.$set(_vm.form, "LoginShow", $$v)},expression:"form.LoginShow"}})],1),_c('el-form-item',{attrs:{"label":"封面","prop":"Image"}},[_c('ImageUpload',{attrs:{"url":_vm.form.Image,"notip":""},on:{"update:url":function($event){return _vm.$set(_vm.form, "Image", $event)}}})],1),_c('el-form-item',{attrs:{"prop":"Content"}},[_c('Tinymce',{on:{"tinymceId":_vm.getTinymceId,"eventTinymce":_vm.eventTinymce},model:{value:(_vm.form.Content),callback:function ($$v) {_vm.$set(_vm.form, "Content", $$v)},expression:"form.Content"}})],1)],1)],1),_c('FixedActionBar',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.onEstablish()}}},[_vm._v(_vm._s(_vm.type ? '编辑' : '立即创建'))]),_c('el-button',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("取消")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }