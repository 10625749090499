// user 用户接口
import http from '../request.js'

export default {
  /* 获取相册列表 */
  getList: data => {
    return http({
      url: '/api/userimage/list',
      data
    })
  },

  /* 用户相册审核 */
  audit: data => {
    return http({
      url: '/api/userimage/audit',
      data
    })
  }
}
