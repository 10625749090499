// 挂载路由导航守卫
import router from './router'
import store from './store'
import Cookies from 'vue-cookies'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({ easing: 'ease', showSpinner: false })

router.beforeEach((to, from, next) => {
  NProgress.start() // 进度条开启
  // console.log('beforeEach')

  const isLogin = Cookies.get('token')
  // console.log('isLogin', isLogin)
  const info = store.state.user.info || { Categorys: false }

  if (to.path === '/login') return next()

  if (!isLogin) return next('login')

  if (!info.Categorys) return next('login')

  let flag = false
  const time = localStorage.getItem('loginTime')

  if (time) {
    const time1 = new Date(time - 0).getTime()
    const time2 = new Date().getTime()
    const num = ((time2 - time1) / 1000).toFixed(0)
    console.log('num', num)
    flag = num < 60 * 5
  }

  if (!flag) {
    console.log('flag', flag)
    localStorage.setItem('loginTime', Date.now())
    store.dispatch('user/getInfo').catch((err) => {
      console.log('出错了请新登录', err)
      return next('login')
    })
  }

  try {
    router.options.routes.forEach((item) => {
      if (to.path.split('/')[1] === 'home' || to.path.split('/')[1] === '') {
        throw Error('主页')
      }

      if (item.path.indexOf(to.path.split('/')[1]) > -1) {
        if (info.Categorys.indexOf(item.meta.title) > -1) {
          throw Error(item.meta.title)
        } else {
          throw Error('home')
        }
      }
    })
  } catch (e) {
    console.log(e.message)
    if (e.message === 'home') {
      next('home')
    } else {
      next()
    }
  }
})

router.afterEach((transition) => {
  NProgress.done() // 进度条关闭
})
