<!--
 * @Description: 套餐管理
-->
<template>
  <div>
    <el-table ref="TableListRef" class="TableList" border :data="list" v-loading="loading">
      <el-table-column prop="Title" label="标题" align="center"></el-table-column>
      <el-table-column prop="Explain" label="副标题" align="center"></el-table-column>
      <el-table-column prop="Remark" label="提示语" align="center"></el-table-column>
      <el-table-column prop="Price" label="促销价" align="center"></el-table-column>
      <el-table-column prop="OldPrice" label="原价" align="center"></el-table-column>
      <el-table-column prop="GiveIntegral" label="送积分" align="center"></el-table-column>
      <el-table-column label="操作" align="center" width="100" fixed="right">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onEdit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="Status" label="状态" align="center">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.Status"
            :active-value="1"
            :inactive-value="0"
            @change="editStatus(scope.row)"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </template>
      </el-table-column> -->
    </el-table>
    <!-- 编辑对话框 -->
    <el-dialog :visible.sync="visible" class="FormDialogBox" width="700px" title="编辑"
      :close-on-click-modal="false" @close="onCancel()">
      <el-form :model="form" :rules="formRules" ref="formRef" label-width="auto">
        <el-form-item label="标题" prop="Title">
          <el-input v-model="form.Title" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="副标题" prop="Explain">
          <el-input v-model="form.Explain" placeholder="请输入副标题"></el-input>
        </el-form-item>
        <el-form-item label="提示语" prop="Remark">
          <el-input v-model="form.Remark" placeholder="请输入提示语"></el-input>
        </el-form-item>
        <el-form-item label="促销价" prop="Price">
          <el-input v-model="form.Price" placeholder="请输入促销价"></el-input>
        </el-form-item>
        <el-form-item label="原价" prop="OldPrice">
          <el-input v-model="form.OldPrice" placeholder="请输入原价"></el-input>
        </el-form-item>
        <el-form-item label="送积分" prop="GiveIntegral">
          <el-input v-model="form.GiveIntegral" placeholder="请输入赠送的积分"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="onCancel()">取 消</el-button>
        <el-button type="primary" @click="onDetermine()">修改</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'packageIndex',
  data () {
    return {
      list: [],
      loading: true,
      // 编辑
      visible: false,
      form: {},
      formRules: {
        Title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        Explain: [{ required: true, message: '请输入副标题', trigger: 'blur' }],
        Remark: [{ required: true, message: '请输入提示语', trigger: 'blur' }],
        Price: [{ required: true, message: '请输入促销价', trigger: 'blur' }],
        OldPrice: [{ required: true, message: '请输入原价', trigger: 'blur' }],
        GiveIntegral: [{ required: true, message: '请输入赠送的积分', trigger: 'blur' }]
      }
    }
  },
  created () {
    this.getList()
  },
  methods: {
    // 获取列表
    async getList () {
      const { data: res } = await this.$api.members.getPackageList({ type: 0 })
      console.log('获取列表', res)
      this.loading = false
      this.list = res
    },

    // 编辑
    onEdit (item) {
      const form = this.$globalFun.deepClone(item)
      this.form = form
      this.visible = true
    },

    // 对话框关闭
    onCancel () {
      this.$refs.formRef.resetFields()
      this.form = this.$options.data().form
      this.visible = false
    },

    // 确认
    onDetermine () {
      console.log('this.form', this.form)
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) return

        await this.$api.members.editPackage(this.form)

        this.$message.success('编辑成功')
        this.onCancel()
        this.getList()
      })
    }

    // // 状态改变
    // async editStatus (item) {
    //   console.log('状态改变', item)

    //   try {
    //     const form = {
    //       listid: [item.Id],
    //       status: item.Status
    //     }
    //     await this.$api.members.editMembersStatus(form)
    //     this.$message.success('状态改变成功')
    //     this.getList()
    //   } catch (e) {
    //     this.getList()
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped></style>
