<!--
 * @Description: 栏目管理
-->
<template>
  <div>
    <div class="TableTop">
      <!-- 搜索 -->
      <div class="TableSearch">
        <el-form :model="query" @submit.native.prevent>
          <el-form-item label="栏目：" class="form-item-b0">
            <el-input
              v-model="query.search"
              placeholder="请输入栏目"
              size="small"
              clearable
              @clear="getList()"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="TableSearch_btn">
          <el-button type="primary" size="small" @click="getList()">筛选</el-button>
          <el-button size="small" @click="init()">重置</el-button>
        </div>
      </div>
      <!-- 按钮 -->
      <div class="TableTopBtn">
        <el-button type="primary" size="small" icon="el-icon-plus" @click="onAddOrEdit(null, 0)">
          新增
        </el-button>
      </div>
    </div>
    <!-- 表格 -->
    <el-table
      ref="TableListRef"
      class="TableList"
      border
      :data="list"
      row-key="Id"
      :tree-props="{ children: 'Subitem' }"
      v-loading="loading"
    >
      <el-table-column prop="Title" label="标题" width="160"></el-table-column>
      <el-table-column label="层级" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.Level === 0" type="success">一级</el-tag>
          <el-tag v-if="scope.row.Level === 1" type="warning">二级</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="图片" align="center" width="120">
        <template slot-scope="scope">
          <ImgBox v-if="scope.row.Image" :src="scope.row.Image" :width="80" :height="80"></ImgBox>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="跳转路径" align="center" width="160">
        <template slot-scope="scope">
          <span v-if="scope.row.Link">{{ scope.row.Link }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.Status"
            :active-value="1"
            :inactive-value="0"
            @change="editStatus(scope.row)"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="LoginShow" label="是否登录可见" align="center">
        <template slot-scope="scope">{{ scope.row.LoginShow === 1 ? '是' : '否' }}</template>
      </el-table-column>
      <el-table-column prop="Sort" label="排序" align="center"></el-table-column>
      <el-table-column label="操作" align="center" width="200" fixed="right">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.Level === 0"
            :disabled="scope.row.Status !== 1"
            type="primary"
            size="mini"
            @click="onAddOrEdit(scope.row.Id, 0)"
          >
            新增子集
          </el-button>
          <el-button type="primary" size="mini" :disabled="scope.row.Status !== 1" @click="onAddOrEdit(scope.row.Id, 1)">
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增、编辑对话框 -->
    <AddOrEditDialog ref="addOrEditDialogRef" @fetch-data="getList('place')" />
  </div>
</template>

<script>
import AddOrEditDialog from './addOrEditDialog'

export default {
  name: 'columnIndex',
  components: {
    AddOrEditDialog
  },
  data () {
    return {
      list: [],
      loading: true,
      query: {
        search: ''
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    // 初始化
    init () {
      this.query.search = ''
      this.getList()
    },

    // 获取列表
    async getList () {
      const { data: res } = await this.$api.column.getList(this.query)
      console.log('获取列表', res)

      this.loading = false
      this.list = res
    },

    // 新增、编辑
    onAddOrEdit (id, type) {
      this.$refs.addOrEditDialogRef.showAddOrEdit(id, type)
    },

    // 状态改变
    async editStatus (item) {
      console.log('状态改变', item)

      const form = {
        listid: [item.Id],
        status: item.Status
      }
      await this.$api.column.edit_status(form)
      this.$message.success('状态改变成功')
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped></style>
