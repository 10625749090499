// 轮播图 ad
import http from '../request.js'

export default {
  // 获取列表
  getList: data => {
    return http({
      url: '/api/ad/list_server',
      data
    })
  },

  // 获取详情
  getInfo: data => {
    return http({
      url: '/api/ad/get',
      data
    })
  },

  // 新增
  add: data => {
    return http({
      url: '/api/ad/add',
      data
    })
  },

  // 编辑
  edit: data => {
    return http({
      url: '/api/ad/edit',
      data
    })
  },

  // 修改状态
  edit_status: data => {
    return http({
      url: '/api/ad/edit_status',
      data
    })
  }
}
