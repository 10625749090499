// 会员管理 members
import http from '../request.js'

export default {
  /* 商户审核列表 */
  getStoreList: data => {
    return http({
      url: '/api/user/audit_store_list',
      data
    })
  },

  /* 删除商户 */
  deleteStore: data => {
    return http({
      url: 'api/user/delete_store',
      data
    })
  },

  /* 编辑商户商户 */
  editStore: data => {
    return http({
      url: '/api/user/edit_store',
      data
    })
  },

  /* 商户审核列表 */
  auditStore: data => {
    return http({
      url: '/api/user/audit_store',
      data
    })
  },

  /* 会员列表列表 */
  getMembersList: data => {
    return http({
      url: '/api/user/list',
      data
    })
  },
  // 修改状态
  editMembersStatus: data => {
    return http({
      url: '/api/user/edit_status',
      data
    })
  },
  // 编辑会员信息
  editInfo: data => {
    return http({
      url: '/api/user/update_info',
      data
    })
  },

  // 修改头像
  edit: data => {
    return http({
      url: '/api/user/edit',
      data
    })
  },

  /* 充值流水列表 */
  getTopUpWaterList: data => {
    return http({
      url: '/api/recharge/list',
      data
    })
  },

  /* 套餐列表 */
  getPackageList: data => {
    return http({
      url: '/api/recharge/getconfig',
      data
    })
  },
  // 修改套餐
  editPackage: data => {
    return http({
      url: '/api/recharge/config_edit',
      data
    })
  },
  // 导出Excel
  exportExcel: data => {
    return http({
      url: '/api/user/excel',
      data
    })
  },

  auditcount: data => {
    return http({
      url: 'api/userarticle/auditcount',
      data
    })
  },

  refund: data => {
    return http({
      url: '/api/recharge/refund',
      data
    })
  }
}
