import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
// 路由导航守卫
import './permission'

// css样式初始化
import 'normalize.css/normalize.css'
// 全局组件自动注册
import './components/autoRegister'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

// tinymce
import tinymce from 'tinymce'
import VueTinymce from '@packy-tang/vue-tinymce'

import '@/styles/index.scss' // 全局css
import '@/styles/global.scss'
import '@/icons' // icon

// api挂载
import api from '@/api'

import * as filters from './filters' // 全局过滤器
import globalFun from './utils'

import VueDND from 'awe-dnd'

import Decimal from 'decimal.js'

// excel导出
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

// 组件注册
Vue.use(ElementUI)
Vue.use(VueTinymce)
Vue.use(VueDND)

// 挂载全局过滤器
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

// 挂载全局方法
Vue.prototype.$globalFun = globalFun
Vue.prototype.$api = api.module
Vue.prototype.$d = Decimal
Vue.prototype.$tinymce = tinymce

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
