import http from '../request.js'

export default {
  // 后台提现列表
  getCashList: data => {
    return http({
      url: '/api/usercommon/cashlist_server',
      data
    })
  },

  // 后台提现审核
  getCashAudit: data => {
    return http({
      url: '/api/usercommon/cash_audit',
      data
    })
  },

  // 后台提现审核
  getCommonList: data => {
    return http({
      url: '/api/usercommon/commonlist_server',
      data
    })
  },

  // 银行卡
  editBank: data => {
    return http({
      url: '/api/user/edit_bank',
      data
    })
  },

  // 列表
  userchild: data => {
    return http({
      url: '/api/usercommon/userchild',
      data
    })
  },

  // 下线用户
  getUserChildList: data => {
    return http({
      url: '/api/usercommon/getUserChildList',
      data
    })
  }
}
