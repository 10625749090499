<!--
 * @Description: 新增编辑对话框
-->
<template>
  <el-dialog
    :visible.sync="addOrEditDialog"
    class="FormDialogBox"
    width="700px"
    :title="addOrEditTypeTitle[addOrEditType]"
    @close="addOrEditCancel()"
  >
    <el-form :model="addOrEditForm" :rules="addOrEditRules" ref="addOrEditFormRef" label-width="auto">
      <el-form-item label="标题" prop="Title">
        <el-input v-model="addOrEditForm.Title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="Remark" prop="Remark">
        <el-input v-model="addOrEditForm.Remark" placeholder="请输入Remark"></el-input>
      </el-form-item>
      <el-form-item label="父级">
        <el-select v-model="addOrEditForm.ParentId" placeholder="请选择父级">
          <el-option label="顶级" :value="0"></el-option>
          <!-- 获取一级类目循环 -->
          <el-option :label="item.Title" :value="item.Id" v-for="item in parentList" :key="item.Id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="排序" prop="Sort">
        <el-input v-model="addOrEditForm.Sort" type="number" placeholder="请输入排序"></el-input>
      </el-form-item>
      <el-form-item label="跳转路径">
        <el-input v-model="addOrEditForm.Link" placeholder="请输入跳转路径"></el-input>
      </el-form-item>
      <el-form-item label="图片">
        <ImageUpload :url.sync="addOrEditForm.Image" notip />
      </el-form-item>
      <el-form-item label="是否登录可见" prop="LoginShow">
        <el-select v-model="addOrEditForm.LoginShow" placeholder="请选择是否登录可见">
          <el-option label="显示" :value="1"></el-option>
          <el-option label="不显示" :value="0"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="addOrEditCancel()">取 消</el-button>
      <el-button type="primary" @click="addOrEditDetermine()">{{ addOrEditTypeTitle[addOrEditType] }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'addOrEditDialog',
  data () {
    return {
      addOrEditDialog: false,
      addOrEditType: 0, // 0新增,1编辑
      addOrEditTypeTitle: ['新增', '编辑'],
      addOrEditForm: {
        Title: '',
        Remark: '',
        ParentId: 0,
        Sort: 0,
        Link: '',
        Image: '',
        Status: 1,
        LoginShow: 1
      },
      addOrEditRules: {
        Title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        Status: [{ required: true, message: '请输入排序', trigger: 'blur' }],
        LoginShow: [{ required: true, message: '请选择是否登录可见', trigger: 'change' }]
      },
      // 父级列表
      parentList: []
    }
  },
  methods: {
    // 获取父级列表
    async getParentList () {
      const { data: res } = await this.$api.column.getList({})
      this.parentList = res
    },

    // 对话框显示隐藏
    async showAddOrEdit (Id, type) {
      this.addOrEditType = type
      this.getParentList()

      if (type === 1) {
        // 获取详情
        const { data: res } = await this.$api.column.getInfo({ Id })
        console.log('根据id获取详情', res)
        this.addOrEditForm = res
      } else {
        // 新增子集
        if (Id) {
          console.log(Id)
          this.addOrEditForm.ParentId = Id
        }
      }

      this.addOrEditDialog = true
    },

    // 对话框关闭
    addOrEditCancel () {
      this.$refs.addOrEditFormRef.resetFields()
      this.addOrEditForm = this.$options.data().addOrEditForm
      this.addOrEditDialog = false
    },

    // 确认
    addOrEditDetermine () {
      console.log('this.addOrEditForm', this.addOrEditForm)
      this.$refs.addOrEditFormRef.validate(async valid => {
        if (!valid) return

        this.addOrEditForm.Level = this.addOrEditForm.ParentId === 0 ? 0 : 1

        if (this.addOrEditType === 0) {
          await this.$api.column.add(this.addOrEditForm)
        } else {
          await this.$api.column.edit(this.addOrEditForm)
        }
        this.$message.success(`${this.addOrEditTypeTitle[this.addOrEditType]}成功`)
        this.addOrEditCancel()
        // 通知父级更新列表
        this.$emit('fetch-data')
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
