<!--
 * @Description: 编辑页
-->
<template>
  <div class="page">
    <div>
      <el-form :model="form" :rules="rules" ref="formRef" label-width="auto" class="demo-ruleForm">
        <el-form-item label="文章标题" prop="Title">
          <el-input v-model="form.Title" placeholder="请输入文章标题"></el-input>
        </el-form-item>
            <el-form-item label="排序" prop="Sort">
          <el-input v-model="form.Sort" placeholder="请输入排序"></el-input>
        </el-form-item>
        <!-- <el-form-item label="来源" prop="Source">
          <el-input v-model="form.Source" placeholder="请输入来源"></el-input>
        </el-form-item> -->
        <el-form-item label="栏目" prop="Category">
          <el-cascader
            style="width: 100%;"
            v-model="form.Category"
            :options="columnList"
            :show-all-levels="false"
            :props="{
              multiple: true,
              emitPath: false,
              value: 'Id',
              label: 'Title',
              children: 'Subitem'
            }"
            placeholder="请选择栏目"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="是否置顶" prop="Top">
          <el-switch v-model="form.Top" :active-value="1" :inactive-value="0"></el-switch>
          <span style="color: red; margin-left: 20px;">*显示在列表最顶部</span>
        </el-form-item>
        <el-form-item label="是否推荐" prop="Recommend">
          <el-switch v-model="form.Recommend" :active-value="1" :inactive-value="0"></el-switch>
          <span style="color: red; margin-left: 20px;">*显示在首页推荐栏目下的列表</span>
        </el-form-item>
        <!-- <el-form-item label="是否热门" prop="Hot">
          <el-switch v-model="form.Hot" :active-value="1" :inactive-value="0"></el-switch>
          <span style="color: red; margin-left: 20px;">*显示在首页的热点行情列表里</span>
        </el-form-item> -->
        <el-form-item label="是否登录可见" prop="LoginShow">
          <el-switch v-model="form.LoginShow" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="封面" prop="Image">
          <ImageUpload :url.sync="form.Image" notip />
        </el-form-item>
        <el-form-item prop="Content">
          <Tinymce v-model="form.Content" @tinymceId="getTinymceId" @eventTinymce="eventTinymce" />
        </el-form-item>
      </el-form>
    </div>
    <FixedActionBar>
      <el-button type="primary" @click="onEstablish()">{{ type ? '编辑' : '立即创建' }}</el-button>
      <el-button @click="$router.go(-1)">取消</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import Tinymce from './Tinymce'

export default {
  components: {
    Tinymce
  },
  props: {
    // 0新增， 1编辑
    type: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      tinymceId: null,
      form: {
        Title: '',
        Content: '',
        // Source: '',
        Sort: 0,
        Top: 0,
        Hot: 0,
        Recommend: 0,
        LoginShow: 1,
        Image: '',
        Category: [],
        Author: '',
        Status: 1
      },
      rules: {
        Title: [{ required: true, message: '请输入文章标题', trigger: 'blur' }],
        // Source: [{ required: true, message: '请输入来源', trigger: 'blur' }],
        Category: [{ required: true, message: '请选择栏目', trigger: 'change' }],
        Content: [{ required: true, message: '请输入文章内容', trigger: 'blur' }],
        Image: [{ required: true, message: '请上传封面' }]
      },
      // 栏目列表
      columnList: []
    }
  },
  created () {
    this.getColumnList()

    if (this.type) {
      const { id } = this.$route.query
      this.getInfo(id)
    }
  },
  methods: {
    // 获取栏目列表
    async getColumnList () {
      const { data: res } = await this.$api.column.getList({})
      console.log('获取栏目列表', res)
      const hot = {
        Id: 0,
        Title: '推荐',
        Subitem: [
          {
            Id: -1,
            Title: '热点行情',
            Subitem: null
          }
        ]
      }
      this.columnList = [hot, ...res]
    },

    // 编辑获取详情
    async getInfo (Id) {
      const { data: res } = await this.$api.article.getInfo({ Id })
      console.log('编辑获取详情', res)

      const Category = []
      for (const i of res.Category) {
        Category.push(i.Id)
      }
      res.Category = Category
      this.form = res
    },

    // 编辑器id
    getTinymceId (e) {
      // console.log(e)
      this.tinymceId = e
    },
    // 编辑器内容
    eventTinymce (e) {
      // console.log(e)
      this.form.Content = e
    },

    // 创建/编辑
    onEstablish () {
      console.log('form', this.form)
      this.$refs.formRef.validate(async valid => {
        if (!valid) return

        if (this.type === 0) {
          await this.$api.article.add(this.form)
        } else {
          await this.$api.article.edit(this.form)
        }

        this.$message.success(this.type === 0 ? '新增成功' : '编辑成功')
        this.$router.go(-1)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding-bottom: 90px;
}
</style>
