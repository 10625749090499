// user 用户接口
import http from '../request.js'

export default {
  // 登录
  login: data => {
    return http({
      url: '/api/user/login_server',
      data
    })
  },

  // 登录
  get: data => {
    return http({
      url: '/api/user/get',
      data
    })
  },

  // 退出
  logout: data => {
    return http({
      url: '/api/user/loginout_server',
      data
    })
  },

  // 检查手机号是否注册
  checkPhone: data => {
    return http({
      url: '/api/user/checkPhone',
      data
    })
  },

  // 用户访问
  loglist: data => {
    return http({
      url: '/api/user/logcount',
      data
    })
  },

  // 用户访问
  h5Jump: data => {
    return http({
      url: '/api/user/loglist',
      data
    })
  },

  // 管理员列表访问
  adminlist: data => {
    return http({
      url: '/api/user/adminlist',
      data
    })
  },

  // 保存管理员
  saveAdmin: data => {
    return http({
      url: '/api/user/saveAdmin',
      data
    })
  },

  // 删除管理员
  deleteAdmin: data => {
    return http({
      url: '/api/user/deleteAdmin',
      data
    })
  },

  // 获取热门城市
  getHotArea: data => {
    return http({
      url: '/api/user/getHotArea',
      data
    })
  }

  // // 修改密码
  // change_pd: data => {
  //   return http({
  //     url: 'api/admin/account/change_pd',
  //     data
  //   })
  // },

  // // 账号权限
  // role: data => {
  //   return http({
  //     url: 'api/admin/account/role',
  //     data
  //   })
  // }
}
