<!--
 * @Description: 图片控件
-->
<template>
  <el-image
    :class="ifAnimation ? 'ifAnimation' : ''"
    :src="imgUrl"
    :alt="alt"
    :fit="fit"
    :lazy="lazy"
    :style="`width:${width}px; height:${height}px;`"
    :preview-src-list="ifOnShow ? srcList || [imgUrl] : null"
    :z-index="zIndex"
  >
    <!-- 错误 -->
    <div slot="error" class="image-slot">
      <svg-icon icon-class="image-load-fail" />
    </div>
    <!-- 加载 -->
    <div slot="placeholder" class="image-slot">
      <i class="el-icon-loading"></i>
    </div>
  </el-image>
</template>

<script>
export default {
  name: 'ImgBox',
  props: {
    // 链接
    src: {
      type: String,
      required: true
    },
    // 原生 alt
    alt: {
      type: String,
      default: ''
    },
    // 宽度
    width: {
      type: Number,
      default: 150
    },
    // 高度
    height: {
      type: Number,
      default: 150
    },
    // 是否开启动画效果
    ifAnimation: {
      type: Boolean,
      default: true
    },
    // 是否开启懒加载
    lazy: {
      type: Boolean,
      default: false
    },
    // 适应容器框
    fit: {
      type: String,
      default: 'cover'
    },
    // 设置图片预览的 z-index
    zIndex: {
      type: Number,
      default: 2000
    },
    // 是否可点击预览
    ifOnShow: {
      type: Boolean,
      default: true
    },
    // 预览时多图片集合
    srcList: {
      type: Array,
      default: null
    }
  },
  data () {
    return {}
  },
  created () {},
  computed: {
    // 如果线上地址没带域名就补充
    imgUrl () {
      const src = this.src
      const imgBaseUrl = this.$store.state.app.domainUrl

      if (/(http:|https:|\/img)/.test(src)) {
        return src
      } else {
        return imgBaseUrl + src
      }
    }
  },
  mounted () {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.el-image {
  border-radius: 5px;
  background-color: #ebeef5;
  box-shadow: 0 0 5px 1px #ccc;
  ::v-deep .image-slot {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #909399;
    font-size: 30px;
  }
}

.ifAnimation {
  ::v-deep .el-image__inner {
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      transform: scale(1.2);
    }
  }
}
</style>
