<!--
 * @Description: 会员管理
-->
<template>
  <div>
    <div class="TableTop">
      <!-- 搜索 -->
      <div class="TableSearch">
        <el-form :model="query" @submit.native.prevent>
          <el-form-item label="手机号码：" class="form-item-b0">
            <el-input v-model="query.search" placeholder="请输入手机号码" size="small" clearable></el-input>
          </el-form-item>
          <el-form-item label="状态：" class="form-item-b0">
            <el-select v-model="query.status" placeholder="请选择状态">
              <el-option label="正常" :value="1"></el-option>
              <el-option label="禁用" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="注册时间：" class="form-item-b0">
            <el-date-picker
              v-model="beginDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="最后登录时间：" class="form-item-b0">
            <el-date-picker
              v-model="LoginDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="会员状态" class="form-item-b0">
            <el-select v-model="query.isvip" placeholder="请选择状态">
              <el-option label="正常" :value="1"></el-option>
              <el-option label="已过期" :value="0"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="近期" class="form-item-b0">
            <span
              :class="current === 4 ? 'blue' : ''"
              style="margin-left: 20px; cursor: pointer"
              @click="getList('place', 2)"
              >昨天</span
            >
            <span
              :class="current === 2 ? 'blue' : ''"
              style="margin-left: 20px; cursor: pointer"
              @click="getList('place', 1)"
              >今天</span
            >
            <span
              :class="current === 1 ? 'blue' : ''"
              style="margin: 0 20px; cursor: pointer"
              @click="getList('place', 7)"
              >7天</span
            >
            <span :class="current === 3 ? 'blue' : ''" style="cursor: pointer" @click="getList('place', 30)">30天</span>
          </el-form-item>
        </el-form>
        <div class="TableSearch_btn">
          <el-button type="primary" size="small" @click="getList('place')">筛选</el-button>
          <el-button size="small" @click="init()">重置</el-button>
          <el-button type="warning" size="small" @click="excelDialogVisible = true">导出excel</el-button>
          <!-- <el-button type="success" size="small" @click="excelDialogVisible = true">导出</el-button> -->
          <el-dialog title="提示" :visible.sync="excelDialogVisible" width="30%" center>
            <span style="display: block; width: 100%; text-align: center">是否导出Excel</span>
            <span style="display: block; width: 100%; text-align: center; font-size: 24px; font-weight: 600"
              >{{ list.length }}条</span
            >
            <span slot="footer" class="dialog-footer">
              <el-button @click="excelDialogVisible = false">取 消</el-button>
              <el-button style="margin-left: 30%" type="primary" @click="exportExcel()">确 定</el-button>
            </span>
          </el-dialog>
        </div>
      </div>
    </div>
    <!-- 表格 -->
    <el-table ref="TableListRef" class="TableList" border :data="list" v-loading="loading">
      <el-table-column label="序" align="center" type="index" min-width="50"></el-table-column>

      <el-table-column prop="LoginName" label="手机号码" align="center" min-width="80">
        <template slot-scope="scope">
          <div style="cursor: pointer" @click.stop="query.search = scope.row.LoginName">
            {{ scope.row.LoginName }}
          </div>
        </template>
      </el-table-column>

      <el-table-column label="省市区" align="center" min-width="130">
        <template slot-scope="scope">{{ scope.row.Province }}-{{ scope.row.City }}-{{ scope.row.Area }}</template>
      </el-table-column>

      <el-table-column prop="StoreHeadImage" label="头像" align="center" min-width="50">
        <template slot-scope="scope">
          <template v-if="scope.row.StoreHeadImage">
            <el-avatar :size="30" fit="contain" :src="scope.row.StoreHeadImage"></el-avatar>
          </template>
          <template v-else>--</template>
        </template>
      </el-table-column>

      <el-table-column prop="VipTime" label="会员剩余时间" align="center" min-width="124">
        <template slot-scope="scope">
          <template>
            <span v-if="ifOverdue(scope.row.VipTime)" style="color: #f00">VIP已过期</span>
            <span v-else>VIP {{ scope.row.VipTime | remainingDays }}天</span>
          </template>
          ({{ new Date(scope.row.VipTime) | parseTime('{y}-{m}-{d}') }})
        </template>
      </el-table-column>

      <el-table-column prop="Integral" label="积分" align="center" min-width="60"></el-table-column>

      <el-table-column prop="ParentId" label="上线Id" align="center" min-width="60"></el-table-column>

      <el-table-column prop="CreateTime" label="创建时间" align="center" min-width="108">
        <template slot-scope="scope">
          {{ new Date(scope.row.CreateTime) | parseTime('{y}-{m}-{d} {h}:{i}') }}
        </template>
      </el-table-column>

      <el-table-column prop="LastUseTime" label="最后访问时间" align="center" min-width="108">
        <template slot-scope="scope">
          {{ new Date(scope.row.LastUseTime) | parseTime('{y}-{m}-{d} {h}:{i}') }}
        </template>
      </el-table-column>

      <!-- <el-table-column prop="LastLoginIp" label="最后登录IP" align="center"></el-table-column> -->

      <!-- <el-table-column prop="Status" label="状态" align="center">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.Status"
            :active-value="1"
            :inactive-value="0"
            @change="editStatus(scope.row)"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </template>
      </el-table-column> -->

      <el-table-column label="操作" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onEditInfo(scope.row)"> 编辑 </el-button>
          <!-- <div style="display: flex;">
            <el-button type="primary" size="mini" @click="onEditInfo(scope.row)">
              编辑
            </el-button>
            <el-button type="warning" size="mini" @click="onStoreInfo(scope.row)">
              升级
            </el-button>
          </div> -->
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="fenye">
      <el-pagination
        class="TablePagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageindex"
        :page-sizes="[20, 40, 60, 100, 1000]"
        :page-size="query.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <!-- 编辑会员资料 -->
    <el-dialog
      :visible.sync="editPhoneDialog"
      class="FormDialogBox"
      width="600px"
      title="编辑会员资料"
      :close-on-click-modal="false"
      @close="editPhoneCancel()"
    >
      <el-form label-width="auto" :model="editForm" :rules="rules" ref="ruleForm">
        <div style="display: flex">
          <el-form-item label="手机号" prop="Phone">
            <el-input v-model="editForm.Phone" placeholder="手机号" clearable maxlength="11"></el-input>
          </el-form-item>

          <el-form-item label="状态">
            <el-switch
              v-model="editForm.Status"
              :active-value="1"
              :inactive-value="0"
              @change="editStatus(editForm)"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </el-form-item>

          <el-form-item label="IP">
            <span>{{ editForm.LastLoginIp }}</span>
          </el-form-item>
        </div>

        <el-form-item label="密码" prop="Password">
          <el-input v-model="editForm.PassWord" placeholder="密码" clearable minlength="6" maxlength="12"></el-input>
        </el-form-item>

        <div style="display: flex">
          <el-form-item label="会员" prop="VipTime">
            <el-date-picker
              v-model="editForm.VipTime"
              type="date"
              placeholder="会员日期"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="商户" prop="VipTime">
            <el-date-picker
              v-model="editForm.StoreVipTime"
              type="date"
              placeholder="商户会员"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
        </div>

        <div style="display: flex">
          <el-form-item label="姓名">
            <el-input v-model="editForm.CardName" placeholder="银行卡姓名" clearable></el-input>
          </el-form-item>

          <el-form-item label="开户行">
            <el-input v-model="editForm.BankOfDeposit" placeholder="开户行" clearable></el-input>
          </el-form-item>
        </div>

        <el-form-item label="银行卡">
          <el-input v-model="editForm.BankCard" placeholder="银行卡" clearable></el-input>
        </el-form-item>

        <div style="display: flex">
          <el-form-item label="等级" prop="Level">
            <el-input v-model="editForm.Level" placeholder="会员等级" type="number" clearable></el-input>
          </el-form-item>

          <el-form-item label="积分" prop="Integral">
            <el-input v-model="editForm.Integral" placeholder="积分" type="number" clearable></el-input>
          </el-form-item>

          <el-form-item label="">
            <el-button type="primary" size="mini" @click="onTopUpWater(editForm.LoginName)">流水</el-button>
          </el-form-item>
        </div>

        <el-form-item label="省市区" prop="Province">
          <el-cascader
            v-model="region"
            :options="regionOptions"
            :props="{
              value: 'label',
              label: 'label',
              children: 'children'
            }"
            @change="handleChange"
            clearable
            style="width: 100%"
          ></el-cascader>
        </el-form-item>

        <el-form-item label="头像">
          <el-upload
            ref="upload"
            action="https://api.chajiuqu.com/api/current/uploadimage"
            list-type="picture-card"
            :file-list="myImage"
            :limit="1"
            :on-success="handleAvatarSuccess"
            :on-remove="handleRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editPhoneDialog = false">取 消</el-button>
        <el-button type="warning" @click="onStoreInfo(editForm)">升级</el-button>
        <el-button type="primary" @click="editPhoneDetermine()">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 商户管理 -->
    <el-dialog
      :visible.sync="editStoreDialog"
      class="FormDialogBox"
      width="800px"
      title="商户管理"
      :close-on-click-modal="false"
      @close="editStoreCancel()"
    >
      <el-form label-width="auto" :model="editForm2" :rules="ruleStore" ref="ruleStoreForm">
        <div style="display: flex">
          <el-form-item label="手机号" prop="LoginName">
            <el-input v-model="editForm2.LoginName" placeholder="手机号" disabled></el-input>
          </el-form-item>

          <el-form-item label="商家电话" prop="StorePhone">
            <el-input v-model="editForm2.StorePhone" placeholder="商家电话"></el-input>
          </el-form-item>
        </div>

        <div style="display: flex">
          <el-form-item label="商铺名称" prop="StoreName">
            <el-input v-model="editForm2.StoreName" placeholder="商铺名称"></el-input>
          </el-form-item>

          <el-form-item label="商户微信">
            <el-input v-model="editForm2.StoreWx" placeholder="商户微信"></el-input>
          </el-form-item>
        </div>

        <div style="display: flex">
          <el-form-item label="省份" prop="Province">
            <el-input v-model="editForm2.Province" placeholder="省份"></el-input>
          </el-form-item>
          <el-form-item label="城市" prop="City">
            <el-input v-model="editForm2.City" placeholder="城市"></el-input>
          </el-form-item>
          <el-form-item label="区域" prop="Area">
            <el-input v-model="editForm2.Area" placeholder="区域"></el-input>
          </el-form-item>
        </div>

        <el-form-item label="具体地址" prop="StoreAddress">
          <el-input v-model="editForm2.StoreAddress" placeholder="具体地址"></el-input>
        </el-form-item>

        <div style="display: flex">
          <el-form-item label="经度" prop="Longitude">
            <el-input v-model="editForm2.Longitude" placeholder="经度"></el-input>
          </el-form-item>
          <el-form-item label="纬度" prop="Latitude">
            <el-input v-model="editForm2.Latitude" placeholder="纬度"></el-input>
          </el-form-item>
        </div>

        <el-form-item label="营业执照">
          <el-upload
            ref="upload"
            action="https://api.chajiuqu.com/api/current/uploadimage"
            list-type="picture-card"
            :limit="1"
            :on-success="handleAvatarSuccess1"
            :on-remove="handleRemove1"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="身份证正面">
          <el-upload
            ref="upload"
            action="https://api.chajiuqu.com/api/current/uploadimage"
            list-type="picture-card"
            :limit="1"
            :on-success="handleAvatarSuccess2"
            :on-remove="handleRemove2"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="身份证反面">
          <el-upload
            ref="upload"
            action="https://api.chajiuqu.com/api/current/uploadimage"
            list-type="picture-card"
            :limit="1"
            :on-success="handleAvatarSuccess3"
            :on-remove="handleRemove3"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="商铺正面图片">
          <el-upload
            ref="upload"
            action="https://api.chajiuqu.com/api/current/uploadimage"
            list-type="picture-card"
            :limit="1"
            :on-success="handleAvatarSuccess4"
            :on-remove="handleRemove4"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <!-- <el-form-item label="审核状态">
          <el-input v-model="editForm2.StoreAuditStatus" disabled></el-input>
          <el-radio v-model="editForm2.StoreAuditStatus" :label="1">正在审核</el-radio>
          <el-radio v-model="editForm2.StoreAuditStatus" :label="2">审核通过</el-radio>
          <el-radio v-model="editForm2.StoreAuditStatus" :label="3">审核退回</el-radio>
        </el-form-item>
        <el-form-item label="审核内容">
          <el-input type="textarea" v-model="editForm2.StoreAuditContent"></el-input>
        </el-form-item> -->
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editStoreDialog = false">取 消</el-button>
        <el-button type="warning" @click="editStoreDetermine()">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="topUpWaterDialog"
      class="FormDialogBox"
      width="800px"
      title="商户管理"
      :close-on-click-modal="false"
      @close="topUpWaterCancel"
    >
      <TopUpList v-if="topUpWaterDialog" :merchantsInfo="{ LoginName: topUpWaterPhone }" :vipTime="false"></TopUpList>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="topUpWaterCancel">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import regionArr from '@/utils/citys.json'
import XLSX from 'xlsx'

export default {
  name: 'membersIndex',
  data() {
    return {
      StoreBannerImage: '',
      StoreIDImage: '',
      StoreIDImage2: '',
      StoreLicenseImage: '',
      imageUrl: '',
      options: [
        {
          value: '选项1',
          label: '全部'
        },
        {
          value: '选项2',
          label: '已过期'
        }
      ],
      value: '全部',
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      list: [],
      loading: true,
      LoginDate: [],
      beginDate: [
        this.$globalFun.parseTime(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 7), '{y}-{m}-{d}'),
        this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}')
      ],
      query: {
        pageindex: 1,
        pagesize: 20,
        search: '',
        status: null,
        isvip: null
      },
      total: 0, // 总条数
      // 修改手机号
      editPhoneDialog: false,
      editForm: {
        Phone: '',
        PassWord: '',
        VipTime: '',
        Level: '',
        Integral: '',
        Province: '',
        City: '',
        Area: ''
      },
      editForm2: {},
      rules: {
        Phone: [
          { required: true, message: ' ', trigger: 'blur' },
          { pattern: /^1[3|4|5|6|7|8|9]\d{9}$/, message: '请输入正确的号码格式', trigger: 'change' }
        ],
        // PassWord: [{ required: true, message: '密码长度6-12', trigger: 'change' }],
        VipTime: [{ required: true, message: ' ', trigger: 'change' }],
        Level: [{ required: true, message: ' ', trigger: 'blur' }],
        Integral: [{ required: true, message: ' ', trigger: 'blur' }]
        // Province: [{ required: true, message: '请选择省市区', trigger: 'change' }]
      },
      ruleStore: {
        StorePhone: [
          { required: true, message: ' ', trigger: 'blur' },
          { pattern: /^1[3|5|7|8|9]\d{9}$/, message: '请输入正确的号码格式', trigger: 'change' }
        ],
        StoreName: [{ required: true, message: ' ', trigger: 'blur' }],
        Province: [{ required: true, message: ' ', trigger: 'blur' }],
        City: [{ required: true, message: ' ', trigger: 'blur' }],
        Area: [{ required: true, message: ' ', trigger: 'blur' }],
        StoreAddress: [{ required: true, message: ' ', trigger: 'blur' }],
        Longitude: [{ required: true, message: ' ', trigger: 'blur' }],
        Latitude: [{ required: true, message: ' ', trigger: 'blur' }]
      },
      // 省市区
      region: null,
      regionOptions: regionArr,

      excelDialogVisible: false,

      editStoreDialog: false,

      current: 1,

      cardName: '',
      bankCard: '',
      bankOfDeposit: '',

      topUpWaterDialog: false,
      topUpWaterPhone: ''
    }
  },
  created() {
    this.getList('place', 7)
  },
  computed: {
    Address: function () {
      return this.editForm.Province + this.editForm.City + this.editForm.Area
    },
    srcList: function () {
      return [
        this.editForm.StoreLicenseImage,
        this.editForm.StoreIDImage,
        this.editForm.StoreIDImage2,
        this.editForm.StoreBannerImage
      ]
    },
    myImage: function () {
      const arr = []
      if (this.editForm.StoreHeadImage) {
        arr.push({
          url: this.editForm.StoreHeadImage
        })
      }
      return arr
    }
  },
  methods: {
    // 初始化
    init() {
      this.query.search = ''
      this.LoginDate = []
      this.beginDate = []
      this.query.status = null
      this.query.isvip = null

      this.current = 0
      this.region = null

      this.getList('place')
    },

    // 导出excel
    exportExcel() {
      console.log(XLSX, this.list)
      this.excelDialogVisible = false
      if (this.list.length === 0) {
        this.$message.warning('暂无数据')
        return
      }
      const exportList = [['手机号']]
      this.list.forEach((item) => {
        exportList.push([item.LoginName])
      })
      const sheet = XLSX.utils.aoa_to_sheet(exportList)
      this.openDownloadDialog(this.sheet2blob(sheet), '过期会员手机号.xlsx')
    },

    sheet2blob(sheet, sheetName) {
      sheetName = sheetName || 'sheet1'
      var workbook = {
        SheetNames: [sheetName],
        Sheets: {}
      }
      workbook.Sheets[sheetName] = sheet
      // 生成excel的配置项
      var work = {
        bookType: 'xlsx', // 要生成的文件类型
        bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        type: 'binary'
      }
      var xlsx = XLSX.write(workbook, work)
      var blob = new Blob([s2ab(xlsx)], { type: 'application/octet-stream' })
      // 字符串转ArrayBuffer
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length)
        var view = new Uint8Array(buf)
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
        return buf
      }
      return blob
    },

    openDownloadDialog(url, saveName) {
      if (typeof url === 'object' && url instanceof Blob) {
        url = URL.createObjectURL(url) // 创建blob地址
      }
      var aLink = document.createElement('a')
      aLink.href = url
      aLink.download = saveName || '' // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
      var event
      if (window.MouseEvent) event = new MouseEvent('click')
      else {
        event = document.createEvent('MouseEvents')
        event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
      }
      aLink.dispatchEvent(event)
    },

    handleAvatarSuccess(res, file, filelist) {
      // console.log(res, file, filelist)
      this.imageUrl = res.data
    },
    handleRemove(file, filelist) {
      console.log(file, filelist)
      this.editForm.StoreHeadImage = ''
      this.imageUrl = ''
    },

    // 图片上传
    handleAvatarSuccess1(res, file, filelist) {
      this.StoreBannerImage = res.data
    },
    handleRemove1(file, filelist) {
      console.log(file, filelist)
      this.StoreBannerImage = ''
    },
    handleAvatarSuccess2(res, file, filelist) {
      this.StoreIDImage = res.data
    },
    handleRemove2(file, filelist) {
      this.StoreIDImage = ''
    },
    handleAvatarSuccess3(res, file, filelist) {
      this.StoreIDImage2 = res.data
    },
    handleRemove3(file, filelist) {
      this.StoreIDImage2 = ''
    },
    handleAvatarSuccess4(res, file, filelist) {
      this.StoreLicenseImage = res.data
    },
    handleRemove4(file, filelist) {
      this.StoreLicenseImage = ''
    },

    // async exportExcel() {
    //   this.excelDialogVisible = false
    //   if (!this.LoginDate || !this.LoginDate.length) {
    //     delete this.query.beginLoginTime
    //     delete this.query.endLoginTime
    //   } else {
    //     this.query.beginLoginTime = this.LoginDate[0]
    //     this.query.endLoginTime = this.LoginDate[1]
    //   }

    //   if (!this.beginDate || !this.beginDate.length) {
    //     delete this.query.beginTime
    //     delete this.query.endTime
    //   } else {
    //     this.query.beginTime = this.beginDate[0]
    //     this.query.endTime = this.beginDate[1]
    //   }
    //   console.log(this.query)
    //   delete this.query.pageindex
    //   delete this.query.pagesize
    //   const { data } = await this.$api.members.exportExcel(this.query)
    //   console.log('excel-url', data)
    //   window.location.href = data
    // },

    // 获取列表
    async getList(place, day) {
      // 重置页数
      if (place) {
        this.query.pageindex = 1
        // 滚动条回到顶部
        this.$nextTick(() => {
          this.$refs.TableListRef.bodyWrapper.scrollTop = 0
        })
      }

      if (!this.LoginDate || !this.LoginDate.length) {
        delete this.query.beginLoginTime
        delete this.query.endLoginTime
      } else {
        this.query.beginLoginTime = this.LoginDate[0]
        this.query.endLoginTime = this.LoginDate[1]
      }

      if (!this.beginDate || !this.beginDate.length) {
        delete this.query.beginTime
        delete this.query.endTime
      } else {
        this.query.beginTime = this.beginDate[0]
        this.query.endTime = this.beginDate[1]
      }

      if (day) {
        this.query.endTime = this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}')
        this.query.beginTime = this.$globalFun.parseTime(
          new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * (day - 1)),
          '{y}-{m}-{d}'
        )
        this.beginDate[0] = this.query.beginTime
        this.beginDate[1] = this.query.endTime
        if (day === 7) this.current = 1
        if (day === 1) this.current = 2
        if (day === 2) {
          this.current = 4
          this.query.endTime = this.query.beginTime
          this.beginDate[0] = this.query.beginTime
          this.beginDate[1] = this.query.endTime
        }
        if (day === 30) this.current = 3
      } else {
        this.current = 0
      }

      if (this.query.search) {
        delete this.query.beginTime
        delete this.query.endTime
      }

      console.log(this.query)

      const { data: res } = await this.$api.members.getMembersList(this.query)
      console.log('获取列表', res)

      this.loading = false
      this.list = res.list
      this.total = res.count

      // const { data } = await this.$api.user.loglist({
      //   pageindex: 1,
      //   pagesize: 10,
      //   search: '13542884241'
      // })
      // console.log('data', data)
    },
    // 每页显示条数和当前页码
    handleSizeChange(newSize) {
      this.query.pagesize = newSize
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    },
    handleCurrentChange(newPage) {
      this.query.pageindex = newPage
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    },

    // 状态改变
    async editStatus(item) {
      console.log('状态改变', item)

      try {
        const form = {
          listid: [item.Id],
          status: item.Status
        }
        await this.$api.members.editMembersStatus(form)
        this.$message.success('状态改变成功')
        this.getList()
      } catch (e) {
        this.getList()
      }
    },

    async onTopUpWater(LoginName) {
      console.log(LoginName)
      this.topUpWaterPhone = LoginName

      await this.$nextTick()
      this.topUpWaterDialog = true
    },

    topUpWaterCancel() {
      this.topUpWaterDialog = false
      this.topUpWaterPhone = ''
    },

    // 编辑会员信息
    onEditInfo(item) {
      const form = this.$globalFun.deepClone(item)
      form.Phone = form.LoginName
      this.editForm = form
      this.region = [this.editForm.Province, this.editForm.City, this.editForm.Area]
      this.bankCard = this.editForm.BankCard
      this.cardName = this.editForm.CardName
      this.bankOfDeposit = this.editForm.BankOfDeposit
      console.log('editForm', this.editForm)
      this.editPhoneDialog = true
    },
    editPhoneCancel() {
      // console.log('对话框关闭')
      this.editForm = {}
      this.imageUrl = ''
      this.region = null
      this.bankCard = ''
      this.cardName = ''
      this.bankOfDeposit = ''
    },
    async editPhoneDetermine() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (!valid) return

        if (this.imageUrl.length > 0) {
          this.editForm.StoreHeadImage = this.imageUrl
        }
      })

      console.log('region', this.region)

      if (this.region) {
        this.editForm.Province = this.region[0]
        this.editForm.City = this.region[1]
        this.editForm.Area = this.region[2]
      } else {
        this.editForm.Province = ''
        this.editForm.City = ''
        this.editForm.Area = ''
      }

      const form = {
        Id: this.editForm.Id,
        StoreheadImage:
          this.editForm.StoreHeadImage && this.editForm.StoreHeadImage.length > 0
            ? this.editForm.StoreHeadImage
            : 'https://api.chajiuqu.com/uploadImage/img/defaultPict.png',
        PassWord: this.editForm.PassWord
      }
      if (this.editForm.PassWord.length > 10) delete form.PassWord
      await this.$api.members.edit(form)

      if (
        this.bankCard !== this.editForm.BankCard ||
        this.cardName !== this.editForm.CardName ||
        this.bankOfDeposit !== this.editForm.BankOfDeposit
      ) {
        const { data: res } = await this.$api.commission.editBank({
          Id: this.editForm.Id,
          BankCard: this.editForm.BankCard,
          CardName: this.editForm.CardName,
          BankOfDeposit: this.editForm.BankOfDeposit,
          CardImage: this.editForm.CardImage
        })
        console.log('银行卡', res)
      }

      await this.$api.members.editInfo(this.editForm)
      this.$message.success('修改成功')
      this.editForm = {}
      this.imageUrl = ''
      this.region = null
      this.getList()
      this.editPhoneDialog = false
    },

    // 升级商户
    onStoreInfo(item) {
      const form = this.$globalFun.deepClone(item)
      this.editForm2 = form
      // console.log('1111', this.editForm)
      this.editStoreDialog = true
    },
    editStoreCancel() {
      this.editForm2 = {}
    },
    async editStoreDetermine() {
      this.$refs.ruleStoreForm.validate(async (valid) => {
        if (!valid) {
          this.$message.warning('请完善资料')
          return
        }
        if (
          this.StoreBannerImage === '' ||
          this.StoreIDImage === '' ||
          this.StoreIDImage2 === '' ||
          this.StoreLicenseImage === ''
        ) {
          this.$message.warning('请完善资料')
          return
        }

        const form = {}
        form.Id = this.editForm2.Id
        form.StoreName = this.editForm2.StoreName
        form.StorePhone = this.editForm2.StorePhone
        form.Province = this.editForm2.Province
        form.City = this.editForm2.City
        form.Area = this.editForm2.Area
        form.StoreAddress = this.editForm2.StoreAddress
        form.Longitude = this.editForm2.Longitude - 0
        form.Latitude = this.editForm2.Latitude - 0
        form.StoreWx = this.editForm2.StoreWx
        form.StoreBannerImage = this.StoreBannerImage
        form.StoreIDImage = this.StoreIDImage
        form.StoreIDImage2 = this.StoreIDImage2
        form.StoreLicenseImage = this.StoreLicenseImage

        console.log('form', form)

        const { data: res1 } = await this.$api.members.editStore(form)
        console.log('提交结果', res1)

        // if (this.editForm2.StoreAuditStatus === 2 || this.editForm2.StoreAuditStatus === 3) {
        //   const { data: res2 } = await this.$api.members.auditStore({
        //     Id: this.editForm2.Id,
        //     StoreAuditStatus: this.editForm2.StoreAuditStatus,
        //     StoreAuditContent: this.editForm2.StoreAuditContent
        //   })
        //   console.log('审核结果', res2)
        // }

        this.$message.success('修改成功')
        this.StoreBannerImage = ''
        this.StoreIDImage = ''
        this.StoreIDImage2 = ''
        this.StoreLicenseImage = ''
        this.getList()
        this.editStoreDialog = false
      })
    },

    // 地区选择
    handleChange(e) {
      console.log('e', e)
      this.editForm.Province = e[0]
      this.editForm.City = e[1]
      this.editForm.Area = e[2]
    },

    // 判断是否过期
    ifOverdue(time) {
      // console.log('time', time)
      const nowDate = new Date()
      const date = new Date(time).getTime() - nowDate.getTime()

      return date < 0
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table {
  font-size: 12px !important;
}
</style>
