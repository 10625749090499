<!--
 * @Description: 资讯文章管理
-->
<template>
  <div>
    <div class="TableTop">
      <!-- 搜索 -->
      <div class="TableSearch">
        <el-form :model="query" @submit.native.prevent>
          <el-form-item label="标题：" class="form-item-b0">
            <el-input
              v-model="query.search"
              placeholder="请输入标题"
              size="small"
              clearable
              @clear="getList('place')"
            ></el-input>
          </el-form-item>
          <el-form-item label="栏目：" class="form-item-b0">
            <el-cascader
              v-model="query.category"
              :options="columnList"
              :show-all-levels="false"
              :props="{
                emitPath: false,
                value: 'Id',
                label: 'Title',
                children: 'Subitem'
              }"
              placeholder="请选择栏目"
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="类型：" class="form-item-b0" clearable>
            <el-select v-model="type" placeholder="请选择类型" @change="ontypeChange">
              <el-option label="置顶" :value="0"></el-option>
              <el-option label="推荐" :value="1"></el-option>
              <!-- <el-option label="热门" :value="2"></el-option> -->
              <el-option label="是否登录可见" :value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="TableSearch_btn">
          <el-button type="primary" size="small" @click="getList('place')">筛选</el-button>
          <el-button size="small" @click="init()">重置</el-button>
        </div>
      </div>
      <!-- 按钮 -->
      <div class="TableTopBtn">
        <el-button type="primary" size="small" icon="el-icon-plus" @click="onAddOrEdit()">
          新增
        </el-button>
      </div>
    </div>
    <!-- 表格 -->
    <el-table ref="TableListRef" class="TableList" border :data="list" v-loading="loading">
      <el-table-column label="封面" align="center" width="120">
        <template slot-scope="scope">
          <ImgBox :src="scope.row.Image" :width="80" :height="80"></ImgBox>
        </template>
      </el-table-column>
      <el-table-column prop="Title" label="标题" align="center" min-width="160"></el-table-column>
      <!-- <el-table-column prop="Source" label="来源" align="center"></el-table-column> -->
      <el-table-column label="栏目" align="center">
        <template slot-scope="scope">
          <el-tag v-for="item in scope.row.Category" :key="item.Id" type="info" style="margin: 0 4px;">{{
            item.Title
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="是否推荐" align="center">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.Recommend"
            :active-value="1"
            :inactive-value="0"
            @change="editRecommend(scope.row)"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="Hot" label="是否热门" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.Hot === 1">是</el-tag>
          <el-tag v-else type="info">否</el-tag>
        </template>
      </el-table-column> -->
      <el-table-column prop="LoginShow" label="是否登录可见" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.LoginShow === 1">是</el-tag>
          <el-tag v-else type="info">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="Hits" label="点击数" align="center"></el-table-column>
      <el-table-column prop="Sort" label="排序" align="center"></el-table-column>
      <el-table-column prop="ReleaseTime" label="发布时间" align="center">
        <template slot-scope="scope">
          {{ new Date(scope.row.ReleaseTime) | parseTime }}
        </template>
      </el-table-column>
      <el-table-column prop="UpdateTime" label="修改时间" align="center">
        <template slot-scope="scope">
          {{ new Date(scope.row.UpdateTime) | parseTime }}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="Status" label="状态" align="center">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.Status"
            :active-value="1"
            :inactive-value="0"
            @change="editStatus(scope.row)"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </template>
      </el-table-column> -->
      <el-table-column label="操作" align="center" width="160" fixed="right">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onAddOrEdit(scope.row.Id)">编辑</el-button>
          <el-button type="danger" size="mini" @click="editStatus(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      class="TablePagination"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.page"
      :page-sizes="[20, 40, 60, 100]"
      :page-size="query.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  name: 'articleIndex',
  data () {
    return {
      list: [],
      loading: true,
      type: null,
      query: {
        pageindex: 1,
        pagesize: 20,
        search: '',
        category: ''
      },
      total: 0, // 总条数
      // 栏目列表
      columnList: []
    }
  },
  created () {
    this.init()
    this.getColumnList()
  },
  methods: {
    // 获取栏目列表
    async getColumnList () {
      const { data: res } = await this.$api.column.getList({})
      const hot = {
        Id: 0,
        Title: '推荐',
        Subitem: [
          {
            Id: -1,
            Title: '热点行情',
            Subitem: null
          }
        ]
      }
      this.columnList = [hot, ...res]
      console.log(this.columnList)
    },

    // 类型初始化
    typeInit () {
      this.query.top = null
      this.query.recommend = null
      this.query.hot = null
      this.query.loginShow = null
    },

    // 初始化
    init () {
      this.query.search = ''
      this.query.category = null

      this.type = null

      this.typeInit()
      this.getList('place')
    },

    // 类型选中
    ontypeChange (e) {
      console.log('e', e)
      this.typeInit()

      switch (e) {
        case 0:
          this.query.top = 1
          break
        case 1:
          this.query.recommend = 1
          break
        case 2:
          this.query.hot = 1
          break
        default:
          this.query.loginShow = 1
          break
      }
    },

    // 获取列表
    async getList (place) {
      // 重置页数
      if (place) {
        this.query.pageindex = 1
        // 滚动条回到顶部
        this.$nextTick(() => {
          this.$refs.TableListRef.bodyWrapper.scrollTop = 0
        })
      }
      console.log(this.query)
      const { data: res } = await this.$api.article.getList(this.query)
      console.log('获取列表', res)
      this.loading = false
      this.list = res.list
      this.total = res.count
    },
    // 每页显示条数和当前页码
    handleSizeChange (newSize) {
      this.query.pagesize = newSize
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    },
    handleCurrentChange (newPage) {
      this.query.pageindex = newPage
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    },

    // 新增、编辑
    onAddOrEdit (id) {
      const url = id ? 'edit?id=' + id : 'add'
      this.$router.push(url)
    },

    // 推荐
    async editRecommend (item) {
      const form = {
        ...item
      }
      form.Category = []
      for (const i of item.Category) {
        form.Category.push(i.Id)
      }
      await this.$api.article.edit(form)
      this.$message.success('状态改变成功')
      this.getList()
    },

    // 状态改变
    async editStatus (item) {
      // 模态框
      const confirmResult = await this.$confirm('是否删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      // 取消
      if (confirmResult !== 'confirm') return

      // console.log('状态改变', item)

      const form = {
        listid: [item.Id],
        Status: 0
      }
      await this.$api.article.edit_status(form)
      this.$message.success('删除成功')
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped></style>
