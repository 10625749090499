<!--
 * @Description: 新增编辑对话框
-->
<template>
  <el-dialog
    :visible.sync="addOrEditDialog"
    class="FormDialogBox"
    width="700px"
    :title="addOrEditTypeTitle[addOrEditType]"
    @close="addOrEditCancel()"
  >
    <el-form :model="addOrEditForm" :rules="addOrEditRules" ref="addOrEditFormRef" label-width="auto">
      <el-form-item label="显示位置" prop="Category">
        <el-select v-model="addOrEditForm.Category" placeholder="请选择显示位置">
          <el-option label="首页" :value="-1"></el-option>
          <el-option label="报价列表" :value="-2"></el-option>
          <el-option v-for="item in columnList" :key="item.Id" :label="item.Title" :value="item.Id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="轮播图名称" prop="Title">
        <el-input v-model="addOrEditForm.Title" placeholder="请输入轮播图名称"></el-input>
      </el-form-item>
      <el-form-item label="跳转路径" prop="Link">
        <el-input v-model="addOrEditForm.Link" placeholder="请输入跳转路径"></el-input>
      </el-form-item>
      <el-form-item label="图片" prop="Image">
        <ImageUpload :url.sync="addOrEditForm.Image" :width="300" :height="140" />
      </el-form-item>
      <el-form-item label="排序" prop="Sort">
        <el-input v-model.number="addOrEditForm.Sort" type="number" placeholder="请输入排序"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="addOrEditCancel()">取 消</el-button>
      <el-button type="primary" @click="addOrEditDetermine()">{{ addOrEditTypeTitle[addOrEditType] }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'addOrEditDialog',
  data () {
    return {
      addOrEditDialog: false,
      addOrEditType: 0, // 0新增,1编辑
      addOrEditTypeTitle: ['新增', '编辑'],
      addOrEditForm: {
        Category: '',
        Title: '',
        Link: '',
        Image: '',
        Sort: 0,
        Status: 1
      },
      addOrEditRules: {
        Category: [{ required: true, message: '请选择显示位置', trigger: 'change' }],
        Title: [{ required: true, message: '请输入轮播图名称', trigger: 'blur' }],
        // Link: [{ required: true, message: '请输入跳转路径', trigger: 'blur' }],
        Image: [{ required: true, message: '请上传图片' }],
        Sort: [{ required: true, message: '请输入排序', trigger: 'blur' }]
      },
      // 栏目列表
      columnList: []
    }
  },
  methods: {
    // 对话框显示隐藏
    async showAddOrEdit (Id) {
      this.getColumnList()

      this.addOrEditType = Id ? 1 : 0
      if (Id) {
        const { data: res } = await this.$api.ad.getInfo({ Id })
        console.log('根据id获取详情', res)
        this.addOrEditForm = res
      }
      this.addOrEditDialog = true
    },

    // 获取栏目列表
    async getColumnList () {
      const { data: res } = await this.$api.column.getList({})
      // console.log('columnList', res)
      this.columnList = res
    },

    // 对话框关闭
    addOrEditCancel () {
      this.$refs.addOrEditFormRef.resetFields()
      this.addOrEditForm = this.$options.data().addOrEditForm
      this.addOrEditDialog = false
    },

    // 确认
    addOrEditDetermine () {
      console.log('this.addOrEditForm', this.addOrEditForm)
      this.$refs.addOrEditFormRef.validate(async valid => {
        if (!valid) return

        if (this.addOrEditType === 0) {
          await this.$api.ad.add(this.addOrEditForm)
        } else {
          await this.$api.ad.edit(this.addOrEditForm)
        }
        this.$message.success(`${this.addOrEditTypeTitle[this.addOrEditType]}成功`)
        this.addOrEditCancel()
        // 通知父级更新列表
        this.$emit('fetch-data')
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
