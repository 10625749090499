<!--
 * @Description: 会员管理
-->
<template>
  <div>
    <div class="TableTop">
      <!-- 搜索 -->
      <div class="TableSearch">
        <el-form :model="query" @submit.native.prevent>
          <el-form-item label="手机号码：" class="form-item-b0">
            <el-input
              v-model="query.search"
              maxlength="11"
              placeholder="请输入手机号码"
              size="small"
              clearable
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="时间：" class="form-item-b0">
            <el-date-picker
              v-model="beginDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>-->
        </el-form>
        <div class="TableSearch_btn">
          <el-button type="primary" size="small" @click="getList('place')">筛选</el-button>
          <el-button size="small" @click="init()">重置</el-button>
        </div>
        <div style="color: red; margin-left: 10px; height: 42px; line-height: 42px;">{{ hintStr }}</div>
      </div>
    </div>
    <!-- 表格 -->
    <el-table ref="TableListRef" class="TableList" border :data="list" v-loading="loading">
      <el-table-column prop="LoginName" label="手机号码" align="center">
        <template slot-scope="scope">
          <template>
            <div
              style="cursor: pointer;"
              @click.stop="query.search = scope.row.LoginName"
            >{{ scope.row.LoginName }}</div>
          </template>
          <template>
            <span v-if="ifOverdue(scope.row.VipTime)" style="color: #f00;">VIP已过期</span>
            <span v-else>(VIP {{ scope.row.VipTime | remainingDays }}天)</span>
          </template>
        </template>
      </el-table-column>

      <el-table-column prop="Date" align="center" label="一周总访问">
        <template slot-scope="scope">{{ scope.row.allCount }}</template>
      </el-table-column>

      <el-table-column
        prop="Date"
        align="center"
        :label="new Date(day1.time) | parseTime(`{y}-{m}-{d}\n星期{a}\n(${day1.count}人)`)"
      >
        <template slot-scope="scope">{{ scope.row[day1.time]?scope.row[day1.time].LogCount:0 }}</template>
      </el-table-column>

      <el-table-column
        prop="Date"
        align="center"
        :label="new Date(day2.time) | parseTime(`{y}-{m}-{d}\n星期{a}\n(${day2.count}人)`)"
      >
        <template slot-scope="scope">{{ scope.row[day2.time]?scope.row[day2.time].LogCount:0 }}</template>
      </el-table-column>

      <el-table-column
        prop="Date"
        align="center"
        :label="new Date(day3.time) | parseTime(`{y}-{m}-{d}\n星期{a}\n(${day3.count}人)`)"
      >
        <template slot-scope="scope">{{ scope.row[day3.time]?scope.row[day3.time].LogCount:0 }}</template>
      </el-table-column>

      <el-table-column
        prop="Date"
        align="center"
        :label="new Date(day4.time) | parseTime(`{y}-{m}-{d}\n星期{a}\n(${day4.count}人)`)"
      >
        <template slot-scope="scope">{{ scope.row[day4.time]?scope.row[day4.time].LogCount:0 }}</template>
      </el-table-column>

      <el-table-column
        prop="Date"
        align="center"
        :label="new Date(day5.time) | parseTime(`{y}-{m}-{d}\n星期{a}\n(${day5.count}人)`)"
      >
        <template slot-scope="scope">{{ scope.row[day5.time]?scope.row[day5.time].LogCount:0 }}</template>
      </el-table-column>

      <el-table-column
        prop="Date"
        align="center"
        :label="new Date(day6.time) | parseTime(`{y}-{m}-{d}\n星期{a}\n(${day6.count}人)`)"
      >
        <template slot-scope="scope">{{ scope.row[day6.time]?scope.row[day6.time].LogCount:0 }}</template>
      </el-table-column>

      <el-table-column
        prop="Date"
        align="center"
        :label="new Date(day7.time) | parseTime(`{y}-{m}-{d}\n星期{a}\n(${day7.count}人)`)"
      >
        <template slot-scope="scope">{{ scope.row[day7.time]?scope.row[day7.time].LogCount:0 }}</template>
      </el-table-column>

      <el-table-column label="操作" align="center" width="140" fixed="right">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onInfo(scope.row, 30)">历史详情</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="fenye" v-if="!infoDialog">
      <el-pagination
        class="TablePagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageindex"
        :page-sizes="[20, 40, 60, 100]"
        :page-size="query.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <div v-show="infoDialog" class="info">
      <!-- <div class="btn">
        <el-button size="small" @click="infoDialog = false">返回</el-button>
      </div>-->

      <div style="margin-top: 100px; text-align: center; color: blue;">{{ from.LoginName }}</div>

      <div id="myChart" style="width: 1200px; height: 460px; margin: 20px auto 0;"></div>

      <div class="btn2">
        <el-button size="small" @click="infoDialog = false">返回</el-button>
        <el-button size="small" @click="onInfo(from, 7)">7天</el-button>
        <el-button size="small" @click="onInfo(from, 14)">14天</el-button>
        <el-button size="small" @click="onInfo(from, 30)">30天</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
let chartLine = null

export default {
  name: 'accessIndex',
  data() {
    return {
      // 日期选择
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      // 数据列表
      data: [],
      list: [],
      loading: false,
      query: {
        search: ''
      },
      // 总条数
      total: 0,
      // 历史详情
      infoDialog: false,
      from: {},
      infoList: [],

      day1: {
        time: this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}'),
        count: 0
      },
      day2: {
        time: this.$globalFun.parseTime(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 1), '{y}-{m}-{d}'),
        count: 0
      },
      day3: {
        time: this.$globalFun.parseTime(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 2), '{y}-{m}-{d}'),
        count: 0
      },
      day4: {
        time: this.$globalFun.parseTime(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 3), '{y}-{m}-{d}'),
        count: 0
      },
      day5: {
        time: this.$globalFun.parseTime(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 4), '{y}-{m}-{d}'),
        count: 0
      },
      day6: {
        time: this.$globalFun.parseTime(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 5), '{y}-{m}-{d}'),
        count: 0
      },
      day7: {
        time: this.$globalFun.parseTime(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 6), '{y}-{m}-{d}'),
        count: 0
      },

      beginDate: [
        this.$globalFun.parseTime(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 6), '{y}-{m}-{d}'),
        // this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}'),
        this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}')
      ],

      hintStr: ''
    }
  },
  created() {
    // this.getList('place')
  },
  methods: {
    // 初始化
    init() {
      this.beginDate = [
        this.$globalFun.parseTime(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 6), '{y}-{m}-{d}'),
        this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}')
      ]
      this.query = {
        search: ''
      }

      this.getList('place')
    },

    async onInfo(row, day) {
      this.from = row
      const date = this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}')

      const list1 = []
      const list2 = []
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      const cDate = this.$globalFun.parseTime(
        new Date(date).setTime(new Date(date).getTime() - 3600 * 1000 * 24 * day),
        '{y}-{m}-{d}'
      )
      const Dform = {
        pageindex: 1,
        search: this.from.LoginName,
        begindate: cDate,
        enddate: date
      }
      console.log(Dform)
      const { data } = await this.$api.user.loglist(Dform)
      // console.log(data.list)

      for (let i = 0; i < day; i++) {
        const dDate = this.$globalFun.parseTime(
          new Date(date).setTime(new Date(date).getTime() - 3600 * 1000 * 24 * i),
          '{y}-{m}-{d}'
        )

        list1.push(this.$globalFun.parseTime(new Date(dDate), '{m}/{d}\n星期{a}'))

        const arr = data.list.filter(item => {
          return item.Date === dDate
        })

        if (arr[0]) {
          list2.push(arr[0].LogCount)
        } else {
          list2.push(0)
        }
      }
      // console.log(list1, list2)

      loading.close()

      chartLine = echarts.init(document.getElementById('myChart'))
      var option = {
        xAxis: {
          type: '',
          name: '',
          nameTextStyle: {
            padding: [0, 0, 0, 0]
          },
          boundaryGap: false,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#666'
            }
          },
          data: list1
        },
        yAxis: {
          name: '访问次数',
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#666'
            }
          },
          nameTextStyle: {
            padding: [0, 0, 0, 0]
          },
          splitLine: {
            lineStyle: {
              color: ['#ddd']
            }
          }
        },
        series: [
          {
            type: 'line',
            symbol: 'circle',
            symbolSize: 10,
            // smooth: true,
            name: '访问数据',
            data: list2,
            cursor: 'pointer',
            lineStyle: {
              color: '#409EFF'
            },
            label: {
              show: true,
              position: 'top'
            },
            itemStyle: {
              color: '#409EFF'
            }
          }
        ]
      }

      chartLine.setOption(option, true)

      this.infoDialog = true
    },

    infoCancel() {
      this.infoDialog = false
    },

    // 获取列表
    async getList(place) {
      // 重置页数
      if (place) {
        this.query.pageindex = 1

        // 滚动条回到顶部
        this.$nextTick(() => {
          this.$refs.TableListRef.bodyWrapper.scrollTop = 0
        })
      }

      if (!this.beginDate || !this.beginDate.length) {
        delete this.query.begindate
        delete this.query.enddate
      } else {
        this.query.begindate = this.beginDate[0]
        this.query.enddate = this.beginDate[1]
      }

      if (this.query.search === '') {
        this.$message.warning('请输入查询号码')
        return
      }

      console.log('query', this.query)

      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      const { data } = await this.$api.user.loglist(this.query)
      // console.log('data', data)

      if (data.list.length === 0) {
        const res = await this.$api.user.checkPhone({
          phone: this.query.search
        })
        console.log(res.data)
        this.hintStr = res.data ? '手机号未注册' : '该用户一个月内未访问'
      } else {
        this.quickSort(data.list)

        this.data = this.uniq(data.list)

        // this.data = data.list
        this.total = this.data.length

        this.list = this.data.slice(0, 20)
      }

      this.loading = false

      loading.close()
    },

    uniq(arr) {
      var newArr = []
      var temp = {}
      // var num = 0

      for (var i = 0; i < arr.length; i++) {
        const loginName = arr[i].LoginName
        if (!temp[loginName]) {
          temp[loginName] = {
            Date: arr[i].Date,
            LoginName: arr[i].LoginName,
            VipTime: arr[i].VipTime,
            allCount: 0
          }
          temp[loginName][arr[i].Date] = arr[i]
        } else {
          temp[loginName][arr[i].Date] = arr[i]
        }
      }
      console.log('temp', temp)

      // console.log('day', this.day1)

      this.day1.count = 0
      this.day2.count = 0
      this.day3.count = 0
      this.day4.count = 0
      this.day5.count = 0
      this.day6.count = 0
      this.day7.count = 0

      for (const i in temp) {
        temp[i].allCount = 0

        for (const k in temp[i]) {
          if (temp[i][k].LogCount) {
            temp[i].allCount += temp[i][k].LogCount
          }
        }

        if (temp[i][this.day1.time]) {
          this.day1.count += 1
        }
        if (temp[i][this.day2.time]) {
          this.day2.count += 1
        }
        if (temp[i][this.day3.time]) {
          this.day3.count += 1
        }
        if (temp[i][this.day4.time]) {
          this.day4.count += 1
        }
        if (temp[i][this.day5.time]) {
          this.day5.count += 1
        }
        if (temp[i][this.day6.time]) {
          this.day6.count += 1
        }
        if (temp[i][this.day7.time]) {
          this.day7.count += 1
          // console.log(temp[i][this.day7.time].LoginName, temp[i][this.day7.time].LogCount)
        }

        newArr.push(temp[i])
      }

      this.quickSort(newArr)

      // console.log(newArr)

      return newArr
    },

    quickSort(arr) {
      for (var j = 0; j < arr.length - 1; j++) {
        for (var i = 0; i < arr.length - 1 - j; i++) {
          if (arr[i].allCount < arr[i + 1].allCount) {
            var temp = arr[i]
            arr[i] = arr[i + 1]
            arr[i + 1] = temp
          }
        }
      }
      return arr
    },

    // 每页显示条数和当前页码
    handleSizeChange(newSize) {
      this.query.pagesize = newSize
      console.log(newSize)
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    },
    async handleCurrentChange(newPage) {
      this.query.pageindex = newPage
      console.log(20 * (newPage - 1))

      this.list = this.data.slice(20 * (newPage - 1), 20 * newPage)

      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    },

    // 判断是否过期
    ifOverdue(time) {
      // console.log('time', time)
      const nowDate = new Date()
      const date = new Date(time).getTime() - nowDate.getTime()

      return date < 0
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .FormDialogBox > .el-dialog {
  height: 100%;
  margin: 0 !important;
}
::v-deep .el-table__header > .has-gutter {
  .cell {
    white-space: pre-wrap;
  }
}
.info {
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: #fff;
  z-index: 8;
  position: fixed;
  top: 0;
  left: 0;
  .btn {
    position: absolute;
    top: 60px;
    right: 10px;
  }
  .btn2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
  }
}
</style>
