// 产品管理 product
import http from '../request.js'

export default {
  /* 产品列表 */
  getList: data => {
    return http({
      url: '/api/product/list',
      data
    })
  },

  /* 添加图片 */
  addImage: data => {
    return http({
      url: '/api/product/edit',
      data
    })
  },

  /* 添加产品 */
  add: data => {
    return http({
      url: '/api/product/add',
      data
    })
  },

  /* 删除产品 */
  del: data => {
    return http({
      url: '/api/product/delete',
      data
    })
  },

  /* 用户图片上传 */
  userUploadImg: data => {
    return http({
      url: '/api/product/getProductImage',
      data
    })
  },

  /* 用户图片上传 */
  userSaveImg: data => {
    return http({
      url: '/api/product/saveImage',
      data
    })
  }
}
