<template>
  <div>
    <div class="TableTop">
      <div class="TableTop_tit">{{ label }}行情--共{{ allListNum }}只 日期：{{ date }}</div>

      <div class="TableTop_btn">
        <span v-if="draftsData.Content">
          {{ $globalFun.parseTime(new Date(draftsData.DraftsDate), '{y}/{m}/{d} {h}:{i}:{s}') }}
        </span>

        <div style="margin-left: 10px" v-for="item in dialogArr" :key="item.btnName">
          <el-button
            :type="item.btnType"
            :plain="item.btnPlain"
            size="small"
            v-if="item.btnShow(draftsData, loadDraftsBtn)"
            @click="item.dialogShow = true"
          >
            {{ item.btnName }}
          </el-button>
        </div>
      </div>
    </div>

    <!-- 倒计时 -->
    <div class="count_down" v-if="time > 0">上传倒计时---{{ time }}秒</div>

    <div class="divTable">
      <!-- 行数 -->
      <div class="numIndex">
        <div class="order" v-for="item in row" :key="item">{{ item }}</div>
      </div>

      <div class="ulTables">
        <div v-for="(itemList, i) in allList" :key="i">
          <ul class="ulTable" v-for="item in itemList" :key="item.Id">
            <!-- 参考价格 -->
            <li
              class="reference_price"
              :style="{ display: 'mobile' + item[order] + item[column] === current ? 'block' : 'none' }"
              v-if="item.differencePrice && item.differencePrice[price]"
            >
              <span style="color: blue">参考值:</span>

              <span style="color: red">
                {{ $globalFun.getNum(item.differencePrice[price]) }}
              </span>
            </li>

            <!-- 其他区域价格参考 -->
            <li
              class="other_price"
              :style="{ display: 'mobile' + item[order] + item[column] === current ? 'flex' : 'none' }"
            >
              <div v-for="other in otherPrice" :key="other.key">
                <span v-if="item[other.key] && other.key !== price">
                  <span style="color: blue">{{ other.name }}:</span>
                  <span style="color: red">{{ $globalFun.getNum(item[other.key]) }}</span>
                </span>
              </div>
            </li>

            <!-- 名称 :class="[index === current ? 'red' : '', item.Order > 0 ? 'gray' : '']" -->
            <li
              class="title"
              :class="[
                otherOrder && item[otherOrder] > 0 ? 'gray' : '',
                'mobile' + item[order] + item[column] === current ? 'red' : ''
              ]"
            >
              {{ item.Name }}
            </li>

            <el-input
              class="price"
              :ref="'mobile' + item[order] + item[column]"
              v-model="item.Price"
              :label="item.Name"
              :placeholder="item[price]"
              size="medium"
              @keyup.native.down="keyDown(item[order], item[column])"
              @keyup.native.up="keyUp(item[order], item[column])"
              @keyup.native.right="keyRight(item[order], item[column])"
              @keyup.native.left="keyLeft(item[order], item[column])"
              @keyup.native.enter="keyDown(item[order], item[column])"
              @focus="onFocus(item[order], item[column])"
            >
            </el-input>

            <!-- 排序 -->
            <!-- <li>{{ item[order] }},{{ item[column] }}</li> -->

            <!-- 差价>10提示 -->
            <li style="color: red; font-size: 12px" v-if="item.Price && Math.abs(getPrice(item)) > 9">
              {{ getPrice(item) }}
            </li>

            <!-- 与其他地区差价>19提示 -->
            <!-- <li
              style="color: red; font-size: 12px"
              v-if="
                item[price] &&
                item.n > 1 &&
                Math.abs(
                  $d.sub(+$globalFun.getNum($d.div($d.sub(item.allPrice, item[price]), item.n - 1)), item[price])
                ) > 19
              "
            >
              {{
                Math.abs(
                  $d.sub(+$globalFun.getNum($d.div($d.sub(item.allPrice, item[price]), item.n - 1)), item[price])
                )
              }}
            </li> -->
          </ul>
        </div>
      </div>
    </div>

    <div style="width: 100%; height: 500px"></div>

    <div v-for="item in dialogArr" :key="item.title">
      <el-dialog title="提示" :visible.sync="item.dialogShow" width="30%" center>
        <span style="display: block; width: 100%; text-align: center">{{ item.title }}</span>

        <span slot="footer" class="dialog-footer">
          <el-button @click="item.dialogShow = false">取 消</el-button>
          <el-button type="primary" @click="item.onClick(item)">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: 'amendmentItem',

  props: {
    list: {
      type: Array,
      required: true
    },
    order: {
      type: String,
      default: 'Order'
    },
    column: {
      type: String,
      default: 'Column'
    },
    otherOrder: {
      type: String,
      default: ''
    },
    price: {
      type: String,
      default: 'LSPrice'
    },
    type: {
      type: String,
      default: '1'
    },
    label: {
      type: String,
      default: '流沙'
    }
  },

  data() {
    return {
      // 日期
      date: this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}'),

      time: 0,

      allList: [],

      loading: true,

      // 输入框焦点位置
      current: 'mobile11',

      // 总行数
      row: 0,

      otherPrice: [
        {
          name: '流',
          key: 'LSPrice'
        },
        {
          name: '珠',
          key: 'ZSJPrice'
        },
        {
          name: '深A',
          key: 'SZPrice'
        },
        {
          name: '深B',
          key: 'SZPrice2'
        },
        {
          name: '湖',
          key: 'HNPrice'
        }
      ],

      // 按钮
      dialogArr: [
        {
          dialogShow: false,
          btnShow: (draftsData) => {
            return draftsData.Content
          },
          btnName: '载入草稿',
          btnType: 'primary',
          btnPlain: true,
          title: '是否载入草稿',
          onClick: (item) => {
            item.dialogShow = false
            this.loadDrafts()
          }
        },
        {
          dialogShow: false,
          btnShow: (draftsData, loadDraftsBtn = false) => {
            return !draftsData.Content || loadDraftsBtn
          },
          btnName: '保存草稿',
          btnType: 'primary',
          btnPlain: false,
          title: '是否保存草稿',
          onClick: (item) => {
            item.dialogShow = false
            this.saveDrafts()
          }
        },
        {
          dialogShow: false,
          btnShow: (draftsData) => {
            return true
          },
          btnName: '更新',
          btnType: 'success',
          btnPlain: false,
          title: '更新后草稿会清空，请确认更新',
          onClick: (item) => {
            item.dialogShow = false
            this.update()
          }
        },
        {
          dialogShow: false,
          btnShow: (draftsData) => {
            return true
          },
          btnName: '同步',
          btnType: 'warning',
          btnPlain: false,
          title: '是否同步数据',
          onClick: (item) => {
            item.dialogShow = false
            this.synchronization()
          }
        }
      ],
      loadDraftsBtn: false,

      draftsData: {
        Content: ''
      },

      loadDraftVisible: false, // 载入草稿
      saveDraftVisible: false, // 保存草稿
      uploadVisible: false // 更新价格
    }
  },

  computed: {
    allListNum() {
      let num = 0

      this.allList.forEach((item) => {
        num += item.length
      })

      return num
    }
  },

  created() {},

  watch: {
    list: {
      immediate: true,
      handler(newVal) {
        console.log(this.label, newVal)
        if (newVal.length === 0) return

        const a = newVal.map((item) => {
          return item.map((items) => {
            return items.Name
          })
        })
        console.log(a)

        const arr = []
        this.allList = []
        newVal.forEach((item, index) => {
          const length = item.length
          arr.push(length)

          this.allList.push([...item])
        })

        const max = Math.max(...arr)
        this.row = max

        this.$nextTick(() => {
          const mobile = 'mobile11'
          // console.log(this.$refs[mobile])
          if (this.$refs[mobile]) this.$refs[mobile][0].focus()
        })
      }
    }
  },

  mounted() {},

  methods: {
    // 初始化
    init() {
      // 清空输入框
      const refList = this.$refs
      for (const obj in refList) {
        if (refList[obj].length > 0) {
          refList[obj][0].clear()
        }
      }

      const mobile = 'mobile11'
      if (this.$refs[mobile]) this.$refs[mobile][0].focus()
    },

    getPrice(item) {
      if (isNaN(Number(item.Price))) {
        return 0
      }

      if (item.Price && item[this.price]) {
        // console.log(this.$d.sub(item.Price, item[this.price]).toNumber())
        return this.$d.sub(item.Price, item[this.price]).toNumber()
      } else {
        return 0
      }
    },

    async getDrafts() {
      console.log('getDrafts', this.type)
      const { data } = await this.$api.offer.getDrafts({
        type: this.type
      })

      console.log(data)

      if (!data) {
        return
      }

      this.draftsData = data

      if (!data || !data.Content) {
        return
      }

      const strArr = data.Content.split('|')
      console.log(strArr)
    },

    async loadDrafts() {
      console.log('loadDrafts')
      this.loadDraftsBtn = true

      if (!this.draftsData.Content) {
        this.$message.warning('暂无草稿')
        return
      }

      const loading = this.$loading({
        lock: true,
        text: '保存中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      const { data } = await this.$api.offer.getDrafts({
        type: this.type
      })
      // console.log(data)
      this.draftsData = data

      if (!data || !data.Content) {
        this.$message({
          message: '数据错误',
          type: 'success',
          duration: 800
        })
        return
      }

      const strArr = data.Content.split('|')
      // console.log(strArr)

      this.allList.forEach((list) => {
        list.forEach((item) => {
          strArr.forEach((items, index) => {
            if (items.indexOf(item.Name + ',') === 0) {
              const num = strArr[index].indexOf(',') + 1
              item.Price = strArr[index].slice(num)
            }
          })
        })
      })

      loading.close()

      this.$message({
        message: '载入成功',
        type: 'success',
        duration: 800
      })
    },

    async saveDrafts() {
      console.log('saveDrafts')

      const refList = this.$refs
      console.log(refList)

      const priceObj = {}
      const loading = this.$loading({
        lock: true,
        text: '保存中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      for (const obj in refList) {
        if (refList[obj].length < 1) {
          return
        }

        if (isNaN(refList[obj][0].value - 0) && refList[obj][0].value) {
          loading.close()
          this.$message.error('请检查数据是否正确')
          this.$refs[obj][0].focus()
          return
        }
        priceObj.type = this.type

        let str = ''
        if (refList[obj][0].value) {
          str = refList[obj][0].label + ',' + (refList[obj][0].value - 0)
        }

        if (str) {
          if (!priceObj.content) {
            priceObj.content = str
          } else {
            priceObj.content = priceObj.content + '|' + str
          }
        }
      }

      console.log(priceObj)
      if (!priceObj.content) {
        loading.close()
        this.$message.warning('请输入数据')
        return
      }

      const { data } = await this.$api.offer.editDrafts(priceObj)
      console.log('保存草稿成功', data)

      this.draftsData = data

      loading.close()
      this.$message.success('保存成功')
    },

    // 同步数据
    async synchronization() {
      console.log('同步数据')
      const loading = this.$loading({
        lock: true,
        text: '正在同步数据',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      try {
        const { data } = await this.$api.offer.synchronization({
          type: this.type
        })
        console.log('同步成功', data)
      } catch (error) {
        this.$message.success('同步失败, 请重试')
      } finally {
        loading.close()
      }
    },

    async update() {
      console.log('update')

      const refList = this.$refs

      const priceObj = {}
      const priceArr = []
      priceObj.Date = this.date
      priceObj.type = this.type
      console.log(priceObj.Date)

      this.isUpdate = true

      const loading = this.$loading({
        lock: true,
        text: '上传中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      this.countDown(30)

      for (const obj in refList) {
        // console.log(refList[obj])
        if (refList[obj].length < 1) {
          return
        }

        if (isNaN(refList[obj][0].value - 0) && refList[obj][0].value) {
          loading.close()
          this.$message.error('请检查数据是否正确')
          this.$refs[obj][0].focus()
          return
        }

        let str = ''
        if (refList[obj][0].value) {
          str = refList[obj][0].label + ',' + (refList[obj][0].value - 0)
        } else {
          str = refList[obj][0].label + ',' + refList[obj][0].$attrs.placeholder
        }

        priceArr.push(str)

        // if (!priceObj.priceStr) {
        //   priceObj.priceStr = str
        // } else {
        //   priceObj.priceStr = priceObj.priceStr + '|' + str
        // }
      }

      priceObj.priceStr = priceArr.join('|')
      console.log(priceObj)
      if (priceObj.priceStr) {
        const data = await this.$api.offer.addData(priceObj)
        await this.$api.offer.editDrafts({
          type: this.type,
          content: ''
        })
        this.isUpdate = false
        // this.$message.success(data.msg)
        this.$alert(data.msg, '提示', {
          confirmButtonText: '确定',
          callback: (action) => {
            this.init()
            this.getDrafts()

            this.$emit('update')
          }
        })
      } else {
        this.$message.error('请检查数据是否正确')
      }

      loading.close()
    },

    countDown(time, timeout = 1000) {
      console.log(time)
      this.time = time
      if (time > 0 && this.isUpdate) {
        setTimeout(() => {
          this.countDown(time - 1)
        }, timeout)
      } else {
        this.time = 0
      }
    },

    async updateData(arr) {
      const priceObj = {}
      priceObj.Date = this.date
      priceObj.type = this.type

      try {
        priceObj.priceStr = arr.slice(0, 100).join('|')
        console.log(priceObj)

        await this.$api.offer.addData(priceObj)
      } catch (error) {
        this.$message.error('上传出错请重试')
      }

      if (arr.length > 100) {
        await this.updateData(arr.slice(100))
      }
    },

    setFocus() {
      const mobile = this.current
      this.$nextTick(() => {
        if (this.$refs[mobile]) this.$refs[mobile][0].focus()
      })
    },

    // 输入框获得焦点
    onFocus(order, column) {
      // console.log(order, column)
      this.current = 'mobile' + order + column
    },
    // 上
    keyUp(order, column) {
      let nextOrder = order - 1
      let nextColum = column
      // console.log('keyUp', nextOrder, nextColum)

      if (nextOrder === 0 && nextColum === 1) {
        console.log('keyUp return')
        return
      }

      if (nextOrder === 0) {
        const list = this.allList[nextColum - 2]
        const length = list.length

        nextOrder = length
        nextColum--
      }

      const nextRef = 'mobile' + nextOrder + nextColum
      console.log('nextRef', nextRef)
      this.$refs[nextRef][0].focus()
    },
    // 下
    keyDown(order, column) {
      console.log('keyDown', order, column)

      let nextOrder = order + 1
      let nextColum = column

      const list = this.allList[column - 1]
      const length = list.length

      if (nextOrder > length && nextColum === this.allList.length) {
        console.log('keyDown return')
        return
      }

      if (nextOrder > length) {
        nextOrder = '1'
        nextColum++
      }

      const nextRef = 'mobile' + nextOrder + nextColum
      // console.log('nextRef', nextRef)
      this.$refs[nextRef][0].focus()
    },
    // 左
    keyLeft(order, column) {
      let nextOrder = order
      let nextColum = column - 1
      //   console.log('keyLeft', nextOrder, nextColum)

      if (nextOrder === 1 && nextColum === 0) {
        console.log('keyLeft return')
        return
      }

      const length = this.allList.length

      if (nextColum === 0) {
        nextOrder--
        nextColum = length
      }

      const nextRef = 'mobile' + nextOrder + nextColum
      this.$refs[nextRef][0].focus()
    },
    // 右
    keyRight(order, column) {
      let nextOrder = order
      let nextColum = column + 1
      //   console.log('keyLeft', nextOrder, nextColum)

      if (nextColum > this.allList.length) {
        nextColum = 1
        nextOrder++
      }

      const list = this.allList[nextColum - 1]
      const length = list.length

      if (nextOrder > length) {
        console.log('keyRight return')
        return
      }

      const nextRef = 'mobile' + nextOrder + nextColum
      this.$refs[nextRef][0].focus()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .price > input.el-input__inner {
  color: #e33ae6 !important;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
}

::v-deep .price > input::-webkit-outer-spin-button,
::v-deep .price > input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep .price > input[type='number'] {
  -moz-appearance: textfield;
  appearance: none;
}

::v-deep .price > input::-webkit-input-placeholder {
  color: #666;
}

.TableTop {
  display: flex;
  padding-top: 10px;

  background-color: #fff;

  position: sticky;
  top: 54px;
  left: 0;

  z-index: 9;

  .TableTop_tit {
    width: 300px;
  }

  .TableTop_btn {
    flex: 2;
    margin-left: 300px;

    display: flex;
    align-items: center;
  }
}

.count_down {
  padding: 10px 20px;

  font-size: 32px;

  background-color: #fff;
  border-radius: 10px;

  position: fixed;
  top: 10%;
  left: 40%;

  transform: translateX(-50%);

  z-index: 999999999;
}

.divTable {
  width: 1480px;
  display: flex;
  // flex-wrap: wrap;
  margin-top: 20px;

  .numIndex {
    width: 40px;

    .order {
      text-align: center;
      color: blue;
      height: 24px;
      line-height: 24px;
    }
  }

  .ulTables {
    flex: 2;
    display: flex;
    justify-content: flex-start;
  }

  .ulTable {
    width: 200px;
    height: 24px;
    line-height: 24px;
    display: flex;
    position: relative;

    .reference_price {
      height: 24px;
      // padding: 0 4px;
      background-color: #efefef;
      display: none;
      position: absolute;
      top: -48px;
      left: 0;
    }

    .other_price {
      // width: 100%;
      height: 24px;
      // padding: 0 4px;
      background-color: #efefef;
      display: none;
      position: absolute;
      top: -24px;
      left: 0;
    }

    li.red:hover {
      overflow: inherit;
    }

    .title {
      width: 43%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-left: 5px;
      font-size: 13px;
    }

    .price {
      width: 35%;
    }

    .red {
      width: 45%;
      color: red;
      font-weight: bold;
      font-size: 14px;
    }

    .gray {
      color: rgba(0, 0, 0, 0.4);
    }

    .red:hover {
      overflow: none;
    }
  }
}
</style>
