<!--
 * @Description: 修改密码
-->
<template>
  <div>
    <el-row>
      <el-col :md="24" :lg="12">
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <el-form-item label="原密码" prop="old_pd">
            <el-input v-model="form.old_pd" type="password" placeholder="请输入原密码" />
          </el-form-item>
          <el-form-item label="新密码" prop="pd">
            <el-input v-model="form.pd" type="password" placeholder="请输入原密码" />
          </el-form-item>
          <el-form-item label="确认新密码" prop="again_pd">
            <el-input v-model="form.again_pd" type="password" placeholder="请输入原密码" />
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <fixed-action-bar>
      <el-button type="primary" @click="onSubmit">提交</el-button>
      <el-button @click="onCancel()">取消</el-button>
    </fixed-action-bar>
  </div>
</template>

<script>
export default {
  data () {
    const validatePassword = (rule, value, callback) => {
      if (value !== this.form.pd) {
        callback(new Error('2次密码不一致'))
      } else {
        callback()
      }
    }
    return {
      form: {
        old_pd: '',
        pd: '',
        again_pd: ''
      },
      rules: {
        old_pd: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
        pd: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, max: 18, trigger: 'blur', message: '密码长度为6到18位' }
        ],
        again_pd: [{ required: true, message: '请输入新密码', trigger: 'blur' }, { validator: validatePassword }]
      }
    }
  },
  methods: {
    onSubmit () {
      this.$refs.form.validate(async valid => {
        if (!valid) return

        // const form = this.form
        // await this.$api.user.change_pd(form)

        this.$store.dispatch('user/logout').then(() => {
          this.$message({
            type: 'success',
            message: '修改成功，请重新登录'
          })
        })
      })
    },

    onCancel () {
      this.$router.go(-1)
    }
  }
}
</script>
