<template>
  <div :class="sidebar.opened?'':'menu_item'">
    <template v-if="icon">
      <template v-if="icon.includes('el-icon')">
        <i :class="[icon, 'sub-el-icon']" />
      </template>

      <template v-else>
        <svg-icon :icon-class="icon" />
      </template>
    </template>

    <template v-if="sidebar.opened">
      <span slot='title' v-if="title">{{ title.split('(')[0] }}</span>

      <span slot='num' class="num" v-if="num">{{ num }}</span>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'MenuItem',
  // functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    num: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters(['sidebar', 'avatar'])
  }
  // render (h, context) {
  //   const { icon, title, num } = context.props
  //   console.log('12345', context.props)
  //   const vnodes = []

  //   if (icon) {
  //     if (icon.includes('el-icon')) {
  //       vnodes.push(<i class={[icon, 'sub-el-icon']} />)
  //     } else {
  //       vnodes.push(<svg-icon icon-class={icon}/>)
  //     }
  //   }

  //   if (title) {
  //     vnodes.push(<span slot='title'>{(title)}</span>)
  //   }

  //   if (num) {
  //     vnodes.push(<span slot='num' class="num">{(num)}</span>)
  //   }
  //   return vnodes
  // }
}
</script>

<style scoped>
.menu_item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu_item .sub-el-icon {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.sub-el-icon {
  color: currentColor;
  width: 1em !important;
  height: 1em;
}

.num {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  border-radius: 50%;
  background-color: red;
  color: #fff;
  text-align: center;
  line-height: 20px;
}
</style>
