<!--
 * @Description: 报价公告: 新增、编辑
-->
<template>
  <el-dialog
    :visible.sync="addOrEditDialog"
    class="FormDialogBox"
    width="700px"
    :title="addOrEditTypeTitle[addOrEditType] + '-' + title"
    @close="addOrEditCancel()"
  >
    <el-form :model="addOrEditForm" :rules="addOrEditRules" ref="addOrEditFormRef" label-width="auto">
      <el-form-item label="内容" prop="Content">
        <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="addOrEditForm.Content"> </el-input>
      </el-form-item>
      <!-- <el-form-item label="类型" prop="Type">
        <el-select v-model="addOrEditForm.Type" placeholder="请选择类型">
          <el-option label="酒" :value="1"></el-option>
          <el-option label="烟" :value="2"></el-option>
        </el-select>
      </el-form-item> -->
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="addOrEditCancel()">取 消</el-button>
      <el-button type="primary" @click="addOrEditDetermine()">{{ addOrEditTypeTitle[addOrEditType] }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'announcementDialog',
  data () {
    return {
      addOrEditDialog: false,
      title: '',
      addOrEditType: 0, // 0新增,1编辑
      addOrEditTypeTitle: ['新增', '编辑'],
      addOrEditForm: {
        Content: '',
        Type: ''
      },
      addOrEditRules: {
        Content: [{ required: true, message: '请输入内容', trigger: 'blur' }]
        // Type: [{ required: true, message: '请选择类型', trigger: 'change' }]
      }
    }
  },
  methods: {
    // 对话框显示隐藏
    async showAddOrEdit (Id) {
      this.addOrEditType = Id ? 1 : 0
      if (Id) {
        const { data: res } = await this.$api.offer.getInfo({ Id })
        console.log('根据id获取详情', res)
        this.addOrEditForm = res

        this.title = res.Type === 1 ? '酒' : '烟'
      }

      this.addOrEditDialog = true
    },

    // 对话框关闭
    addOrEditCancel () {
      this.$refs.addOrEditFormRef.resetFields()
      this.addOrEditForm = this.$options.data().addOrEditForm
      this.addOrEditDialog = false
    },

    // 确认
    addOrEditDetermine () {
      console.log('this.addOrEditForm', this.addOrEditForm)
      this.$refs.addOrEditFormRef.validate(async valid => {
        if (!valid) return

        if (this.addOrEditType === 0) {
          // await this.$api.ad.add(this.addOrEditForm)
        } else {
          await this.$api.offer.edit(this.addOrEditForm)
        }
        this.$message.success(`${this.addOrEditTypeTitle[this.addOrEditType]}成功`)
        this.addOrEditCancel()
        // 通知父级更新列表
        this.$emit('fetch-data')
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
