<!--
 * @Description: 会员管理
-->
<template>
  <div>
    <!-- 搜索 -->
    <div class="TableTop">
      <div class="TableSearch">
        <el-form :model="query" @submit.native.prevent>
          <el-form-item label="手机号码：" class="form-item-b0">
            <el-input v-model="query.search" placeholder="请输入手机号码" size="small" maxlength="11" clearable></el-input>
          </el-form-item>

          <el-form-item label="地区" class="form-item-b0">
            <AddressPicker ref="AddressPicker" @handleChange="handleChange"></AddressPicker>
          </el-form-item>

          <el-form-item label="热门" class="form-item-b0">
            <el-select v-model="hotAreaVal" filterable placeholder="请选择">
              <el-option
                v-for="(item, index) in hotAreaList"
                :key="index"
                :label="item.Area || '空'"
                :value="item.Area">
                <span style="float: left">{{ item.Area || '空' }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.RegCount }}</span>
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="认证商户" class="form-item-b0">
            <el-select v-model="query.isStore" placeholder="请选择状态">
              <el-option label="全部" :value="null"></el-option>
              <el-option label="认证" :value="1"></el-option>
              <el-option label="过期" :value="0"></el-option>
              <!-- <el-option label="未认证" :value="0"></el-option> -->
            </el-select>
          </el-form-item>

          <el-form-item label="VIP会员" class="form-item-b0">
            <el-select v-model="query.isvip" placeholder="请选择状态">
              <el-option label="全部" :value="null"></el-option>
              <el-option label="正常" :value="1"></el-option>
              <el-option label="已过期" :value="0"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="是否充值" class="form-item-b0">
            <el-select v-model="query.isRecharge" placeholder="请选择状态">
              <el-option label="全部" :value="null"></el-option>
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="0"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="最后登录时间：" class="form-item-b0">
            <el-date-picker
              v-model="LoginDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="注册时间：" class="form-item-b0">
            <el-date-picker
              v-model="beginDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="快捷" class="form-item-b0">
            <span
              :class="current === 1 ? 'blue' : ''"
              style="margin-left: 20px; cursor: pointer;"
              @click="getList('place', 1)"
              >昨天</span
            >
            <span
              :class="current === 2 ? 'blue' : ''"
              style="margin-left: 20px; cursor: pointer;"
              @click="getList('place', 2)"
              >今天</span
            >
            <span
              :class="current === 3 ? 'blue' : ''"
              style="margin: 0 20px; cursor: pointer;"
              @click="getList('place', 3)"
              >7天</span
            >
            <span :class="current === 4 ? 'blue' : ''" style="cursor: pointer;" @click="getList('place', 4)">30天</span>
          </el-form-item>

          <div style="height: 40px; line-height: 40px; margin-bottom: 10px;">
            <span style="font-size: 18px; color: #333333;"> 筛选用户数 {{ total }} </span>
          </div>
        </el-form>

        <div class="TableSearch_btn">
          <el-button type="primary" size="small" @click="getList('place')">筛选</el-button>
          <el-button size="small" @click="init()">重置</el-button>
        </div>
      </div>
    </div>

    <!-- 表格 -->
    <el-table ref="TableListRef" class="TableList" border :data="list" v-loading="loading">
      <el-table-column label="序" align="center" type="index" min-width="50"></el-table-column>

      <el-table-column prop="LoginName" label="手机号码" align="center" min-width="80">
        <template slot-scope="scope">
          <div style="cursor: pointer;" @click.stop="query.search = scope.row.LoginName">
            {{ scope.row.LoginName }}
          </div>
        </template>
      </el-table-column>

      <el-table-column label="省市区" align="center" min-width="130">
        <template slot-scope="scope">{{ scope.row.Province }}-{{ scope.row.City }}-{{ scope.row.Area }}</template>
      </el-table-column>

      <el-table-column prop="VipTime" label="会员剩余时间" align="center" min-width="124">
        <template slot-scope="scope">
          <template>
            <span v-if="ifOverdue(scope.row.VipTime)" style="color: #f00;">VIP已过期</span>
            <span v-else>VIP {{ scope.row.VipTime | remainingDays }}天</span>
          </template>
          ({{ new Date(scope.row.VipTime) | parseTime('{y}-{m}-{d}') }})
        </template>
      </el-table-column>

      <el-table-column prop="Integral" label="积分" align="center" min-width="60"></el-table-column>

      <el-table-column prop="Integral" label="认证商户" align="center" min-width="60">
        <template slot-scope="scope">
          {{ scope.row.CertificationType ? '是' : '否' }}
        </template>
      </el-table-column>

      <el-table-column prop="ParentId" label="上线Id" align="center" min-width="60"></el-table-column>

      <el-table-column prop="CreateTime" label="注册时间" align="center" min-width="108">
        <template slot-scope="scope">
          {{ new Date(scope.row.CreateTime) | parseTime('{y}-{m}-{d} {h}:{i}') }}
        </template>
      </el-table-column>

      <el-table-column prop="LastUseTime" label="最后访问时间" align="center" min-width="108">
        <template slot-scope="scope">
          {{ new Date(scope.row.LastUseTime) | parseTime('{y}-{m}-{d} {h}:{i}') }}
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onEditInfo(scope.row)">
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="fenye">
      <el-pagination
        class="TablePagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageindex"
        :page-sizes="[20, 40, 60, 100]"
        :page-size="query.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <!-- 编辑会员资料 -->
    <el-dialog
      :visible.sync="editDialog"
      class="FormDialogBox"
      width="800px"
      title="编辑会员资料"
      :close-on-click-modal="false"
      @close="editCancel()"
    >
      <div class="tabs">
        <el-tabs v-model="activeName" stretch @tab-click="handleClick">
          <el-tab-pane label="会员资料" name="会员资料"></el-tab-pane>
          <el-tab-pane v-if="editForm.IsStore" label="商户资料" name="商户资料"></el-tab-pane>
          <el-tab-pane v-if="editForm.IsStore" label="发布历史" name="发布历史"></el-tab-pane>
          <el-tab-pane v-if="editForm.IsStore" label="商品发布" name="商品发布"></el-tab-pane>
          <el-tab-pane label="充值记录" name="充值记录"></el-tab-pane>
          <el-tab-pane label="商户产品库" name="商户产品库"></el-tab-pane>
          <el-tab-pane label="留言" name="留言"></el-tab-pane>
          <el-tab-pane label="关注" name="关注"></el-tab-pane>
          <el-tab-pane label="粉丝" name="粉丝"></el-tab-pane>
        </el-tabs>
      </div>

      <div style="min-height: 600px;" v-if="activeName === '会员资料'">
        <membersInfo ref="membersInfo" @editOk="editOk" :info="editForm"></membersInfo>
      </div>

      <div style="min-height: 600px;" v-else-if="activeName === '商户资料'">
        <MerchantsInfo ref="MerchantsInfo" @editOk="editOk" :info="editForm"></MerchantsInfo>
      </div>

      <div style="min-height: 600px;" v-else-if="activeName === '发布历史'">
        <ReleaseList :StoreName="editForm.StoreName"></ReleaseList>
      </div>

      <div style="min-height: 600px;" v-else-if="activeName === '充值记录'">
        <TopUpList :merchantsInfo="editForm"></TopUpList>
      </div>

      <div style="min-height: 600px;" v-else-if="activeName === '商品发布'">
        <GoodsRelease ref="GoodsRelease" :merchantsInfo="editForm"></GoodsRelease>
      </div>

      <div style="min-height: 600px;" v-else-if="activeName === '商户产品库'">
        <MerchantsProducts ref="MerchantsProducts" :merchantsInfo="editForm"></MerchantsProducts>
      </div>

      <div style="min-height: 600px;" v-if="activeName === '关注'">
        <FocusAndFans :loading="loading" :UserId="editForm.Id"></FocusAndFans>
      </div>

      <div style="min-height: 600px;" v-else-if="activeName === '粉丝'">
        <FocusAndFans :loading="loading" type="1" :UserId="editForm.Id"></FocusAndFans>
      </div>

      <div style="min-height: 600px;" v-else-if="activeName === '留言'">
        <MessageList
          :merchantsInfo="editForm"
          type="1"
          :merchants="true"
          releaseType="1"
          :loading="loading"
        ></MessageList>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editCancel()">取 消</el-button>
        <el-button type="primary" @click="editDetermine()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'managementIndex',
  data () {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },

      current: null,

      loading: false,
      LoginDate: [],
      beginDate: [],
      query: {
        pageindex: 1,
        pagesize: 20,
        search: '',
        isvip: null,
        isStore: null,
        isRecharge: null,
        province: null,
        city: null,
        area: null
      },

      list: [],
      hotAreaList: [],
      hotAreaVal: '无名',
      total: 0,

      rules: {
        Phone: [
          { required: true, message: ' ', trigger: 'blur' },
          { pattern: /^1[3|4|5|6|7|8|9]\d{9}$/, message: '请输入正确的号码格式', trigger: 'change' }
        ],
        // PassWord: [{ required: true, message: '密码长度6-12', trigger: 'change' }],
        VipTime: [{ required: true, message: ' ', trigger: 'change' }],
        Level: [{ required: true, message: ' ', trigger: 'blur' }],
        Integral: [{ required: true, message: ' ', trigger: 'blur' }]
        // Province: [{ required: true, message: '请选择省市区', trigger: 'change' }]
      },
      editDialog: false,
      editForm: {},

      activeName: '会员资料'
    }
  },

  created () {
    this.getList()
    this.getHotArea()
  },

  methods: {
    init () {
      this.current = null
      this.LoginDate = []
      this.beginDate = []
      this.query = {
        pageindex: 1,
        pagesize: 20,
        search: '',
        isvip: null,
        isStore: null,
        isRecharge: null,
        province: null,
        city: null,
        area: null
      }

      if (this.$refs.AddressPicker) {
        this.$refs.AddressPicker.init()
      }

      this.getList()
    },

    handleChange (e) {
      for (const key in e) {
        if (e[key]) {
          this.query[key] = e[key]
        } else {
          this.query[key] = null
        }
      }
      if (
        this.query.province === '北京' ||
        this.query.province === '天津' ||
        this.query.province === '上海' ||
        this.query.province === '重庆'
      ) {
        this.query.city = this.query.province + '市'
        this.query.province = null
      }
      // console.log(this.query)
    },

    async handleClick (tab) {
      console.log(this.activeName)
      if (this.activeName === '商户产品库') {
        const { data: res } = await this.$api.release.goodsList({
          category1: '',
          loginName: this.editForm.LoginName,
          pageindex: 1,
          pagesize: 10,
          type: 1
        })
        console.log('商户产品库', res)
      }
    },

    async getHotArea () {
      const { data } = await this.$api.user.getHotArea({})
      console.log('热门城市', data)
      this.hotAreaList = data
      this.hotAreaList[0].Area = '无名'
      this.hotAreaList.forEach(item => {
        console.log(item.Area + item.RegCount)
      })
    },

    async getList (place, current) {
      this.loading = true
      // 重置页数
      if (place) {
        this.query.pageindex = 1
        // 滚动条回到顶部
        this.$nextTick(() => {
          this.$refs.TableListRef.bodyWrapper.scrollTop = 0
        })
      }

      if (!this.LoginDate || !this.LoginDate.length) {
        delete this.query.beginLoginTime
        delete this.query.endLoginTime
      } else {
        this.query.beginLoginTime = this.LoginDate[0]
        this.query.endLoginTime = this.LoginDate[1]
      }

      if (!this.beginDate || !this.beginDate.length) {
        delete this.query.beginTime
        delete this.query.endTime
      } else {
        this.query.beginTime = this.beginDate[0]
        this.query.endTime = this.beginDate[1]
      }

      if (current) {
        switch (current) {
          case 1:
            this.query.beginTime = this.$globalFun.parseTime(
              new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 1),
              '{y}-{m}-{d}'
            )
            break
          case 2:
            this.query.beginTime = this.$globalFun.parseTime(
              new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 0),
              '{y}-{m}-{d}'
            )
            break
          case 3:
            this.query.beginTime = this.$globalFun.parseTime(
              new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 6),
              '{y}-{m}-{d}'
            )
            break
          case 4:
            this.query.beginTime = this.$globalFun.parseTime(
              new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 29),
              '{y}-{m}-{d}'
            )
            break
        }

        this.current = current
        this.query.endTime = this.$globalFun.parseTime(
          new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * (current === 1 ? 1 : 0)),
          '{y}-{m}-{d}'
        )
        this.beginDate = []
        this.beginDate[0] = this.query.beginTime
        this.beginDate[1] = this.query.endTime
      } else {
        this.current = null
      }

      if (this.query.search) {
        delete this.query.beginTime
        delete this.query.endTime
        delete this.query.isvip
        delete this.query.isStore
        delete this.query.isRecharge
      }

      console.log(this.query)

      const { data: res } = await this.$api.members.getMembersList(this.query)
      console.log('获取列表', res)
      this.loading = false
      this.list = res.list
      this.total = res.count
    },

    // 编辑会员信息
    async onEditInfo (item) {
      const { data: res } = await this.$api.members.getStoreList({
        search: item.LoginName
      })
      this.editForm = res.list[0]

      console.log('editForm', this.editForm)
      this.editDialog = true
    },
    editCancel () {
      // console.log('对话框关闭')
      this.editForm = {}
      this.activeName = '会员资料'
      this.editDialog = false
    },
    editDetermine () {
      // 13531348938
      if (this.activeName === '会员资料' && this.$refs.membersInfo) {
        this.$refs.membersInfo.editDetermine()
      } else if (this.activeName === '商户资料' && this.$refs.MerchantsInfo) {
        this.$refs.MerchantsInfo.editDetermine()
      } else {
        this.editForm = {}
        this.editDialog = false
      }
    },
    editOk () {
      this.editForm = {}
      this.editDialog = false
    },

    // 每页显示条数和当前页码
    handleSizeChange (newSize) {
      this.query.pagesize = newSize
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    },
    handleCurrentChange (newPage) {
      this.query.pageindex = newPage
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    },

    // 判断是否过期
    ifOverdue (time) {
      // console.log('time', time)
      const nowDate = new Date()
      const date = new Date(time).getTime() - nowDate.getTime()

      return date < 0
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table {
  font-size: 12px !important;
}
</style>
