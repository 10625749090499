
<!--
 * @Description: 会员管理
-->
<template>
  <div class="contains">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="流沙" name="1">
        <div class="TableTop">
          <div class="TableTop_tit">流沙行情 {{ date }}</div>
          <div class="TableTop_btn">
            {{ DraftsDate }}
            <el-button v-if="getDraftsBtnFlag" type="primary" plain size="small" @click="draftVisible = true">载入草稿</el-button>
            <el-button v-if="editDraftsBtnFlag" type="primary" size="small" @click="editDrafts()">保存草稿</el-button>
            <el-button type="success" size="small" @click="uploadDialogVisible = true">更新</el-button>
          </div>
        </div>
        <div class="divTable" v-if="activeName === '1'">
          <div class="numIndex">
            <div class="order" v-for="item in row" :key="item">{{ item }}</div>
          </div>
          <div class="ulTables">
            <ul class="ulTable" v-for="(item,index) in allList" :key="item.Id">
              <li class="title" :class="index === current?'red':''">{{ item.Name }}</li>
              <el-input @keyup.native.right="keyRight(parseInt(index/6),(index%6),6)" class="price" :label="item.Name"
                  @keyup.native.down="keyDown(parseInt(index/6),(index%6),6)" :ref="'mobile'+parseInt(index/6)+(index%6)"
                  @keyup.native.up="keyUp(parseInt(index/6),(index%6),6)" v-model="item.Price" size="medium"
                  @keyup.native.left="keyLeft(parseInt(index/6),(index%6),6)" @focus="onFocus(parseInt(index/6),(index%6),6)"
                  @keyup.native.enter="keyDown(parseInt(index/6),(index%6),6)" :placeholder="item.LSPrice"></el-input>
            </ul>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="珠三角" name="2">
        <div class="TableTop">
          <div class="TableTop_tit">珠三角行情 {{ date }}</div>
          <div class="TableTop_btn">
            {{ DraftsDate }}
            <el-button v-if="getDraftsBtnFlag" type="primary" plain size="small" @click="draftVisible = true">载入草稿</el-button>
            <el-button v-if="editDraftsBtnFlag" type="primary" size="small" @click="editDrafts()">保存草稿</el-button>
            <el-button type="success" size="small" @click="uploadDialogVisible = true">更新</el-button>
          </div>
        </div>
        <div class="divTable" v-if="activeName === '2'">
          <div class="numIndex">
            <div class="order" v-for="item in row" :key="item">{{ item }}</div>
          </div>
          <div class="ulTables">
            <ul class="ulTable" v-for="(item,index) in allList" :key="item.Id"
              style="width: 210px">
              <li class="title" :class="index === current?'red':''">{{ item.Name }}</li>
              <el-input @keyup.native.right="keyRight(parseInt(index/5),(index%5),5)" class="price" :label="item.Name"
                  @keyup.native.down="keyDown(parseInt(index/5),(index%5),5)" :ref="'mobile'+parseInt(index/5)+(index%5)"
                  @keyup.native.up="keyUp(parseInt(index/5),(index%5),5)" v-model="item.Price" size="medium"
                  @keyup.native.left="keyLeft(parseInt(index/5),(index%5),5)" @focus="onFocus(parseInt(index/5),(index%5),5)"
                  @keyup.native.enter="keyDown(parseInt(index/5),(index%5),5)" :placeholder="item.ZSJPrice"></el-input>
            </ul>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="深圳" name="3">
        <div class="TableTop">
          <div class="TableTop_tit">深圳行情 {{ date }}</div>
          <div class="TableTop_btn">
            {{ DraftsDate }}
            <el-button v-if="getDraftsBtnFlag" type="primary" plain size="small" @click="draftVisible = true">载入草稿</el-button>
            <el-button v-if="editDraftsBtnFlag" type="primary" size="small" @click="editDrafts()">保存草稿</el-button>
            <el-button type="success" size="small" @click="uploadDialogVisible = true">更新</el-button>
          </div>
        </div>
        <div class="divTable" style="width: 880px" v-if="activeName === '3'">
          <div class="numIndex">
            <div class="order" v-for="item in row" :key="item">{{ item }}</div>
          </div>
          <div class="ulTables">
            <ul class="ulTable" v-for="(item,index) in allList" :key="item.Id">
              <li class="title" :class="index === current?'red':''">{{ item.Name }}</li>
              <el-input @keyup.native.right="keyRight(parseInt(index/4),(index%4),4)" class="price" :label="item.Name"
                  @keyup.native.down="keyDown(parseInt(index/4),(index%4),4)" :ref="'mobile'+parseInt(index/4)+(index%4)"
                  @keyup.native.up="keyUp(parseInt(index/4),(index%4),4)" v-model="item.Price" size="medium"
                  @keyup.native.left="keyLeft(parseInt(index/4),(index%4),4)" @focus="onFocus(parseInt(index/4),(index%4),4)"
                  @keyup.native.enter="keyDown(parseInt(index/4),(index%4),4)" :placeholder="item.SZPrice"></el-input>
            </ul>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-dialog
      title="提示"
      :visible.sync="draftVisible"
      width="30%"
      center>
      <span style="display: block; width: 100%; text-align: center">是否载入草稿</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="draftVisible = false">取 消</el-button>
        <el-button type="primary" @click="getDrafts()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="提示"
      :visible.sync="uploadDialogVisible"
      width="30%"
      center>
      <span style="display: block; width: 100%; text-align: center; color: red;">更新后草稿会清空，请确认更新</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="uploadDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine()">确 定</el-button>
      </span>
    </el-dialog>

    <div class="paixu">
      <div>
        <h4>未排序</h4>
      </div>
      <div class="paixu_btn">
        <el-button type="primary" size="small" @click.stop="iconFlag = !iconFlag">编辑</el-button>
        <el-button v-if="iconFlag" type="success" size="small" @click.stop="sortingSave()">保存</el-button>
        <el-button v-if="iconFlag" type="warning" size="small" @click.stop="sortingCancel()">取消</el-button>
      </div>
    </div>

    <div class="divTable">
      <div class="numIndex">
        <div class="order" v-for="item in (Math.ceil(list.length / 6))" :key="item">{{ item }}</div>
      </div>
      <div class="ulTables">
        <ul class="ulTable" :class="index%6 == 0?'ulTable1':''" v-for="(item,index) in list" :key="index">
          <i v-if="iconFlag" class="el-icon-menu" style="line-height: 24px; cursor: pointer;"
            slot="reference" @click.stop="aa(item)"></i>
          <li class="title">{{ item.Name }}</li>
          <el-input class="price" v-model="item.Price" size="medium" :label="item.Name"
            :placeholder="activeName==='1'?item.LSPrice:(activeName==='2'?item.ZSJPrice:item.SZPrice)"></el-input>
        </ul>
      </div>
      <el-dialog
        title="编辑位置"
        :visible.sync="visible"
        width="400px"
        :close-on-click-modal="false"
        @close="editCancel()">
        <el-form label-width="auto" ref="ruleForm">
          <el-form-item label="名称">
            <el-input v-model="listItem.Name" disabled></el-input>
          </el-form-item>
          <el-form-item label="插入行">
            <el-input v-model="Order" placeholder="请输入插入行的位置"></el-input>
          </el-form-item>
          <el-form-item label="插入列">
            <el-input v-model="Column" placeholder="请输入插入列的位置"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="visible = false">取 消</el-button>
          <el-button type="primary" @click="changeIsOk()">确 定</el-button>
        </span>
      </el-dialog>
    </div>

  </div>
</template>

<script>
var storage = window.localStorage
export default {
  name: 'test',
  data () {
    return {
      // 日期
      date: this.$globalFun.parseTime(new Date().setMonth(new Date().getMonth() - 24), '{y}-{m}-{d}'),
      // date: this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}'),
      DraftsDate: '',

      // 数据列表
      list: [],
      allList: [],
      // 流沙
      lsList: [],
      lsList1: [],
      // 珠三角
      zsjList: [],
      zsjList1: [],
      // 深圳
      szList: [],
      szList1: [],

      loading: true,

      // 输入框焦点位置
      current: 0,

      // 总行列
      row: 0,
      remainder: 0,

      // 排序图标
      iconFlag: false,

      // 更新数据对话框
      uploadDialogVisible: false,
      // 载入草稿对话框
      draftVisible: false,
      // 排序对话框
      visible: false,

      activeName: '1',

      listItem: {},
      Order: 0,
      Column: 0,
      sortingArr: [],

      titleArr: ['流沙', '珠三角', '深圳'],
      titleIndex: 0,

      // 控制保存草稿
      editDraftsBtnFlag: false,
      // 控制载入草稿
      getDraftsBtnFlag: false,

      yesDate: []
    }
  },
  async created () {
    await this.getList()
    this.$refs.mobile00[0].focus()

    console.log(this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}'))

    // this.getDrafts()
    let data = null
    switch (this.activeName) {
      case '1':
        data = JSON.parse(storage.getItem('liusha'))
        break
      case '2':
        data = JSON.parse(storage.getItem('zhusanjiao'))
        break
      case '3':
        data = JSON.parse(storage.getItem('shenzhen'))
        break
    }
    if (data) {
      this.editDraftsBtnFlag = false
      this.getDraftsBtnFlag = true
      this.DraftsDate = this.$globalFun.parseTime(new Date(data.date), '{y}/{m}/{d} {h}:{i}:{s}')
    } else {
      this.editDraftsBtnFlag = true
      this.getDraftsBtnFlag = false
      this.DraftsDate = ''
    }
  },
  methods: {
    // 初始化
    async init () {
      // 清空输入框
      const refList = this.$refs
      for (const obj in refList) {
        if (refList[obj].length > 0) {
          refList[obj][0].clear()
        }
      }

      await this.getList()
      this.$refs.mobile00[0].focus()
    },

    // 点击排序图标
    aa (name) {
      console.log(name.Name, name)
      this.listItem = name
      // this.Order = name.Order
      // this.Column = name.Column
      this.visible = true
    },
    // 保存
    async sortingSave () {
      console.log('保存', this.sortingArr)
      let str = ''
      this.sortingArr.forEach(item => {
        if (str === '') {
          str = item.name + ',' + item.order + ',' + item.column
        } else {
          str = str + '|' + item.name + ',' + item.order + ',' + item.column
        }
      })
      console.log({
        type: (this.activeName - 0),
        priceStr: str
      })

      const { data } = await this.$api.offer.updateSort({
        type: this.activeName,
        priceStr: str
      })
      console.log(data)
    },
    // 取消
    sortingCancel () {
      console.log('取消')
      this.handleClick()
      // switch (this.activeName) {
      //   case '1':
      //     this.allList = this.lsList
      //     this.list = this.lsList1

      //     this.row = Math.ceil(this.allList.length / 6)
      //     this.remainder = this.allList.length % 6
      //     break
      //   case '2':
      //     this.allList = this.zsjList
      //     this.list = this.zsjList1

      //     this.row = Math.ceil(this.allList.length / 5)
      //     this.remainder = this.allList.length % 5
      //     break
      //   case '3':
      //     this.allList = this.szList
      //     this.list = this.szList1

      //     this.row = Math.ceil(this.allList.length / 4)
      //     this.remainder = this.allList.length % 4
      //     break
      // }
    },

    // 切换地区
    async handleClick () {
      // console.log('-----', this.allList)
      this.date = this.$globalFun.parseTime(new Date().setMonth(new Date().getMonth() - 24), '{y}-{m}-{d}')

      const refList = this.$refs
      for (const obj in refList) {
        if (refList[obj].length > 0) {
          refList[obj][0].clear()
        }
      }

      switch (this.activeName) {
        case '1':
          this.allList = this.lsList
          this.list = this.lsList1
          break
        case '2':
          this.allList = this.zsjList
          this.list = this.zsjList1
          break
        case '3':
          this.allList = this.szList
          this.list = this.szList1
          break
      }

      this.row = Math.ceil(this.allList.length / (7 - (this.activeName - 0)))
      this.remainder = this.allList.length % (7 - (this.activeName - 0))

      this.loading = false

      let data = null
      switch (this.activeName) {
        case '1':
          data = JSON.parse(storage.getItem('liusha'))
          break
        case '2':
          data = JSON.parse(storage.getItem('zhusanjiao'))
          break
        case '3':
          data = JSON.parse(storage.getItem('shenzhen'))
          break
      }
      if (data) {
        this.editDraftsBtnFlag = false
        this.getDraftsBtnFlag = true
        this.DraftsDate = this.$globalFun.parseTime(new Date(data.date), '{y}/{m}/{d} {h}:{i}:{s}')
      } else {
        this.editDraftsBtnFlag = true
        this.getDraftsBtnFlag = false
        this.DraftsDate = ''
      }

      this.$nextTick(() => {
        this.$refs.mobile00[0].focus()
        // this.getDrafts()
      })
    },

    // 保存草稿
    async editDrafts () {
      const refList = this.$refs
      console.log(refList)
      const priceObj = {}
      const loading = this.$loading({
        lock: true,
        text: '保存中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      for (const obj in refList) {
        if (refList[obj].length > 0) {
          if (isNaN((refList[obj][0].value - 0)) && refList[obj][0].value) {
            loading.close()
            this.$message.error('请检查数据是否正确')
            this.$refs[obj][0].focus()
            return
          }
          priceObj.type = this.activeName

          let str = ''
          if (refList[obj][0].value) {
            str = refList[obj][0].label + ',' + (refList[obj][0].value - 0)
          }

          if (str) {
            if (!priceObj.content) {
              priceObj.content = str
            } else {
              priceObj.content = priceObj.content + '|' + str
            }
          }
        }
      }

      console.log(priceObj)
      if (!priceObj.content) {
        loading.close()
        this.$message.warning('请输入数据')
        return
      }

      const obj = JSON.stringify({
        date: this.$globalFun.parseTime(new Date(), '{y}/{m}/{d} {h}:{i}:{s}'),
        str: priceObj.content
      })

      switch (this.activeName) {
        case '1':
          storage.setItem('liusha', obj)
          break
        case '2':
          storage.setItem('zhusanjiao', obj)
          break
        case '3':
          storage.setItem('shenzhen', obj)
          break
      }

      this.DraftsDate = this.$globalFun.parseTime(new Date(), '{y}/{m}/{d} {h}:{i}:{s}')

      loading.close()
      this.getDraftsBtnFlag = true
      this.$message.success('保存成功')
    },

    // 载入草稿
    async getDrafts () {
      let data = null
      switch (this.activeName) {
        case '1':
          data = JSON.parse(storage.getItem('liusha'))
          break
        case '2':
          data = JSON.parse(storage.getItem('zhusanjiao'))
          break
        case '3':
          data = JSON.parse(storage.getItem('shenzhen'))
          break
      }
      console.log(data)

      if (!data) {
        this.DraftsDate = ''
        this.draftVisible = false
        this.editDraftsBtnFlag = true
        return
      } else {
        this.DraftsDate = this.$globalFun.parseTime(new Date(data.date), '{y}/{m}/{d} {h}:{i}:{s}')
      }

      const strArr = data.str.split('|')
      console.log(strArr)

      this.allList.forEach(item => {
        strArr.forEach((items, index) => {
          if (items.indexOf(item.Name + ',') === 0) {
            const num = strArr[index].indexOf(',') + 1
            item.Price = strArr[index].slice(num)
          }
        })
      })

      this.draftVisible = false
      this.editDraftsBtnFlag = true
      this.$message({
        message: '载入成功',
        type: 'success',
        duration: 800
      })
    },

    // 更新
    update () {
      this.uploadDialogVisible = true
    },
    // 确定
    async determine () {
      const refList = this.$refs

      const priceObj = {}
      priceObj.Date = this.date
      console.log(priceObj.Date)

      this.uploadDialogVisible = false
      const loading = this.$loading({
        lock: true,
        text: '上传中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      for (const obj in refList) {
        // console.log(refList[obj])
        if (refList[obj].length > 0) {
          if (isNaN((refList[obj][0].value - 0)) && refList[obj][0].value) {
            loading.close()
            this.$message.error('请检查数据是否正确')
            this.$refs[obj][0].focus()
            return
          }
          priceObj.type = this.activeName - 0

          let str = ''
          if (refList[obj][0].value) {
            str = refList[obj][0].label + ',' + (refList[obj][0].value - 0)
          } else {
            str = refList[obj][0].label + ',' + refList[obj][0].$attrs.placeholder
          }

          if (!priceObj.priceStr) {
            priceObj.priceStr = str
          } else {
            priceObj.priceStr = priceObj.priceStr + '|' + str
          }
        }
      }
      console.log(priceObj)
      if (priceObj.priceStr) {
        const data = await this.$api.offer.addData(priceObj)
        console.log('添加结果', data)

        switch (this.activeName) {
          case '1':
            storage.removeItem('liusha')
            break
          case '2':
            storage.removeItem('zhusanjiao')
            break
          case '3':
            storage.removeItem('shenzhen')
            break
        }

        this.$message.success(data.msg)
      } else {
        this.$message.error('请检查数据是否正确')
      }
      loading.close()
      this.init()
    },

    // 排序框取消
    editCancel () {
      this.visible = false
    },

    // 排序框确定
    changeIsOk () {
      const list = []
      const num = 7 - (this.activeName - 0)
      console.log(this.Order, this.Column)

      this.allList.forEach(item => {
        let index = 0
        switch (this.activeName) {
          case '1':
            if (item.Order >= (this.Order - 0) && item.Column === (this.Column - 0)) {
              item.Order += 1
            }
            index = (item.Order - 1) * num + (item.Column - 1)
            break
          case '2':
            if (item.Order2 >= (this.Order - 0) && item.Column2 === (this.Column - 0)) {
              item.Order2 += 1
            }
            index = (item.Order2 - 1) * num + (item.Column2 - 1)
            break
          case '3':
            if (item.Order3 >= (this.Order - 0) && item.Column3 === (this.Column - 0)) {
              item.Order3 += 1
            }
            index = (item.Order3 - 1) * num + (item.Column3 - 1)
            break
        }

        list[index] = item
      })

      let indexs = 0
      switch (this.activeName) {
        case '1':
          this.listItem.Order = this.Order - 0
          this.listItem.Column = this.Column - 0
          indexs = (this.listItem.Order - 1) * num + (this.listItem.Column - 1)
          list[indexs] = this.listItem
          break
        case '2':
          this.listItem.Order2 = this.Order - 0
          this.listItem.Column2 = this.Column - 0
          indexs = (this.listItem.Order2 - 1) * num + (this.listItem.Column2 - 1)
          list[indexs] = this.listItem
          break
        case '3':
          this.listItem.Order3 = this.Order - 0
          this.listItem.Column3 = this.Column - 0
          indexs = (this.listItem.Order3 - 1) * num + (this.listItem.Column3 - 1)
          list[indexs] = this.listItem
          break
      }

      const lists = list.filter(item => {
        return item
      })
      // console.log('lists', lists)

      this.allList = lists
      this.row = Math.ceil(this.allList.length / num)
      this.remainder = this.allList.length % num

      // if (this.list.indexOf(this.listItem) > -1) this.list.splice(this.list.indexOf(this.listItem), 1)

      this.visible = false

      const obj = []
      this.allList.forEach(item => {
        switch (this.activeName) {
          case '1':
            obj.push({
              name: item.Name,
              order: item.Order,
              column: item.Column
            })
            break
          case '2':
            obj.push({
              name: item.Name,
              order: item.Order2,
              column: item.Column2
            })
            break
          case '3':
            obj.push({
              name: item.Name,
              order: item.Order3,
              column: item.Column3
            })
            break
        }
      })

      this.Order = 0
      this.Column = 0
      this.sortingArr = obj
      console.log(this.sortingArr)
      // this.iconFlag = false
    },

    // 获取列表
    async getList (place) {
      const { data: res } = await this.$api.product.getList({
        pageindex: 1,
        pagesize: 1000
      })
      // console.log('res', res)

      const date = this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}')
      const { data } = await this.$api.offer.listServer({
        pageindex: 1,
        pagesize: 600,
        category1: '全部',
        date: date
      })
      // console.log('data', data)

      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < res.list.length; j++) {
          if (data[i].Title === res.list[j].Name) {
            res.list[j].LSPrice = data[i].RetrieveValue
            res.list[j].ZSJPrice = data[i].ZSJRetrieveValue
            res.list[j].SZPrice = data[i].SZRetrieveValue
            break
          }
        }
      }

      console.log('res', res.list)

      const arr = []
      const zsjArr = []
      const szArr = []
      this.lsList1 = []
      this.zsjList1 = []
      this.szList1 = []
      res.list.forEach(item => {
        const order = item.Order
        const column = item.Column
        const index = (order - 1) * 6 + (column - 1)
        if (index >= 0) {
          arr[index] = item
        } else {
          if (!item.Order2) {
            this.lsList1.push(item)
          }
        }

        const order2 = item.Order2
        const column2 = item.Column2
        const index2 = (order2 - 1) * 5 + (column2 - 1)
        if (index2 >= 0) {
          zsjArr[index2] = item
        } else {
          if (!item.Order) {
            this.zsjList1.push(item)
          }
        }

        const order3 = item.Order3
        const column3 = item.Column3
        const index3 = (order3 - 1) * 4 + (column3 - 1)
        if (index3 >= 0) {
          szArr[index3] = item
        } else {
          if (!item.Order && !item.Order2) {
            this.szList1.push(item)
          }
        }
      })

      this.lsList = arr.filter(item => {
        return item
      })
      this.zsjList = zsjArr.filter(item => {
        return item
      })
      this.szList = szArr.filter(item => {
        return item
      })

      switch (this.activeName) {
        case '1':
          this.allList = this.lsList
          this.list = this.lsList1
          break
        case '2':
          this.allList = this.zsjList
          this.list = this.zsjList1
          break
        case '3':
          this.allList = this.szList
          this.list = this.szList1
          break
      }

      this.loading = false
      this.row = Math.ceil(this.allList.length / (7 - (this.activeName - 0)))
      this.remainder = this.allList.length % (7 - (this.activeName - 0))
    },
    // test
    async getLists (place) {
      const { data: res } = await this.$api.product.getList({
        pageindex: 1,
        pagesize: 800
      })

      const arr = []
      const noSortArr = []

      res.list.forEach(item => {
        let index = null
        switch (this.titleIndex) {
          case 0:
            index = (item.Order - 1) * 6 + (item.Column - 1)
            if (index >= 0) {
              arr[index] = item
            } else {
              if (!item.Order2) {
                noSortArr.push(item)
              }
            }
            break
          case 1:
            index = (item.Order2 - 1) * 5 + (item.Column2 - 1)
            if (index >= 0) {
              arr[index] = item
            } else {
              if (!item.Order) {
                noSortArr.push(item)
              }
            }
            break
          case 2:
            index = (item.Order3 - 1) * 4 + (item.Column3 - 1)
            if (index >= 0) {
              arr[index] = item
            } else {
              if (!item.Order && !item.Order2) {
                noSortArr.push(item)
              }
            }
            break
        }
      })

      // const allList = arr.filter(item => {
      //   return item
      // })
      // console.log(allList, noSortArr)
      // console.log(this.allList.length, this.list.length)

      // this.allList = allList
      // this.list = noSortArr

      // this.loading = false
      // this.row = Math.ceil(this.allList.length / (6 - this.titleIndex))
      // this.remainder = this.allList.length % (6 - this.titleIndex)
    },

    // 输入框获得焦点
    onFocus (order, column, num) {
      // console.log(order, column)
      this.current = order * num + column
    },
    // 上
    keyUp (order, column, num) {
      let nextRef = 'mobile' + (order - 1) + column

      if (!this.$refs[nextRef] || this.$refs[nextRef].length === 0) {
        if (this.remainder > 0 && column > this.remainder) {
          nextRef = 'mobile' + (this.row - 2) + (column - 1)

          if (!this.$refs[nextRef] || this.$refs[nextRef].length === 0) return

          this.current = (this.row - 2) * num + (column - 1)
        } else {
          nextRef = 'mobile' + (this.row - 1) + (column - 1)

          if (!this.$refs[nextRef]) return

          this.current = (this.row - 1) * num + (column - 1)
        }
      } else {
        this.current = (order - 1) * num + column
      }
      this.$refs[nextRef][0].focus()
    },
    // 下
    keyDown (order, column, num) {
      let nextRef = 'mobile' + (order + 1) + column

      if (!this.$refs[nextRef] || this.$refs[nextRef].length === 0) {
        nextRef = 'mobile0' + (column + 1)

        if (!this.$refs[nextRef] || this.$refs[nextRef].length === 0) return

        this.current = (column + 1)
      } else {
        this.current = (order + 1) * num + column
      }

      this.$refs[nextRef][0].focus()
    },
    // 左
    keyLeft (order, column, num) {
      let nextRef = 'mobile' + order + (column - 1)
      // console.log(order, column)

      if (!this.$refs[nextRef] || this.$refs[nextRef].length === 0) {
        nextRef = 'mobile' + (order - 1) + (num - 1).toString()

        if (!this.$refs[nextRef] || this.$refs[nextRef].length === 0) return

        this.current = (order - 1) * num + (num - 1)
        // console.log(this.current)
      } else {
        this.current = order * num + (column - 1)
        // console.log(this.current)
      }
      this.$refs[nextRef][0].focus()
    },
    // 右
    keyRight (order, column, num) {
      let nextRef = 'mobile' + order + (column + 1)
      if (!this.$refs[nextRef] || this.$refs[nextRef].length === 0) {
        nextRef = 'mobile' + (order + 1) + '0'

        if (!this.$refs[nextRef] || this.$refs[nextRef].length === 0) return

        this.current = (order + 1) * num
        // console.log(this.current)
      } else {
        this.current = order * num + (column + 1)
        // console.log(this.current)
      }
      this.$refs[nextRef][0].focus()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .price>input.el-input__inner {
    color: #e33ae6 !important;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
}
::v-deep .price>input::-webkit-outer-spin-button,
::v-deep .price>input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep .price>input[type="number"]{
    -moz-appearance:textfield;
}
.contains {
  width: 1280px;
  margin: 0 auto;
}
.TableTop {
  display: flex;
  margin: 10px auto 0;
  .TableTop_tit {
    width: 200px;
  }
  .TableTop_btn {
    flex: 2;
    margin-left: 300px;
  }
}
.paixu {
  margin-top: 12px;
  display: flex;
  .paixu_btn {
    margin-left: 20px;
    margin-top: 12px;
  }
}
.divTable {
  width: 1280px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  .numIndex {
    width: 40px;
    .order {
      text-align: center;
      color: blue;
      height: 24px;
      line-height: 24px;
    }
  }
  .ulTables {
    flex: 2;
    display: flex;
    flex-wrap: wrap;
  }
  .ulTable {
    width: 200px;
    height: 24px;
    line-height: 24px;
    display: flex;
    li.red:hover {
      overflow: inherit;
    }
    .title {
      width: 43%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-left: 5px;
      font-size: 13px;
    }
    .price {
      width: 35%;
    }
    .red {
      width: 45%;
      color: red;
      font-weight: bold;
      font-size: 14px;
    }
    .red:hover {
      overflow: none;
    }
  }
}
.titltTab {
  width: 100%;
  border-bottom: 2px solid #cccccc;
  display: flex;
  text-align: center;
  font-size: 14px;
  .titltTab_item {
    margin: 0 20px;
    cursor: pointer;
  }
  .titltTab_item:hover {
    color: #409EFF;
  }
}
.blue {
  color: #409EFF;
  padding-bottom: 10px;
  border-bottom: 2px solid #409EFF;
}
</style>
