<!--
 * @Description: 会员管理
-->
<template>
  <div>
    <div class="TableTop">
      <!-- 搜索 -->
      <div class="TableSearch">
        <el-form :model="query" @submit.native.prevent>

          <el-form-item label="" class="form-item-b0">
            <el-button :type="checkBtnCurrent == 1?'primary':''" size="small" @click="getList('place', 1)">待审核</el-button>
            <el-button :type="checkBtnCurrent == 2?'success':''" size="small" @click="getList('place', 2)">已通过</el-button>
            <el-button :type="checkBtnCurrent == 0?'warning':''" size="small" @click="getList('place', 0)">已退回</el-button>
          </el-form-item>

          <el-form-item label="手机号码：" class="form-item-b0">
            <el-input v-model="query.search" placeholder="请输入手机号码" size="small" clearable></el-input>
          </el-form-item>

          <el-form-item label="上传时间：" class="form-item-b0">
            <el-date-picker v-model="beginDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>

          <el-form-item label="总条数" class="form-item-b0">
            <span style="font-size: 40px;">{{ total }}</span>
          </el-form-item>

        </el-form>

        <div class="TableSearch_btn">
          <el-button type="primary" size="small" @click="getList('place')">筛选</el-button>
          <el-button size="small" @click="init()">重置</el-button>
        </div>

      </div>
    </div>
    <!-- 表格 -->
    <el-table ref="TableListRef" class="TableList" border :data="list" v-loading="loading">

      <el-table-column prop="UserId" label="用户Id" align="center"></el-table-column>

      <el-table-column prop="LoginName" label="手机号码" align="center">
        <template slot-scope="scope">
          <div style="cursor: pointer;" @click.stop="query.search = scope.row.LoginName">
            {{ scope.row.LoginName }}
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="StoreName" label="商家名称" align="center">
        <template slot-scope="scope">
          <div style="cursor: pointer;" @click.stop="query.search = scope.row.StoreName">
            {{ scope.row.StoreName }}
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="Title" label="相片描述" align="center"></el-table-column>

      <el-table-column prop="ImgUrl" label="相片" align="center">
        <template slot-scope="scope">
          <el-image style="width: 100px; height: 100px" :src="scope.row.ImgUrl" :preview-src-list="[scope.row.ImgUrl]" fit="contain"></el-image>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" width="160" fixed="right">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onEditInfo(scope.row)">
            审 核
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="fenye">
      <el-pagination class="TablePagination" background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.pageindex" :page-sizes="[20, 40, 60, 100]" :page-size="query.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </div>

    <!-- 商户管理 -->
    <el-dialog :visible.sync="editPhoneDialog" class="FormDialogBox" width="800px" title="商户管理" top="5vh" :close-on-click-modal="false" @close="editPhoneCancel()">

      <div class="tabs">
        <el-tabs v-model="activeName" stretch @tab-click="handleClick">
          <el-tab-pane label="相册详情" name="相册详情"></el-tab-pane>
          <el-tab-pane label="商户资料" name="商户资料"></el-tab-pane>
        </el-tabs>
      </div>

      <template v-if="activeName === '相册详情'">
        <el-form label-width="auto" :model="editForm" :rules="rules" ref="ruleForm">

          <el-form-item label="Title">
            <el-input type="textarea" v-model="editForm.Title" placeholder="相片描述"></el-input>
          </el-form-item>

          <el-form-item label="图片审核">
            <div class="auditImg">
              <div class="block">
                <el-image style="width: 100px;" :src="editForm.ImgUrl" :preview-src-list="[editForm.ImgUrl]" alt=""></el-image>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="审核状态">
            <el-input v-model="editForm.Status" disabled></el-input>
            <el-radio v-model="editForm.Status" :label="1">正在审核</el-radio>
            <el-radio v-model="editForm.Status" :label="2">审核通过</el-radio>
            <el-radio v-model="editForm.Status" :label="0">审核退回</el-radio>
          </el-form-item>
          <el-form-item label="审核内容">
            <el-input type="textarea" v-model="editForm.AuditContent"></el-input>
          </el-form-item>
        </el-form>
      </template>

      <template v-else>
        <div class="merchants_info">
          <div class="merchants_info_item">
            <div style="width: 80px; margin-right: 20px; color: #909399;">登录手机号</div>
            {{ merchantsInfo.LoginName }}(VIP{{ merchantsInfo.VipTime | remainingDays }}天)
          </div>

          <div class="merchants_info_item">
            <div style="width: 80px; margin-right: 20px; color: #909399;">商家手机号</div>
            {{ merchantsInfo.StorePhone || '暂无认证' }}
          </div>

          <div class="merchants_info_item">
            <div style="width: 80px; margin-right: 20px; color: #909399;">商铺名称</div>
            {{ merchantsInfo.StoreName || '暂无认证' }}
          </div>

          <div class="merchants_info_item">
            <div style="width: 80px; margin-right: 20px; color: #909399;">认证名称</div>
            {{ merchantsInfo.Name || '暂无认证' }}
          </div>

          <div class="merchants_info_item">
            <div style="width: 80px; margin-right: 20px; color: #909399;">商铺地区</div>
            {{ merchantsInfo.Province + merchantsInfo.City + merchantsInfo.Area || '暂无认证' }}
          </div>

          <div class="merchants_info_item">
            <div style="width: 80px; margin-right: 20px; color: #909399;">商铺地址</div>
            {{ merchantsInfo.StoreAddress || '暂无认证' }}
          </div>
        </div>
      </template>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editPhoneDialog = false">取 消</el-button>
        <el-button type="warning" @click="editStoreDetermine()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'membersIndex',
  data () {
    return {
      list: [],
      loading: true,
      beginDate: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      query: {
        pageindex: 1,
        search: '',
        status: null
      },
      total: 0, // 总条数
      // 修改手机号
      editPhoneDialog: false,
      releaseDialog: false,
      editForm: {},
      rules: {},

      checkBtnCurrent: 2,

      activeName: '相册详情',
      merchantsInfo: {}
    }
  },
  created () {
    this.getList('place', 1)
  },
  methods: {
    // 初始化
    init () {
      this.query.pageindex = 1
      this.query.search = ''
      this.beginDate = []
      this.query.status = null

      this.getList('place')
    },

    // 获取列表
    async getList (place, status) {
      // 重置页数
      if (place) {
        this.query.pageindex = 1
        // 滚动条回到顶部
        this.$nextTick(() => {
          this.$refs.TableListRef.bodyWrapper.scrollTop = 0
        })
      }

      if (!this.beginDate || !this.beginDate.length) {
        delete this.query.begintime
        delete this.query.endtime
      } else {
        this.query.begintime = this.beginDate[0]
        this.query.endtime = this.beginDate[1]
      }

      if (status || status === 0) {
        this.query.status = status
        this.checkBtnCurrent = status
      }
      console.log('this.query', this.query)

      const { data: res } = await this.$api.userImage.getList(this.query)
      console.log('获取商户审核列表', res)

      this.loading = false
      this.list = res
      this.total = res.length
    },
    // 每页显示条数和当前页码
    handleSizeChange (newSize) {
      this.query.pagesize = newSize
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    },
    handleCurrentChange (newPage) {
      this.query.pageindex = newPage
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    },

    handleClick (e) {},

    // 审核商户信息
    async onEditInfo (item) {
      const form = this.$globalFun.deepClone(item)
      form.Phone = form.LoginName
      this.editForm = form
      console.log('this.editForm', this.editForm)
      this.editPhoneDialog = true

      const { data: res } = await this.$api.members.getStoreList({
        search: this.editForm.LoginName
      })
      this.merchantsInfo = res.list[0]
      console.log('获取商户资料', this.merchantsInfo)
    },
    editPhoneCancel () {
      this.editForm = {}
      this.merchantsInfo = {}
      this.activeName = '相册详情'
    },

    async editStoreDetermine () {
      console.log(this.editForm)
      const { data } = await this.$api.userImage.audit({
        id: this.editForm.Id,
        status: this.editForm.Status,
        content: this.editForm.AuditContent
      })
      console.log('审核结果', data)
      this.editForm = {}
      this.editPhoneDialog = false

      this.getList()
    },

    // 判断是否过期
    ifOverdue (time) {
      // console.log('time', time)
      const nowDate = new Date()
      const date = new Date(time).getTime() - nowDate.getTime()

      return date < 0
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  background-color: #fff;
  position: sticky;
  top: -30px;
  z-index: 9;
}

.merchants_info {
  // min-height: 440px;

  .merchants_info_item {
    padding: 10px;
    color: #606266;
    font-size: 14px;
    line-height: 40px;
    font-weight: bold;
    display: flex;
  }
}
</style>
