/*
 * @Author: 云逸 1832583130@qq.com
 * @Date: 2023-09-21 18:25:31
 * @LastEditors: 云逸
 * @LastEditTime: 2024-03-20 15:10:04
 * @Description:
 */
const regionArr = [
  {
    name: 'lsList',
    list: [],
    order: 'Order',
    column: 'Column',
    type: '1',
    label: '流沙',
    price: 'LSPrice'
  },
  {
    name: 'ls2List',
    list: [],
    order: 'Order5',
    column: 'Column5',
    otherOrder: 'Order',
    type: '1',
    label: '流沙B',
    price: 'LSPrice'
  },
  {
    name: 'zsjList',
    list: [],
    order: 'Order2',
    column: 'Column2',
    type: '2',
    label: '珠三角',
    price: 'ZSJPrice'
  },
  {
    name: 'szList',
    list: [],
    order: 'Order3',
    column: 'Column3',
    type: '3',
    label: '深圳A',
    price: 'SZPrice'
  },
  {
    name: 'sz2List',
    list: [],
    order: 'Order4',
    column: 'Column4',
    type: '4',
    label: '深圳B',
    price: 'SZPrice2'
  },
  {
    name: 'hnList',
    list: [],
    order: 'Order6',
    column: 'Column6',
    type: '5',
    label: '湖南',
    price: 'HNPrice'
  }
]

const a = /[^0-9\\.]/g
console.log(a)

export default regionArr
