import http from '../request.js'

export default {
  list: (data) => {
    return http({
      url: '/api/integral_product/list',
      data
    })
  },

  add: (data) => {
    return http({
      url: '/api/integral_product/save',
      data
    })
  },

  delete: (data) => {
    return http({
      url: '/api/integral_product/delete',
      data
    })
  }
}
