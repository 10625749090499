<!--
 * @Description: 充值流水管理
-->
<template>
  <div>
    <div class="TableTop">
      <!-- 搜索 -->
      <div class="TableSearch">
        <el-form :model="query" @submit.native.prevent>
          <el-form-item label="手机号码：" class="form-item-b0">
            <el-input v-model="query.search" placeholder="请输入手机号码" size="small" clearable></el-input>
          </el-form-item>

          <el-form-item label="支付时间：" class="form-item-b0">
            <el-date-picker
              v-model="date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="状态：" class="form-item-b0">
            <el-select v-model="query.status" placeholder="请选择状态">
              <el-option label="支付成功" :value="1"></el-option>
              <el-option label="支付失败" :value="0"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="会员：" class="form-item-b0">
            <el-select v-model="query.isVipOut" placeholder="请选择状态">
              <el-option label="全部" :value="1"></el-option>
              <el-option label="过期" :value="false"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="近期" class="form-item-b0">
            <span :class="current === 4?'blue':''" style="margin-left: 20px; cursor: pointer;" @click="getList('place', 1)">昨天</span>
            <span :class="current === 5?'blue':''" style="margin-left: 20px; cursor: pointer;" @click="getList('place', 0)">今天</span>
            <span :class="current === 1?'blue':''" style="margin: 0 20px; cursor: pointer;" @click="getList('place', 7)">7天</span>
            <!-- <span :class="current === 2?'blue':''" style="margin: 0 20px; cursor: pointer;" @click="getList('place', 10)">10天</span> -->
            <span :class="current === 3?'blue':''" style="cursor: pointer;" @click="getList('place', 30)">30天</span>
          </el-form-item>

        </el-form>
        <div class="TableSearch_btn">
          <el-button type="primary" size="small" @click="getList('place')">筛选</el-button>
          <el-button size="small" @click="init()">重置</el-button>
        </div>
      </div>
    </div>
    <!-- 表格 -->
    <el-table ref="TableListRef" class="TableList" fit border :data="list" v-loading="loading">
      <el-table-column type="expand">
        <template slot-scope="scope">
          <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item label="订单编号">
              <span>{{ scope.row.OrderNo }}</span>
            </el-form-item>
            <el-form-item label="手机号码">
              <span>{{ scope.row.LoginName }}</span>
            </el-form-item>
            <el-form-item label="地址">
              <span>{{ (scope.row.Province || '') + (scope.row.City || '') + (scope.row.Area || '') }}</span>
            </el-form-item>
            <el-form-item label="会员套餐">
              <span>{{ scope.row.Remark }}</span>
            </el-form-item>
            <el-form-item label="送积分">
              <span>{{ scope.row.GiveIntegral }}</span>
            </el-form-item>
            <el-form-item label="会员">
              <template>
                <span v-if="ifOverdue(scope.row.VipTime)" style="color: #f00;">VIP已过期</span>
                <span v-else>VIP {{ scope.row.VipTime | remainingDays }}天</span>
              </template>
              <span>({{ new Date(scope.row.VipTime) | parseTime('{y}-{m}-{d} {h}:{i}:{m}') }})</span>
            </el-form-item>
              <el-form-item label="创建时间">
              <span>{{ new Date(scope.row.CreateTime) | parseTime }}</span>
            </el-form-item>
            <el-form-item label="支付日期">
              <span>{{ new Date(scope.row.CreateDate) | parseTime }}</span>
            </el-form-item>
          </el-form>

        </template>
      </el-table-column>

      <!-- <el-table-column prop="OrderNo" label="订单编号" align="center" width="200"></el-table-column> -->

      <el-table-column prop="LoginName" label="手机号码" align="center" width="100">
        <template slot-scope="scope">
          <div style="cursor: pointer;" @click.stop="query.search = scope.row.LoginName">
            {{ scope.row.LoginName }}
          </div>
        </template>
      </el-table-column>

      <el-table-column label="地址" align="center" width="160">
        <template slot-scope="scope">
          {{ (scope.row.Province || '') + (scope.row.City || '') + (scope.row.Area || '') }}
        </template>
      </el-table-column>

      <el-table-column prop="CreateTime" label="创建时间" align="center">
        <template slot-scope="scope">
          {{ new Date(scope.row.CreateTime) | parseTime('{y}-{m}-{d}') }}
        </template>
      </el-table-column>

      <!-- <el-table-column prop="Remark" label="会员套餐" align="center"></el-table-column> -->

      <el-table-column prop="ParentId" label="上级Id" align="center"></el-table-column>

      <el-table-column prop="Price" label="充值金额" align="center">
        <template slot-scope="scope">
          {{ scope.row.Price }}
        </template>
      </el-table-column>

      <!-- <el-table-column prop="GiveIntegral" label="送积分" align="center"></el-table-column> -->

      <el-table-column prop="VipTime" label="会员" align="center">
        <template slot-scope="scope">
          <template>
            <span v-if="ifOverdue(scope.row.VipTime)" style="color: #f00;">VIP已过期</span>
            <span v-else>VIP {{ scope.row.VipTime | remainingDays }}天</span>
          </template>
          <!-- ({{ new Date(scope.row.VipTime) | parseTime('{y}/{m}/{d}') }}) -->
        </template>
      </el-table-column>

      <el-table-column prop="CreateDate" label="支付日期" align="center">
        <template slot-scope="scope">
          {{ new Date(scope.row.CreateDate) | parseTime('{y}-{m}-{d}') }}
        </template>
      </el-table-column>

      <el-table-column prop="Status" label="状态" align="center" width="160">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.Status === 1" type="success">成功</el-tag>
          <el-tag v-else-if="scope.row.Status === 2" type="info">已退款</el-tag>
          <el-tag v-else type="danger">未支付</el-tag>
          <el-button type="primary" style="margin-left: 10px; margin-top: 2px;" size="small"
            @click="onEditInfo(scope.row)" v-if="scope.row.Status === 1">
            退款
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="fenye">
      <el-pagination
        class="TablePagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageindex"
        :page-sizes="[20, 40, 60, 100]"
        :page-size="query.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <el-dialog
      title="提示"
      :visible.sync="draftVisible"
      width="30%"
      center>
      <span style="display: block; width: 100%; text-align: center">是否退款</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="getDrafts()">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'topUpWaterIndex',
  data () {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      list: [],
      loading: true,
      date: [this.$globalFun.parseTime(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 7), '{y}-{m}-{d}'),
        this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}')],
      query: {
        pageindex: 1,
        pagesize: 20,
        search: '',
        status: null,
        isVipOut: null
      },
      total: 0, // 总条数

      current: 1,

      id: null,
      draftVisible: false
    }
  },
  created () {
    this.getList('place', 7)
  },
  methods: {
    // 初始化
    init () {
      this.query.search = ''
      this.query.status = null
      this.date = []

      this.getList('place')
    },

    // 获取列表
    async getList (place, day) {
      // 重置页数
      if (place) {
        this.query.pageindex = 1
        // 滚动条回到顶部
        this.$nextTick(() => {
          this.$refs.TableListRef.bodyWrapper.scrollTop = 0
        })
      }

      if (!this.date || !this.date.length) {
        delete this.query.startdate
        delete this.query.enddate
      } else {
        this.query.startdate = this.date[0]
        this.query.enddate = this.date[1]
      }

      if (day || day === 0) {
        this.query.enddate = this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}')
        this.query.startdate = this.$globalFun.parseTime(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * day), '{y}-{m}-{d}')
        if (day === 0) this.current = 5
        if (day === 1) {
          this.current = 4
          this.query.enddate = this.query.startdate
        }
        if (day === 7) this.current = 1
        if (day === 10) this.current = 2
        if (day === 30) this.current = 3
      } else {
        this.current = 0
      }

      if (this.query.isVipOut || this.query.isVipOut === null) {
        this.query.isVipOut = null
      } else {
        this.query.isVipOut = false
      }

      const { data: res } = await this.$api.members.getTopUpWaterList(this.query)
      console.log('获取列表', res)
      console.log('this.query', this.query)
      this.loading = false
      this.list = res.list
      this.total = res.count
    },

    async onEditInfo (item) {
      const form = this.$globalFun.deepClone(item)
      console.log(form)

      this.id = form.Id

      this.draftVisible = true
    },

    cancel () {
      this.draftVisible = false
      this.id = null
    },

    async getDrafts () {
      const { data } = await this.$api.members.refund({
        id: this.id
      })
      console.log('退款', data)

      this.getList()
      this.draftVisible = false
    },

    // 每页显示条数和当前页码
    handleSizeChange (newSize) {
      this.query.pagesize = newSize
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    },
    handleCurrentChange (newPage) {
      this.query.pageindex = newPage
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    },
    // 判断是否过期
    ifOverdue (time) {
      // console.log('time', time)
      const nowDate = new Date()
      const date = new Date(time).getTime() - nowDate.getTime()

      return date < 0
    }
  }
}
</script>

<style lang="scss" scoped>
 .demo-table-expand {
    font-size: 0;
    width: 100%;
    padding: 0 20px;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
  </style>
